import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import * as SvgList from "../utils/svg.js";
import { SidebarItems, userTypes } from "../utils/constants";
import { connect } from "react-redux";
import * as actionCreators from "../modules/actions";
import NotificationBadge from "react-notification-badge";

const images = require.context("../assests/images", true);

const isLoggedAndViewOnly = (propsObj) => {
  const data = JSON.parse(sessionStorage.getItem("userToken"));
  return data && propsObj.currentClientType !== userTypes.ADMIN ? true : false;
};

const sidebar_nav_items = [
  {
    name: SidebarItems.SETTINGS,
    linkTo: "/settings",
    icon: "SettingsIcon",
  },
  {
    name: SidebarItems.DASHBOARD,
    linkTo: "/dashboard",
    icon: "DashboardIcon",
  },
  {
    name: SidebarItems.NOTIFICATIONS,
    linkTo: "/notifications",
    icon: "NotificationIcon",
    iconStyle: { width: "24px", height: "24px" },
  },
  {
    name: SidebarItems.EVENTS,
    linkTo: "/events",
    icon: "EventsIcon",
  },
  {
    name: SidebarItems.PROMOS,
    linkTo: "/promos",
    icon: "PromosIcon"
  },
  // {
  //   name: SidebarItems.INSHIGHTS,
  //   linkTo: "/insights",
  //   icon: "InsightsIcon",
  //   disabled: true
  // },
  {
    name: SidebarItems.SOCIALMEDIAPOSTS,
    linkTo: "/socialMediaPosts",
    icon: "SchedulerIcon",
    // disabled: true
  },
  {
    name: SidebarItems.RESERVATIONS,
    linkTo: "/reservations",
    icon: "ReservationIcon",
    iconStyle: { width: "24px", height: "24px" },
  },
];

function Sidebar(props) {
  const [toggleSmallSidebar, setToggleSmallSidebar] = useState(true);

  useEffect(() => {
    if (props.currentClientType === null) {
      props.handleClientLoaded();
    }
  });

  const onToggleNotifications = (event) => {
    event.preventDefault();
    props.onClickNotifications();
  };

  const onCloseNotifications = () => {
    props.onClickNotifications(false);
  };

  const handleToggleSmallSidebar = () => {
    setToggleSmallSidebar(!toggleSmallSidebar);
  };

  return (
    <div 
      className={`text--navy fs-caption1 ${
        !toggleSmallSidebar ? 'small-sidebar' : 'large-sidebar'
      }`}
    >
      <div className="logo">
        {!toggleSmallSidebar ? (
          <img
            className="mb-4"
            src={images("./img/logo-color-horizontal.svg")}
            alt="brand logo"
          />
        ) : (
          <img
            className="mb-4"
            src={images("./img/logo-without-text.svg")}
            alt="brand logo"
          />
        )}
      </div>
      <ul className="">
        {sidebar_nav_items.map((item, i) => {
          const ParentElm =
            (isLoggedAndViewOnly(props) &&
              item.name !== SidebarItems.DASHBOARD) ||
            item.disabled
              ? "div"
              : NavLink;
          const SvgIcon = SvgList[item.icon];
          return (
            <li key={i}>
              <ParentElm
                to={item.linkTo}
                className={`d-flex flex-column justify-content-center ${item.name.toLowerCase()}`}
                activeclassname="selectedElement"
                onClick={
                  item.name === SidebarItems.NOTIFICATIONS
                    ? onToggleNotifications
                    : onCloseNotifications
                }
              >
                <div className="d-flex justify-content-center ">
                  {item.name === SidebarItems.NOTIFICATIONS ? (
                    <div style={{ position: "relative", height: "100%" }}>
                      <NotificationBadge
                        count={props.unseenNotifications}
                        style={{ right: "-10px" }}
                      />
                      <SvgIcon
                        alt={item.name}
                        className=""
                        style={item.iconStyle}
                      />
                    </div>
                  ) : (
                    <SvgIcon
                      alt={item.name}
                      className=""
                      style={item.iconStyle}
                    />
                  )}
                </div>
                {!toggleSmallSidebar && (
                  <p className="m-auto p-2">{item.name}</p>
                )}
              </ParentElm>
            </li>
          );
        })}
      </ul>
      {!toggleSmallSidebar ? (
        <img
          onClick={() => handleToggleSmallSidebar()}
          src={images("./img/back-arrow.svg")}
          alt="brand logo"
          width={16}
        />
      ) : (
        <img
          onClick={() => handleToggleSmallSidebar()}
          src={images("./img/forward-arrow.svg")}
          alt="brand logo"
          width={16}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentClientType: state.currentClientType,
    notifications: state.notifications,
    unseenNotifications: state.unseenNotifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleClientLoaded: (params) => {
      dispatch(actionCreators.handleClientLoaded(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
