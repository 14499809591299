import React, { useState, useEffect } from "react";
import CustomInputText from "../../components/CustomInput";
import "react-datepicker/dist/react-datepicker.css";
import * as api from "../../utils/api";
import axios from "axios";

const images = require.context("../../assests/images", true);

const CompetitionsFilter = props => {

    const [competitions, setCompetitions] = useState({tagFilters: []});
  
    const [filterText, setFilterText] = useState("");

    useEffect(() => {      
      async function getFavCompetitions() {
        let res = await axios.get("/favorite_competitions");
        setCompetitions({tagFilters: res.data.favorite_competitions});
      }     
      getFavCompetitions();
    }, []);  
  
    const updateCompetitions = (competitionFilter, crudType) => {
      api.endpoints.updateCompetitions(competitionFilter, crudType, res =>
        setCompetitions({tagFilters: res})          
      );
    };
  
    const addCompetition = (competitionFilterName) => {
      if (!competitions.tagFilters.some(e => e.competition_name === competitionFilterName)) {
        updateCompetitions(competitionFilterName, "add");
        setCompetitions(
            { tagFilters: arrayAdd(competitions.tagFilters, filterText)}
        );
        setFilterText('');
      } else {
        setFilterText('');
      }
    };

    const deleteCompetitions = (competition) => {
      if (competitions.tagFilters.some(e => e.competition_name === competition.competition_name)) {
        updateCompetitions(competition.id, "delete");
        setCompetitions(
          { tagFilters: arrayRemove(competitions.tagFilters, competition.competition_name)}
        );
      }
    };

    const arrayRemove = (arr, value) => {
      return arr.filter(ele => ele !== value);
    };

    const arrayAdd = (arr, value) => {
      if (!arr.includes(value)) {
        arr.push(value);
      }
      return arr;
    };

    const onSearchInput = event => {
      setFilterText(event);
    };

    const onSearchKeyDown = event => {
      if (event.keyCode === 13) onSearchClick();
    };

    const onSearchClick = () => {       
      if (filterText) addCompetition(filterText);
    };

    const resetFilters = () => {
      setCompetitions({ tagFilters: [] });
      setFilterText('');
    };

    const Button = props => (
      <button
          className="btn p-0"
          onClick={() => props.onClick(props.wordId)}
          title={props.title}
      >
        <img src={images(props.imagePath)} alt={props.imageAlt} />
      </button>
    )

    const renderCompetitionFilters = () => {
      return competitions.tagFilters.map((competition, index) => {
        return(
          <li
          className="bg-white  mb-3 channel-list__border element--clickable highlight_on_hover"
          key={"_usrFltr" + index}
          >
            <div className="d-flex  p-2 align-items-center ">
              <div className="m-auto ">
                  <h3 className="channel-names">{competition.competition_name}</h3>
              </div>
              <Button
                  wordId={index}
                  onClick={() => deleteCompetitions(competition)}
                  imagePath='./img/events/remove.svg'
                  imageAlt="Delete"
                  title="Delete"
              />
            </div>         
          </li>
        )
      });
    }
    
    return (
      <div className="pt-2 panel-sticky bg--gray1">
        <div className="d-flex justify-content-between mt-2 mb-2 filters">
          <CustomInputText
              onChange={e => onSearchInput(e.target.value)}
              keyPress={onSearchKeyDown}
              value={filterText}
              onBtnClick={onSearchClick}
              placeholder="Add a filter word for competitions"
          />
          {/* <u className="element--clickable" onClick={resetFilters}>
              Reset Filters
          </u> */}
        </div>
        <div>
          <ul>{renderCompetitionFilters()}</ul>
        </div>
      </div>
    );
  };
  
  export default CompetitionsFilter;
  
