import React from "react";

const images = require.context("../../assests/images/img/settings/", true);

const SettingHeader = props => (
  <React.Fragment>
    <div className="pt-4 pb-2 pl-2 d-flex font--size--base text--navy align-items-center">
      <img
        className="mr-2 mb-2"
        src={images(props.image)}
        alt={props.imageAlt}
      />
      <h3 className="fs-h3 text--shadenavy">{props.title}</h3>
    </div>
    <div className="border-bottom  ml-2 mr-2 " />
  </React.Fragment>
);

export default SettingHeader;