import React from "react";

const images = require.context(
  "../../../../../assests/images/img/channels/48pxIcons/",
  true
);

const RenderChannel = props => {
  return (
    <React.Fragment>
      <div
        onClick={props.showChannelsPopup}
        className={`channel-item ${props.moreStationsCount === 0 &&
          "channel-item--move-left"} m-1 element--clickable justify-content-center ${props.dashboardtitle &&
          "channel-item--noimg"}`}
        key={props.stationName + props.name}
      >
        <div className="channel-item__logo justify-content-center align-items-center rounded">
          <img src={images(props.iconPath)} className="w-100" alt="channels" />
        </div>
        <div
          className={
            "channel-item__name text-wrap fs-caption2 w-100 bg-white text-uppercase text-center" +
            (props.isFavorite ? " text--green1" : "") +
            (props.moreStationsCount === 0
              ? " channel-item__name--move-single-left"
              : " channel-item__name--move-double-left")
          }
        >
          {props.stationName}
        </div>
      </div>
      {props.moreStationsCount > 0 && (
        <div
          onClick={props.showChannelsPopup}
          className="channel-item element--clickable justify-content-center"
        >
          <div className="channel-item__more bg-white fs-body2 border d-flex justify-content-center align-items-center rounded p-2">
            +{props.moreStationsCount}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default RenderChannel;
