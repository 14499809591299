import React, { useState, useEffect } from "react";
import CustomInputText from "../../components/CustomInput";
import "react-datepicker/dist/react-datepicker.css";
import * as api from "../../utils/api";
import axios from "axios";

const images = require.context("../../assests/images", true);

const TeamsFilter = props => {

    const [teams, setTeams] = useState({tagFilters: []});
  
    const [filterText, setFilterText] = useState("");

    useEffect(() => {      
      async function getFavTeams() {
        let res = await axios.get("/favorite_teams");
        setTeams({tagFilters: res.data.favorite_teams});
      }     
      getFavTeams();
    }, []);  
  
    const updateTeams = (teamFilter, crudType) => {
      api.endpoints.updateTeams(teamFilter, crudType, res =>
        setTeams({tagFilters: res})          
      );
    };
  
    const addTeams = (teamFilterName) => {
      if (!teams.tagFilters.some(e => e.team_name === teamFilterName)) {
        updateTeams(teamFilterName, "add");
        setTeams(
            { tagFilters: arrayAdd(teams.tagFilters, filterText) },
        );
        setFilterText('');
      } else {
        setFilterText('');
      }
    };

    const deleteTeams = (team) => {
        if (teams.tagFilters.some(e => e.team_name === team.team_name)) {
          updateTeams(team.id, "delete");
          setTeams(
            { tagFilters: arrayRemove(teams.tagFilters, team.team_name) },
          );
        }
    };

    const arrayRemove = (arr, value) => {
        return arr.filter(ele => ele !== value);
    };

    const arrayAdd = (arr, value) => {
        if (!arr.includes(value)) {
            arr.push(value);
        }
        return arr;
    };

    const onSearchInput = event => {
        setFilterText(event);
    };

    const onSearchKeyDown = event => {
        if (event.keyCode === 13)
            onSearchClick();
    };

    const onSearchClick = () => {       
        if (filterText) {
            addTeams(filterText);
        }
    };

    const resetFilters = () => {
        setTeams({ tagFilters: [] });
        setFilterText('');
    };

    const Button = props => (
        <button
            className="btn p-0"
            onClick={() => props.onClick(props.wordId)}
            title={props.title}
        >
            <img src={images(props.imagePath)} alt={props.imageAlt} />
        </button>
    )

    const renderTeamFilters = () => {
        return teams.tagFilters.map((team, index) => {
            return(
                <li
                className="bg-white  mb-3 channel-list__border element--clickable highlight_on_hover"
                key={"_usrFltr" + index}
                >
                    <div className="d-flex  p-2 align-items-center ">
                        <div className="m-auto ">
                            <h3 className="channel-names">{team.team_name}</h3>
                        </div>
                        <Button
                            wordId={index}
                            onClick={() => deleteTeams(team)}
                            imagePath='./img/events/remove.svg'
                            imageAlt="Delete"
                            title="Delete"
                        />
                    </div>         
                </li>
            )
        });
    }
    
    return (
        <div className="pt-2 panel-sticky bg--gray1">
            <div className="d-flex justify-content-between mt-2 mb-2 filters">
                <CustomInputText
                    onChange={e => onSearchInput(e.target.value)}
                    keyPress={onSearchKeyDown}
                    value={filterText}
                    onBtnClick={onSearchClick}
                    placeholder="Add a filter word for teams"
                />
                {/* <u className="element--clickable" onClick={resetFilters}>
                    Reset Filters
                </u> */}
            </div>
            <div>                
                <ul>{renderTeamFilters()}</ul>
            </div>
        </div>
    );
  };
  
  export default TeamsFilter;
  
