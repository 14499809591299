import React, { useEffect, useReducer, useState } from "react";
import { Button } from "react-bootstrap-buttons";
import { Formik, Form, Field } from "formik";
import TimezonePicker from 'react-bootstrap-timezone-picker';
import Select from 'react-select';

import * as api from "../../utils/api";
import {
  validateTextField,
  validateMapsLinkInTextField,
  renderFieldError
} from "../../utils/validation";
import { connect } from "react-redux";
import * as actionCreators from "../../modules/actions";

import axios from "axios";
import CustomAttributeToggle from "../../components/CustomAttributeToggle";
import CustomAttributeInput from "../../components/CustomAttributeInput";
import SettingHeader from "./SettingsHeader";
import { languages } from "../../utils/utils";

const images = require.context("../../assests/images", true);
const defaultCompanyImage = images("./img/settings/Default-Bar-Cover.png");

const FEATURE_TYPES = {
  BOOLEAN: "BOOLEAN",
  NUMBER: "NUMBER"
};

const timeFormatOptions = [
  { value: 'AM/PM', label: '12H' },
  { value: '24H', label: '24H' }
]

const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'da-DK', label: 'Danish' },
  { value: 'sv', label: 'Swedish' }
]

const allFeatures = {
  FREE_WIFI: {
    name: "free-wifi",
    text: "FREE WIFI",
    type: FEATURE_TYPES.BOOLEAN
  },
  BRING_FOOD: {
    name: "bring-food",
    text: "BRING YOUR OWN FOOD",
    type: FEATURE_TYPES.BOOLEAN
  },
  SERVES_FOOD: {
    name: "serves-food",
    text: "FOOD SERVING",
    type: FEATURE_TYPES.BOOLEAN
  },
  PARKING: {
    name: "parking",
    text: "CAR PARK",
    type: FEATURE_TYPES.BOOLEAN
  },
  CAPACITY: {
    name: "capacity",
    text: "TOTAL CAPACITY",
    type: FEATURE_TYPES.NUMBER
  },
  SCREEN: {
    name: "screen",
    text: "TOTAL NUMBER OF SCREENS",
    type: FEATURE_TYPES.NUMBER
  },

  BIG_SCREEN: {
    name: "big-screen",
    text: "NUMBER OF BIG SCREENS",
    type: FEATURE_TYPES.NUMBER
  }
};

const companyDetailsFieldLabel = {
  name: { description: "Company name" },
  address: { description: "Company address" },
  maps: { title: "maps", description: "Google maps link" }
};
const getNameFromIcon = icon => {
  return icon.toString().replace("-", " ");
};

const LabelledField = props => {
  const handleTextFieldChange = value => {
    props.setter(value);
    return props.title;
  };
  return (
    <div className="form-group">
      <label className="fs-body2 text--gray8">{props.labelName}</label>
      <div className="input-group">
        {props.img && (
          <div className="input-group-prepend">
            <span className="input-group-text ">
              <img src={images(props.img)} alt={props.imageAlt} />
            </span>
          </div>
        )}
        <Field
          name={props.title}
          className={"form-control" + (props.img ? " border-left-0" : "")}
          placeholder={props.placeholder}
          value={props.value || ""}
          validate={
            props.title === companyDetailsFieldLabel.maps.title
              ? validateMapsLinkInTextField
              : validateTextField
          }
          onChange={e =>
            props.setFieldValue(
              handleTextFieldChange(e.target.value),
              e.target.value
            )
          }
        />
      </div>
      <div>
        {renderFieldError(
          props.touched[props.title],
          props.errors[props.title],
          companyDetailsFieldLabel[props.title].description,
          props.title
        )}
      </div>
    </div>
  );
};

const SectionTitle = props => {
  return <h3 className="fs-h3 text--gray10">{props.title}</h3>;
};

const RenderCompanyDetails = props => {
  return (
		<div className="p-2 mt-1 font--size--mm font-color--light-grey">
			<SectionTitle title={companyDetailsFieldLabel.name.description} />
			<LabelledField
				labelName="TYPE IN YOUR COMPANY NAME"
				value={props.cname}
				setter={props.setCName}
				placeholder="Enter Name"
				setFieldValue={props.setFieldValue}
				title="name"
				errors={props.errors}
				touched={props.touched}
			/>
			<br />
			<div className="border-bottom  ml-2 mr-2 " />
			<br />
			<SectionTitle title={companyDetailsFieldLabel.address.description} />
			<LabelledField
				labelName="TYPE IN YOUR COMPANY ADDRESS"
				img="./img/location.svg"
				imgAlt="location"
				value={props.location}
				setter={props.setLocation}
				placeholder="Enter an address..."
				setFieldValue={props.setFieldValue}
				title="address"
				errors={props.errors}
				touched={props.touched}
			/>
			<LabelledField
				labelName="ADD YOUR GOOGLE MAPS LINK"
				img="./img/map.svg"
				imgAlt="map"
				value={props.mapsLink}
				setter={props.setMapsLink}
				placeholder="https://www.google.com/maps..."
				setFieldValue={props.setFieldValue}
				title="maps"
				errors={props.errors}
				touched={props.touched}
			/>
			<br />
		</div>
	);
};

const RenderCoverPhoto = props => {
  return (
    <div className="pt-2 pl-2 pr-2 pb-0 mt-1 font--size--mm font-color--light-grey">
      <SectionTitle title="Change your company cover photo" />
      <div className="form-group company-image-div">
        <div
          className=" m-auto border"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        >
          <img
            className="company-image"
            src={props.selectedImageFile}
            alt="cover"
          />
          <div className="justify-content-start image-button-div">
            <Button className="button--success-no-bg no-border-button">
              <span className="text-span">
                <img src={images("./img/upload.svg")} alt="upload" />
              </span>
              <input
                type="file"
                id="single"
                name={"Upload"}
                onChange={props.onImageChangeHandler}
              />
              Upload
            </Button>
            <Button
              className="button--success-no-bg ml-3 no-border-button"
              onClick={props.onCancelUploadClickHandler}
            >
              <span className="text-span">
                <img src={images("./img/close.svg")} alt="remove" />
              </span>
              Remove
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderCompanyAttributes = props => {
  const getFeatureValue = (icon, isBoolean) => {
    const feature = props.features
      ? props.features.find(feature => {
        return feature.icon === icon;
      })
      : null;
    if (!feature) {
      return isBoolean ? false : 0;
    } else {
      if (isBoolean) {
        return feature.value === 1;
      }
      return feature.value;
    }
  };

  const featureImg = imgName =>
    images("./img/settings/location-features/" + imgName + ".svg");

  const renderFeature = item => (
    <React.Fragment key={item.name}>
      {item.type === FEATURE_TYPES.BOOLEAN
        ? renderToogleFeature(item)
        : renderInputFeature(item)}
      <br />
    </React.Fragment>
  );

  const renderToogleFeature = item => (
    <CustomAttributeToggle
      imageSource={featureImg(item.name)}
      text={item.text}
      enabled={getFeatureValue(item.name, true)}
      switchClass="feature-switch"
      onToggle={checked => props.updateFeature(item, checked)}
    />
  );

  const renderInputFeature = item => (
    <CustomAttributeInput
      imageSource={featureImg(item.name)}
      text={item.text}
      value={getFeatureValue(item.name, false)}
      inputClassName="feature-input"
      onChange={e => {
        props.updateFeature(item, e.target.value);
      }}
    />
  );

  const entries = Object.values(allFeatures);
  const index = Math.ceil(entries.length / 2);
  const col1 = entries.slice(0, index);
  const col2 = entries.slice(index);
  return (
    <div className="pt-2 pl-2 pr-2 pb-0 mt-1 font--size--mm font-color--light-grey mb-5">
      <SectionTitle title="Company amenities" />
      <br />
      <div className="feature-column">
        {col1.map(item => renderFeature(item))}
      </div>
      <div className="feature-column">
        {col2.map(item => renderFeature(item))}
      </div>
      <br/><br/>
    </div>
  );
};

const CompanyDetails = props => {
  let currencyOptions = []
  if(props.currencies?.length) {
    currencyOptions = props.currencies.map((currency) =>{return {value: currency.id,label: currency.name}});
  }

  let selectedImageFile = null;
  const timezone = sessionStorage.getItem('timezone') === 'null' ? null : sessionStorage.getItem('timezone');
  const timeFormat = sessionStorage.getItem('time_format') === 'null' ? '' : sessionStorage.getItem('time_format');
  const currencyName = props.currency ? props.currency.name : sessionStorage.getItem('currency_name') === 'null' ? '' : sessionStorage.getItem('currency_name');
  const currencyId = props.currency ? props.currency.name : sessionStorage.getItem('currency_id') === 'null' ? '' : sessionStorage.getItem('currency_id')
  const language = props.language? props.language : sessionStorage.getItem('language') === 'null' ? '' : sessionStorage.getItem('language')

  const onImageChangeHandler = event => {
    selectedImageFile = event.target.files[0];
    dispatch({ type: "UPD_SELECTED_PHOTO", payload: selectedImageFile });

    selectedImageFile = URL.createObjectURL(selectedImageFile);
    dispatch({ type: "UPD_TEMP_PHOTO", payload: selectedImageFile });
  };

  const onCancelUploadClickHandler = () => {
    selectedImageFile = defaultCompanyImage;
    dispatch({ type: "UPD_TEMP_PHOTO", payload: selectedImageFile });

    selectedImageFile = "delete";
    dispatch({ type: "UPD_SELECTED_PHOTO", payload: selectedImageFile });
  };

  const cancelChangesMade = props => {
    // TODO to be fixed to work with dispatch
    // dispatch({ type: "RESET" });
    window.location.reload();
  };

  const updateCompanyDetails = props => {
    const companyData = {
      name: companyDetail.name,
      address: companyDetail.address,
      maps_link: companyDetail.maps_link,
      features_attributes: companyDetail.features,
      time_format: companyDetail.time_format !== undefined ? companyDetail.time_format : sessionStorage.getItem('time_format'),
      timezone: companyDetail.timezone !== undefined ? companyDetail.timezone : sessionStorage.getItem('timezone'),
      language: companyDetail.language !== undefined ? companyDetail.language : sessionStorage.getItem('language')
    };
    const newImage = companyDetail.selected_image_file;

    if (companyDetail.locationId) {
      api.endpoints.updateLocation(
        companyDetail.locationId,
        companyData,
        newImage,
        handleApiResponse
      );
    } else {
      api.endpoints.createLocation(
        companyData,
        selectedImageFile,
        handleApiResponse
      );
    }

    sessionStorage.setItem('timezone', companyData.timezone)
    sessionStorage.setItem('time_format', companyData.time_format)
    sessionStorage.setItem('language', companyData.language)
    props.setUserTimeFormat(companyData.time_format)
    props.setUserTimeZone(companyData.timezone)
    props.setUserLanguage(companyData.language)
  };

  const handleApiResponse = (res, err) => {
    if (res) {
      dispatch({ type: "UPDATE", payload: res });
      props.showNotice("Settings updated", true);
    } else if (err) {
      props.showNotice(err, false);
    }
  };

  const companyDetailsReducer = (state, action) => {
    switch (action.type) {
      case "INIT":
      case "UPDATE":
        let obj = action.payload ? action.payload : {};
        const originalValues = {
          name: obj.name,
          address: obj.address,
          maps_link: obj.maps_link,
          locationId: obj.id,
          features: obj.features || [],
          photo: obj.photo,
          time_format: obj.time_format,
          timezone: obj.timezone,
          language: obj.language,
          temp_photo: obj.photo || defaultCompanyImage,
          selected_image_file: null
        };
        return {
          ...state,
          ...originalValues,
          originalValues: originalValues
        };
      case "RESET":
        return {
          ...state,
          ...state.originalValues
        };
      case "UPD_NAME":
        return { ...state, name: action.payload };
      case "UPD_TIME_FORMAT":
        return { ...state, time_format: action.payload };
      case "UPD_TIMEZONE":
        return { ...state, timezone: action.payload };
      case "UPD_LANGUAGE":
        return { ...state, language: action.payload }
      case "UPD_ADDRESS":
        return { ...state, address: action.payload };
      case "UPD_LINK":
        return { ...state, maps_link: action.payload };
      case "UPD_FEATURES":
        return { ...state, features: action.payload };
      case "UPD_TEMP_PHOTO":
        return { ...state, temp_photo: action.payload };
      case "UPD_SELECTED_PHOTO":
        return { ...state, selected_image_file: action.payload };
      default:
        return { ...state };
    }
  };
  const [companyDetail, dispatch] = useReducer(companyDetailsReducer, {
    name: "",
    address: "",
    locationId: "",
    maps_link: "",
    time_format: "",
    timezone: "",
    features: [],
    temp_photo: "",
    language: ""
  });

  useEffect(() => {
    async function getLocations() {
      let res = await axios.get("/locations");
      dispatch({
        type: "INIT",
        payload: res.data.locations ? res.data.locations[0] : null
      });
    }
    getCurrencies();
    getLocations();
    if(props.client_id) getCurrency();
  }, [props.client_id]);

  const setTimezone = val => {
    dispatch({ type: "UPD_TIMEZONE", payload: val });
  }
  const setTimeFormat = val => {
    dispatch({ type: "UPD_TIME_FORMAT", payload: val });
  }
  const setLanguage = val => {
    dispatch({ type: "UPD_LANGUAGE", payload: val })
  }
  const setName = val => {
    dispatch({ type: "UPD_NAME", payload: val });
  };
  const setAddress = val => {
    dispatch({ type: "UPD_ADDRESS", payload: val });
  };
  const setLink = val => {
    dispatch({ type: "UPD_LINK", payload: val });
  };

  const updateFeature = (item, value) => {
    let icon = item.name;
    const feature_type = item.type;
    // for toggle features
    if (typeof value === "boolean") {
      value = value ? 1 : 0;
    } else {
      if (value < 0) {
        return;
      }
    }
    let featureIndex = companyDetail.features.findIndex(feature => {
      return feature.icon === icon;
    });

    if (featureIndex === -1) {
      companyDetail.features.push({
        icon,
        value,
        name: getNameFromIcon(icon),
        feature_type
      });
    } else {
      companyDetail.features[featureIndex].value = value;
    }
    dispatch({ type: "UPD_FEATURES", payload: companyDetail.features });
  };

  const getInitialValue = elem => {
    return elem === undefined || "" ? "" : elem
  }

  const getCurrencies = () => {
    props.getCurrencies();
  }

  const getCurrency = (client_id) => {
    props.getCurrency(client_id);
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: getInitialValue(companyDetail.name),
        address: getInitialValue(companyDetail.address),
        maps: getInitialValue(companyDetail.maps_link),
        time_format: timeFormat,
        timezone: timezone,
        language: language
      }}
      onSubmit={() => {
        updateCompanyDetails(props);
      }}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form className="d-flex bg-secondary w-75 h-100 ml-5 overflow-auto">
          <div className="w-75 mr-5 ml-2 bg-secondary">
            <SettingHeader
              image="./user-company.svg"
              imageAlt="User Company Image"
              title="Your Company"
            />
            <RenderCompanyDetails
              cname={companyDetail.name}
              location={companyDetail.address}
              mapsLink={companyDetail.maps_link}
              setLocation={setAddress}
              setCName={setName}
              setMapsLink={setLink}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />

            <div className="border-bottom  ml-2 mr-2 " />
            <RenderCoverPhoto
              selectedImageFile={companyDetail.temp_photo}
              onImageChangeHandler={onImageChangeHandler}
              onCancelUploadClickHandler={onCancelUploadClickHandler}
            />

            <div className="mb-5">
              <h4 className="fs-h4 text--gray8 mt-4">Time Zone</h4>
              <label className="fs-caption2 text--gray7 ">CHOOSE YOUR PREFERRED TIME ZONE</label>
                <TimezonePicker
                  absolute = {true}
                  placeholder = "Select timezone"
                  onChange = {e => setTimezone(e)}
                  defaultValue = {timezone}
                />
            </div>

            <div className="mb-5">
              <h4 className="fs-h4 text--gray8 mt-4">Time Format</h4>
              <label className="fs-caption2 text--gray7 ">CHOOSE YOUR PREFERRED TIME FORMAT</label>
              <Select
                options={timeFormatOptions}
                placeholder="Select time format"
                onChange = {(e) => {
                  setTimeFormat(e.value);
                  setFieldValue('time_format', e.value);
                }}
                defaultValue={{ label: timeFormat === "AM/PM" ? "12H" : timeFormat, value: timeFormat }}
              />
            </div>
            <div className="mb-5">
            <h4 className="fs-h4 text--gray8 mt-4">Currency</h4>
              <label className="fs-caption2 text--gray7 ">CHOOSE YOUR PREFERRED CURRENCY</label>
              <Select
                options={currencyOptions}
                placeholder="Select Currency type"
                onChange = {(e) => props.updateCurrency(props.client_id, e.value)}
                defaultValue={{ label: currencyName, value: currencyId ,}}
              />
            </div>
            <div className="mb-5">
              <h4 className="fs-h4 text--gray8 mt-4">Language</h4>
              <label className="fs-caption2 text--gray7 ">CHOOSE YOUR PREFERRED LANGUAGE (Facebook posts, posters & widgets)</label>
              <Select
                  options={languageOptions}
                  placeholder="Select language"
                  onChange = {(e) => setLanguage(e.value)}
                  defaultValue={{ label: languages[language], value: language }}
              />
            </div>


            <div className="border-bottom  ml-2 mr-2 " />
            <RenderCompanyAttributes
              features={companyDetail.features}
              updateFeature={updateFeature}
            />
          </div>

          <div className="update-company-info-div">
            <Button type="submit" className="button--success no-border-button">
              Update Details
              </Button>
            <Button
              className="button--warning ml-5 no-border-button"
              onClick={() => cancelChangesMade()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    client_id: state.client_id,
    currencies: state.currencies,
    currency: state.currency,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showNotice: (message, success) =>
      dispatch(actionCreators.showNotice(message, success)),
    setUserTimeZone: (timezone) =>
      dispatch(actionCreators.setUserTimeZone(timezone)),
    setUserTimeFormat: (time_format) =>
      dispatch(actionCreators.setUserTimeFormat(time_format)),
    setUserLanguage: (language) =>
        dispatch(actionCreators.setUserLanguage(language)),
    updateCurrency: (client_id, currency_id) =>
      dispatch(actionCreators.updateCurrency(client_id, currency_id)),
    getCurrencies: () =>
      dispatch(actionCreators.getCurrencies()),
    getCurrency: (client_id) =>
      dispatch(actionCreators.getCurrency(client_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
