import React, { useState, useEffect, useReducer } from "react";
import "react-datepicker/dist/react-datepicker.css";
import channels from "../../utils/channels";
import InfiniteScroll from "react-infinite-scroll-component";
import * as api from "../../utils/api";


const images = require.context("../../assests/images", true);
const channelimages = require.context(
  "../../assests/images/img/channels/48pxIcons/",
  true
);

const ChannelsFilter = props => {
    let fav_stations_ids = [];
    let channelsSelected = [];

    const [filterText, setFilterText] = useState("");
    const [checked, setChecked] = useState(false);
    const [hasMore,setHasMore] = useState(true);
    const [searchedStations, setSearchedStations] = useState([]);
    const [timer, setTimer] = useState(null)


    const selectChannel = (chId, e) => {
        if (fav_stations_ids.includes(chId)) {
            props.updatePreferences(chId, "delete");
        } else {
            props.updatePreferences(chId, "add");
        }
    };

    const selectAll = () => {
        checked ?  props.updatePreferences(channelsSelected,"delete") : props.updatePreferences(channelsSelected,"add");
    }

    props.favstations.map( (s) => {
        fav_stations_ids.push(s.id);
    })

    const RenderChannel = props => {
        const { id, name, iconPath } = props;
        channelsSelected.push(id);

        return (
            <li
                className="bg-white  mb-3 channel-list__border element--clickable highlight_on_hover"
                onClick={ (event) => selectChannel(id, event)}
            >
                <div className="d-flex  p-2 align-items-center ">
                    <div className="checkbox-wrapper">
                        <input
                            type="checkbox"
                            readOnly
                            className="align-self-center border-right ml-1 mr-3"
                            checked={fav_stations_ids.includes(id)}
                        />
                        <span className="checkmark"></span>
                    </div>
                    <div className="align-self-start border-right w-20 ml-1 pr-3">
                        <img className="" src={channelimages(iconPath)} alt="club name" />
                    </div>
                    <div className="m-auto ">
                        <h3 className="channel-names">{name}</h3>
                    </div>
                </div>
            </li>
        );
    };

    const fetchMoreStations = () => {
        props.setLimit(props.limit + 50)
        if(props.stations.length % 50 !== 0){
            setHasMore(false)
        }
    };

    const searchChannels = (searchTerm) => {
        clearTimeout(timer)
        setFilterText(searchTerm);

        if (!searchTerm) {
            setSearchedStations([])
            setHasMore(true)
            props.setLimit(0)
            return
        }

        const newTimer = setTimeout(() => {
            api.endpoints.searchStations(searchTerm, (response) => {
                setSearchedStations(response.data.stations)
                setHasMore(false)
           })
        }, 500)

        setTimer(newTimer)
    }

    return (
        <>
            <div className="form-group">
                <label className="fs-caption2 text--gray7 ">
                    USE THE SEARCH FILTER OR SCROLL THROUGH THE LIST TO FIND AND MARK
                    YOUR SUPPORTED CHANNELS
                </label>
                <div className="input-group">
                    <div className="checkbox-wrapper" style={{height : "100%",marginTop : 5}}>
                        <input
                            type="checkbox"
                            id="select all"
                            checked={checked}
                            onChange={e =>{
                                selectAll();
                                setChecked(!checked);
                            }
                            }
                            className="align-self-center border-right ml-1 mr-3"
                        />
                        <span className="checkmark"></span>
                    </div>
                    <div style={{height : "100%",marginTop : 5,marginRight: 10}}>
                        <label htmlFor={"selectall"}>Select all</label>
                    </div>
                    <div className="input-group-prepend "   style={{height : "100%"}} >
                        <button
                            className="btn bg-white btn-sm border-right-0 "
                            type="button"
                            style={{
                                disabled: true
                            }}
                        >
                            <img
                                src={images("./img/searchIconDisabled.svg")}
                                alt="close"
                            />
                        </button>
                    </div>
                    <input
                        type="text"
                        className="form-control border-0 bg-white"
                        value={filterText}
                        onChange={e => {
                            e.preventDefault()
                            setChecked(false);
                            searchChannels(e.target.value);
                        }
                        }
                        placeholder="Filter channels by name"
                        style={{height : "100%"}}
                    />
                </div>
            </div>
            <div className=" d-flex flex-column justify-content-center">
                <ul>
                    {
                        <InfiniteScroll
                            dataLength={props.stations.length}
                            next={fetchMoreStations}
                            hasMore={hasMore}
                            height={"calc(100vh - 370px)"}
                            loader={
                                <div className={"events-data-status"}>
                                    <h4>Loading...</h4>
                                </div>
                            }
                            endMessage={
                                <p className={"events-data-status"}>
                                    <b>All the data has been loaded!</b>
                                </p>
                            }
                        >
                            {
                                (searchedStations.length > 0 ? searchedStations : props.stations || [])
                                    .filter(
                                        station =>
                                            !filterText ||
                                            station.name
                                                .toLowerCase()
                                                .includes(filterText.toLowerCase())
                                    )
                                    .map((station, index) => {
                                        let channel = Object.keys(channels).find(chName =>
                                            station.name.toLowerCase().includes(chName.toLowerCase())
                                        );
                                        if (channels[channel].name) station.name = channels[channel].name;
                                        return (
                                            <RenderChannel
                                                id={station.id}
                                                name={station.name}
                                                iconPath={channels[channel].icon || channels[channel] || "default.svg"}
                                                key={station.name + index}
                                            />
                                        );
                                    })
                            }
                        </InfiniteScroll>
                    }
                </ul>
            </div>
        </>
    );
};

export default ChannelsFilter;

