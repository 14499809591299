const channels = {
  "C MORE Hockey (sweden)": "./Swedish/C More Hockey.svg",
  "Sportkanalen (sweden)": "./Swedish/Sport Kanalen.svg",
  "Sportbladet Play (sweden)": "./Swedish/sportbladet play.svg",
  "SVT 1 (sweden)": "./Swedish/SVT1.svg",
  "SVT 2 (sweden)": "./Swedish/SVT2.svg",
  "SVT 24 (sweden)": "./Swedish/SVT 24.svg",
  "SVT Play (sweden)": "./Swedish/SVT Play.svg",
  "C More Fotboll (sweden)": "./Swedish/C More Fotboll.svg",
  "C MORE Live (sweden)": "./Swedish/C More Live 1.svg",
  "C MORE Live 2": "./Swedish/C More Live 2.svg",
  "C MORE Live 3": "./Swedish/C More Live 3.svg",
  "C MORE Live 4": "./Swedish/C More Live 4.svg",
  "TV4 (sweden)": "./Swedish/TV 4.svg",
  "TV4 Play (sweden)": "./Swedish/Tv4 play.svg",
  "TV6 (sweden)": "./Swedish/TV 6.svg",
  "TV10 (sweden)": "./Swedish/TV10.svg",
  "TV12 (sweden)": "./Swedish/TV 12.svg",
  "Kanal 5 (sweden)": "./Swedish/Kanal 5.svg",
  "Kanal 9 (sweden)": "./Swedish/Kanal 9.svg",
  'Kanal 9 Sweden HD': "./Swedish/Kanal 9.svg",
  "Viasat Sport Extra": "./Swedish/Viasat Sport Extra.svg",
  "V Sport Extra HD (sweden)": "./Swedish/Viasat Sport Extra.svg",
  "V Sport Premium (sweden)": "./Swedish/VSport_premium.svg",
  "V Sport 1 (sweden)": "./Swedish/VSport1.svg",
  "V Sport Motor (sweden)": "./Swedish/VSportMotor.svg",
  "Aftonbladet Plus (sweden)": "./Swedish/Aftonbladet Plus.svg",
  "Sport Expressen Play (sweden)": "./Swedish/Sport Expressen.svg",
  "Kunskapskanalen (sweden)": "./Swedish/Kunskapskanalen.svg",
  "Hajper (sweden)": "./Swedish/Hajper.svg",
  // "ATG": "./Swedish/ATG.svg",
  "BET 365": "./Swedish/Bet 365.svg",
  "BET365": "./Swedish/Bet 365.svg",
  "C MORE Fotboll": "./Swedish/C More Fotboll.svg",

  "Amazon Prime": "./UK/Amazon-Prime.svg",
  "TV3 MAX (dk)": "./Danish/3-Max.svg",
  "TV3 Sport (dk)": "./Danish/3-Sport.svg",
  "TV3+ (dk)": "./Danish/3-Plus.svg",
  "6'Eren (dk)": "./Danish/6-eren.svg",
  "Canal 9 (dk)": "./Danish/9-eren.svg",
  "C More Stream": "./Danish/C-More.svg",
  "Canal 8 Sport": "./Danish/Canal-8-Sport.svg",
  dPlay: "./Danish/dPlay.svg",
  DR1: "./Danish/DR1.svg",
  DR2: "./Danish/DR2.svg",
  DR3: "./Danish/DR3.svg",
  "Eurosport 1": "./Danish/Eurosport-1.svg",
  "Eurosport 2": "./Danish/Eurosport-2.svg",
  "Eurosport Player": "./Danish/Eurosport-Player.svg",
  "Kanal 5 (dk)": "./Danish/Kanal-5.svg",
  "Strive Sport": "./Danish/Strive.svg",
  "TV2 Sport": "./Danish/TV2-Sport.svg",
  TV2: "./Danish/TV2.svg",
  "TV3 Sport 2": "./Danish/TV3-Sport-2.svg",
  "Viaplay": "./Danish/viaplay.svg",
  "Viasat Golf": "./Danish/Viasat-Golf.svg",
  "Viasat Xtra": "./Danish/Viasat-Xtra.svg",
  //UK Channels list

  "BBC Alba": "./UK/BBC-Alba.svg",
  "BBC Northern Ireland": "./UK/BBC-Northern-Ireland.svg",
  "BBC Scotland / HD": "./UK/BBC-Scotland.svg",
  "BBC Sport": "./UK/BBC-Sport.svg",
  "BBC Wales": "./UK/BBC-Wales.svg",
  BBC: "./UK/BBC.svg",
  "BT Sport": "./UK/BT-Sport.svg",
  "Channel 4": "./UK/Channel-4.svg",
  "Channel 5": "./UK/Channel-5.svg",

  "ITV Sport": "./UK/ITV-Sport.svg",
  "ITV 4": "./UK/ITV-4.svg",
  ITV: "./UK/ITV.svg",
  "Premier Sports": "./UK/Premier-Sports.svg",
  Quest: "./UK/Quest.svg",
  "Racing TV": "./UK/Racing-TV.svg",
  S4C: "./UK/S4C.svg",
  "Eleven Sports": "./UK/Eleven-Sports.svg",
  "Eurosport UK": "./UK/Eurosport.svg",
  "FreeSports ": "./UK/FreeSports.svg",
  "BT Sport 1": "./UK/BT-Sport-1.svg",
  "BT Sport 2": "./UK/BT-Sport-2.svg",
  "BT Sport 3": "./UK/BT-Sport-3.svg",
  "Prime Video": "./UK/Prime-Video.svg",

  "Sky Sports F1": "./UK/Sky-Sports-F1.svg",
  "Sky Sports Racing": "./UK/Sky-Sports-Racing.svg",
  "Sky Sports": "./UK/Sky-Sports.svg",
  "Star India": "./UK/Star-India.svg",
  UEFA: "./UK/UEFA.svg",
  Youtube: "./UK/Youtube.svg",
  "BBC Red Button": "./UK/BBC-Red-Button.svg",

  "See": "./Danish/See.svg",

  // NORWAY
  'Direkte Sport': './Norway/Direktesport.svg',
  'DirekteSport.no': './Norway/Direktesport.svg',
  'MAX Norge': './Norway/MAX.svg',
  'NRK 1': './Norway/NRK 1.svg',
  'NRK 2': './Norway/NRK 2.svg',
  'NRK 3': './Norway/NRK 3.svg',
  'TV2 Sumo Norge': './Norway/TV2 Sumo.svg',
  'VG+': './Norway/VG+.svg',
  'Viasat 4 Norge': './Norway/Viasat 4.svg',
  'ViaSport 1': './Norway/Viasport 1.svg',
  'ViaSport 2': './Norway/Viasport 2.svg',
  'ViaSport 3': './Norway/Viasport 3.svg',
  'ViaSport+': './Norway/Viasport +.svg',
  'V Sport 1 HD (norge)': './Norway/V Sport Live 1.svg',
  'V Sport 1 Norge HD': './Norway/V Sport Live 1.svg',
  'V Sport 2 HD (norge)': './Norway/V Sport Live 2.svg',
  'V Sport 2 Norge HD': './Norway/V Sport Live 2.svg',
  'V Sport 3 HD (norge)': './Norway/V Sport Live 3.svg',
  'V Sport 3 Norge HD': './Norway/V Sport Live 3.svg',

  // FINLAND
  'C More Sport 1 Finland HD': './Finland/C More Sport 1.svg',
  'C More Sport 1 HD (finland)': './Finland/C More Sport 1.svg',
  'C More Sport 2 Finland HD': './Finland/C More Sport 2.svg',
  'C More Sport 2 HD (finland)': './Finland/C More Sport 2.svg',
  'Elisa Finland': './Finland/Elisa Viihde Sport.svg',
  'ruutu / ruutu+': './Finland/Ruutu+.svg',
  'Ruutu+': './Finland/Ruutu+.svg',
  'Sub': './Finland/Sub.svg',
  'Unibet': './Finland/Unibet TV.svg',
  'Viasat Fotboll': './Finland/Viasat Fotboll.svg',
  'Viasat Hockey': './Finland/Viasat Hockey.svg',
  'Viasat Jääkiekko': './Finland/Viasat Jääkiekko.svg',
  'Viasat Jalkapallo': './Finland/Viasat Jalkapallo.svg',
  'Viasat Sport Premium': './Finland/Viasat Sport Premium.svg',
  'Viasat Urheilu': './Finland/Viasat Urheilu.svg',
  'Viasat Ultra': './Finland/Viasat Sport Ultra HD.svg',
  'YLE Areena': './Finland/YLE Areena.svg',
  'YLE TV2': './Finland/Yle TV2.svg',

  // NEW ZEALAND
  'TV5MONDE Pacific': './NewZealand/TV5MONDE Pacific.svg',
  'Spark Sport': './NewZealand/Spark Sport.svg',
  'beIN SPORTS 1': './NewZealand/beIN SPORTS.svg',
  'beIN SPORTS 3 (New Zealand)': './Australian/beIN SPORTS 3.svg',
  'TVNZ Sport (New Zealand)': './NewZealand/TVNZ+.svg',
  'beIN SPORTS XTRA (New Zealand)': './NewZealand/beIN SPORTS XTRA.svg',
  'Duke': './NewZealand/Duke.svg',
  'Espn': './NewZealand/ESPN.svg',
  'Espn2': './NewZealand/ESPN 2.svg',
  'Prime': './NewZealand/Prime.svg',
  'Sky Sport Select': './NewZealand/Sky Sport Select.svg',
  'Sky Sport 1': './NewZealand/Sky Sport.svg',
  'Sky Sport 2': './NewZealand/Sky Sport 2.svg',
  'Sky Sport 3': './NewZealand/Sky Sport 3.svg',
  'Sky Sport 4': './NewZealand/Sky Sport 4.svg',
  'Sky Sport 5': './NewZealand/Sky Sport 5.svg',
  'Sky Sport 6': './NewZealand/Sky Sport 6.svg',
  'Sky Sport 7': './NewZealand/Sky Sport 7.svg',
  'Sky Sport 8': './NewZealand/Sky Sport 8.svg',
  'Sky Sport 9': './NewZealand/Sky Sport 9.svg',
  'Sky Arena': './NewZealand/Sky Arena.svg',
  'Sky Sport Pop-up 1': './NewZealand/SKY Sport Pop Up 1.svg',
  'Sky Sport Pop-up 2': './NewZealand/SKY Sport Pop Up 2.svg',
  'Trackside 1': './NewZealand/Trackside 1.svg',
  'Trackside 2': './NewZealand/Trackside 2.svg',

  // AUSTRALIA
  'beIN SPORTS Australia 1': './Australian/beIN SPORTS.svg',
  'beIN SPORTS Australia 2': './Australian/beIN SPORTS 2.svg',
  'beIN SPORTS Australia 3': './Australian/beIN SPORTS 3.svg',
  'Fox Footy Australia': './Australian/Fox Footy.svg',
  'Fox Sports 5': './Australian/Fox Sports 505.svg',
  'Fox Sports More': './Australian/Fox Sports More.svg',
  'Stan Sport Australia': './Australian/Stan Sport.svg',
  'Chelsea TV HD': './Australian/Chelsea TV.svg',
  'Kayo Sports Streaming': './Australian/Kayo Sports.svg',
  'LFC TV HD': './Australian/LFCTV.svg',
  'Main Event': './Australian/Main Event.svg',
  'MUTV': './Australian/MUTV.svg',
  'Optus Sport': './Australian/Optus sport.svg',
  'RAI Italia': './Australian/RAI Italia.svg',

  // GERMANY
  'Das Erste': './Germany/Das Erste.svg',
  'DAZN': './Germany/DAZN.svg',
  'Laola': './Germany/LAOLA1.svg',
  'Magenta Sport': './Germany/Magenta Sport.svg',
  'MDR': './Germany/MDR.svg',
  'NDR': './Germany/NDR.svg',
  'ProSieben MAXX': './Germany/ProSieben MAXX.svg',
  'RBB': './Germany/RBB.svg',
  'RTL': './Germany/RTL.svg',
  'SAT.1': './Germany/SAT.1.svg',
  'Servus TV Deutschland': './Germany/ServusTV Deutschland.svg',
  'Sky Bundesliga 1': './Germany/Sky Bundesliga 1.svg',
  'Sky Bundesliga 2': './Germany/Sky Bundesliga 2.svg',
  'Sky Bundesliga 3': './Germany/Sky Bundesliga 3.svg',
  'Sky Bundesliga 4K UHD': './Germany/Sky Bundesliga UHD.svg',
  'Sky Bundesliga 4': './Germany/Sky Bundesliga 4.svg',
  'Sky Bundesliga 5': './Germany/Sky Bundesliga 5.svg',
  'Sky Bundesliga 6': './Germany/Sky Bundesliga 6.svg',
  'Sky Bundesliga 7': './Germany/Sky Bundesliga 7.svg',
  'Sky Bundesliga 8': './Germany/Sky Bundesliga 8.svg',
  'Sky Bundesliga 9': './Germany/Sky Bundesliga 9.svg',
  'Sportdigital': './Germany/Sportdigital.svg',
  'SR Fernsehen': './Germany/SR Fernsehen.svg',
  'SWR': './Germany/SWR.svg',
  'TVNOW': './Germany/TVNOW.svg',
  'WDR': './Germany/WDR.svg',
  'ZDF': './Germany/ZDF.svg',

  // DEFAULT
  "": "./default.svg"
};

export default channels;
