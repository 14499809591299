import React from "react";
import TextyAnim from "rc-texty";
import "rc-texty/assets/index.css";

const WinnerName = props => {
  return (
    <TextyAnim
      type={props.type}
      duration={props.duration}
      interval={props.interval}
      exclusive={props.exclusive}
      delay={props.delay}
    >
      {props.showWinner && props.winner}
    </TextyAnim>
  );
};

export default WinnerName;
