import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import * as api from "../../utils/api";
import { SketchPicker } from 'react-color';
import {Alert, Button, Container, Row} from "react-bootstrap";
import CustomLoader from "../../components/CustomLoader";
import rgbHex from "rgb-hex";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Select from 'react-select';

const iframeStyle = {
    backgroundColor: 'transparent',
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    padding: "0px 0px",
    border: "0px",
    height: "650px"
}

let reservationWidgetColors = {
    widget_reservation_title_font_color: "#FFFFFF",
    widget_reservation_create_button_color: "#333333",
    widget_reservation_field_font_color: "#000000",
    widget_reservation_input_field_font_color: "#FFFFFF",
    widget_reservation_field_color: '#5A3A3A',
    widget_reservation_input_field_color: "#000000",
}

let reservationWidgetFont = {
    widget_reservation_font: 'Asap'
}

const styles = {
    color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px'
    },
    swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }
};

  

const ReservationWidget = forwardRef(( { locationHash }, ref) => {
    const [titleFontColor, setTitleFontColor] = useState("#000000")
    const [createButtonFontColor, setCreateButtonFontColor] = useState("#FFFFFF")
    const [fieldFontColor, setFieldFontColor] = useState("#333333")
    const [inputFieldFontColor, setInputFieldFontColor] = useState("#000000")
    const [fieldColor, setFieldColor] = useState("#000000")
    const [inputFieldColor, setInputFieldColor] = useState("#000000")
    const [selectedFont, setSelectedFont] = useState("Asap")
    const [widgetPreview, setWidgetPreview] = useState("");
    const [displayTitleFontColor, setDisplayTitleFontColor] = useState(false)
    const [displayFieldFontColor, setDisplayFieldFontColor] = useState(false)
    const [displayCreateButtonColorPicker, setDisplayCreateButtonColorPicker] = useState(false)
    const [displayFieldColor, setDisplayFieldColor] = useState(false)
    const [displayInputFieldFontColor, setDisplayInputFieldFontColor] = useState(false)
    const [displayInputFieldColor, setDisplayInputFieldColor] = useState(false)

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false)
    const [timer, setTimer] = useState(null)

    const availableFonts = [
        { value: 'Asap', label: 'Asap' },
        { value: 'Cabin', label: 'Cabin' },
        { value: 'Courgette', label: 'Noto Mono' },
        { value: 'Dhurjati', label: 'Dhurjati' },
        { value: 'Kanit', label: 'Kanit' },
        { value: 'Merriweather', label: 'Merriweather' },
        { value: 'Outfit', label: 'Outfit' },
        { value: 'Playpen+Sans', label: 'Playpen Sans' },
        { value: 'Roboto', label: 'Roboto' },
        { value: 'Ubuntu', label: 'Ubuntu' },
        { value: 'hwt-aetna', label: 'Hwt-Aetna'},
        { value: 'Gill+Sans', label: 'Gill Sans'},
        { value: 'Cortado', label: 'Cortado'},
    ];
      

    useImperativeHandle(ref, () => ({
        setPreview() {
            setLoading(true)
            getReservationCustomWidgetPreview(reservationWidgetColors, reservationWidgetFont)
        }
    }))

    useEffect(() => {
        api.endpoints.getReservationWidgetCustomColors((response) => {
            reservationWidgetColors = response
            setTitleFontColor(reservationWidgetColors.widget_reservation_title_font_color)
            setCreateButtonFontColor(reservationWidgetColors.widget_reservation_create_button_color)
            setFieldFontColor(reservationWidgetColors.widget_reservation_field_font_color)
            setInputFieldFontColor(reservationWidgetColors.widget_reservation_input_field_font_color)
            setFieldColor(reservationWidgetColors.widget_reservation_field_color)
            setInputFieldColor(reservationWidgetColors.widget_reservation_input_field_color)
        })
    }, [])
// aici
    useEffect(() => {
        api.endpoints.getReservationWidgetCustomFont((response) => {
            reservationWidgetFont = response
            setSelectedFont(reservationWidgetFont.widget_reservation_font)
        })
    }, [])

    useEffect(() => {
        locationHash && getReservationCustomWidgetPreview("#FFFFFF")
    }, [locationHash])

    const getReservationCustomWidgetPreview = (colors,font) => {
        api.endpoints.getReservationCustomWidgetPreview(colors, font, locationHash, (response) => {
            setWidgetPreview(response)
            setLoading(false)

        })
    }

    const saveReservationWidgetColors = () => {
        api.endpoints.setReservationCustomWidgetColor(reservationWidgetColors, reservationWidgetFont, (response) => {
            setShow(true)
        })
    }

    const handleColorChange = (color, element) => {
        color = "#" + rgbHex(color.r, color.g, color.b, color.a)
        switch (element) {
            case 'title': {
                setTitleFontColor(color)
                reservationWidgetColors.widget_reservation_title_font_color = color
                break
            }
            case 'button': {
                setCreateButtonFontColor(color)
                reservationWidgetColors.widget_reservation_create_button_color = color
                break
            }
            case 'fieldFontColor': {
                setFieldFontColor(color)
                reservationWidgetColors.widget_reservation_field_font_color = color
                break
            }
            case 'inputFieldFontColor': {
                setInputFieldFontColor(color)
                reservationWidgetColors.widget_reservation_input_field_font_color = color
                break
            }
            case 'fieldColor': {
                setFieldColor(color)
                reservationWidgetColors.widget_reservation_field_color = color
                break
            }
            case 'inputFieldColor': {
                setInputFieldColor(color)
                reservationWidgetColors.widget_reservation_input_field_color = color
                break
            }
        }

        clearTimeout(timer)

        const newTimer = setTimeout(() => {
            api.endpoints.getReservationCustomWidgetPreview(reservationWidgetColors, reservationWidgetFont, locationHash, (response) => {
                setWidgetPreview(response)
                setLoading(false)
            })
        }, 500)
        setTimer(newTimer)
    }

    return (
        <Container style={{ marginTop: '20px', height: "100%", overflow: "hidden" }} >
            <Alert show={show} variant="success" onClose={() => setShow(false)} style={{ marginTop: '10px' }} dismissible>
                <Alert.Heading>Custom reservation widget successfully updated</Alert.Heading>
            </Alert>
            <div className="col-12">
                <h4 className="font-color--grey font-weight-bold text-center">
                    Style your custom reservation widget!

                    <OverlayTrigger
                        trigger="focus"
                        placement= "top"
                        overlay={
                            <Popover id="widget_preview_popover">
                                <Popover.Title as="h3">Widget Live Preview</Popover.Title>
                                <Popover.Content>For live preview, please publish the events in your list.</Popover.Content>
                            </Popover>
                        }
                    >
                        <Button variant="info" size="sm" className="ml-2 py-0">?</Button>
                    </OverlayTrigger>
                </h4>
                <Row className="d-flex align-items-center" style={{ height:"100%", marginTop: "50px" }}>

                <div className="col-6 col-lg-3">
                        <h6>Title font color: </h6>
                    </div>
                    <div className="col-6 col-lg-3">
                        <div style={ styles.swatch } onClick={ () => setDisplayTitleFontColor(!displayTitleFontColor) }>
                            <div style={{ ...styles.color, background: titleFontColor }} />
                        </div>
                        { displayTitleFontColor ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ () => setDisplayTitleFontColor(false) }/>
                            <SketchPicker
                                onChange={(color) => {
                                    handleColorChange(color.rgb, 'title')
                                }}
                                color={titleFontColor}
                            />
                        </div> : null }
                    </div>

                    <div className="col-6 col-lg-3">
                        <h6>Font color: </h6>
                    </div>
                    <div className="col-6 col-lg-3">
                        <div style={ styles.swatch } onClick={ () => setDisplayFieldFontColor(!displayFieldFontColor) }>
                            <div style={{ ...styles.color, background: fieldFontColor }} />
                        </div>
                        { displayFieldFontColor ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ () => setDisplayFieldFontColor(false) }/>
                            <SketchPicker
                                onChange={(color) => {
                                    handleColorChange(color.rgb, 'fieldFontColor')
                                }}
                                color={fieldFontColor}
                            />
                        </div> : null }
                    </div>

                    <div className="col-6 col-lg-3">
                        <h6>Field background color: </h6>
                    </div>
                    <div className="col-6 col-lg-3">
                        <div style={ styles.swatch } onClick={ () => setDisplayFieldColor(!displayFieldColor) }>
                            <div style={{ ...styles.color, background: fieldColor }} />
                        </div>
                        { displayFieldColor ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ () => setDisplayFieldColor(false) }/>
                            <SketchPicker
                                onChange={(color) => {
                                    handleColorChange(color.rgb, 'fieldColor')
                                }}
                                color={fieldColor}
                            />
                        </div> : null }
                    </div>
                
                    <div className="col-6 col-lg-3">
                        <h6>Underline color: </h6>
                    </div>
                    <div className="col-6 col-lg-3">
                        <div style={ styles.swatch } onClick={ () => setDisplayInputFieldColor(!displayInputFieldColor) }>
                            <div style={{ ...styles.color, background: inputFieldColor }} />
                        </div>
                        { displayInputFieldColor ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ () => setDisplayInputFieldColor(false) }/>
                            <SketchPicker
                                onChange={(color) => {
                                    handleColorChange(color.rgb, 'inputFieldColor')
                                }}
                                color={inputFieldColor}
                            />
                        </div> : null }
                    </div>

                    <div className="col-6 col-lg-3">
                        <h6>Button font color: </h6>
                    </div>
                    <div className="col-6 col-lg-3">
                        <div style={ styles.swatch } onClick={ () => setDisplayCreateButtonColorPicker(!displayCreateButtonColorPicker) }>
                            <div style={{ ...styles.color, background: createButtonFontColor }} />
                        </div>
                        { displayCreateButtonColorPicker ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ () => setDisplayCreateButtonColorPicker(false) }/>
                            <SketchPicker
                                onChange={(color) => {
                                    handleColorChange(color.rgb, 'button')
                                }}
                                color={createButtonFontColor}
                            />
                        </div> : null }
                    </div>

                    <div className="col-6 col-lg-3">
                        <h6>Button background: </h6>
                    </div>
                    <div className="col-6 col-lg-3">
                        <div style={ styles.swatch } onClick={ () => setDisplayInputFieldFontColor(!displayInputFieldFontColor) }>
                            <div style={{ ...styles.color, background: inputFieldFontColor }} />
                        </div>
                        { displayInputFieldFontColor ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ () => setDisplayInputFieldFontColor(false) }/>
                            <SketchPicker
                                onChange={(color) => {
                                    handleColorChange(color.rgb, 'inputFieldFontColor')
                                }}
                                color={inputFieldFontColor}
                            />
                        </div> : null }
                    </div>
                    {/* // aici reserv */}

                    <div className="col-6 col-lg-3">
                        <h6>Select a font: </h6>
                    </div>
                    <Select
                        className="col-6 col-lg-3"
                        options={availableFonts}
                        placeholder={selectedFont}
                        onChange = {(e) => {
                            setSelectedFont(e)
                            reservationWidgetFont.widget_reservation_font = e.value
                            clearTimeout(timer)

                            const newTimer = setTimeout(() => {
                                api.endpoints.getReservationCustomWidgetPreview(reservationWidgetColors, reservationWidgetFont, locationHash, (response) => {
                                    setWidgetPreview(response)
                                    setLoading(false)
                                })
                            }, 500)
                            setTimer(newTimer)
                            }
                        }
                    />

                    <hr style={{width:"100%"}}/>

                    <div className="col-6 col-lg-6">
                        <Button
                            onClick={() => {
                                saveReservationWidgetColors()
                                // saveReservationWidgetFont()
                            }}
                        >
                            Save widget
                        </Button>
                    </div>
                </Row>
                <Row>
                    <CustomLoader loading={loading}>
                        <iframe
                            className="col-12"
                            srcDoc={widgetPreview}
                            style={iframeStyle}
                            title="reservation"
                        />
                    </CustomLoader>
                </Row>
            </div>
        </Container>
    )
})

export default ReservationWidget;