import React from "react";
import ReactDOM from "react-dom";
import Popup from 'react-popup';
import "bootstrap/dist/css/bootstrap.css";
import "./assests/scss/index.scss";
import 'emoji-mart/css/emoji-mart.css'
import { Provider } from "react-redux";
import store from "./store";
import actionCable from "actioncable"
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";


var baseURL 
let wsURL    

if (process.env.NODE_ENV !== "production")
{ 
  baseURL = "http://localhost:3000" + "/api/v1";
  wsURL = "ws://localhost:3000";
} 
else
{ 
  if (process.env.REACT_APP_API_HOST) {
    baseURL =  process.env.REACT_APP_API_HOST + "/api/v1";
    wsURL =  "wss://app-staging.sportcompass.net";
  } else {
    baseURL = "https://app.sportcompass.net" + "/api/v1";
    wsURL = "wss://app.sportcompass.net";
  }
}

axios.defaults.baseURL = baseURL

const userToken = JSON.parse(sessionStorage.getItem("userToken"));
if (userToken != null) {
  const AuthStr = "Bearer ".concat(userToken);
  axios.defaults.headers.common["Authorization"] = AuthStr;
}

if (process.env.NODE_ENV !== "production") {
  axios.interceptors.request.use(
    request => {
      // Edit request config
      return request;
    },
    error => {
      console.log(error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      // Edit request config
      return response;
    },
    error => {
      console.log(error);
      return Promise.reject(error);
    }
  );
}


const CableApp = {};
CableApp.cable = actionCable.createConsumer(`${wsURL}/cable`)

ReactDOM.render(
  <Provider store={store}>
    <App cable={CableApp.cable}/>
  </Provider>,
  document.getElementById("root")
);

ReactDOM.render(
    <Popup />,
    document.getElementById('popupContainer')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
