import React, { Component } from "react";
import CustomInputText from "../../components/CustomInput";
import moment from "moment-timezone";
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import CustomInputDate from "../../components/CustomInputDate";
import * as actionCreators from "../../modules/actions";
import { connect } from "react-redux";
import {dtLocalToDtz, dtSetZone, dtzWithLocalZone} from "../../utils/utils";
import ReactSelect from "react-select";

/**Register the locale to display calendar in UK format, week starting with Monday */
registerLocale("en-GB", enGB);
const images = require.context("../../assests/images", true);

class ReservationsFilter extends Component {
    constructor(props) {
        super(props);
        let reservationsDefStartDate = dtzWithLocalZone(moment().tz(props.timezone).startOf('day'));
        this.state = {
            tagFilters: [],
            searchText: '',
            fromDate: reservationsDefStartDate,
            toDate: this.props.reservationsDefEndDate,
            ARFilter: ''
        };
    }

    componentDidMount() {
        this.filter();
    }

    filter = () => {
        const filterData = {
            tags: this.state.tagFilters.slice(),
            start_date: dtSetZone(this.state.fromDate, this.props.timezone),
            end_date: dtSetZone(this.state.toDate, this.props.timezone),
        };
        this.props.filterReservations(filterData);
    }

    setARFilter = (e) => {
        const _filters = this.state.tagFilters.slice();
        this.setState(
            {tagFilters: this.arrayAdd(_filters, e)},
            this.filter
        )
    }

    arrayRemove = (arr, value) => {
        return arr.filter(ele => ele !== value);
    };

    arrayAdd = (arr, value) => {
        if (!arr.includes(value)) {
            arr.push(value);
        }
        return arr;
    };

    onSearchInput = event => {
        this.setState({ searchText: event.target.value });
    };

    onSearchKeyDown = event => {
        if (event.keyCode === 13)
            this.onSearchClick();
    };

    onSearchClick = () => {
        if (this.state.searchText) {
            const inText = this.state.searchText.slice();
            const _filters = this.state.tagFilters.slice();
            this.setState(
                { tagFilters: this.arrayAdd(_filters, inText), searchText: '' },
                this.filter
            );
        }
    };

    removeTagFilter = item => {
        const _filters = this.state.tagFilters.slice();
        if (_filters.includes(item)) {
            this.setState(
                {tagFilters: this.arrayRemove(_filters, item)},
                this.filter
            );
        }
    };

    resetFilters = () => {
        let reservationsDefStartDate = dtzWithLocalZone(moment().tz(this.props.timezone).startOf('day'));
        this.setState(
            {
                tagFilters: [],
                searchText: '',
                fromDate: reservationsDefStartDate,
                toDate: this.props.reservationsDefEndDate,
                ARFilter: ''
            },
            this.filter
        );
    };

    renderTagFilters = () =>
        this.state.tagFilters.map((item, index) => (
            <button
                type="button"
                className="btn btn-info btn-sm ml-2 bg--grad4 fs-btn2"
                onClick={() => this.removeTagFilter(item)}
                key={"_usrFltr" + index}
            >
                <span className="mr-2 text--white">{item}</span>
                <span><img src={images("./img/closeIcon.svg")} alt="close" /></span>
            </button>
        ));

    handleFromDateSelect = date => {
        if(date !== null){
            this.setState(
                {fromDate: dtzWithLocalZone(dtLocalToDtz(date, this.props.timezone).startOf("day"))},
                this.filter
            );
        }
    };

    handleToDateSelect = date => {
        if(date !== null){
            this.setState(
                {toDate: dtzWithLocalZone(dtLocalToDtz(date, this.props.timezone).endOf("day"))},
                this.filter
            );
        }
    };

    render() {
        return (
            <div className="pt-2 panel-sticky bg--gray1">
                <div className=" w-100 d-flex justify-content-between pl-3 mt-2 mb-2 pr-3 filters">
                    <CustomInputText
                        className="w-25"
                        onChange={this.onSearchInput}
                        keyPress={this.onSearchKeyDown}
                        value={this.state.searchText}
                        onBtnClick={this.onSearchClick}
                        placeholder="Search for a reservation"
                    />
                    
                    <div className="d-flex w-75">
                        <ReactSelect 
                            className='w-50 ml-2 filters' 
                            title='Filter'
                            options={ [ 
                                {value: 'Rejected', label: 'Rejected'}, 
                                {value: 'Approved', label:'Approved'}
                            ]}
                            placeholder= 'Select filter'
                            onChange = {(e) => {
                                this.setARFilter(e.label)
                                this.setState({ARFilter: e})}
                            }
                        />
                        <div className="ml-2 w-25" title={"From date (" + this.props.timezone + ')'}>
                            <DatePicker
                                selected={this.state.fromDate}
                                dateFormat="dd/MM/yyyy"
                                strictParsing={true}
                                popperPlacement="top-end"
                                onChange={this.handleFromDateSelect}
                                locale="en-GB"
                                useWeekdaysShort={true}
                                monthsShown={1}
                                customInput={<CustomInputDate />}
                                minDate={this.props.reservationsMinDate}
                                maxDate={this.state.toDate}
                            />
                        </div>
                        <div className="ml-2 w-25" title="To date">
                            <DatePicker
                                selected={this.state.toDate}
                                dateFormat="dd/MM/yyyy"
                                popperPlacement="top-end"
                                strictParsing={true}
                                onChange={this.handleToDateSelect}
                                locale="en-GB"
                                useWeekdaysShort={true}
                                monthsShown={1}
                                customInput={<CustomInputDate />}
                                minDate={this.state.fromDate}
                            />
                        </div>
                        <div className="ml-2 w-25 pl-5 d-flex align-items-center">
                            <u className="element--clickable" onClick={this.resetFilters}>
                                Reset Filters
                            </u>
                        </div>
                    </div>
                </div>
                <div className="container p-0 pr-4 pl-1 w-100 mw-100 h-100">
                    <div className="form-inline mb-1">{this.renderTagFilters()}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        _reservationsFilters: state.reservationsFilters,
        reservationsDefEndDate: state.reservationsDefEndDate,
        reservationsMinDate: state.reservationsMinDate,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getReservationsFilters: () => dispatch(actionCreators.getReservationsFilters()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationsFilter);
