import React, { useState, useEffect, useRef } from "react";
import * as api from "../../utils/api";
import SettingHeader from "./SettingsHeader";
import CustomLoader from "../../components/CustomLoader";
import {Alert, Card, Container, Col, Row, ToggleButton, ToggleButtonGroup, Tab, Tabs, Button} from 'react-bootstrap'
import CustomWidget from "./CustomWidget";
import ReservationWidget from "./ReservationWidget";
import CustomAttributeToggle from "../../components/CustomAttributeToggle";

const WidgetOptions = {
  default: "Default Widget",
  white_black: "White Black Widget",
  black_white: "Black White Widget",
  blue_black: "Blue Black Widget",
  custom: "Custom Widget"
}

const ReservationEventWidgets = {
  reservations: {
    image: "./widget-icon.svg",
    imageAlt: "Widget",
    title: "Reservations Widget"
  },
  events:{
    image: "./widget-icon.svg",
    imageAlt: "Widget",
    title: "Events Widget"
   },
}

const filterOptions = {
  onlyToday: "?filter=onlyToday",
  onlySports: "?filter=onlySports",
  onlyEvents: "?filter=onlyEvents",
}

const filterReservationOptions = {
  allEvents: "?filter=allEvents",
  onlyToday: "?filter=onlyToday",
}

const Widget = props => {
  const childRef = useRef(null);
  const [show, setShow] = useState(false);
  const [locationHash, setLocationHash] = useState(null)
  const [locationId, setLocationId] = useState(null)
  const [loading, setLoading] = useState(true);
  const [widgetOption, setWidgetOption] = useState();
  const [widgets, setWidgets] = useState([]);
  const [widgetType, setWidgetType] = useState("events");
  const [preopenDescription, setPreopenDescripton] = useState(false)

  const baseURL = () => {
    if (process.env.NODE_ENV !== "production")
    {
      return "http://localhost:3000/widgets/show/";
    }
    else
    {
      if (process.env.REACT_APP_API_HOST) {
        return  process.env.REACT_APP_API_HOST + "/widgets/show/";
      } else {
        return "https://app.sportcompass.net/widgets/show/";
      }
    }
  }


  const baseURLReservation = () => {
    if (process.env.NODE_ENV !== "production")
    {
      return "http://localhost:3000/widgets/widget_reservation/show/";
    }
    else
    {
      if (process.env.REACT_APP_API_HOST) {
        return  process.env.REACT_APP_API_HOST + "/widgets/widget_reservation/show/";
      } else {
        return "https://app.sportcompass.net/widgets/widget_reservation/show/";
      }
    }
  }

  const getFavoriteWidgetOption = () => {
    api.endpoints.getFavoriteWidgetOption((options) => {
      setWidgetOption(options.widget_option)
      setPreopenDescripton(options.description_preopening)
    })
  }

  useEffect(() => {
    api.endpoints.getLocationWidget(
      (location) => {
        setLocationHash(location.location_hash);
        setLocationId(location.id);
        setLoading(false);
      }
    );

    api.endpoints.getWidgets((widgets_response) => {
      setWidgets(widgets_response)
    })

    getFavoriteWidgetOption()
  }, []);

  const setFavoriteWidget = (e) => {
    api.endpoints.setFavoriteWidget(e.currentTarget.value, (response) => {
      setShow(true);
    })
    setWidgetOption(e.currentTarget.value)
  }

  const handleDescriptionPreopening = (checked) => {
    api.endpoints.setWidgetDescriptionPreopen(checked, () => {
      if (widgetOption === "custom") {
        childRef.current.setPreview()
      }
      setPreopenDescripton(checked)
    })
  }

  const renderWidgetCode = (widgetOption, filter='') => {
    // code for default events widget (all events and only today)
    if (widgetOption === 'default') {
      switch (filter) {
        case '':
          return (
            <textarea
                    readOnly
                    type="text"
                    className="form-control align-top"
                    placeholder="Your default footer"
                    rows="15"
                    value={`<div id="sc_events"></div>
  <script>
    (function (w, d, s, o, f, js, fjs) {
      w['JS-Widget'] = o;
      w[o] = w[o] || function () {
        (w[o].q = w[o].q || []).push(arguments)
      };
      js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
      js.id = o;
      js.src = f;
      js.async = 1;
      fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', 'mw', 'https://sportcompass.net/widget/widget.js'));
    mw('getfixtures',` + locationId + `);
  </script>`}
                  />
          )

        case filterOptions.onlyToday:
          return (
            <textarea
                      readOnly
                      type="text"
                      className="form-control align-top"
                      placeholder="Your default footer"
                      rows="15"
                      value={`<div id="sc_events"></div>
    <script>
      (function (w, d, s, o, f, js, fjs) {
        w['JS-Widget'] = o;
        w[o] = w[o] || function () {
          (w[o].q = w[o].q || []).push(arguments)
        };
        js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
        js.id = o;
        js.src = f;
        js.async = 1;
        fjs.parentNode.insertBefore(js, fjs);
      }(window, document, 'script', 'mw', 'https://sportcompass.net/widget/widget.js'));
      mw('getfixtures', {id:` + locationId + `, onlyToday: true});
    </script>`}
                    />
          )
    }
  }
    // code for new events widgets including filters (only today, only custom events, only sports)
    return (
      <textarea
              readOnly
              type="text"
              className="form-control align-top"
              placeholder="Your default footer"
              rows="13"
              value={`<div id="sc_events"></div>
<script>
async function fetchWidget(){
let e=await fetch("` + baseURL() + locationHash + filter +`");
if(200===e.status){
  let t=await e.text();
  document.getElementById("sc_events").innerHTML=t}
var t=document.getElementById("sc_events").getElementsByTagName("script")[0],a=document.getElementsByTagName("head")[0],c=document.createElement("script");
c.type="text/javascript",c.src=t.src,a.appendChild(c)
}
fetchWidget();
</script>`}
          />
    )
  }

  //code for new reservation widget (only today, all avents)
  const renderReservationWidgetCode = (filter='') => {
    return (
      <textarea
              readOnly
              type="text"
              className="form-control align-top"
              placeholder="Your default footer"
              rows="13"
              value={`<iframe id="sc_reservations" style="width: 650px; height: 650px; border: none" sandbox="allow-forms allow-scripts allow-popups"></iframe>
<script>
async function fetchWidget(){
let e=await fetch("` + baseURLReservation() + locationHash + filter +`");
if(200===e.status){
  let t=await e.text();
  document.getElementById("sc_reservations").srcdoc = t;}
var a=document.getElementsByTagName("head")[0],c=document.createElement("script");
c.type="text/javascript",a.appendChild(c)
}
fetchWidget();
</script>`}
          />
    )
  }

  return (
    <div className="d-flex bg-secondary w-75 h-100 ml-5" style={{overflow: 'scroll'}}>
      <div className="w-75 mr-5 ml-2 bg-secondary">
        <Alert show={show} variant="success" onClose={() => setShow(false)} style={{ marginTop: '10px' }} dismissible>
          <Alert.Heading>Widget updated successfully</Alert.Heading>
        </Alert>
        <ToggleButtonGroup type="checkbox" name="widgets" style={{marginTop: '10px'}}>
          {
            Object.values(ReservationEventWidgets).map(type => {
              return(
              <ToggleButton
              key={type.title}
              id={type.title}
              className="mb-2"
              variant="outline-primary"
              value={type.title}
              onClick={() => setWidgetType(type)}
              style={{backgroundColor:"#fff"}}
              >
                <SettingHeader
                image = {type.image}
                imageAlt = {type.imageAlt}
                title = {type.title}
                />
              </ToggleButton>
              )
            })
          }
        </ToggleButtonGroup>
          
        {widgetType === ReservationEventWidgets.events ? (
        <CustomLoader loading={loading}>
          <div>
            <ToggleButtonGroup type="checkbox" value={widgetOption} name='widgets' style={{marginTop: '10px'}}>
              {
                Object.values(widgets).map(widget => {
                  return (
                    <ToggleButton
                      key={widget.name}
                      id={widget.name}
                      className="mb-2"
                      variant="outline-primary"
                      value={widget.name}
                      onChange={(e) => setFavoriteWidget(e)}
                    >
                      {WidgetOptions[widget.name]}
                    </ToggleButton>
                  )
                })
              }
            </ToggleButtonGroup>
          </div>

          <Tabs defaultActiveKey="all_events" id="uncontrolled-tab-example" className="mb-3">
            <Tab
              eventKey="all_events"
              title="All events"
              className="tabs-container h-100 events-tab-header"
              headerClass="tabs-header"
              activeHeaderClass="tabs-header--active"
              contentClass="tabs-content"
            >
              { renderWidgetCode(widgetOption) }
            </Tab>
            <Tab eventKey="today" title="Today's events">
              { renderWidgetCode(widgetOption, filterOptions.onlyToday) }
            </Tab>
            <Tab eventKey="sports" title="Sports only">
            { renderWidgetCode(widgetOption, filterOptions.onlySports) }
            </Tab>
            <Tab eventKey="events" title="Custom events">
            { renderWidgetCode(widgetOption, filterOptions.onlyEvents) }
            </Tab>
          </Tabs>

          <div className="col-12 col-lg-12">
            <hr/>
            <h4 className="font-color--grey font-weight-bold text-center mt-3">
              Preferences</h4>
            <br/>
            <CustomAttributeToggle
                text={"Pre-open descriptions"}
                enabled={preopenDescription}
                switchClass="feature-switch"
                onToggle={checked => {handleDescriptionPreopening(checked)}}
                tooltip="If checked, custom events descriptions will be visible by default."
            />
            <hr/>
          </div>

          { widgetOption === "custom" ? <CustomWidget locationHash={locationHash} ref={childRef}/> :
              <Container style={{marginTop: '10px'}}>
                <Row>
                  {
                    Object.values(widgets).filter(widget => widget.name !== 'custom').map(widget => {
                      return (
                          <Col xs={10} md={10} lg={4} style={{margin: '10px'}} key={widget.name}>
                            <Card style={{height: "100%"}}>
                              <Card.Img variant="top" src={widget.image} style={{flex: 1}}/>
                              <Card.Body style={{flex: "0 1 auto"}}>
                                <Card.Title>{WidgetOptions[widget.name]}</Card.Title>
                              </Card.Body>
                            </Card>
                          </Col>
                      )
                    })
                  }
                </Row>
              </Container>
          }
        </CustomLoader>
        ) : (
        <div>
        <Tabs defaultActiveKey="all_reservations" id="uncontrolled-tab-example" className="mb-3">
            <Tab
              eventKey="all_reservations"
              title="Reservation widget code"
              className="tabs-container h-100 events-tab-header"
              headerClass="tabs-header"
              activeHeaderClass="tabs-header--active"
              contentClass="tabs-content"
            >
              { renderReservationWidgetCode(filterReservationOptions.allEvents) }
            </Tab>
            {/* <Tab eventKey="today" title="Today's events">
              { renderReservationWidgetCode(filterReservationOptions.onlyToday) }
            </Tab> */}
          </Tabs>
           
        <ReservationWidget locationHash={locationHash} ref={childRef} /> 
        </div> 
        
        )}
      </div>
    </div>
  );
};

export default Widget;
