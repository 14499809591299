import React from "react";
const images = require.context("../../../../assests/images/img/events", true);
const SocialItem = ({ image, name }) => {
  return (
    <div className="eventlist-socials__wrapper justify-content-center  element--clickable">
      <div
        className={`eventlist-socials__icon eventlist-socials__icon-${name} element--clickable d-flex justify-content-center align-items-center`}
      >
        <img src={image} alt={name} />
      </div>
    </div>
  );
};
const SocialsInEvents = props => {
  return (
    <div className="eventlist-socials col d-flex align-items-center justify-content-center">
      {/* Meetup icon disabled for now */}
      {/* <SocialItem image={images("./meetup.svg")} name="meetup" /> */}
      <SocialItem image={images("./instagram.svg")} name="instagram" />
      <SocialItem image={images("./facebook.svg")} name="facebook" />
      <SocialItem image={images("./sportcompass.svg")} name="sportcompass" />
    </div>
  );
};

export default SocialsInEvents;
