import React, { useState, useEffect } from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";

const DEFAULT_WEEK_DAYS = [
    {
        key: 0,
        title: 'Sunday',
        symbol: 'S'
    },
    {
        key: 1,
        title: 'Monday',
        symbol: 'M'
    },
    {
        key: 2,
        title: 'Tuesday',
        symbol: 'T'
    },
    {
        key: 3,
        title: 'Wednesday',
        symbol: 'W'
    },
    {
        key: 4,
        title: 'Thursday',
        symbol: 'T'
    },
    {
        key: 5,
        title: 'Friday',
        symbol: 'F'
    },
    {
        key: 6,
        title: 'Saturday',
        symbol: 'S'
    }
]

const WeekDaysSelector = ({ weekDaysRepetition, onDayClicked }) => {
    const [selectedDays, selectDay] = useState([])

    const handleDayClicked = (day) => {
        selectDay(prevState => [...prevState, day.key])
    }

    useEffect(() => {
        onDayClicked(selectedDays)
    }, [selectedDays])

    return (
        <div
            data-testid='recurrence-week-days-selector'
        >
            {DEFAULT_WEEK_DAYS.map((day) => (
                <ToggleButtonGroup type="checkbox"  onChange={() => handleDayClicked(day)}>
                    <ToggleButton
                        key={`${day.key}-btn`}
                        value={day.key}
                        className="togglebutton-delete_modal"
                        data-testid={`weekdays-${day.key}`}
                        checked={weekDaysRepetition.indexOf(day.key) === -1}
                    >
                        {day.symbol}
                    </ToggleButton>
                </ToggleButtonGroup>
            ))}
        </div>
    )
}

export default WeekDaysSelector
