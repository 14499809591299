import React from "react";
import * as actionCreators from "../modules/actions";
import { connect } from "react-redux";
import Loader from 'react-loader-spinner'

const images = require.context("../assests/images", true);

const MessageDialog = props => {
  if (props.show)
    return (
      <div>
        <div
          className="modal-backdrop bg-secondary opacity6"
          onClick={props.loading ? null : props.hideNotice}
        />
        <div
          className="modal modal--first d-block"
          onClick={props.loading ? null : props.hideNotice}
        >
          <div className="modal-dialog">
            <div className={"modal-content pl-5 pr-5 pt-3 pb-3 m-auto " +
              (props.loading ? "" : (props.success ? "bg--grad8" : "bg--red1"))}>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="p-3">
                  {props.loading ? (
                    <Loader
                      type="TailSpin"
                      color="#0084f4"
                      height="64"
                      width="64"
                    />
                  ) : props.success ? (
                    <img src={images(`./img/message-dialog-success.svg`)} alt="success" />
                  ) : (
                    <img src={images(`./img/message-dialog-error.svg`)} alt="error" />
                  )}
                </div>
                <p className={"fs-body1 " + (props.loading ? "" : "text--white")}>
                  {props.message}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return null;
};

const mapStateToProps = state => {
  return {
    show: state.modal.show,
    message: state.modal.message,
    success: state.modal.success,
    loading: state.modal.loading
  };
}

const mapDispatchToProps = dispatch => {
  return {
    hideNotice: () =>
      dispatch(actionCreators.hideNotice()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageDialog);
