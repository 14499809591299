export const ConfirmRepick = () => {
  window.ShowConfirmation = (message, callback) => {
    const RemoveConfirmation = () => {
      let dialogs = document.getElementsByClassName("confirm-repick");
      for (let i = 0; i < dialogs.length; i++) {
        dialogs[i].remove();
      }
      let dialogs2 = document.getElementsByClassName("cr-overlay");
      for (let i = 0; i < dialogs2.length; i++) {
        dialogs2[i].remove();
      }
    };
    let overlay = document.createElement("div");
    overlay.className = "cr-overlay";
    let outerContainer = document.createElement("div");
    outerContainer.className = "confirm-repick";
    let textContainer = document.createElement("div");
    textContainer.className = "cr-text p-4 text-center";
    let buttonContainer = document.createElement("div");
    buttonContainer.className =
      "cr-buttons col-12 mb-3 text-center justify-content-center";
    let textParagraph = document.createElement("p");
    textParagraph.className = "cr-p";
    textParagraph.textContent = message;
    textContainer.appendChild(textParagraph);
    outerContainer.appendChild(textContainer);
    let buttonOK = document.createElement("button");
    buttonOK.className =
      "button--success mr-4 no-border-button bootstrap-buttons---btn bootstrap-buttons---btn-md bootstrap-buttons---btn-default";
    buttonOK.textContent = "OK";
    buttonOK.onclick = () => {
      RemoveConfirmation();
      return !!callback ? callback(true) : true;
    };
    let buttonCancel = document.createElement("button");
    buttonCancel.className =
      "button--warning no-border-button bootstrap-buttons---btn bootstrap-buttons---btn-md bootstrap-buttons---btn-default";
    buttonCancel.textContent = "Cancel";
    buttonCancel.onclick = () => {
      RemoveConfirmation();
      return !!callback ? callback(false) : true;
    };
    buttonContainer.appendChild(buttonOK);
    buttonContainer.appendChild(buttonCancel);
    outerContainer.appendChild(buttonContainer);

    // Add our confirmation dialog into the body
    document.getElementsByTagName("body")[0].appendChild(overlay);
    document.getElementsByTagName("body")[0].appendChild(outerContainer);

    // Show the confirmation dialog
    outerContainer.className = "confirm-repick open";
    overlay.className = "cr-overlay open";
  };
};
