import React, { useState, useEffect, useReducer } from "react";
import "react-datepicker/dist/react-datepicker.css";
import * as api from "../../utils/api";
import axios from "axios";

const sportsimages = require.context(
  "../../assests/images/img/event-type/",
  true
);

const SportsFilter = props => {
    const prefReducer = (state, action) => {
      switch (action.type) {        
        case "SPORTS":
          return {
            ...state,
            sports: action.payload
          };
        case "SPORTSFAV":
          return {
            ...state,
            favsports: action.payload
          };       
        default:
          return state
      }
    };

    const [preferences, dispatch] = useReducer(prefReducer, {
      sports: [],
      favsports: [],
    });

    useEffect(() => {   
      async function getSports() {
        let res = await axios.get("/sports");
        dispatch({
          type: "SPORTS",
          payload: res.data.sport_objects
        });
      }
      async function getFavSports() {
        let res = await axios.get("/favorite_sports");
        dispatch({
          type: "SPORTSFAV",
          payload: res.data.favorite_sports
        });
      }
      getSports();
      getFavSports();
    }, []);  

    const updateSports = (favSportId, sportId, crudType) => {
      api.endpoints.updateSports(favSportId, sportId, crudType, res =>
        dispatch({
          type: "SPORTSFAV",
          payload: res
        })
      );
    };

    const handleSportSelection = (favSportId, sportId, e) => { 
      if (favSportId) {
        updateSports(favSportId, sportId, "delete");
      } else {
        updateSports(sportId, sportId, "add");   
      }
    };

    const RenderSports = props => {
      const { id, name, favSport, iconName } = props;
      return (
        <li
          className="bg-white  mb-3 channel-list__border element--clickable highlight_on_hover"
          onClick={event => handleSportSelection(favSport && favSport.id, id, event)}
          key={"_usrFltr" + id}
        >
          <div className="d-flex  p-2 align-items-center ">
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                readOnly
                className="align-self-center border-right ml-1 mr-3"
                checked={favSport}
              />
              <span className="checkmark"></span>
            </div>
            <div className="align-self-start border-right w-20 ml-1 pr-3">
              <img 
                height="35px"
                width="35px"
                className="event-item__sport-type" 
                src={sportsimages('./' + iconName)} 
                alt="sport icon" 
              />
            </div>
            <div className="m-auto ">
              <h3 className="channel-names">{name}</h3>
            </div>
          </div>
        </li>
      );
    };
    
    return (
        <ul>
          {
            (preferences.sports || []).map((sportItem, index) => {         
              let favSport=preferences.favsports.find(favsp => favsp.sport_object_id == sportItem.id);
              return (
                <RenderSports
                  id={sportItem.id}
                  name={sportItem.short_name}
                  key={sportItem.short_name + index}
                  favSport={favSport}
                  iconName={sportItem.image_name || "custom.svg"}
                />
              );})
          }            
        </ul>
    );
  };
  
  export default SportsFilter;
  
