import React, { Fragment } from "react";

const images = require.context("../assests/images", true);
const VideoFrameUploadable = (props) => {
	const width = props.width || "80px";
	const height = props.height || "80px";

	return (
		<Fragment>
			{props.src !== null ? (
				<div
					className="d-flex align-items-center justify-content-center imageUploader position-relative mr-2"
					style={{
						width: width,
						height: height,
						backgroundColor: "none",
					}}
				>
					{props.changeable && (
						<img
							className="mw-100 mh-100 imageUploader__removeButton"
							src={images("./img/remove_image_icon.svg")}
							alt="close"
							onClick={() => {
								props.changeVideo(null);
							}}
						/>
					)}
					<video className="w-100 h-100 bg-white" controls>
						<source
							src={
								typeof props.src === "object"
									? URL.createObjectURL(props.src)
									: props.src
							}
							type="video/mp4"
						/>
						Your browser does not support the video tag.
					</video>
				</div>
			) : (
				props.changeable && (
					<label
						className="d-flex align-items-center justify-content-center imageUploader position-relative mr-2"
						style={{
							width: width,
							height: height,
							backgroundColor: "none",
						}}
					>
						<input
							style={{
								display: "none",
							}}
							type="file"
							name="video"
							accept="video/*"
							onChange={(event) =>
								props.changeVideo(event.target.files[0])
							}
						/>
						<div className="w-100 h-100 d-flex align-items-center justify-content-center">
							<img
								className="user-select-none"
								src={images("./img/add_image.svg")}
							/>
						</div>
					</label>
				)
			)}
		</Fragment>
	);
};

export default VideoFrameUploadable;
