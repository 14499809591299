import React, { useState } from 'react'
import { Modal, Button, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import * as api from "../utils/api";

const DeleteModal = (props) => {
    const [deleteMode, setDeleteMode] = useState(0)

    const cancelModal = () => {
        props.cancelModal()
    }

    const deleteRecurring = (meetupIds, deleteMode) => {
        if (meetupIds.length > 1) {
            api.endpoints.deleteMultipleMeetups(null, meetupIds, deleteMode, () => {
                props.refreshEvents()
            })
        } else {
            props.deleteCustomEvent(props.selectedEvent, deleteMode)
        }
    }

    return (
        <Modal show={true} onHide={cancelModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Delete recurring event</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
                <h4>Which event(s) do you want to delete?</h4>
                { props.selectedEvent.length > 1 && <h6 style={{ color: "grey" }}>Be aware! Your option will be applied to all selected recurring events! </h6>}

                <ToggleButtonGroup type="radio" name='deleteEvents' style={{ marginTop: '10px' }} vertical>
                    <ToggleButton
                        key={"this_event"}
                        id={"this_event"}
                        className="mb-2"
                        variant="outline-primary"
                        value={1}
                        onChange={(e) => setDeleteMode(e.target.value)}
                    >
                        This event
                    </ToggleButton>
                    <ToggleButton
                        key={"this_and_following"}
                        id={"this_and_following"}
                        className="mb-2"
                        variant="outline-primary"
                        value={2}
                        onChange={(e) => setDeleteMode(e.target.value)}
                    >
                        This and following events
                    </ToggleButton>
                    <ToggleButton
                        key={"all_events"}
                        id={"all_events"}
                        className="mb-2"
                        variant="outline-primary"
                        value={3}
                        onChange={(e) => setDeleteMode(e.target.value)}
                    >
                        All events
                    </ToggleButton>
                </ToggleButtonGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancelModal}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        deleteRecurring(props.selectedEvent, deleteMode)
                        cancelModal()
                    }}
                    disabled={deleteMode === 0}
                >
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteModal
