import React, { Fragment } from "react";

const images = require.context("../assests/images", true);
const UploadableVideoFrame = (props) => {
	const width = props.width || "80px";
	const height = props.height || "80px";

	return (
		<Fragment>
			<div
				className="d-flex align-items-center justify-content-center imageUploader position-relative mr-2"
				style={{
					width: width,
					height: height,
					backgroundColor: "none",
				}}
			>
				{props.removable && (
					<img
						className="mw-100 mh-100 imageUploader__removeButton"
						src={images("./img/remove_image_icon.svg")}
						alt="close"
						onClick={() => props.removeVideo(null)}
					/>
				)}
				<video className="w-100 h-100 bg-white" controls>
					<source
						src={
							typeof props.src === "object"
								? URL.createObjectURL(props.src)
								: props.src
						}
						type="video/mp4"
					/>
					Your browser does not support the video tag.
				</video>
			</div>
		</Fragment>
	);
};

export default UploadableVideoFrame;
