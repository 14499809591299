import React from "react";
import Switch from "react-switch";

const CustomSwitch = (props) => {
	return (
		<Switch
			checked={props.checked}
			className={props.className}
			onHandleColor={props.onHandleColor ? props.onHandleColor : "#769BF9"}
			offHandleColor={
				props.changeOffHandleColor ? props.changeOffHandleColor : "#959BAB"
			}
			offColor="#FFFFFF"
			onColor="#FFFFFF"
			height={19}
			width={40}
			checkedIcon={false}
			uncheckedIcon={false}
			onChange={props.onChange}
			disabled={props.selectable == null ? false : !props.selectable}
		/>
	);
};

export default CustomSwitch;
