import * as actionTypes from "./actionTypes";
import * as api from "../utils/api";
import axios from "axios";
import { Filters } from "../utils/constants";
import moment from "moment-timezone";

export const initState = () => {
	return {
		type: actionTypes.INIT_STATE,
	};
};

export const showNotice = (message, success, options) => {
	return {
		type: actionTypes.SHOW_NOTICE,
		message: message,
		success: success,
		loading:
			options === undefined || options.loading === undefined
				? false
				: options.loading,
	};
};

export const hideNotice = () => {
	return {
		type: actionTypes.HIDE_NOTICE,
	};
};

export const refreshMeetups = (res) => {
	return {
		type: actionTypes.RELOAD_MEETUPS,
		payload: res,
	};
};

export const saveMeetups = (res, viewtype) => {
	return {
		type: actionTypes.SAVE_MEETUPS,
		payload: res,
		view: viewtype,
	};
};

export const saveFilteredMeetups = (res, offset) => {
	return {
		type: actionTypes.SAVE_FILTERED_MEETUPS,
		payload: res,
		offset: offset,
	};
};

export const isExistFilteredMeetups = (res) => {
	return {
		type: actionTypes.IS_EXIST_FILTERED_MEETUPS,
		payload: res,
	};
};

export const saveFilteredFixtures = (res, when, offset) => {
	return {
		type: actionTypes.SAVE_FILTERED_FIXTURES,
		payload: res,
		reqTime: when,
		offset: offset,
	};
};

// export const fixturesLoader = res => {
//   return {
//     type: actionTypes.FIXTURES_LOADER,
//     payload: res,
//   };
// };

// export const meetupsLoader = res => {
//   return {
//     type: actionTypes.MEETUPS_LOADER,
//     payload: res,
//   };
// };

export const saveReservations = (res) => {
	return {
		type: actionTypes.SAVE_RESERVATIONS,
		payload: res,
	};
};

export const saveReservationsExist = (res) => {
	return {
		type: actionTypes.SAVE_RESERVATIONS_EXIST,
		payload: res,
	};
};

export const getReservationsFilters = () => {
	return {
		type: actionTypes.GET_RESERVATIONS_FILTERS,
	};
};

export const saveNotifications = (res) => {
	return {
		type: actionTypes.SAVE_NOTIFICATIONS,
		payload: res,
	};
};

export const saveNotificationsExist = (res) => {
	return {
		type: actionTypes.SAVE_NOTIFICATIONS_EXIST,
		payload: res,
	};
};

export const readState = () => {
	return {
		type: actionTypes.READ_STATE,
	};
};

export const getMeetupsFilters = () => {
	return {
		type: actionTypes.GET_MEETUPS_FILTERS,
	};
};

export const saveMeetupsFilters = (res) => {
	return {
		type: actionTypes.SAVE_MEETUPS_FILTERS,
		payload: res,
	};
};

export const getFixturesFilters = () => {
	return {
		type: actionTypes.GET_FIXTURES_FILTERS,
	};
};

export const saveFixturesFilters = (res) => {
	return {
		type: actionTypes.SAVE_FIXTURES_FILTERS,
		payload: res,
	};
};

export const saveFavoriteStation = (res) => {
	return {
		type: actionTypes.SAVE_FAVORITE_STATIONS,
		payload: res,
	};
};

export const saveSports = (res) => {
	return {
		type: actionTypes.SAVE_SPORTS,
		payload: res,
	};
};

export const saveFavoriteSports = (res) => {
	return {
		type: actionTypes.SAVE_FAVORITE_SPORTS,
		payload: res,
	};
};

export const saveFavoriteTeams = (res) => {
	return {
		type: actionTypes.SAVE_FAVORITE_TEAMS,
		payload: res,
	};
};

export const saveFavoriteCompetitions = (res) => {
	return {
		type: actionTypes.SAVE_FAVORITE_COMPETITIONS,
		payload: res,
	};
};

export const getMeetups = (params) => {
	return async (dispatch) => {
		api.endpoints.getMeetups(params.filters, (data) => {
			dispatch(saveMeetups(data.meetups, params.view));
		});
	};
};

export const getFavoriteStations = (params) => {
	return async (dispatch) => {
		try {
			let res = await axios.get("/favorite_stations");
			dispatch(saveFavoriteStation(res.data.favorite_stations));
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	};
};

export const getSports = (params) => {
	return async (dispatch) => {
		try {
			let res = await axios.get("/sports");
			dispatch(saveSports(res.data.sports));
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	};
};

export const getFavoriteSports = (params) => {
	return async (dispatch) => {
		try {
			let res = await axios.get("/favorite_sports");
			dispatch(saveFavoriteSports(res.data.favorite_sports));
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	};
};

export const getFavoriteTeams = (params) => {
	return async (dispatch) => {
		try {
			let res = await axios.get("/favorite_teams");
			dispatch(saveFavoriteTeams(res.data.favorite_teams));
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	};
};

export const getFavoriteCompetitions = (params) => {
	return async (dispatch) => {
		try {
			let res = await axios.get("/favorite_competitions");
			dispatch(saveFavoriteCompetitions(res.data.favorite_competitions));
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	};
};

export const filterMeetups = (params) => {
	return async (dispatch) => {
		let newParams = {};
		newParams.filters = {
			sports: params.filters.sports.slice(),
			tags: params.filters.tags.slice(),
			start_date: params.filters.start_date,
			end_date: params.filters.end_date,
			offset: params.filters.offset,
		};
		// if(params.filters.offset === 0) {
		//   dispatch(meetupsLoader(true));
		// }
		//DEBUG:
		api.endpoints.getMeetups(newParams.filters, (data) => {
			dispatch(isExistFilteredMeetups(data.meetups_exist));
			dispatch(saveFilteredMeetups(data.meetups, params.filters.offset));
		});
	};
};

export const filterFixtures = (params) => {
	return async (dispatch) => {
		try {
			let newParams = {};
			newParams.filters = {
				sports: params.filters.sports.filter(
					(elem) => elem !== Filters.CUSTOM_EVENTS
				),
				tags: params.filters.tags.slice(),
				start_date: params.filters.start_date,
				offset: params.filters.offset,
				end_date: params.filters.end_date,
				only_fav_stations: params.filters.only_fav_stations,
				fav_teams: params.filters.fav_teams,
				fav_competitions: params.filters.fav_competitions,
			};
			// if(params.filters.offset === 0) {
			//   dispatch(fixturesLoader(true));
			// }
			//DEBUG:
			let res = await axios.get(api.urls.fixtures, {
				params: { ...newParams.filters },
			});
			dispatch(
				saveFilteredFixtures(
					res.data.fixtures,
					params.reqTime,
					params.filters.offset
				)
			);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	};
};

// export const filterFixtures = (params) => {
// 	return async (dispatch) => {
// 		try {
// 			let newParams = {};
// 			newParams.filters = {
// 				sports: params.filters.sports.filter(
// 					(elem) => elem !== Filters.CUSTOM_EVENTS
// 				),
// 				tags: params.filters.tags.slice(),
// 				start_date: params.filters.start_date,
// 				offset: params.filters.offset,
// 				end_date: params.filters.end_date,
// 				only_fav_stations: params.filters.only_fav_stations,
// 				fav_teams: params.filters.fav_teams,
// 				fav_competitions: params.filters.fav_competitions,
// 			};
// 			// if(params.filters.offset === 0) {
// 			//   dispatch(fixturesLoader(true));
// 			// }
// 			let res = await axios.get(api.urls.fixtures, {
// 				params: { ...newParams.filters },
// 			});
// 			dispatch(
// 				saveFilteredFixtures(
// 					res.data.fixtures,
// 					params.reqTime,
// 					params.filters.offset
// 				)
// 			);
// 		} catch (ex) {
// 			console.log(ex.response.data.message);
// 		}
// 	};
// };

export const getReservations = (params) => {
	return async (dispatch) => {
		api.endpoints.getReservations(
			params.filters,
			(data) => {
				dispatch(saveReservationsExist(data.data.reservations_exist));
				dispatch(saveReservations(data.data.reservations));
			},
			(err) => {
				params.err(err);
			}
		);
	};
};

export const saveReservationsFilters = (res) => {
	return {
		type: actionTypes.SAVE_RESERVATIONS_FILTERS,
		payload: res,
	};
};

export const getNotifications = () => {
	return async (dispatch) => {
		api.endpoints.getNotifications(
			(res) => {
				dispatch(saveNotificationsExist(res.data.notifications_exist));
				dispatch(saveNotifications(res.data.notification));
				let count = 0;
				res.data.notification.map((item) => {
					item.seen ? null : count++;
				});
				dispatch(saveUnseenNotifications(count));
			},
			(err) => {
				params.err(err);
			}
		);
	};
};

export const saveCable = (res) => {
	return {
		type: actionTypes.SAVE_CABLE,
		payload: res,
	};
};

export const saveUnseenNotifications = (res) => {
	return {
		type: actionTypes.SAVE_UNSEEN_NOTIFICATIONS,
		payload: res,
	};
};

export const saveActiveTabIndex = (res, viewtype) => {
	return {
		type: actionTypes.SAVE_ACTIVE_TABINDEX,
		payload: res,
		view: viewtype,
	};
};

export const saveActiveSettingsMenu = (res) => {
	return {
		type: actionTypes.SAVE_ACTIVE_SETTINGS_MENU,
		payload: res,
	};
};

export const setCurrentClientAccountType = (payload) => ({
	type: actionTypes.SET_CURRENT_CLIENT_TYPE,
	payload,
});

export const setCurrentClientNeedPay = (payload) => ({
	type: actionTypes.SET_CURRENT_CLIENT_NEED_PAY,
	payload,
});

export const setUserTimeZone = (payload) => ({
	type: actionTypes.SET_USER_TIME_ZONE,
	payload,
});

export const setUserTimeFormat = (payload) => ({
	type: actionTypes.SET_USER_TIME_FORMAT,
	payload,
});

export const setUserLanguage = (payload) => ({
	type: actionTypes.SET_USER_LANGUAGE,
	payload,
});

export const setClientId = (payload) => ({
	type: actionTypes.SET_CLIENT_ID,
	payload,
});

export const saveCurrencies = (res) => {
	return {
		type: actionTypes.SAVE_CURRENCIES,
		payload: res,
	};
};

export const getCurrencyData = (res) => {
	return {
		type: actionTypes.GET_CURRENCY_DATA,
		payload: res,
	};
};

export const handleClientLoaded = () => {
	return (dispatch) => {
		try {
			api.endpoints.getCurrentClient((res) => {
				dispatch(setCurrentClientAccountType(res.client_account.role));
				dispatch(setCurrentClientNeedPay(res.client_account.need_pay));
				dispatch(setClientId(res.client_account.client_id));
				// dispatch(setUserTimeZone(''));
				// dispatch(setUserTimeFormat(''));
				if (res.time_settings.timezone && res.time_settings.format) {
					dispatch(setUserTimeZone(res.time_settings.timezone));
					dispatch(setUserTimeFormat(res.time_settings.format));
				} else {
					dispatch(setUserTimeZone(moment.tz.guess()));
					dispatch(setUserTimeFormat("24H"));
				}
			});
		} catch (ex) {
			console.log(
				"export const handleClientLoaded error response:",
				ex.response.data.message
			);
		}
	};
};

export const getCurrencies = (params) => {
	return async (dispatch) => {
		api.endpoints.getCurrencies(
			(data) => {
				dispatch(saveCurrencies(data.data.currencies));
			},
			(err) => {
				params.err(err);
			}
		);
	};
};

export const getCurrency = (params) => {
	return async (dispatch) => {
		api.endpoints.getCurrency(
			params,
			(data) => {
				sessionStorage.setItem("currency_name", data.name);
				sessionStorage.setItem("currency_id", data.currency_id);
				dispatch(getCurrencyData(data));
			},
			(err) => {
				console.log(err);
			}
		);
	};
};

export const updateCurrency = (clientId, currencyId) => {
	return async (dispatch) => {
		api.endpoints.updateCurrency(
			clientId,
			currencyId,
			(data) => {
				dispatch(getCurrencyData(data));
			},
			(err) => {
				clientId.err(err);
			}
		);
	};
};

export const saveHeaderMessage = (res) => {
	return {
		type: actionTypes.SAVE_HEADER_MESSAGE,
		payload: res,
	};
};

export const deleteHeaderMessage = () => {
	return {
		type: actionTypes.DELETE_HEADER_MESSAGE,
		payload: null,
	};
};

export const getHeaderMessage = (meetup) => {
	return async (dispatch) => {
		api.endpoints.getPostHeader(meetup, (data) => {
			dispatch(saveHeaderMessage(data));
		});
	};
};

export const showFakeLoader = (isLoading) => {
	return {
		type: actionTypes.SHOW_FAKE_LOADER,
		payload: { showFakeLoader: isLoading },
	};
};
