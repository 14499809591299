import React, { Component } from "react";
import { Button } from "react-bootstrap-buttons";
import DatePicker, { registerLocale } from "react-datepicker";
import { Formik, Form, Field } from "formik";
import enGB from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import CustomInputDate from "../../components/CustomInputDate";
import CustomInputTime from "../../components/CustomInputTime";
import moment from "moment-timezone";
import * as api from "../../utils/api";
import {
	validateIntegerField,
	validateTextField,
	renderFieldError,
	validatePhoneNumber,
	validateEmail,

} from "../../utils/validation";
import * as actionCreators from "../../modules/actions";
import { connect } from "react-redux";
import { dtzWithLocalZone, dtLocalToDtz } from "../../utils/utils";


/**Register the locale to display calendar in UK format, week starting with Monday */
registerLocale("en-GB", enGB);
/**Store the folder path for webpack created images folder */
const images = require.context("../../assests/images", true);

function emptyReservation(props) {
	return {
		location_id: null,
		name: "",
		phone_number: null,
		email: "",
		start_time: moment().tz(props.timezone).startOf("hour"),
		end_time: moment().tz(props.timezone).add(1, "hour").startOf("hour"),
		description: "",
		attendees_count: null,
		sitting_area: "",
		offers: "",
		reason: "",
	};
}

/**Component displayed as a modal popup with input for 'Reservation for', details, start date/time end date/time
 * for reservation creation end editing
 * @param cancelEditing = called when editing was cancelled when clicked outside or Cancel button clicked
 * @param handleSuccess = called when a reservation was created or updated
 * @param reservation = the reservation that is edited (attributes)
 * @param locationId = current Location id
 *
 * Imported component DatePicker for the date selection feature
 * CustomInputDate sent as prop to DatePicker to render the customized
 * input text field with calendar icon at right
 */
class ReservationEditor extends Component {
	constructor(props) {
		super(props);
		let formData;
		let min_date_time = moment().tz(props.timezone);
		if (props.reservation.id) {
			const { start_time, end_time } = props.reservation;
			const mo_start_time = moment(start_time).tz(props.timezone);
			if (mo_start_time < min_date_time) {
				min_date_time = mo_start_time.clone();
			}
			formData = {
				...props.reservation,
				start_time: mo_start_time,
				end_time: end_time
					? moment(end_time).tz(props.timezone)
					: mo_start_time.clone(),
			};
		} else {
			formData = emptyReservation(props);
			formData.location_id = props.locationId; // current location id for new reservation
		}
		min_date_time.set("minute", Math.floor(min_date_time.minute() / 30.0) * 30);
		formData.min_date_time = min_date_time;
		this.state = { formData: formData };
	}

	saveReservation = (values, actions) => {
		const editor = this;
		function onError(err) {
			editor.props.showNotice(err.response.data.message, false);
			actions.setSubmitting(false);
		}
		function onSuccess() {
			editor.props.handleSuccess();
			editor.props.showNotice(
				`Reservation ${editor.props.reservation.id ? "saved" : "created"}`,
				true
			);
			actions.setSubmitting(false);
		}

		const formData = { ...this.state.formData };
		if (formData.id)
			api.endpoints.updateReservation(formData, onSuccess, onError);
		else api.endpoints.createReservation(formData, onSuccess, onError);
	};

	cancelEditing = () => {
		this.props.cancelEditing();
	};

	deleteReservation = (id) => {
		this.props.deleteReservation(id);
	};

	changeHandler = (field, fieldValue) => {
		const formData = { ...this.state.formData };
		formData[field] = fieldValue;
		this.setState({ formData: formData });
	};

	handleChangeValue = (field, fieldValue, name) => {
		this.changeHandler(field, fieldValue);
		return name;
	};

	validateAttendees(value) {
		return validateIntegerField(value, 0, 999);
	}

	render() {
		const timeFmtForDatePicker =
			this.props.time_format === "24H" ? "HH:mm" : "h:mm a";
		return (
			<div>
				<div className="modal-backdrop bg-secondary opacity6" />
				<div className="modal d-inline-block">
					<div className="modal-dialog-centered modal__custom-event modal__custom-event--dialog-width">
						<div className="modal-content pl-5 pr-5 pt-3 pb-3  m-auto bg--gray1">
							{/*
                            TODO: Either use a current location or load list of locations and add location selection to get the location_id
                            <div>Current location id: {this.props.locationId}</div>
                            <div>Reservation's location id: {this.props.reservation.location_id}</div>
                            */}
							<Formik
								enableReinitialize={true}
								initialValues={{
									reservation_date:
										this.state.formData.start_time.toDate() === undefined || ""
											? ""
											: dtzWithLocalZone(this.state.formData.start_time),
									name:
										this.state.formData.name === undefined || ""
											? ""
											: this.state.formData.name,
									attendees_count: this.state.formData.attendees_count,
								}}
								onSubmit={this.saveReservation}
							>
								{({ errors, touched, isSubmitting, setFieldValue }) => (
									<Form>
										{/* Top horizontal part of the form */}
										<div className="modal-header pl-0 py-3">
											<button 
												type="button" 
												onClick={this.cancelEditing} 
												style={{position: 'absolute',top: '10px',right: '10px', border: "none", background: "white" }} 
											>
												<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> </svg>
											</button>

											<div className="d-flex">
												<div className="pr-3 pl-0 pt-1 col-1 border border-left-0 border-top-0 border-bottom-0">
													<img
														src={images("./img/custom-event.svg")}
														alt="reservation"
														className="icon-img-size"
													/>
												</div>
												{/* Field `Date and time` (start_time) */}
												<div className="d-flex flex-column col-6 border border-left-0 border-top-0 border-bottom-0">
													<label className="fs-body2">Date</label>
													<DatePicker
														name="reservation_date"
														selected={dtzWithLocalZone(
															this.state.formData.start_time
														)}
														dateFormat="dd/MM/yyyy"
														strictParsing={true}
														onChange={(date) => {
															if (date !== null) {
																const date_tz = dtLocalToDtz(
																	date,
																	this.props.timezone
																);
																let newStartDateTime =
																	date_tz < this.state.formData.min_date_time
																		? this.state.formData.min_date_time
																		: date_tz;
																let newEndDateTime = newStartDateTime.clone();
																newEndDateTime.set(
																	"hour",
																	this.state.formData.end_time.hour()
																);
																newEndDateTime.set(
																	"minute",
																	this.state.formData.end_time.minute()
																);
																if (newEndDateTime < newStartDateTime)
																	newEndDateTime = newStartDateTime.clone();
																const formData = { ...this.state.formData };
																formData["start_time"] = newStartDateTime;
																formData["end_time"] = newEndDateTime;
																this.setState({ formData: formData });

																setFieldValue(
																	"reservation_date",
																	dtzWithLocalZone(newStartDateTime),
																	false
																);
																setFieldValue(
																	"end_time",
																	dtzWithLocalZone(newEndDateTime),
																	false
																);
															}
														}}
														locale="en-GB"
														useWeekdaysShort={true}
														monthsShown={1}
														customInput={<CustomInputDate />}
														minDate={dtzWithLocalZone(
															this.state.formData.min_date_time
														)}
													/>
													<div className="text-danger">
														{errors.reservation_date}
													</div>
												</div>
												{/* Field `End date and time` (end_time) */}
												<div className=" d-flex flex-column col-6 ">
													<label className="fs-body2">Time: from, to</label>

													<div className=" d-flex col-11 p-0  ">
														<DatePicker
															selected={dtzWithLocalZone(
																this.state.formData.start_time
															)}
															dateFormat={
																this.props.time_format === "24H"
																	? "HH:mm"
																	: "hh:mm aa"
															}
															timeFormat={
																this.props.time_format === "24H"
																	? "HH:mm"
																	: "hh:mm aa"
															}
															onChange={(date) => {
																if (date !== null) {
																	const date_tz = dtLocalToDtz(
																		date,
																		this.props.timezone
																	);
																	this.changeHandler("start_time", date_tz);
																}
															}}
															showTimeSelect
															showTimeSelectOnly
															timeCaption=""
															customInput={<CustomInputTime />}
															{...(this.state.formData.min_date_time.isSame(
																this.state.formData.start_time,
																"day"
															) && {
																minTime: dtzWithLocalZone(
																	this.state.formData.min_date_time
																),
																maxTime: dtzWithLocalZone(
																	this.state.formData.min_date_time
																		.clone()
																		.endOf("day")
																),
															})}
														/>
														<div className="p-2" />

														<DatePicker
															selected={dtzWithLocalZone(
																this.state.formData.end_time
															)}
															dateFormat={
																this.props.time_format === "24H"
																	? "HH:mm"
																	: "hh:mm aa"
															}
															timeFormat={
																this.props.time_format === "24H"
																	? "HH:mm"
																	: "hh:mm aa"
															}
															onChange={(date) => {
																if (date !== null) {
																	const date_tz = dtLocalToDtz(
																		date,
																		this.props.timezone
																	);
																	this.changeHandler("end_time", date_tz);
																}
															}}
															showTimeSelect
															showTimeSelectOnly
															timeCaption=""
															customInput={<CustomInputTime />}
															{...(this.state.formData.min_date_time.isSame(
																this.state.formData.start_time,
																"day"
															) && {
																minTime: dtzWithLocalZone(
																	this.state.formData.min_date_time
																),
																maxTime: dtzWithLocalZone(
																	this.state.formData.min_date_time
																		.clone()
																		.endOf("day")
																),
															})}
														/>
													</div>
												</div>
											</div>
										</div>
										{/* Vertical part of the form */}
										<div className="modal-body px-0 ml-0 bg--gray1">
											{/* Field `Reservation for` (name) */}
											<div className="form-group ">
												<label className="fs-body2">Reservation for</label>
												<Field
													type="text"
													name="name"
													className="form-control"
													placeholder=""
													value={this.state.formData.name}
													validate={validateTextField}
													onChange={(event) => {
														setFieldValue(
															this.handleChangeValue(
																"name",
																event.target.value,
																"name"
															),
															event.target.value
														);
													}}
													maxLength={300}
												/>
												<div>
													{renderFieldError(
														touched["name"],
														errors["name"],
														"Reservation for",
														"name"
													)}
												</div>
											</div>
											{/* Field `Phone number` (phone_number) */}
											<div className="form-group ">
												<label className="fs-body2">Phone number</label>
												<Field
													type="text"
													name="phone_number"
													className="form-control"
													placeholder=""
													value={this.state.formData.phone_number}
													// validate={validatePhoneNumber}
													onChange={(event) => {
														setFieldValue(
															this.handleChangeValue(
																"phone_number",
																event.target.value,
																"phone_number"
															),
															event.target.value
														);
													}}
													maxLength={300}
												/>
												<div>
													{renderFieldError(
														touched["phone_number"],
														errors["phone_number"],
														"Phone number",
														"phone_number"
													)}
												</div>
											</div>
											{/* Field `Email` (email) */}
											<div className="form-group ">
												<label className="fs-body2">Email</label>
												<Field
													type="text"
													name="email"
													className="form-control"
													placeholder=""
													value={this.state.formData.email}
													// validate={validateEmail}
													onChange={(event) => {
														setFieldValue(
															this.handleChangeValue(
																"email",
																event.target.value,
																"email"
															),
															event.target.value
														);
													}}
													maxLength={300}
												/>
												<div>
													{renderFieldError(
														touched["email"],
														errors["email"],
														"Email",
														"email"
													)}
												</div>
											</div>
											{/* Field `Details` (description) */}
											<div className="form-group ">
												<label className="fs-body2">Details</label>
												<textarea
													name="description"
													rows="2"
													cols="250"
													className="form-control align-top"
													placeholder=""
													value={this.state.formData.description}
													maxLength={10000}
													onChange={(event) => {
														this.changeHandler(
															"description",
															event.target.value
														);
													}}
												/>
											</div>
											{/* Field `Number of guests` (attendees_count) */}
											<div className="form-group ">
												<label className="fs-body2">Number of guests</label>
												<Field
													type="text"
													name="attendees_count"
													className="form-control"
													placeholder=""
													value={this.state.formData.attendees_count || ""}
													validate={this.validateAttendees}
													onChange={(event) => {
														const v = event.target.value;
														this.changeHandler("attendees_count", v);
														setFieldValue("attendees_count", v);
													}}
												/>
												<div>
													{renderFieldError(
														touched["attendees_count"],
														errors["attendees_count"],
														"Number of guests",
														"attendees_count"
													)}
												</div>
											</div>
											{/* Field `Sitting area` (sitting_area) */}
											<div className="form-group ">
												<label className="fs-body2">Sitting area</label>
												<Field
													type="text"
													name="sitting_area"
													className="form-control"
													placeholder=""
													value={this.state.formData.sitting_area}
													onChange={(event) =>
														this.changeHandler(
															"sitting_area",
															event.target.value
														)
													}
													maxLength={200}
												/>
											</div>
											{/* Field `Offers` (offers) */}
											<div className="form-group ">
												<label className="fs-body2">Offers</label>
												<Field
													type="text"
													name="offers"
													className="form-control"
													placeholder=""
													value={this.state.formData.offers}
													onChange={(event) =>
														this.changeHandler("offers", event.target.value)
													}
													maxLength={200}
												/>
											</div>
											{/* Field `For event` (reason) */}
											<div className="form-group ">
												<label className="fs-body2">For event</label>
												<Field
													type="text"
													name="reason"
													className="form-control"
													placeholder=""
													value={this.state.formData.reason}
													onChange={(event) =>
														this.changeHandler("reason", event.target.value)
													}
													maxLength={200}
												/>
											</div>
										</div>
										{/* Form actions */}
										<div className="modal-footer p-0 border-0">
											{this.props.reservation.id ? (
											<Button
												type="button"
												className="button-warning--modal p-1 mr-2"
												onClick={() => this.deleteReservation(this.props.reservation.id)}
											>
												Delete
											</Button>) : null} 
											<Button
												type="submit"
												className="button-success--modal p-1"
												disabled={isSubmitting}
											>
												{(this.props.reservation.id ? "Update" : "Create") +
													" reservation"}
											</Button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		showNotice: (message, success) =>
			dispatch(actionCreators.showNotice(message, success)),
	};
};

export default connect(null, mapDispatchToProps)(ReservationEditor);
