import React, { useState, useEffect } from "react";
import * as api from "../../utils/api";
import SettingHeader from "./SettingsHeader";
import PosterSelector from "../../components/PosterSelector";
import Button from "react-bootstrap/esm/Button";
import { transformArrayToHashId } from "../../utils/utils";
import { UploadableImageFrame } from "../../components/UploadableImageFrame";

const PosterSettings = props => {

  const [imageOptions, setImageOptions] = useState({})
  const [selectedSM, setSelectedSM] = useState(1);
  const [selectedPR, setSelectedPR] = useState(1);
  const [whiteLogo, setWhiteLogo] = useState(null);
  const [blackLogo, setBlackLogo] = useState(null);
  const [loadingOption, setLoadingOptions] = useState(true)
  const [loadingSelections, setLoadingSelections] = useState(true)

  useEffect(() => {
    api.endpoints.getPosterOptions(
      (res) => {
        setImageOptions(transformArrayToHashId(res.poster_options));
        setLoadingOptions(false);
      }
    );
    api.endpoints.getPosterSettings(
      (res) => {
        setSelectedSM(res.social_media);
        setSelectedPR(res.printing_poster_option_id);
        setWhiteLogo(res.white_logo);
        setBlackLogo(res.black_logo);
        setLoadingSelections(false);
      }
    );
  }, []);

  const sendUpdates = () => {
    api.endpoints.updatePosterSettings({
      social_media: selectedSM,
      printing_poster_option_id: selectedPR,
      white_logo: whiteLogo,
      black_logo: blackLogo
    }, () => {})
  }

  return (
    <div className="d-flex bg-secondary w-75 h-100 ml-5 overflow-auto">
      <div className="w-75 mr-5 ml-2 bg-secondary">
        <SettingHeader
          image="./poster-settings-icon.svg"
          imageAlt="Poster settings icon"
          title="Poster Settings"
        />
        { !(loadingOption || loadingSelections) &&
          <>
            <h4 className="mt-3 fs-h4 text--gray8 ">Default poster for Social Media: </h4>
            <span className="mx-3" >Selected: {imageOptions[selectedSM].name}</span>
            <br/>
            <PosterSelector
              images={imageOptions}
              selected={selectedSM}
              setSelected={setSelectedSM}
            />
            <h4 className="mt-3 fs-h4 text--gray8 ">Default poster for Printing: </h4>
            <span className="mx-3" >Selected: {imageOptions[selectedPR].name}</span>
            <br/>
            <PosterSelector
              images={imageOptions}
              selected={selectedPR}
              setSelected={setSelectedPR}
            />
            <h4 className="mt-3 fs-h4 text--gray8 ">White Logo: </h4>
            <UploadableImageFrame
              src={whiteLogo}
              changeable={true}
              changeImage={setWhiteLogo}
            />
            <h4 className="mt-3 fs-h4 text--gray8 ">Black Logo: </h4>
            <UploadableImageFrame
              src={blackLogo}
              changeable={true}
              changeImage={setBlackLogo}
            />
            <br/><br/>
            <Button
              className="button--success fs-btn2 ml-3"
              onClick={() => sendUpdates()}
            >
              Update Selections
            </Button>
          </>
        }
      </div>
    </div>
  );
};

export default PosterSettings;
