export const SettingsMenuEnum = {
  PROFILE: "profile",
  COMPANY: "company",
  RESERVATION: "reservation",
  MANAGEMENT: "management",
  SOCIAL_MEDIA: "socialmedia",
  PREFERENCES: "PREFERENCES",
  WIDGET: "Widget",
  PAYMENT: "payment",
  POSTERSETTINGS: "PosterSettings"
};

export const publishStatus = {
  SC: "SC",
  FACEBOOK_PAGE: "facebook_page",
  INSTAGRAM_PAGE: "instagram_page",
  FACEBOOK_GROUP: "facebook_group",
  SC_TITLE: "Sportcompass.net",
  FACEBOOK_PAGE_TITLE: "Facebook page",
  FACEBOOK_GROUP_TITLE: "Facebook group",
  INSTAGRAM_PAGE_TITLE: "Instagram page",
  SUCCESS: "SUCCESS",
  NOT_SET: "NOT_SET",
  ERROR: "ERROR"
};

export const SettingsActiveMenu = "SettingsActiveMenu";

export const SessionFields = {
  eventsActiveTabIndex: "eventsActiveTabIndex",
  dashboardActiveTabIndex: "dashboardActiveTabIndex"
};

export const SportsFilters = {
  FOOTBALL: "football",
  RUGBY: "rugby",
  AMERICAN_FOOTBALL: "american football",
  HANDBALL: "handball",
  COMBAT_SPORTS: "combat sports",
  HOCKEY: "hockey",
  CRICKET: "cricket",
  RACING: "racing",
  TENNIS: "tennis",
  CYCLING: "cycling",
  BASKETBALL: "basketball",
  GAA: "gaelic football",
  HORSERACING: "horse racing",
  VOLLEYBALL: "volleyball",
  BADMINTON: "badminton",
  BASEBALL: "baseball",
  AUSTRALIAN_FOOTBALL: "australian rules football",
  OTHER: "other"
};

export const Filters = {
  CUSTOM_EVENTS: "custom events",
  ...SportsFilters
};


export const SidebarItems = {
  SETTINGS: "Settings",
  DASHBOARD: "Dashboard",
  EVENTS: "Events",
  PROMOS: "Promos",
  INSHIGHTS: "Insights",
  SOCIALMEDIAPOSTS: "Scheduler",
  RESERVATIONS: "Reservations",
  NOTIFICATIONS: "Notifications",
};

export const userTypes = {
  ADMIN: "ADMIN",
  VIEWONLY: "VIEWONLY"
};
