import React from "react";
import Layout from "./index/Layout";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = (props) => {
	return (
		<>
		<ToastContainer />
									
			<BrowserRouter>
				<Layout cable={props.cable} />
			</BrowserRouter>
		</>
	);
};

export default App;
