import React, { useState } from "react";
import ChannelsList from "../../../../components/ChannelsListPopup";
import channels from "../../../../utils/channels";
import { formatTime } from "../../../../utils/utils"
import RenderChannel from "./standardEvents/StationsinEvents";
// Promos in events disabled for now
import PromosInEvents from "./PromosInEvents";
import SocialsInEvents from "./SocialsInEvents";
import * as svgs from "../../../../utils/svg";
import { connect } from "react-redux";
import { Filters } from "../../../../utils/constants";
import moment from "moment-timezone";
import "../../../../assests/scss/index.scss";

const svgMap = {
  [Filters.CUSTOM_EVENTS]: "CustomEventIcon",
  [Filters.FOOTBALL]: "FootballIcon",
  [Filters.RUGBY]: "RugbyIcon",
  [Filters.HOCKEY]: "HockeyIcon",
  [Filters.CRICKET]: "CricketIcon",
  [Filters.RACING]: "RacingIcon",
  [Filters.TENNIS]: "TennisIcon",
  [Filters.CYCLING]: "CyclingIcon",
  [Filters.FIGHTING]: "FightingIcon",
  [Filters.COMBAT_SPORTS]: "FightingIcon",
  [Filters.HANDBALL]: "HandballIcon",
  [Filters.AMERICAN_FOOTBALL]: "AmericanFootballIcon",
  [Filters.BASKETBALL]: "BasketballIcon",
  [Filters.GAA]: "GAAIcon",
  [Filters.HORSERACING]: "HorseRacingIcon",
  [Filters.BADMINTON]: "BadmintonIcon",
  [Filters.VOLLEYBALL]: "VolleyballIcon",
  [Filters.BASEBALL]: "BaseballIcon",
  [Filters.AUSTRALIAN_FOOTBALL]: "AustralianFootballIcon",
  [Filters.OTHER]: "OtherIcon"
};

/**Returns the JSX for standard events */
const RenderStandardEvent = props => {
  const [showChannels, setShowChannels] = useState(false);

  const renderChannel = (selectedStationId, isFavorite) => {
    let station;
    for (let stationIndex in props.stations) {
      if (selectedStationId === props.stations[stationIndex].id) {
        station = props.stations[stationIndex];
        break;
      }
    }

    let match = findMatch(station);
    let iconPath = "./default.svg";
    let stationName = station?.name;
    if (match) {
      let obj = channels[match];
      if (obj.name) {
        stationName = obj.name;
        if (obj.icon) iconPath = obj.icon;
      } else iconPath = obj;
    }

    return (
      <RenderChannel
        stationName={stationName}
        iconPath={iconPath}
        {...props}
        showChannelsPopup={() => setShowChannels(true)}
        isFavorite={isFavorite}
        moreStationsCount={props.stations.length - 1}
        dashboardtitle={iconPath === "./default.svg"}
      />
    );
  };

  let favIds = [];
  props.favoriteStations.map( (station) => {
    favIds.push(station.id)
  })

  const selectStation = preferredStationId => {
    if (!props.stations) return null;
    if (preferredStationId) {
      return renderChannel(
        preferredStationId,
        favIds.includes(preferredStationId)
      );
    }
    for (let stationIndex in props.stations) {
      if (!favIds.includes(props.stations[stationIndex].id))
        continue;
      return renderChannel(props.stations[stationIndex].id, true);
    }
    if (props.stations[0]) return renderChannel(props.stations[0].id, false);
    return null;
  };

  const findMatch = station => {
    for (let chName in channels) {
      if (station?.name?.toLowerCase()?.includes(chName.toLowerCase())) {
        return chName;
      }
    }
    return null;
  };

  const SvgIcon = svgs[svgMap[props.sport_object?.short_name]|| "OtherIcon"];
  return (
    <React.Fragment>
      <div className="col-6 d-flex justify-content-start align-items-center ">
        {props.children}
        <SvgIcon alt={props.sport_object?.short_name} className="event-item__sport-type" />
        <div className="d-flex flex-column-reverse align-items-start">
          <div
            className="fs-body2 text-center p-1 text-wrap text--gray8 truncate_text"
            key={props.title + props.name}
          >
            {props.title}
          </div>
          <div className="d-flex fs-body1 event-item__team-names align-items-end">
            {props.name.includes(" v ") ? (
              props.name.split(" v ").map((teamName, index) =>
                index === 0 ? (
                  <div
                    className="p-1 text-wrap"
                    key={teamName + "team1 " + index}
                  >
                    {teamName}
                  </div>
                ) : (
                  <React.Fragment key={teamName + " dash " + index}>
                    <div className="d-flex event-item__team-icons">
                      <div className="pt-1 pb-1 ">-</div>
                    </div>
                    <div
                      className="p-1 text-left text-wrap"
                      key={teamName + " team 2 " + index}
                    >
                      {teamName}
                    </div>
                  </React.Fragment>
                )
              )
            ) : (
              <div
                className="fs-body1 pl-2 text-center text-wrap"
                key={props.name + "team1 "}
              >
                {props.name}
              </div>
            )}
            <div
              className="fs-body1 text--red1 pl-2 text-center text-wrap"
              key={props.name + "status "}
            >
              {props.status ? props.status : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="col-1 d-flex flex-column justify-content-center align-items-center event-item__match-times">
        <div
            className="fs-body1"
            title={props.timezone + ' time (UTC: ' + moment(props.time).utc().format('LLL') + ')'}
        >
          {formatTime(props.time, props.time_format, props.timezone)}
        </div>
      </div>
      <div className="eventlist-channels col d-flex align-items-center justify-content-center fs-caption2">
        {selectStation(props.pref_station)}
      </div>
      {showChannels ? (
        <ChannelsList
          {...props}
          show={showChannels}
          cancelModal={() => setShowChannels(false)}
        />
      ) : null}
      {/* Promos in events disabled for now */}
       <PromosInEvents />
      {props.socials && <SocialsInEvents />}
    </React.Fragment>
  );
};

const mapStateToProps = state => {

  return {
    favoriteStations: state.favoriteStations,
    timezone: state.timezone,
    time_format: state.time_format
  };
};

export default connect(mapStateToProps)(RenderStandardEvent);
