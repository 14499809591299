import React from "react";
import Loader from "react-loader-spinner";

const CustomLoader = props => {
  return (
    <>
    {(props.loading) ? (
      <div className={props.className ? props.className + ' m-5' : 'm-5'}>
        <Loader
          type="TailSpin"
          color="#0084f4"
          height="120"
          width="120"
          />
        <div>Loading ...</div>
      </div>
      ) : (
      props.children
    )}
    </>
  )
}

export default CustomLoader;