import React from "react";
const images = require.context("../assests/images/img", true);

const CustomSwitchShareEvents = (props) => {
	const handleChange = (event) => {
		event.persist();
		const checked = event.target.checked;
		props.onChange(checked);
	};

	return (
		<div
			className="checkbox-button pr-4"
			style={props.checked ? { border: props.style } : null}
		>
			<label className="d-flex align-items-center mt-1 mb-1">
				<img className="ml-2 mr-2" src={props.image} alt={props.name} />
				<input
					type="checkbox"
					checked={props.checked}
					onChange={handleChange}
					disabled={props.isDisabled}
				/>
				<span>{props.name}</span>
				{props.showCheckmark ? (
					<div className="d-flex ml-auto mr-0">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20px"
							height="20px"
							viewBox="0 0 24 24"
							fill={props.checked ? props.fill : "#dadee2"}
						>
							<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
						</svg>
					</div>
				) : null}
			</label>
		</div>
	);
};

export default CustomSwitchShareEvents;
