import React from "react";
import { ReservationIcon } from "../../utils/svg";
import moment from "moment-timezone";
const images = require.context("../../assests/images", true);

const Button = props => (
    <>
    <button
        className="btn p-0"
        onClick={() => props.onClick(props.reservationId)}
        title={props.title}
    >
        <img src={images(props.imagePath)} alt={props.imageAlt} />
    </button>
    </>
)

// Functional component to render a reservation item from props
const ReservationItem = props => {
    const timeFormat = props.time_format === '24H' ? 'HH:mm' : 'h:mm A';
    let to = null;
    const start_time_w_tz = moment(props.start_time).tz(props.timezone);
    if (props.end_time && props.end_time !== props.start_time){
        const end_time_w_tz = moment(props.end_time).tz(props.timezone);
        let sameDay = start_time_w_tz.isSame(end_time_w_tz, 'day');
        to = (
            <React.Fragment>
                <span className="fs-body2"> to</span>
                <br />
                <span className={"text-nowrap " + (sameDay ? "" : "fs-body3")}>{end_time_w_tz.format(sameDay ? timeFormat : "D MMMM " + timeFormat)}</span>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment key={props.id}>
            {/* Column 1 with checkbox, reservation name and description (details) */}
            <div>
                <div className="flex-center">
                    <div className="checkbox-wrapper">
                        <input
                            type="checkbox"
                            onChange={event => props.selectItemHandler(props.id, event)}
                            checked={props.selReservationIds.indexOf(props.id) >= 0}
                        />
                        <span className="checkmark"/>
                    </div>
                    <div><ReservationIcon alt="reservation" className="icon"/></div>
                    <div className="element--clickable w-100" onClick={() => props.editReservation(props.id)}>
                        <span className="limit-to-2lines">{props.name}</span>
                        <span className="details limit-to-2lines">{props.description}</span>
                    </div>
                </div>
            </div>
            {/* Column 2 - Time. Will show end date/time too if not blank and different from start time  style={{width: '100%'}} */}
            <div>{start_time_w_tz.format(timeFormat)}{to}</div>

            {/* Column 3 - Phone number (phone_number) */}
            <div><span className="limit-to-2lines">{props.phone_number}</span></div>

            {/* Column 4 - Email (email) */}
            <div><span className="limit-to-2lines">{props.email}</span></div>

            {/* Column 5 - Guests (attendees_count) */}
            <div>{props.attendees_count > 0 ? props.attendees_count : null}</div>

            {/* Column 6 - Sitting area */}
            <div><span className="limit-to-2lines">{props.sitting_area}</span></div>

            {/* Column 7 - Offers */}
            <div><span className="limit-to-2lines">{props.offers}</span></div>

            {/* Column 8 - For event (reason) */}
            <div><span className="limit-to-2lines">{props.reason}</span></div>

            {/* Column 9 - Accept reservation button */}
            <div className="actions">
             {props.validation !== 'Approved' ? ( 
                <Button
                    reservationId={props.id}
                    onClick={props.acceptReservation}
                    imagePath='./img/events/accept_reservation.svg'
                    imageAlt="Accept"
                    title="Accept"
                />
             ) : null}  
             </div>

            {/* Column 10 - Actions */}
            <div className="actions">
                <Button
                    reservationId={props.id}
                    onClick={props.rejectReservation}
                    imagePath='./img/events/remove.svg'
                    imageAlt="Cancel"
                    title="Cancel"
                />
                <Button
                    reservationId={props.id}
                    onClick={props.editReservation}                    
                    imagePath='./img/events/more.svg'
                    imageAlt="Edit"
                    title="Edit"
                />
            </div>
        </React.Fragment>
    );
};

export default ReservationItem;
