import React, { Fragment, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import Sidebar from "../components/Sidebar";
import Dashboard from "../Pages/Dashboard";
import Events from "../Pages/Events";
import Settings from "../Pages/Settings";
import { Route, Redirect, withRouter, Switch } from "react-router-dom";
import Login from "../Pages/Login";
import MessageDialog from "../components/MessageDialog";
import Promos from "../Pages/Promos";
import PromoItemPage from "../Pages/promos/PromoItemPage";
import { userTypes } from "../utils/constants";
import { connect } from "react-redux";
import SocialMediaPosts from "../Pages/SocialMediaPosts";
import Reservations from "../Pages/Reservations";
import Notifications from "../Pages/Notifications";
import * as actionCreators from "../modules/actions";
import TermsOfUse from "../Pages/TermsOfUse";

const isAuthenticated = () => {
	const data = JSON.parse(sessionStorage.getItem("userToken"));
	return data ? true : false;
};

const isViewOnly = (propsObj) => propsObj.currentClientType !== userTypes.ADMIN;

/** checks if the user is authenticated, renders the "component" prop. If not, it redirects the user to /login.
 *
 * @param component :  component to be routed to
 * @param rest : props passed
 */
const PrivateRoute = ({ component: Component, cable, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isAuthenticated() ? (
				<Component {...props} cable={cable} />
			) : (
				<Redirect to="/login" />
			)
		}
	/>
);

const Layout = (props) => {
	const toastId = useRef(null);
	const { cable } = props;
	const [showNotifications, setShowNotifications] = useState(false);
	const [notifications, setNotifications] = useState(0);
	const previousNotificationCount = useRef(null);

	const onClickNotifications = (displayNotifications) => {
		setShowNotifications(
			displayNotifications === false ? false : !showNotifications
		);
	};

	useEffect(() => {
		if (isAuthenticated()) {
			props.saveCable(cable);
			props.getNotifications();
		}
	}, [cable]);

	useEffect(() => {
		props._fixturesFilters.offset = 0;
		props._meetupsFilters.offset = 0;
		const _meetupsFilters = props._meetupsFilters;
		const _fixturesFilters = props._fixturesFilters;
		props.getFilteredMeetups({ filters: _meetupsFilters });
		props.getFilteredFixtures({ filters: _fixturesFilters });
		props.refreshMeetups(true);
	}, [props._fixturesFilters, props._meetupsFilters])

	useEffect(() => {
		if (cable && props.client_id) {
			const subscription = cable.subscriptions.create(
				{
					channel: "NotificationsChannel",
					client_id: props.client_id,
				},
				{
					received: (notificationCount) => {
						const fakeLoaderHidden = sessionStorage.getItem('fakeLoaderHidden') === 'true';

						if (previousNotificationCount.current !== null && notificationCount > previousNotificationCount.current) {
							if (!fakeLoaderHidden) {
								console.log('Hiding fake loader');
								props.showFakeLoader(false);
								sessionStorage.setItem('fakeLoaderHidden', 'true'); // prevent future executions
							}
						}
						
						props.saveUnseenNotifications(notificationCount);

						if (notifications != props.unseenNotifications) {
							setNotifications(notificationCount);
						}
						props.showFakeLoader(false); //comment this to display loader
						let _toastElem = document.querySelector(
							".Toastify__toast.Toastify__toast--default"
						);

						if (_toastElem !== null) {
							_toastElem.classList.add("completed");
						}
						setTimeout(() => {
							if (_toastElem !== null) {
								props.getNotifications();
							}
						}, 1500);
						setTimeout(() => {
							toast.done(toastId.current);
							toast.dismiss(toastId.current);
						}, 2000);
					},
				}
			);
			return () => {
				subscription.unsubscribe();
			};
		}
	}, [cable, props.client_id]);
	
	useEffect(() => {
		if (props.location.pathname.includes("/notifications")) {
			props.getNotifications();
			setShowNotifications(true);
		}
	}, [notifications]);

	return (
		<div className="container-fluid overflow-hidden">
			<div id="popupContainer" />
			<MessageDialog />
			<Switch>
				<React.Fragment>
					<Route path="/login" component={Login} />
					{isAuthenticated() ? (
						<div className="row flex-nowrap h-100">
							{!props.location.pathname.includes("/promo/") && (
								<div
									className={`bar-container--vertical ${
										showNotifications ? "notification-active" : ""
									}`}
								>
									<Sidebar
										location={props.location.pathname}
										onClickNotifications={onClickNotifications}
									/>
								</div>
							)}
							<div className="col bg-light">
								{/* not toaster location */}
								<PrivateRoute path="/dashboard" component={Dashboard} />
								{!isViewOnly(props) && (
									<Fragment>
										<PrivateRoute path="/settings" component={Settings} />
										<PrivateRoute path="/events" component={Events} />
										<PrivateRoute
											path="/promo/:promoId"
											component={PromoItemPage}
										/>
										<PrivateRoute path="/promos" component={Promos} />
										<PrivateRoute
											path="/socialMediaPosts"
											component={SocialMediaPosts}
										/>
										<PrivateRoute
											path="/reservations"
											component={Reservations}
										/>
										<div
											className={`notifications-content ${
												showNotifications ? "active" : ""
											}`}
										>
											<Notifications showNotifications={showNotifications} />
										</div>
									</Fragment>
								)}
							</div>
						</div>
					) : (
						<Fragment>
							{props.location.pathname !== "/login" && (
								<Redirect from="/" exact to="/login" />
							)}
						</Fragment>
					)}
				</React.Fragment>
			</Switch>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		currentClientType: state.currentClientType,
		client_id: state.client_id,
		notifications: state.notifications,
		unseenNotifications: state.unseenNotifications,
		meetups: state.meetupsFiltered,
		fixtures: state.fixturesFiltered,
		_meetupsFilters: state.meetupsFilters,
		_fixturesFilters: state.fixturesFilters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getFilteredMeetups: (params) =>
			dispatch(actionCreators.filterMeetups(params)),
		getFilteredFixtures: (params) =>
			dispatch(actionCreators.filterFixtures(params)),
		refreshMeetups: (params) => dispatch(actionCreators.refreshMeetups(params)),
		saveCable: (params) => dispatch(actionCreators.saveCable(params)),
		getNotifications: () => dispatch(actionCreators.getNotifications()),
		saveUnseenNotifications: (params) => {
			dispatch(actionCreators.saveUnseenNotifications(params));
		},
		showFakeLoader: (isLoading) => {
			dispatch(actionCreators.showFakeLoader(isLoading));
		},
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
