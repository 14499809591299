import React, { Component, createRef } from "react";
import EventItems from "./events/EventItems";
import EventDialog from "./events/EventDialog";
import { Tabs, Tab } from "react-bootstrap-tabs";
import EventPanel from "./events/EventPanel";
import Header from "../components/Header";
import { Button } from "react-bootstrap-buttons";
import * as api from "../utils/api";
import * as actionCreators from "../modules/actions";
import { connect } from "react-redux";
import moment from "moment-timezone";
import FileSaver from "file-saver";
import PostEditorPopup from "../components/PostEditorPopup";
import SelectModal from "../components/SelectModal";
import { transformArrayToHashId } from "../utils/utils";
import CustomLoader from "../components/CustomLoader";
import DeleteModal from "../components/DeleteModal";
import EditModal from "../components/EditModal";
import ShowFakeLoader from "../components/ShowFakeLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import Popup from "react-popup";
import _ from "lodash";

const images = require.context("../assests/images", true);

class Events extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = createRef();
    this.state = {
      selEventIdList: null,
      allSelected: false,
      locationId: "",
      customMeetupObj: null,
      showPostEditorPopup: false,
      showPrintPosterPopup: false,
      showDeleteEventModal: false,
			showEditEventModal: false,
      enablePostEditor: false,
      enableManualPosting: false,
      postEditorDefaults: null,
      posterOptions: [],
      posterSettings: null,
      needToPay: null,
      meetupIdToDelete: null,
			meetupIdToEdit: null,
			editMode: null,
      instagram: {
        instagram_page: "",
      },
      client_loading: props.client_loading,
      loading: false,
      limit: 50,
			isVideo: false,
      showFilters: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.client_loading !== state.client_loading) {
      props.getFilteredMeetups({ filters: props._meetupsFilters });
      props.getFilteredFixtures({
        filters: props._fixturesFilters,
        reqTime: moment(),
      });
      return { client_loading: props.client_loading };
    }
    return null;
  }

  componentDidMount = () => {
    if (this.state.client_loading === false) {
      const now = moment();
      const prev = this.props.reqTime;
      const valid =
        this.props.fixtures[0] &&
        moment().isBefore(this.props.fixtures[0].time);

      if (!this.props.meetups[0]) {
        const _filters = this.props._meetupsFilters;
        this.props.getFilteredMeetups({ filters: _filters });
      }
      if (!prev || moment.duration(now.diff(prev)).asMinutes() > 60 || !valid) {
        const _filters = this.props._fixturesFilters;
        this.props.getFilteredFixtures({ filters: _filters, reqTime: now });
      }
    }
    this.props.getFavoriteStations();
    this.props.getFavoriteSports();
    this.props.getFavoriteTeams();
    this.props.getFavoriteCompetitions();
    this.publishDefaults();
  };

  componentDidUpdate(prevProps) {
    const { _fixturesFilters, _meetupsFilters } = this.props;

    const fixturesFiltersChanged = !_.isEqual(
      prevProps._fixturesFilters,
      _fixturesFilters
    );
    const meetupsFiltersChanged = !_.isEqual(
      prevProps._meetupsFilters,
      _meetupsFilters
    );

    if (fixturesFiltersChanged || meetupsFiltersChanged) {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTop = 0;
      }
    }
  }

  filterEvents = (_filters) => {
    if (this.props.tabIndex) {
      this.props.saveFixturesFilters(_filters);
      this.props.getFilteredFixtures({ filters: _filters, reqTime: moment() });
    } else {
      this.props.saveMeetupsFilters(_filters);
      this.props.getFilteredMeetups({ filters: _filters });
    }
  };

  publishDefaults = () => {
    api.endpoints.publishDefaults((defaults) => {
      this.setState({
        enablePostEditor: defaults.posting_editor,
        enableManualPosting: defaults.manual_posting,
        postEditorDefaults: defaults,
      });
    });
    api.endpoints.getPosterOptions((res) => {
      this.setState({
        posterOptions: transformArrayToHashId(res.poster_options),
      });
    });
    api.endpoints.getPosterSettings((res) => {
      this.setState({
        posterSettings: res,
      });
    });
  };

  /**Handler for event selection.
   *@param selEventId = the selected event Id,
   *@param index = index of the event element from data list,
   *@param  event = click event object
   */
  handleCheckboxSelection = (selEventId, event) => {
    const selEventIds = (this.state.selEventIdList || []).slice();
    if (event.target.checked) {
      selEventIds.push(selEventId);
    } else {
      const elemIndex = selEventIds.indexOf(selEventId);
      if (elemIndex >= 0) {
        selEventIds.splice(elemIndex, 1);
      }
    }
    this.setState({
      selEventIdList: selEventIds,
      allSelected: this.props.tabIndex
        ? this.props.fixtures.length ===
          selEventIds.length + this.props.meetups.length
        : this.props.meetups.length === selEventIds.length,
    });
  };

  /**Handler for select all check box selection
   *@param event = clickevent
   */
  selectAllHandler = (event) => {
    const eventDataList = this.props.tabIndex
      ? this.props.fixtures
          .map((elem) => elem.id)
          .filter(
            (fixtureId) =>
              !this.props.meetups.some(
                (meetup) => meetup.fixture && meetup.fixture.id === fixtureId
              )
          )
      : this.props.meetups.map((elem) => elem.id).slice();
    if (event.target.checked) {
      this.setState({
        selEventIdList: eventDataList,
        allSelected: true,
      });
    } else {
      this.setState({
        selEventIdList: null,
        allSelected: false,
      });
    }
  };

  /**Clears any selected event on tab navigation
   *@param index = current tab index
   */
  clearSelection = (index) => {
    this.setState({
      selEventIdList: null,
      allSelected: false,
    });
    this.props.saveActiveTabIndex(index);
  };

  /**Clears any selected event on clear selection action
   */
  clearEvents = () => {
    this.setState({
      selEventIdList: null,
      allSelected: false,
    });
  };

  /**Adds selected event on add event action
   */
  addEvents = () => {
    const selEventIds = (this.state.selEventIdList || []).slice();
    api.endpoints.createMeetups(this.state.locationId, selEventIds, () =>
      this.refreshMyEvents()
    );
    this.clearEvents();
  };

  deleteEvents = () => {
    const selEventIds = (this.state.selEventIdList || []).slice();
    api.endpoints.deleteMultipleMeetups(
      this.state.locationId,
      selEventIds,
      () => this.refreshMyEvents()
    );
    this.clearEvents();
  };

  announceNeedToPay = () => {
    this.props.showNotice(
      "Your free trial has expired. To continue using the software, please contact Simon Sulek on ssu@sportcompass.net, 52 52 59 84",
      false,
      { loading: false }
    );
  };

  optionsCounter = (options) => {
    let count = 1;
    if (
      options.instagram_attachment ||
      options.instagram_caption ||
      options.instagram_image_post ||
      options.instagram_video_post ||
      options.video_story_instagram ||
      options.image_story_instagram ||
      options.attachment_story_instagram
    ) {
      count++;
    }
    if (
      options.text_post ||
      options.video ||
      options.video_post ||
      options.image_post ||
      options.attachment
    ) {
      count++;
    }
    return count;
  };

	isPostingOptionVideo = (options) =>
		options.instagram_video_post ||
		options.video_story_instagram ||
		options.video ||
		options.video_post;

  shareEvents = async (sharingOptions) => {
    const selEventIds = (this.state.selEventIdList || []).slice();
    sharingOptions =
      this.state.postEditorDefaults.posting_editor === true
        ? sharingOptions
        : {};
    this.clearEvents();
    await api.endpoints.publish(
      selEventIds,
      sharingOptions,
      () => {
        this.props.hideNotice();
        this.refreshMyEvents();
				const isVideo = this.isPostingOptionVideo(sharingOptions);
				this.setState({ isVideo });
        this.props.fakeLoader(true);
      },
      (err) => {
        this.props.hideNotice();
        this.props.showNotice(err, false);
      }
    );
  };

  printEvents = () => {
    const selEventIds = (this.state.selEventIdList || []).slice();
    api.endpoints.printMeetups(selEventIds, (res) => {
      var binaryData = [];
      binaryData.push(res.data);
      // var blob = new Blob(binaryData, {type: "text/excel"});
      let file = new File(
        binaryData,
        "schedule-" + moment().format("LLL") + ".xlsx",
        { type: "text/excel;charset=utf-8" }
      );
      FileSaver.saveAs(file);
    });
    this.clearEvents();
  };

	generateVideo = () => {
		const selEventIds = (this.state.selEventIdList || []).slice();
		this.props.showNotice("Generating Video", null, { loading: true });
		api.endpoints.generateVideo(
			selEventIds,
			(res) => {
				this.subscribeToVideoJob();
			},
			(err) => {
				this.props.showNotice(
					"An error has occurred. Please try again. If the error persists please contact Sport Compass.",
					false,
					{ loading: false }
				);
			}
		);
		this.clearEvents();
	};

	printPoster = (option) => {
		const selEventIds = (this.state.selEventIdList || []).slice();
		this.props.showNotice("Generating Poster", null, { loading: true });

		// Make an API call to start the job
		api.endpoints.printPoster(
			selEventIds,
			option,
			(res) => {
				this.subscribeToPosterJob(false); // Use the job ID from the response
			},
			(err) => {
				console.log(err);
				this.props.hideNotice();
				this.props.showNotice(
					"An error has occurred. Please try again. If the error persists please contact Support.",
					false,
					{ loading: false }
				);
			}
		);

		this.clearEvents();
	};

	printPosterPdf = (option) => {
		const selEventIds = (this.state.selEventIdList || []).slice();
		this.props.showNotice("Generating PDF", null, { loading: true });

		// Make an API call to start the job
		api.endpoints.printPosterPdf(
			selEventIds,
			option,
			(res) => {
				this.subscribeToPosterJob(true); // Use the job ID from the response
			},
			(err) => {
				console.log(err);
				this.props.hideNotice();
				this.props.showNotice(
					"An error has occurred. Please try again. If the error persists please contact Support.",
					false,
					{ loading: false }
				);
			}
		);

		this.clearEvents();
	};

	subscribeToPosterJob = (isPdf) => {
		if (this.props.cable && this.props.client_id) {
			const subscription = this.props.cable.subscriptions.create(
				{
					channel: "PostersChannel",
					client_id: this.props.client_id,
				},
				{
					received: (data) => {
						if (data.status === "completed") {
							data.is_zip
								? this.handlePosterJobCompletionZip(data.content)
								: isPdf
								? this.handlePosterJobCompletionPdf(data.content)
								: this.handlePosterJobCompletion(data.content);
							subscription.unsubscribe();
						} else if (data.status === "error") {
							this.props.hideNotice();
							this.props.showNotice(data.content, false, { loading: false });
							subscription.unsubscribe();
						}
					},
				}
			);
		};
	};
	
	editCustomEvent = (meetupId, editMode) => {
		if (editMode) {
			this.setState({
				editMode: editMode,
			});
		};

		if (meetupId) {
			const meetupObj = this.props.meetups
				.filter((elem) => elem.id === meetupId)
				.pop();
			this.setState({ customMeetupObj: meetupObj });
		} else {
			this.setState({ customMeetupObj: null });
		}
	};
	

	handlePosterJobCompletion = (encodedData) => {
		this.props.hideNotice();
		const binaryData = this.base64ToBlob(encodedData, "image/png");
		const fileName = `poster-${moment().format("LLL")}.png`;

		FileSaver.saveAs(binaryData, fileName);
	};

	handlePosterJobCompletionPdf = (encodedData) => {
		this.props.hideNotice();
		const binaryData = this.base64ToBlob(encodedData, "application/pdf");
		const fileName = `poster-${moment().format("LLL")}.pdf`;

		FileSaver.saveAs(binaryData, fileName);
	};

	handlePosterJobCompletionZip = (encodedData) => {
		this.props.hideNotice();
		const binaryData = this.base64ToBlob(encodedData, "application/zip");
		const fileName = `poster-${moment().format("LLL")}.zip`;
		FileSaver.saveAs(binaryData, fileName);
	};

	subscribeToVideoJob = () => {
		if (this.props.cable && this.props.client_id) {
			const subscription = this.props.cable.subscriptions.create(
				{
					channel: "VideosChannel",
					client_id: this.props.client_id,
				},
				{
					received: (data) => {
						if (data.status === "completed") {
							this.handleVideoJobCompletion(data.content);
							subscription.unsubscribe();
						} else if (data.status === "error") {
							this.props.hideNotice();
							this.props.showNotice(data.content, false, { loading: false });
							subscription.unsubscribe();
						}
					},
				}
			);
		}
	};

	handleVideoJobCompletion = (encodedData) => {
		this.props.hideNotice();
		const binaryData = this.base64ToBlob(encodedData, "video/mp4");
		const fileName = `Video-${moment().format("LLL")}.mp4`;

		FileSaver.saveAs(binaryData, fileName);
	};

	// Convert Base64 string to Blob
	base64ToBlob = (base64, type) => {
		const binary = atob(base64);
		const array = [];
		for (let i = 0; i < binary.length; i++) {
			array.push(binary.charCodeAt(i));
		}
		return new Blob([new Uint8Array(array)], { type });
	};

  addSelectedEvent = (selEventId) => {
    const selEventIds = [selEventId];
    api.endpoints.createMeetups(this.state.locationId, selEventIds, () =>
      this.refreshMyEvents()
    );
    this.clearEvents();
  };

  deleteEvent = (selMeetupId, deleteMode) => {
    api.endpoints.deleteMeetup(selMeetupId, deleteMode, () =>
      this.refreshMyEvents()
    );
  };

  refreshMyEvents = () => {
    this.setState({ customMeetupObj: null });
    this.props._fixturesFilters.offset = 0;
    this.props._meetupsFilters.offset = 0;
    const _meetupsFilters = this.props._meetupsFilters;
    const _fixturesFilters = this.props._fixturesFilters;
    this.props.getFilteredMeetups({ filters: _meetupsFilters });
    this.props.getFilteredFixtures({ filters: _fixturesFilters });
    this.props.refreshMeetups(true);
  };

  renderNoMatchingCriteria = () => {
    return (
      <CustomLoader
        loading={this.props.fixtures_loading || this.props.meetups_loading}
        className="loader"
      >
        <div className="d-flex flex-column w-50 m-auto pt-5 justify-content-center align-items-center">
          <p className="fs-h2 text--gray7 text-center">
            No events matching your filters
          </p>
          <p className="fs-body1 text--gray7 text-center">
            There are no events that match your search criteria. Please change
            your filters in order to display results.
          </p>
        </div>
      </CustomLoader>
    );
  };

  cancelPostEditorPopup = () => {
    this.setState({
      showPostEditorPopup: false,
    });
  };

  cancelPrintPosterPopup = () => {
    this.setState({
      showPrintPosterPopup: false,
    });
  };

  showPrintPosterPopup = () => {
    this.setState({
      showPrintPosterPopup: true,
    });
  };

  showPostEditorPopup = () => {
    this.setState({
      showPostEditorPopup: true,
    });
  };

  fetchMoreFixtures = () => {
    this.props._fixturesFilters.offset =
      this.props._fixturesFilters.offset + this.state.limit;
    this.filterEvents(this.props._fixturesFilters);
  };

  fetchMoreMeetups = () => {
    this.props._meetupsFilters.offset =
      this.props._meetupsFilters.offset + this.state.limit;
    this.filterEvents(this.props._meetupsFilters);
  };

  renderDeleteModal = (meetupId) => {
    this.setState({
      showDeleteEventModal: true,
      meetupIdToDelete: meetupId,
    });
  };

  renderEditModal = (meetupId) => {
    this.setState({
      showEditEventModal: true,
      meetupIdToEdit: meetupId,
    });
  };

  cancelDeleteEventModal = () => {
    this.setState({
      showDeleteEventModal: false,
    });
  };

  cancelEditEventModal = () => {
    this.setState({
      showEditEventModal: false,
    })
  };
  
  handleShowFilters = () => {
    this.setState((prevState) => ({
      showFilters: !prevState.showFilters
    }));
  }

  render() {
    return (
      this.state.client_loading === false && (
        <React.Fragment>
          <div className="container-fluid">
            <div className="row flex-nowrap">
              <div className="col bg-light d-flex flex-column h-100 min-vh-100">
                <div>
                  <Header
                    container="events"
                    tabIndex={this.props.tabIndex}
                    reloadEventsTab={this.refreshMyEvents}
                    editMeetupObj={this.state.customMeetupObj}
                    editCustomEvent={this.editCustomEvent}
                    locationId={this.state.locationId}
                    timezone={this.props.timezone}
                    time_format={this.props.time_format}
										editMode={this.state.editMode}
                  />
                  <div className="d-flex flex-column panel-container h-100">
                    {/* Panel with search filter and create event button  */}
                    {
                      this.state.showFilters ? (
                        <EventPanel
                          className={
                            this.props.fixtures_loading ||
                            this.props.meetups_loading
                              ? "event-panel-disabled"
                              : "pt-2 pb-3 panel-sticky bg--gray1"
                          }
                          tabIndex={this.props.tabIndex}
                          filterEvents={this.filterEvents}
                          timezone={this.props.timezone}
                          time_format={this.props.time_format}
                          handleShowFilters={this.handleShowFilters}
                        />
                      ) : null
                    }
                    {!this.state.showFilters && (
                      <div className="d-flex sport-filters__reset pt-2 px-4 bg--gray1 fs-body1" style={{ justifyContent: 'end' }} >
                        <u 
                          className="element--clickable" 
                          onClick={this.handleShowFilters}
                        >
                          Show Filters
                        </u>
                        <img
                          src={images("./img/arrow-down.svg")}
                          alt="arrow down"
                        />
                      </div>
                    )}
                    {/* Tab container for displaying My Events and Add Events tab */}
                    <Tabs
                      selected={this.props.tabIndex}
                      onSelect={(index) => {
                        this.clearSelection(index);
                      }}
                      className={`tabs-container h-100 ${this.state.showFilters && 'events-tab-header'}`}
                      headerClass="tabs-header"
                      activeHeaderClass="tabs-header--active"
                      contentClass="tabs-content"
                    >
                      {/* My Event Tab */}
                      <Tab label="My Schedule">
                        {/* Component to iterate on the events data list and render events */}
                        {this.props.meetups.length > 0 &&
                        !this.props.fixtures_loading &&
                        !this.props.meetups_loading ? (
                          <div
                            style={{
                              height: this.state.showFilters ? "calc(100vh - 243px)" : "calc(100vh - 152px)",
                              overflowY: "auto",
                            }}
                            ref={this.scrollRef}
                            id="my-events-scrollable"
                          >
                            <InfiniteScroll
                              scrollableTarget="my-events-scrollable"
                              style={{ overflow: 'inherit'}}
                              dataLength={
                                this.props.meetups.length || this.state.limit
                              }
                              next={this.fetchMoreMeetups}
                              hasMore={
                                this.props.meetups_length === this.state.limit
                              }
                              loader={
                                <div className={"events-data-status"}>
                                  <h4>Loading...</h4>
                                </div>
                              }
                              endMessage={
                                <p className={"events-data-status"}>
                                  <b>All the data has been loaded!</b>
                                </p>
                              }
                            >
                              <EventItems
                                data={this.props.meetups}
                                container="myevents"
                                checkBoxSelection={this.handleCheckboxSelection}
                                allSelected={this.state.allSelected}
                                selectAllHandler={this.selectAllHandler}
                                selEventIds={this.state.selEventIdList}
                                deleteSelEvent={this.deleteEvent}
                                showDeleteEventModal={this.renderDeleteModal}
                                showEditEventModal={this.renderEditModal}
                                editCustomEvent={this.editCustomEvent}
                                reloadEventsTab={this.refreshMyEvents}
                                timezone={this.props.timezone}
                                time_format={this.props.time_format}
                              />
                            </InfiniteScroll>
                          </div>
                        ) : this.props.meetupsExist === false ? (
                          <div className="d-flex flex-column w-50 h-100 pt-5 m-auto justify-content-center align-items-center">
                            <p className="fs-h2 text--gray7  text-center">
                              Your list looks empty
                            </p>
                            <p className="fs-body1 text--gray7 text-center">
                              Add a match from the Sport Compass database to
                              your schedule or create your own event
                            </p>

                            <Button
                              className="button--success-extra-large p-1 fs-body1 text--white"
                              onClick={() => this.props.saveActiveTabIndex(1)}
                            >
                              <img
                                className="pr-2"
                                src={images("./img/event-add-icon.svg")}
                                alt="Add events"
                              />
                              ADD EVENTS
                            </Button>
                          </div>
                        ) : (
                          this.renderNoMatchingCriteria()
                        )}
                      </Tab>
                      <Tab label="Add Events">
                        {this.props.fixtures.length > 0 &&
                        !this.props.fixtures_loading &&
                        !this.props.meetups_loading ? (
                          <div
                            style={{
                              height: this.state.showFilters ? "calc(100vh - 243px)" : "calc(100vh - 152px)",
                              overflowY: 'auto'
                            }}
                            ref={this.scrollRef}
                            id="add-events-scrollable"
                          >
                            <InfiniteScroll
                              scrollableTarget="add-events-scrollable"
                              style={{ overflow: 'inherit'}}
                              dataLength={
                                this.props.fixtures.length || this.state.limit
                              }
                              next={this.fetchMoreFixtures}
                              hasMore={
                                this.props.fixtures_length === this.state.limit
                              }
                              loader={
                                <div className={"events-data-status"}>
                                  <h4>Loading...</h4>
                                </div>
                              }
                              endMessage={
                                <p className={"events-data-status"}>
                                  <b>All the data has been loaded!</b>
                                </p>
                              }
                            >
                              <EventItems
                                data={this.props.fixtures}
                                meetups={this.props.meetups}
                                container="addevents"
                                checkBoxSelection={this.handleCheckboxSelection}
                                allSelected={this.state.allSelected}
                                selectAllHandler={this.selectAllHandler}
                                selEventIds={this.state.selEventIdList}
                                addSelectedEvent={this.addSelectedEvent}
                                timezone={this.props.timezone}
                                time_format={this.props.time_format}
                              />
                            </InfiniteScroll>
                          </div>
                        ) : (
                          this.renderNoMatchingCriteria()
                        )}
                      </Tab>
                    </Tabs>
                    {this.props.showFakeLoader && (
                      <ShowFakeLoader
												selEventIds={this.state.selEventIdList}
												isVideo={this.state.isVideo}
											/>
                    )}
                    {this.state.selEventIdList &&
                    this.state.selEventIdList.length ? (
                      // Display selected events and further action if any event is selected
                      <EventDialog
                        selEvents={this.state.selEventIdList}
                        tabIndex={this.props.tabIndex}
                        onAddEvents={this.addEvents}
                        onDeleteEvents={this.deleteEvents}
                        onPrintEvents={
                          this.props.needPay
                            ? this.announceNeedToPay
                            : this.printEvents
                        }
                        onPrintPoster={
                          this.props.needPay
                            ? this.announceNeedToPay
                            : this.showPrintPosterPopup
                        }
                        onDownloadVideo={
                          this.props.needPay
                            ? this.announceNeedToPay
                            : this.generateVideo
                        }
                        onShareEvents={
                          this.props.needPay
                            ? this.announceNeedToPay
                            : this.state.enablePostEditor &&
                              this.state.enableManualPosting
                            ? this.showPostEditorPopup
                            : this.shareEvents
                        }
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.showPostEditorPopup ? (
            <PostEditorPopup
              cancelModal={this.cancelPostEditorPopup}
              handleSuccess={this.showEventCreatedDialog}
              shareEvents={this.shareEvents}
              selEventIdList={this.state.selEventIdList}
              postEditorDefaults={this.state.postEditorDefaults}
              posterOptions={this.state.posterOptions}
              posterSetting={this.state.posterSettings}
              video={this.state.videoLink}
            />
          ) : null}
          {this.state.showPrintPosterPopup ? (
            <SelectModal
              cancelModal={this.cancelPrintPosterPopup}
              handleSuccess={this.showEventCreatedDialog}
              printPoster={this.printPoster}
							printPosterPdf={this.printPosterPdf}
              posterOptions={this.state.posterOptions}
              posterSetting={this.state.posterSettings}
            />
          ) : null}
          {this.state.showDeleteEventModal ? (
            <DeleteModal
              cancelModal={this.cancelDeleteEventModal}
              deleteCustomEvent={this.deleteEvent}
              selectedEvent={this.state.meetupIdToDelete}
            />
          ) : null}
					{this.state.showEditEventModal ? (
            <EditModal 
              cancelModal={this.cancelEditEventModal}
              editCustomEvent={this.editCustomEvent}
              selectedEvent={this.state.meetupIdToEdit}
            />
          ) : null}
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    meetups: state.meetupsFiltered,
    meetupsExist: state.meetups_exist,
    fixtures: state.fixturesFiltered,
    reqTime: state.eventsWhen,
    _meetupsFilters: state.meetupsFilters,
    _fixturesFilters: state.fixturesFilters,
    _favoriteSports: state.favoriteSports,
    _favoriteTeams: state.favoriteTeams,
    _favoriteCompetitions: state.favoriteCompetitions,
    tabIndex: state.eventsActiveTabIndex,
    needPay: state.currentClientNeedPay,
    client_loading: state.client_loading,
    meetups_loading: state.meetups_loading,
    fixtures_loading: state.fixtures_loading,
    fixtures_length: state.fixtures_length,
    meetups_length: state.meetups_length,
    timezone: state.timezone,
    time_format: state.time_format,
    showFakeLoader: state.showFakeLoader,
    sharingOptionsCount: state.sharingOptionsCount,
		client_id: state.client_id,
		cable: state.cable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFilteredMeetups: (params) =>
      dispatch(actionCreators.filterMeetups(params)),
    getFilteredFixtures: (params) =>
      dispatch(actionCreators.filterFixtures(params)),
    saveMeetupsFilters: (params) =>
      dispatch(actionCreators.saveMeetupsFilters(params)),
    saveFixturesFilters: (params) =>
      dispatch(actionCreators.saveFixturesFilters(params)),
    refreshMeetups: (params) => dispatch(actionCreators.refreshMeetups(params)),
    saveActiveTabIndex: (params) =>
      dispatch(actionCreators.saveActiveTabIndex(params, "EVENTS")),
    getFavoriteStations: (params) =>
      dispatch(actionCreators.getFavoriteStations(params)),
    getFavoriteSports: (params) =>
      dispatch(actionCreators.getFavoriteSports(params)),
    getFavoriteTeams: (params) =>
      dispatch(actionCreators.getFavoriteTeams(params)),
    getFavoriteCompetitions: (params) =>
      dispatch(actionCreators.getFavoriteCompetitions(params)),
    showNotice: (message, success, options) =>
      dispatch(actionCreators.showNotice(message, success, options)),
    hideNotice: (params) => dispatch(actionCreators.hideNotice(params)),
    fakeLoader: (isLoading) => {
      dispatch(actionCreators.showFakeLoader(isLoading));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
