import React from "react";
import DeleteModal from "../../../../components/DeleteModal";

const images = require.context("../../../../assests/images", true);

const Button = (props) => (
	<button
		className="btn p-0 event-item__action-icons"
		onClick={() => props.method(props.meetupId)}
	>
		<img src={images(props.imagePath)} alt={props.imageAlt} />
	</button>
);

/**Returns JSX For events section to display buttons for further action on the event */
const RenderButtonsInEvents = (props) => {
	const renderDeleteButton = () => (
		<Button
			method={
				props.event_type === "recurring"
					? props.showDeleteEventModal
					: props.deleteSelEvent
			}
			meetupId={props.meetupId}
			imagePath="./img/events/remove.svg"
			imageAlt="Delete event"
		/>
	);

	const renderCustomButtons = () => (
		<React.Fragment>
			{renderDeleteButton()}
			<Button
				method={
					props.event_type === 'recurring'
					? props.showEditEventModal
					: props.editCustomEvent
				}
				meetupId={props.meetupId}
				imagePath="./img/events/more.svg"
				imageAlt="Edit event"
			/>
		</React.Fragment>
	);

	const renderButtons = () => {
		if (props.container === "myevents") {
			if (props.type && props.type === "custom") {
				return renderCustomButtons();
			}
			return renderDeleteButton();
		}
		return (
			<Button
				method={props.addSelectedEvent}
				meetupId={props.id}
				imagePath="./img/events/plus.svg"
				imageAlt="Add event"
			/>
		);
	};

	return (
		<div className="col-1 d-flex align-items-center justify-content-center">
			{renderButtons()}
		</div>
	);
};

export default RenderButtonsInEvents;
