import React from "react";
import { NavLink } from "react-router-dom";
import * as SvgList from '../../utils/svg.js';

const sidebar_nav_items = [
  {
    name: "Your Profile",
    linkTo: "/profile",
    description: "Details about your personal information",
    icon: 'UserProfileIcon'
  },
  {
    name: "Your Company",
    linkTo: "/company",
    description: "Details about your company information",
    icon: 'UserCompanyIcon'
  },
  {
    name: "Your Reservation",
    linkTo: "/reservation",
    description: "Details about your reservations policy",
    icon: "ReservationIcon"
  },
  // {
  //   name: "User Management",
  //   linkTo: "/management",
  //   description: "Coming soon",
  //   icon: 'UserManagementIcon'
  // },
  {
    name: "Social Media",
    linkTo: "/socialmedia",
    description: "Manage your platform integrations",
    icon: 'SocialMediaIcon'
  },
  {
    name: "Your Preferences",
    linkTo: "/preferences",
    description: "Manage your channels and favorites",
    icon: 'FiltersIcon'
  },
  {
    name: "Widget",
    linkTo: "/widget",
    description: "Code for the games widget",
    icon: 'WidgetIcon'
  },
  {
    name: "Payment",
    linkTo: "/payment",
    description: "Setup for payment method",
    icon: 'PaymentIcon'
  },
  {
    name: "Poster Settings",
    linkTo: "/postersettings",
    description: "Poster Settings",
    icon: 'PosterSettingsIcon'
  }
];

const renderListItem = (props, item, i) => {
  const SvgIcon = SvgList[item.icon];
  return (
    <li key={i}>
      <NavLink
        to={props.match.url + item.linkTo}
        activeClassName="selected"
        className="border-bottom"
      >
        <div className="d-flex justify-content-start align-items-center">
          <div className="pr-2 align-self-center">
            <SvgIcon
            />
          </div>
          <div className="align-content-center">
            <p className="m-auto pl-2 fs-h5 settings--p__navy">
              {item.name}
            </p>
            <p
              className="fs-body4 text--gray7 pl-2 m-auto"
            >
              {item.description}
            </p>
          </div>
        </div>
      </NavLink>
    </li >
  );
}

const Sections = props => {
  return (
    <div className="d-flex justify-content-start align-items-start bg-secondary w-25  h-100 ">
      <ul className="p-0 m-0 w-100  active-list">
        {sidebar_nav_items.map((item, i) => {
          return renderListItem(props, item, i)
        })}
      </ul>
    </div>
  );
};

export default Sections;
