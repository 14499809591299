import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import PromoItem from "./promos/PromoItem";
import * as api from "../utils/api";

const Promos = () => {
  const [promos, setPromos] = useState([]);

  useEffect(() => {
    api.endpoints.getPromos(res => {
      setPromos(res.promos);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <div className="col bg-light overflow-auto">
          <Header container="promos" />
          <div className="p-2 mt-1">
            {promos.map(item => (
              <PromoItem promo={item} key={item.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promos;
