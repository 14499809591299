import React, { Fragment } from "react";

const images = require.context("../assests/images", true);
export const UploadableImageFrame = props => {
  const width = props.width || "80px"; 
  const height = props.height || "80px";

  return (
    <Fragment>
      { (props.src !== null) ? (
        <div
          className="d-flex align-items-center justify-content-center imageUploader position-relative mr-2"
          style={{
            width: width,
            height: height,
            backgroundColor: "none"
          }}
        >
          {props.changeable &&
            <img
            className="mw-100 mh-100 imageUploader__removeButton"
            src={images("./img/remove_image_icon.svg")}
            alt="close"
            onClick={() => props.changeImage(null)} />
          }
          <img
            className="mw-100 mh-100 bg-white"
            src={typeof props.src === "object" ? URL.createObjectURL(props.src) : props.src} />
          </div>
      ) : ( 
        (props.changeable) && 
        <label
          className="d-block imageUploader position-relative mb-0"
          style={{
            width: width,
            height: height
          }}
        >
          <input
            style={{
              display: "none",
            }}
            type="file"
            name="image"
            accept="image/*"
            onChange={event => props.changeImage(event.target.files[0])}
          />
          <div className="w-100 h-100 d-flex align-items-center justify-content-center">
            <img className="user-select-none" src={images("./img/add_image.svg")} />
          </div>
        </label>
      )}
    </Fragment>
  );
};
