import {groupBy} from "lodash";
import moment from "moment-timezone";

export const transformArrayToHashId = (array) => {
  let result = {}
  array.forEach(element => {
    result[element.id] = element
  });
  return result
}

/**
 * Groups items by date and sets a "group" property formatted as "D MMMM YYYY - dddd".
 * This is useful if you have multiple items in a single day.
 * @param items Items are extracted from the props.data array.
 * @param {string} dateTimeField The field that the items will be grouped by.
 * @param {string} timezone Timezone of location. */
export function groupItemsByDay(items, dateTimeField, timezone) {
  return groupBy(
      items.map(item => {
        return {
          ...item,
          group: moment(item[dateTimeField])
              .tz(timezone)
              .format("D MMMM YYYY - dddd")
              .toUpperCase()
        };
      }),
      "group"
  );
}

export function logError(error, location){
    let errorMessage = null;
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        if (error.response.data.message)
            errorMessage = error.response.data.message;
        // console.log(error.response.status);
        // console.log(error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    console.log(error.config);
    return errorMessage;
}

export const baseURL = () => {
  if (process.env.NODE_ENV !== "production")
{ 
  return "http://localhost:3000";
} 
else
{ 
  if (process.env.REACT_APP_API_HOST) {
    return  process.env.REACT_APP_API_HOST;
  } else {
    return "https://app.sportcompass.net";
  }
}
}

export const formatTime = (time, format, timezone) => {

  if (format === "24H") {
    return moment(time).tz(timezone).format("HH:mm")
  }

  return moment(time).tz(timezone).format("h:mm A")
}

export const dtzWithLocalZone = (dateTimeZ) => {
  const dateWithoutZone = dateTimeZ.format("YYYY-MM-DDTHH:mm:ss.SSS");
  const localZone = moment(dateWithoutZone).format("Z");
  const dateWithLocalZone = [dateWithoutZone, localZone].join("");

  return new Date(dateWithLocalZone);
}

export const dtLocalToDtz = (dateTime, timezone) => {
  const dateWithoutZone = moment(dateTime).format("YYYY-MM-DDTHH:mm:ss.SSS");

  return moment.tz(dateWithoutZone, timezone);
}

export const dtSetZone = (dateTime, timezone) => {
  const dateWithoutZone = moment(dateTime).format("YYYY-MM-DDTHH:mm:ss.SSS")
  const otherZone = moment.tz(dateTime, timezone).format("Z")
  const dateWithOtherZone = [dateWithoutZone, otherZone].join("")

  return new Date(dateWithOtherZone)
}

export const languages = {
    'en': 'English',
    'da-DK': 'Danish',
    'sv': 'Swedish'
}
