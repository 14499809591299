import React from "react";
import EventItem from "./eventItems/EventItem";
import moment from "moment-timezone";
import { groupBy } from "lodash";

/** Functional component that iterates over the events in the data list and renders Event Item
 * @param selectAllHandler : Function to handle the change state of select all checkbox
 * @param selectAll : boolean variable to hold the state of select all checkbox
 * @param container : identifier to the owning parent container
 * @param selection : Function to handle event item selection
 *
 */
const eventitems = props => {
  /**Returns JSX for rendering select all checkbox */
  const renderSelectAllCheckBox = () => (
    <div className="d-flex align-items-center">
      <div className="checkbox-wrapper d-flex align-items-center d-block float-left">
        <input
          type="checkbox"
          name="select all checkbox"
          onChange={props.selectAllHandler}
          checked={props.allSelected}
        />
        <span className="checkmark"></span>
      </div>
      <label className="text-left text--gray7 fs-body3 m-0 w-25 d-block float-left">SELECT ALL<> ({props.selEventIds ? props.selEventIds.length : 0})</></label>
    </div>
  );

  function renderByType(item) {
      if (item.fixture)
          return (<EventItem
              {...item.fixture}
              meetupId={item.id}
              pref_station={item.pref_station}
              {...props}
              socials={props.container.includes("myevents")}
          />)
      else if (item.event)
          return (<EventItem
              {...item.event}
              meetupId={item.id}
              {...props}
              type="custom"
              socials={props.container.includes("myevents")}
          />)
      else if (item.reservation)
          return (<EventItem
              {...item.reservation}
              meetupId={item.id}
              {...props}
              type="reservation"
              socials={props.container.includes("myevents")}
          />)
      else
          return null;
  }

  /**Returns JSX for rendering a event item  */
  const renderEvent = (item, index) =>
    item ? (
      <div
        className="align-items-center text-nowrap p-0 m-0"
        key={props.container + item.id + index}
      >
        <div
          className={
            "border-bottom d-flex event-item bg-white p-0 m-0" +
              (item.published_instagram ? " event-item--published-instagram" : " event-item--unpublished") +
              (item.published_facebook ? " event-item--published-facebook" : " event-item--unpublished") +
              (item.published ? " event-item--published-sportcompass" : " event-item--unpublished") +
              (item.reservation ? " bg-reservation" : " bg-white")
          }
        >
            {props.container === "addevents" ?
                (<EventItem {...item} {...props} socials={false} meetupId={null}/>)
                : renderByType(item)
            }
        </div>
      </div>
    ) : null;

  const groupedEvents = groupBy(
    props.data.map(event => {
      if (event.time) {
        return {
          ...event,
          group: moment(event.time)
            .tz(props.timezone)
            .format("D MMMM YYYY - dddd")
            .toUpperCase()
        };
      } else if (event.event) {
        return {
          ...event,
          group: moment(event.event.start_time)
            .tz(props.timezone)
            .format("D MMMM YYYY - dddd")
            .toUpperCase()
        };
      } else if (event.fixture) {
        return {
          ...event,
          group: moment(event.fixture.time)
            .tz(props.timezone)
            .format("D MMMM YYYY - dddd")
            .toUpperCase()
        };
      } else if (event.reservation) {
          return {
              ...event,
              group: moment(event.reservation.start_time)
                  .tz(props.timezone)
                  .format("D MMMM YYYY - dddd")
                  .toUpperCase()
          };
      } else return {...event, group: null}
    }),
    "group"
  );

  return (
    <div className="col bg-white event-items">
      <div className="d-flex event-items__header border-top">
        <div className="col-6 p-3">
          {props.container.includes("events")
            ? renderSelectAllCheckBox()
            : null}
        </div>
        <div className="col-1 justify-content-center d-flex align-items-center text--gray7 fs-body3 p-3">
          TIME
        </div>
        <div className="col justify-content-center d-flex align-items-center text--gray7 fs-body3 p-3">
          CHANNELS
        </div>
        {/* Promos in events disabled for now */}
        <div className="col justify-content-center d-flex align-items-center text--gray7 fs-body3 p-3">
          PROMOS
        </div>
        {props.container.includes("myevents") && (
          <div className="col justify-content-center d-flex align-items-center text--gray7 fs-body3 p-3">
            SOCIAL
          </div>
        )}
        {!props.container.includes("dashboard") && (
          <div className="col-1 justify-content-center d-flex align-items-center text--gray7 fs-body3 p-3">
            ACTIONS
          </div>
        )}
      </div>
      {/* Iterate through the events list to render event items */}
      {Object.keys(groupedEvents).map(group => {
        return (
          <div className="event-items__group" key={group}>
            {groupedEvents[group].filter(
              item =>
                (props.container === "addevents" &&
                  !props.meetups.some(
                    meetup => meetup.fixture && meetup.fixture.id === item.id
                  )) ||
                props.container !== "addevents"
            ).length > 0 && (
              <p
                className={`m-0 border-bottom border-top position-sticky event-items__group-title ${
                  props.container.includes("dashboard")
                    ? "event-items__group-title-dashboard"
                    : "event-items__group-title-events"
                } text--gray7 bg-white pl-5 p-3 fs-body3 pl-2`}
              >
                {group}
              </p>
            )}
            {groupedEvents[group]
              .filter(
                item =>
                  (props.container === "addevents" &&
                    !props.meetups.some(
                      meetup => meetup.fixture && meetup.fixture.id === item.id
                    )) ||
                  props.container !== "addevents"
              )
              .map((item, index) => renderEvent(item, index))}
          </div>
        );
      })}
      <div className="p-3 bg-light">
        <div style={{height: "40px"}} />
      </div>
    </div>
  );
};

export default eventitems;
