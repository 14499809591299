import React, { useState, useEffect } from "react";
import Select from "react-select";
import CustomInputDate from "./CustomInputDate";
import DatePicker from "react-datepicker";
import { dtzWithLocalZone, dtLocalToDtz } from "../utils/utils";
import moment from "moment";
import { useSelector } from "react-redux";

const ReservationBlockerPopup = (props) => {
	const timezone = useSelector((state) => state.timezone);
	const time_format = useSelector((state) => state.time_format);

	const blockReservationsOptions = [
		{
			startValue: null,
			value: new Date(new Date().setDate(new Date().getFullYear() + 100)),
			label: "Until I Turn It Off",
		},
		{
			startValue: null,
			value: new Date(new Date().setDate(new Date().getDate() + 1)),
			label: "For Today",
		},
		{
			startValue: null,
			value: new Date(new Date().setDate(new Date().getDate() + 3)),
			label: "For Next Two Days",
		},
		{
			startValue: null,
			value: new Date(new Date().setDate(new Date().getDate() + 7)),
			label: "For One Week",
		},
		{
			startValue: moment().tz(timezone),
			value: moment().tz(timezone).add(1, "hour"),
			label: "Custom",
		},
	];

	const blocked_time = props.blocked_end_time
		? props.blocked_end_time
		: blockReservationsOptions[1].value;

	const [blockedStartTimeVal, setBlockedStartTimeVal] = useState(
		props.blocked_start_time
	);
	const [blockedEndTimeVal, setBlockedEndTimeVal] = useState(blocked_time);
	const [selectedLabel, setSelectedLabel] = useState(null);

	let min_date_time = moment().tz(timezone);
	min_date_time.set("minute", Math.floor(min_date_time.minute() / 30.0) * 30);

	const handleChange = (selectedOption) => {
		setSelectedLabel(selectedOption.label);
		setBlockedStartTimeVal(selectedOption.startValue);
		setBlockedEndTimeVal(selectedOption.value);
	};

	const handleSubmit = () => {
		props.submitModal(blockedStartTimeVal, blockedEndTimeVal);
	};

	return (
		<div>
			<React.Fragment>
				<div className="modal-backdrop bg-black opacity6" />
				<div className="modal modal__publish-status d-flex align-items-center modal--overflow">
					<div className="modal-dialog modal__publish-status--dialog-width">
						<div className="p-2 modal-content">
							<div className="modal-header border-bottom-0 p-0 pr-2">
								<button
									onClick={props.cancelModal}
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body pr-0 pl-3">
								<div style={{ display: "flex", justifyContent: "center" }}>
									<div
										style={{
											flex: "1",
											maxWidth: "66%",
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Select
											styles={{
												menu: (provided) => ({ ...provided, zIndex: 9999 }),
											}}
											options={blockReservationsOptions}
											placeholder={`Select blockage time`}
											onChange={handleChange}
											value={blockReservationsOptions.find(
												(time) => time.value === blockedEndTimeVal
											)}
										/>
										{selectedLabel === "Custom" ? (
											<>
												{/* <br/> */}
												<p className="text-center mt-3"> Start Time: </p>
												<DatePicker
													selected={
														blockedStartTimeVal
															? dtzWithLocalZone(blockedStartTimeVal)
															: dtzWithLocalZone(moment().tz(timezone))
													}
													showTimeSelect
													dateFormat={
														time_format === "24H"
															? "dd/MM/yyyy HH:mm"
															: "dd/MM/yyyy hh:mm aa"
													}
													timeFormat={
														time_format === "24H" ? "HH:mm" : "hh:mm aa"
													}
													strictParsing={true}
													name="reservation_date"
													onChange={(date) => {
														if (date !== null) {
															const date_tz = dtLocalToDtz(date, timezone);
															setBlockedStartTimeVal(date_tz);
														}
													}}
													locale="en-GB"
													useWeekdaysShort={true}
													monthsShown={1}
													timeIntervals={30}
													customInput={<CustomInputDate />}
													minDate={dtzWithLocalZone(min_date_time)}
												/>
												<p className="text-center mt-3"> End Time: </p>

												<DatePicker
													selected={
														blockedEndTimeVal === "custom"
															? dtzWithLocalZone(
																	moment().tz(timezone).add(1, "hour")
															  )
															: dtzWithLocalZone(blockedEndTimeVal)
													}
													showTimeSelect
													dateFormat={
														time_format === "24H"
															? "dd/MM/yyyy HH:mm"
															: "dd/MM/yyyy hh:mm aa"
													}
													timeFormat={
														time_format === "24H" ? "HH:mm" : "hh:mm aa"
													}
													strictParsing={true}
													name="reservation_date"
													onChange={(date) => {
														if (date !== null) {
															const date_tz = dtLocalToDtz(date, timezone);
															setBlockedEndTimeVal(date_tz);
														}
													}}
													locale="en-GB"
													useWeekdaysShort={true}
													monthsShown={1}
													timeIntervals={30}
													customInput={<CustomInputDate />}
													minDate={dtzWithLocalZone(min_date_time)}
												/>
											</>
										) : null}
									</div>
								</div>
							</div>
							<div className="modal-footer pt-0 justify-content-center border-top-0">
								<button
									type="button"
									className="button--warning no-border-button bootstrap-buttons---btn bootstrap-buttons---btn-md bootstrap-buttons---btn-default"
									data-dismiss="modal"
									onClick={props.cancelModal}
								>
									Close
								</button>
								<button
									type="button"
									className="button-success--modal p-1"
									onClick={() => handleSubmit()}
								>
									Submit
								</button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		</div>
	);
};

export default ReservationBlockerPopup;
