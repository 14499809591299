import React, { Component } from "react";
import { Button } from "react-bootstrap-buttons";
import { Form, Formik } from "formik";
import Select from "react-select";
import CustomPostSelector from "./CustomPostSelector";
import CustomSwitch from "./CustomSwitch";
/**Component displayed as a modal popup with input for title, description and event date/time for custom event creation
 * @param cancelModal : Cancel Modal when clicked outside
 * @param evtForm : Form element with event title and description input fields
 * @param moment = prop for date picker element for default date
 * @param handleDateSelect = prop for date picker for handling date selection
 * @param onChange = Handler for event title and desc onChange
 * @param createEvent = Handler for create event button click
 *
 * Imported compoenent DatePicker for the date selection feature
 * CustomInputDate sent as prop to DatePicker to render the customizated
 * input text field with calender icon at right
 */

class SelectModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			poster_setting: props.posterSetting.printing_poster_option_id,
			convertToPdf: false,
		};
	}

	/**Cancels the create custom event modal
	 */
	cancelModal = () => {
		this.props.cancelModal();
	};

	printPoster = (values, actions) => {
		if (this.state.convertToPdf) {
			this.props.printPosterPdf(this.state.poster_setting);
		} else {
			this.props.printPoster(this.state.poster_setting);
		}
		actions.setSubmitting(false);
		this.props.cancelModal();
	};

	changeHandler = (field, value) => {
		this.setState((prevState) => ({
			...prevState,
			[field]: value,
		}));
	};

	render() {
		return (
			<div>
				<div className="modal-backdrop bg-secondary opacity6" />
				<div className="modal d-inline-block">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content pl-5 pr-5 pt-3 pb-3  m-auto bg--gray1">
							<Formik
								enableReinitialize={true}
								onSubmit={this.printPoster}
								initialValues={{}}
							>
								{({ errors, touched, isSubmitting }) => (
									<Form>
										<div className="modal-body modal__print-poster--body-height px-0 ml-0 bg--gray1">
											<div className="m-auto bg--gray1 w-100">
												<div className="row align-items-start bg--gray1 w-100 h-100">
													<div className="w-100">
														<div className="bg--gray1">
															<div className="h5">
																Please select poster type:{" "}
															</div>
															<br />
															<div className="d-flex flex-column align-items-center">
																<CustomPostSelector
																	images={this.props.posterOptions}
																	selected={this.state.poster_setting}
																	setSelected={(selected) => {
																		this.setState({ poster_setting: selected });
																	}}
																/>
															</div>
														</div>
														<div className="d-flex align-items-center justify-content-center mt-2">
															<div className="ml-1 mr-2 ">PNG</div>
															<CustomSwitch
																checked={this.state.convertToPdf}
																className="event-filter__stations-switch"
																changeOffHandleColor="#000000"
																onChange={(checked) => {
																	this.changeHandler("convertToPdf", checked);
																}}
															/>
															<div className="ml-1 mr-2 ">
																PDF With Cutmarks
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="modal-footer p-0 border-0">
											<Button
												type="button"
												className="button-warning--modal p-1 mr-2"
												onClick={this.cancelModal}
											>
												Cancel
											</Button>
											<Button
												type="submit"
												className="button-success--modal p-1"
												disabled={isSubmitting}
											>
												Print Poster
											</Button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SelectModal;
