import React, { useState, useEffect } from "react";
import * as api from "../../utils/api";
import SettingHeader from "./SettingsHeader";

import { loadStripe } from "@stripe/stripe-js";
import CustomLoader from "../../components/CustomLoader";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../modules/actions";
import { baseURL } from "../../utils/utils";
import * as grid from "../../grid.css";
import "../../assests/scss/components/prices_style.css";
import BasicCard from "./paymentCards/BasicCard";

const handleClick = async (props) => {
	// Call your backend to create the Checkout session.
	let { sessionId } = await api.endpoints.createCheckoutSession(props.priceId);

	// When the customer clicks on the button, redirect them to Checkout.
	const stripe = await loadStripe(props.pk);
	const { error } = await stripe.redirectToCheckout({
		sessionId,
	});
	if (error != null) props.showNotice(error.message, false);
	// If `redirectToCheckout` fails due to a browser or network
	// error, display the localized error message to your customer
	// using `error.message`.
};

const handleManageBiling = async () => {
	api.endpoints.createCustomerPortalSession((res) => {
		window.location = res.customerPortalUrl;
	});
};

const PaymentOption = (props) => {
	return (
		<>
			<BasicCard
				type={props.type}
				handleClick={handleClick}
				key={props.key}
				priceId={props.priceId}
				price={props.price}
				currency={props.currency}
				interval={props.interval}
				pk={props.pk}
				terms={props.terms}
				setDangerChekmark={props.setDangerChekmark}
				showNotice={props.showNotice}
			/>
		</>
	);
};

const Payment = (props) => {
	const [options, setOptions] = useState([]);

	const [status, setStatus] = useState(null);
	const [loading, setLoading] = useState(true);
	const [manageBillingPressed, setManageBillingPressed] = useState(false);
	const [pk, setPK] = useState("");
	const [agreeTerms, setAgreeTerms] = useState(false);
	const [dangerCheckmark, setDangerChekmark] = useState(false);

	useEffect(() => {
		api.endpoints.getPaymentDefaults((defaults) => {
			setLoading(false);
			setStatus(defaults.status);
			if (defaults.offers != null) {
				const sortedOptions = [...defaults.offers.data].sort((a, b) => {
					if (a.nickname.toLowerCase() === "basic") {
						return -1;
					}
					if (b.nickname.toLowerCase() === "basic") {
						return 1;
					}
					return 0;
				});
				setOptions(sortedOptions);
				setPK(defaults.pk);
			}
		});
	}, []);

	const list = options.map((option) => (
		<PaymentOption
			// discount="Save 33% per month"
			type={option.nickname.toLowerCase()}
			key={option.id}
			priceId={option.id}
			price={option.unit_amount / 100}
			currency={option.currency}
			interval={option.recurring.interval}
			pk={pk}
			terms={agreeTerms}
			setDangerChekmark={setDangerChekmark}
			showNotice={props.showNotice}
		/>
	));

	return (
		<div
			className="d-flex bg-secondary w-100 h-100 ml-5"
			style={{ overflowY: "scroll" }}
		>
			<div className="w-100 mr-5 ml-2 bg-secondary">
				<SettingHeader
					image="./payment-icon.svg"
					imageAlt="Payment"
					title="Payment"
				/>
				<CustomLoader loading={loading}>
					{status != "inactive" ? (
						<CustomLoader loading={manageBillingPressed}>
							<h4 className="fs-h4 text--gray8 ">
								Here you can manage (cancel/upgrade/save new payment method)
								your Sport Compass subscription.
							</h4>
							<button
								className="button--success fs-btn2"
								onClick={() => {
									setManageBillingPressed(true);
									handleManageBiling();
								}}
							>
								Manage Billing
							</button>
						</CustomLoader>
					) : (
						<>
							<div className="flex-row justify-content-around m-2">
								<div className="d-flex">
									<div className="d-flex flex-column justify-content-between ml-4 mr-auto pt-4 pb-4">
										<div className="d-flex mt-1 align-items-center">
											<div
												style={{
													width: "10px",
													height: "40px",
													backgroundColor: "#34b4f5",
												}}
											></div>
											<div
												className="ml-2 w-100"
												style={{ fontSize: "34px", fontWeight: "800" }}
											>
												Pricing
											</div>
										</div>
										<div>
											<div
												style={{
													color: "gray",
												}}
											>
												<p
													style={{ whiteSpace: "nowrap", marginBottom: "5px" }}
												>
													*Startup fee per venue:
												</p>
												<h5> 1.500 dkk </h5>
											</div>
										</div>
									</div>
									<div
										className="d-flex flex-row"
										style={{ marginLeft: "10px", marginRight: "auto" }}
									>
										{list.map((element) => {
											return (
												<div key={element.id} className={"grid-item"}>
													{element}
												</div>
											);
										})}
									</div>
								</div>
							</div>

							<h4
								className="row fs-h4 text--gray8 pt-2 mt-2"
								style={{ height: 50 }}
							>
								<div className="checkbox-wrapper ml-2">
									<input
										type="checkbox"
										className="border-danger"
										checked={agreeTerms}
										onClick={() => {
											setDangerChekmark(false);
											setAgreeTerms(!agreeTerms);
										}}
									/>
									<span
										className={
											"checkmark" +
											(dangerCheckmark ? " checkmark--danger" : "")
										}
									></span>
								</div>
								I agree to Sport Compass&nbsp;
								<a href={baseURL() + "/terms_of_use.pdf"} target="_blank">
									Terms of use
								</a>
							</h4>
						</>
					)}
				</CustomLoader>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		showNotice: (message, success) =>
			dispatch(actionCreators.showNotice(message, success)),
	};
};

export default connect(null, mapDispatchToProps)(Payment);
