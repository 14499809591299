import axios from "axios";
import moment from "moment-timezone";
// import { useRef } from "react"

export const urls = {
	meetups: "/meetups",
	fixture_events: "/fixture_events",
	fixtures: "/fixtures",
	events: "/events",
	reservations: "/reservations",
	locations: "/locations",
	close_open_hours: "/closing_opening_hour",
	login: "/login",
	stations: "/stations",
	fb_extend_token: "/facebook_integration/extend_short_access",
	fb_status: "/facebook_integration/status",
	fb_revoke_access: "facebook_integration/revoke_access",
	fb_update_posting_targets: "facebook_integration/posting_targets",
	favorite_stations: "/favorite_stations",
	favorite_sports: "/favorite_sports",
	favorite_teams: "/favorite_teams",
	favorite_competitions: "/favorite_competitions",
	update_password: "/reset_password",
	publish: "/publish",
	create_post_header: "/create_post_header",
	publish_defaults: "/publish_defaults",
	get_video_preview: "/get_video_preview",
	generate_excel: "/generate_excel",
	generate_video: "/generate_video",
	generate_poster: "/generate_poster",
	generate_poster_pdf: "/generate_poster_pdf",
	promos: "/promos",
	current_client: "/current",
	payment: "/payment",
	poster_options: "/poster_options",
	poster_settings: "/poster_settings",
	social_media_posts: "/social_media_posts",
	social_media_posting_rules: "/social_media_posting_rules",
	set_favorite_widget: "/widgets/set_favorite_widget",
	currencies: "/currencies",
	get_currency: "/get_currency",
	set_currency: "/set_currency",
	notifications: "/notification",
};

// change base url because widgets endpoint doesn't have 'api/v1' prefix
const widgetsBaseURL = () => {
	if (process.env.NODE_ENV !== "production") {
		return "http://localhost:3000";
	} else {
		if (process.env.REACT_APP_API_HOST) {
			return process.env.REACT_APP_API_HOST;
		} else {
			return "https://app.sportcompass.net";
		}
	}
};

export const endpoints = {
	login: async (data) => {
		let res = await axios.post(urls.login, { ...data });
		const AuthStr = "Bearer ".concat(res.data.jwt);
		axios.defaults.headers.common["Authorization"] = AuthStr;
		return res.data.jwt;
	},
	updatePassword: async (data, suc, err) => {
		try {
			await axios.put(urls.update_password, { ...data });
			suc();
		} catch (ex) {
			err(ex.response);
		}
	},
	createMeetups: async (locationId, selEventsArray, cb) => {
		try {
			if (!locationId) {
				locationId = await endpoints.getLocation();
			}
			for (let i in selEventsArray) {
				await axios.post(urls.fixture_events, {
					fixture_id: selEventsArray[i],
					location_id: locationId,
				});
			}
			cb();
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	deleteMultipleMeetups: async (locationId, selMeetupIds, cb) => {
		try {
			if (!locationId) {
				locationId = await endpoints.getLocation();
			}
			await axios.post(`${urls.meetups}/delete_multiple`, {
				ids: selMeetupIds,
			});
			cb();
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	deleteMeetup: async (meetupId, deleteMode, cb) => {
		try {
			let response = await axios.delete(`${urls.meetups}/${meetupId}`, {
				data: { delete_mode: deleteMode },
			});
			cb(response);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	deleteRecurringEvent: async (meetupId, deleteMode, callback) => {
		try {
			let res = await axios.delete(`${urls.meetups}/recurring/${meetupId}`, {
				data: { delete_mode: deleteMode },
			});
			callback(res);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	getMeetups: async (filters, cb, err) => {
		try {
			let res = await axios.get(urls.meetups, {
				params: filters,
			});
			cb(res.data);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	printMeetups: async (selMeetupIds, cb) => {
		try {
			const meetups = { meetup_ids: selMeetupIds };
			let res = await axios.post(urls.generate_excel, meetups, {
				responseType: "blob",
			});
			cb(res);
		} catch (ex) {
			console.log(ex);
			console.log(ex.response.data.message);
		}
	},
	printPoster: async (selMeetupIds, option, cb, handleError) => {
		try {
			const meetups = {
				meetup_ids: selMeetupIds,
				poster_option: option,
			};
			let res = await axios.post(urls.generate_poster, meetups);
			cb(res);
		} catch (ex) {
			ex.response.data.text().then((txt) => {
				err_cb(ex, JSON.parse(txt).message);
			});
		}
	},

	printPosterPdf: async (selMeetupIds, option, cb, handleError) => {
		try {
			const meetups = {
				meetup_ids: selMeetupIds,
				poster_option: option,
			};
			let res = await axios.post(urls.generate_poster_pdf, meetups);
			cb(res);
		} catch (ex) {
			ex.response.data.text().then((txt) => {
				err_cb(ex, JSON.parse(txt).message);
			});
		}
	},

	generateVideo: async (selMeetupIds, cb, err_cb) => {
		try {
			const meetups = { meetup_ids: selMeetupIds };
			let res = await axios.post(urls.generate_video, meetups);
			cb(res);
		} catch (ex) {
			ex.response.data.text().then((txt) => {
				err_cb(ex, JSON.parse(txt).message);
			});
		}
	},
	createCustomEvent: async (locationId, eventObj, cb, err) => {
		try {
			if (!locationId) {
				locationId = await endpoints.getLocation();
			}
			await axios.post(urls.events, {
				location_ids: [locationId],
				...eventObj,
			});
			cb();
		} catch (ex) {
			err(ex);
		}
	},
	editCustomEvent: async (locationId, eventId, eventObj, editMode, cb, err) => {
		try {
			if (!locationId) {
				locationId = await endpoints.getLocation();
			}
			await axios.put(`${urls.events}/${eventId}`, {
				location_ids: [locationId],
				update_mode: editMode,
				...eventObj,
			});
			cb();
		} catch (ex) {
			err(ex);
		}
	},

	createReservation: function (reservation, cb, err) {
		axios.post(urls.reservations, reservation).then(cb).catch(err);
	},

	updateReservation: function (reservation, cb, err) {
		axios
			.put(`${urls.reservations}/${reservation.id}`, reservation)
			.then(cb)
			.catch(err);
	},

	deleteReservation: function (reservationId, cb, err) {
		axios.delete(`${urls.reservations}/${reservationId}`).then(cb).catch(err);
	},

	deleteReservations: function (reservationIds, cb, err) {
		axios
			.delete(urls.reservations, {
				data: { reservation_ids: reservationIds },
			})
			.then(cb)
			.catch(err);
	},

	getReservations: function (filters, cb, err) {
		axios.get(urls.reservations, { params: filters }).then(cb).catch(err);
	},

	printReservations: async (reservationIds, cb, err) => {
		try {
			const reservations = { reservation_ids: reservationIds };
			let res = await axios.post(
				`${urls.reservations}/generate_excel`,
				reservations,
				{
					responseType: "blob",
				}
			);
			cb(res);
		} catch (ex) {
			err(ex);
		}
	},

	updateReservationValidation: async (reservationId, cb, err) => {
		axios
			.post(`${urls.reservations}/accept_reservation/${reservationId}`)
			.then(cb)
			.catch(err);
	},

	updateReservationRejection: async (reservationId, cb, err) => {
		axios
			.post(`${urls.reservations}/reject_reservation/${reservationId}`)
			.then(cb)
			.catch(err);
	},

	// getReservationSeenStatus: async (reservationId, cb, err) => {
	// 	axios.post(`${urls.reservations}/seen_reservation/${reservationId}`).then(cb).catch(err);
	// },

	updateCloseOpenHour: async (locationId, cohId, data, cb) => {
		try {
			let res = await axios.put(
				`${urls.locations}/${locationId}/${urls.close_open_hours}/${cohId}`,
				data
			);
			cb(res.data.location_raw);
		} catch (ex) {
			console.log(ex.response);
			console.log(ex.response.data.message);
			cb(null, ex.response.data.message);
		}
	},

	createCloseOpenHour: async (locationId, data, cb) => {
		try {
			let res = await axios.post(
				`${urls.locations}/${locationId}/${urls.close_open_hours}`,
				data
			);
			cb(res.data.location_raw, null);
		} catch (ex) {
			console.log(ex.response.data.message);
			cb(null, ex.response.data.message);
		}
	},

	getCloseOpenHour: async (locationId, cb) => {
		try {
			let res = await axios.get(
				`${urls.locations}/${locationId}/${urls.close_open_hours}`
			);
			cb(res.data);
		} catch (ex) {
			console.log(ex.response);
		}
	},

	updateLocation: async (locationId, companyData, image, cb) => {
		try {
			// TODO: fix, to send all in one request.
			if (image !== null) {
				const form = new FormData();
				form.append("photo", image);
				await axios.put(`${urls.locations}/${locationId}`, form);
			}
			let res = await axios.put(`${urls.locations}/${locationId}`, companyData);
			cb(res.data.location_raw, null);
		} catch (ex) {
			console.log(ex.response);
			console.log(ex.response.data.message);
			cb(null, ex.response.data.message);
		}
	},

	createLocation: async (companyData, image, cb) => {
		try {
			let res = await axios.post(urls.locations, companyData);
			if (image !== null) {
				const form = new FormData();
				const locationId = res.data.location_raw.id;
				form.append("photo", image);
				res = await axios.put(`${urls.locations}/${locationId}`, form);
			}
			cb(res.data.location_raw, null);
		} catch (ex) {
			console.log(ex.response.data.message);
			cb(null, ex.response.data.message);
		}
	},
	getLocation: async (cb) => {
		try {
			let res = await axios.get(urls.locations);
			let location = res.data.locations ? res.data.locations[0] : null;
			if (cb) cb(location);
			return location.id;
		} catch (ex) {
			console.log(ex.response);
		}
	},

	getLocationWidget: async (callback) => {
		try {
			let res = await axios.get(urls.locations);
			let location = res.data.locations ? res.data.locations[0] : null;
			if (callback) callback(location);
			return location;
		} catch (ex) {
			console.log(ex.response);
		}
	},

	handleFBToken: async (token, cb, errorHandler) => {
		try {
			let res = await axios.post(urls.fb_extend_token, {
				access_token: token,
			});
			cb(res.data);
		} catch (ex) {
			errorHandler(ex);
		}
	},
	getFBStatus: async (cb, errorHandler, params) => {
		try {
			let res = await axios.get(urls.fb_status, params);
			cb(res.data);
		} catch (ex) {
			errorHandler(ex);
		}
	},
	fbRevokeAccess: async (cb, errorHandler) => {
		try {
			let res = await axios.post(urls.fb_revoke_access);
			cb(res.data);
		} catch (ex) {
			errorHandler(ex);
		}
	},
	fbUpdatePostingTargets: async (postingTargets, cb, errorHandler) => {
		try {
			let res = await axios.put(urls.fb_update_posting_targets, postingTargets);
			cb(res.data);
		} catch (ex) {
			errorHandler(ex);
		}
	},

	publish: async (selMeetupIds, postOptions, cb, err) => {
		const msg = {
			ALREADY_ERROR:
				"We are still working publishing the list of events you shared just before. Please hold tight until we finish.",
			ALREADY_MESSAGE:
				"It looks like you are trying to re-publish this list. Try adding some new games to the list.",
			INVALID_ERROR: "Some meetups are invalid",
			INVALID_ERROR_MESSAGE:
				"One or more of the events you are trying to publish has already started and can not be published anymore",
			GENERAL_ERROR_MESSAGE:
				"Something went wrong when publishing the events. Please contact Calin from Sport Compass if this issue keeps repeating.",
		};
		try {
			let publishOptions = postOptions == null ? {} : postOptions;
			publishOptions.meetup_ids = selMeetupIds;
			if (publishOptions.image != null || publishOptions.video != null) {
				let formData = new FormData();

				for (const [key, val] of Object.entries(publishOptions)) {
					if (key === "image" && Array.isArray(val)) {
						val.forEach((file, index) => {
							formData.append(`image_${index}`, file);
						});
					} else {
						formData.append(key, val);
					}
				}

				publishOptions = formData;
			}

			let res = await axios.post(urls.publish, publishOptions);
			cb(res.data);
		} catch (ex) {
			if (ex.response.status === 400) {
				if (ex.response.data.message === msg.ALREADY_ERROR) {
					err(msg.ALREADY_MESSAGE);
				} else if (ex.response.data.message === msg.INVALID_ERROR) {
					err(msg.INVALID_ERROR_MESSAGE);
				} else {
					err(ex.response.data.message);
				}
			} else {
				console.log(ex.response.data.message);
				err(msg.GENERAL_ERROR_MESSAGE);
			}
		}
	},

	getPostHeader: async (selMeetupIds, cb, err) => {
		try {
			let res = await axios.post(urls.create_post_header, {
				meetup_ids: selMeetupIds,
			});
			cb(res.data);
		} catch (ex) {
			console.log(ex.response);
		}
	},

	publishDefaults: async (cb) => {
		try {
			let res = await axios.get(urls.publish_defaults);
			cb(res.data);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	searchStations: async (searchTerm, callback) => {
		try {
			let res = await axios.get(`/search_stations?query=${searchTerm}`);
			callback(res);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	updatePreferences: async (channelIds, crudType, cb) => {
		try {
			if (crudType === "add") {
				await axios.post(urls.favorite_stations, {
					station_ids: channelIds,
				});
			} else {
				await axios.delete(`${urls.favorite_stations}/${channelIds[0]}`, {
					data: { station_ids: channelIds },
				});
			}
			endpoints.reloadPreferences(cb);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	updateSports: async (favSportId, sportId, crudType, cb) => {
		try {
			if (crudType === "add") {
				await axios.post(urls.favorite_sports, { sport_id: sportId });
			} else {
				await axios.delete(`${urls.favorite_sports}/${favSportId}`);
			}
			endpoints.reloadSports(cb);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	updateTeams: async (favTeamId, crudType, cb) => {
		try {
			if (crudType === "add") {
				await axios.post(urls.favorite_teams, { team_name: favTeamId });
			} else {
				await axios.delete(`${urls.favorite_teams}/${favTeamId}`, {
					team_name: favTeamId,
				});
			}
			endpoints.reloadTeams(cb);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	updateCompetitions: async (favCompetitionId, crudType, cb) => {
		try {
			if (crudType === "add") {
				await axios.post(urls.favorite_competitions, {
					competition_name: favCompetitionId,
				});
			} else {
				await axios.delete(
					`${urls.favorite_competitions}/${favCompetitionId}`,
					{
						competition_name: favCompetitionId,
					}
				);
			}
			endpoints.reloadCompetitions(cb);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	reloadPreferences: async (cb) => {
		try {
			let res = await axios.get(urls.favorite_stations);
			cb(res.data.favorite_stations);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	reloadSports: async (cb) => {
		try {
			let res = await axios.get(urls.favorite_sports);
			cb(res.data.favorite_sports);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	reloadTeams: async (cb) => {
		try {
			let res = await axios.get(urls.favorite_teams);
			cb(res.data.favorite_teams);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	reloadCompetitions: async (cb) => {
		try {
			let res = await axios.get(urls.favorite_competitions);
			cb(res.data.favorite_competitions);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	updateMeetupsChannel: async (meetupId, channelId, cb) => {
		try {
			await axios.put(`${urls.meetups}/${meetupId}`, {
				pref_station_id: channelId,
			});
			cb();
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	getPromos: async (cb) => {
		try {
			let res = await axios.get(urls.promos);
			cb(res.data);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	getPromo: async (promoId, cb) => {
		try {
			let res = await axios.get(`${urls.promos}/${promoId}`);
			cb(res.data);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	getPromoWinner: async (promoId, cb) => {
		try {
			let res = await axios.get(`${urls.promos}/${promoId}/winner`);
			cb(res.data);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	getCurrentClient: async (cb) => {
		const localTimezone = moment.tz.guess();
		const timeFormat = "24H";

		try {
			let res = await axios.get(urls.current_client, {
				params: {
					localTimezone,
					timeFormat,
				},
			});

			if (res.data.client_account.role === "ADMIN") {
				let resCurrency = await axios.get(
					`/clients/${res.data.client_account.client_id}` + urls.get_currency
				);
				if (resCurrency.data.currency_id && resCurrency.data.name) {
					window.sessionStorage.setItem("currency_name", resCurrency.data.name);
					window.sessionStorage.setItem(
						"currency_id",
						resCurrency.data.currency_id
					);
				}
			}

			if (res.data.client_account.client_id) {
				window.sessionStorage.setItem(
					"client_id",
					res.data.client_account.client_id
				);
			}
			if (res.data.time_settings.timezone && res.data.time_settings.format) {
				window.sessionStorage.setItem(
					"timezone",
					res.data.time_settings.timezone
				);
				window.sessionStorage.setItem(
					"time_format",
					res.data.time_settings.format
				);
			} else {
				window.sessionStorage.setItem("timezone", moment.tz.guess());
				window.sessionStorage.setItem("time_format", "24H");
			}
			if (res.data.language) {
				window.sessionStorage.setItem("language", res.data.language);
			}
			cb(res.data);
		} catch (ex) {
			console.log("getCurrentClient error:", ex);
			console.log("getCurrentClient error:", ex.response);
			console.log("getCurrentClient error:", ex.response.data);
			console.log("getCurrentClient error:", ex.response.data.message);
		}
	},

	createCheckoutSession: async (priceId) => {
		try {
			let res = await axios.post(urls.payment + "/checkout_session", {
				priceId: priceId,
			});
			return res.data;
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	createCustomerPortalSession: async (cb) => {
		try {
			let res = await axios.post(urls.payment + "/customer_portal_session");
			cb(res.data);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	getPaymentDefaults: async (cb) => {
		try {
			let res = await axios.get(urls.payment + "/defaults");
			cb(res.data);
		} catch (ex) {
			console.log(ex);
		}
	},

	getPosterOptions: async (cb) => {
		try {
			let res = await axios.get(urls.poster_options);
			cb(res.data);
		} catch (ex) {
			console.log(ex.response);
		}
	},
	getPosterSettings: async (cb) => {
		try {
			let res = await axios.get(`${urls.poster_settings}/current`);
			cb(res.data.poster_setting);
		} catch (ex) {
			console.log(ex.response);
		}
	},
	updatePosterSettings: async (posterSettings, cb) => {
		try {
			if (
				posterSettings.white_logo != null ||
				posterSettings.black_logo != null
			) {
				let formData = new FormData();
				for (const [key, val] of Object.entries(posterSettings)) {
					formData.append(key, val);
				}
				posterSettings = formData;
			}
			let res = await axios.put(
				`${urls.poster_settings}/current`,
				posterSettings
			);
			cb(res);
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	getSocialMediaPosts: async (cb) => {
		try {
			let res = await axios.get(urls.social_media_posts);
			cb(res.data.social_media_posts);
		} catch (ex) {
			console.log(ex.response);
		}
	},
	createSocialMediaPost: async (post, cb, err) => {
		try {
			if (post.image != null || post.video != null) {
				let formData = new FormData();
				for (const [key, val] of Object.entries(post)) {
					if (key === "image" && val === null) {
						continue;
					}
					if (key === "video" && val === null) {
						continue;
					} else {
						formData.append(key, val);
					}
				}
				post = formData;
			}
			const res = await axios.post(urls.social_media_posts, post);
			cb(res.data.social_media_post);
		} catch (ex) {
			err(ex);
		}
	},
	updateSocialMediaPost: async (post, cb, err) => {
		try {
			const id = post.id;
			if (post.image != null || post.video != null) {
				let formData = new FormData();
				for (const [key, val] of Object.entries(post)) {
					if (key === "image" && val === null) {
						continue;
					}
					if (key === "video" && val === null) {
						continue;
					} else {
						formData.append(key, val);
					}
				}
				post = formData;
			}
			const res = await axios.put(`${urls.social_media_posts}/${id}`, post);
			cb(res.data.social_media_post);
		} catch (ex) {
			err(ex);
		}
	},
	deleteSocialMediaPost: async (postId, cb) => {
		try {
			await axios.delete(`${urls.social_media_posts}/${postId}`);
			cb();
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	get_post_preview: async (post, callback, err) => {
		try {
			const id = post.id;
			if (post.image != null || post.video != null) {
				let formData = new FormData();
				for (const [key, val] of Object.entries(post)) {
					if (key === "image" && val === null) {
						continue;
					}
					if (key === "video" && val === null) {
						continue;
					} else {
						formData.append(key, val);
					}
				}
				post = formData;
			}
			const res = await axios.post(
				`${urls.social_media_posts}/post_preview?id=${id}`,
				post
			);
			callback(res.data.post_preview);
		} catch (ex) {
			err(ex);
		}
	},

	getSocialMediaPostingRules: async (cb) => {
		try {
			let res = await axios.get(urls.social_media_posting_rules);
			cb(res.data.social_media_posting_rules);
		} catch (ex) {
			console.log(ex.response);
		}
	},
	createSocialMediaPostingRule: async (postingRule, cb, err) => {
		try {
			if (postingRule.image != null || postingRule.video != null) {
				let formData = new FormData();
				for (const [key, val] of Object.entries(postingRule)) {
					if (key === "image" && val === null) {
						continue;
					}
					if (key === "video" && val === null) {
						continue;
					} else {
						formData.append(key, val);
					}
				}

				postingRule = formData;
			}
			const res = await axios.post(
				urls.social_media_posting_rules,
				postingRule
			);
			cb(res.data.social_media_posting_rule);
		} catch (ex) {
			err(ex);
		}
	},
	updateSocialMediaPostingRule: async (postingRule, cb, err) => {
		try {
			const id = postingRule.id;
			if (postingRule.image != null || postingRule.video != null) {
				let formData = new FormData();
				for (const [key, val] of Object.entries(postingRule)) {
					if (key === "image" && val === null) {
						continue;
					}
					if (key === "video" && val === null) {
						continue;
					} else {
						formData.append(key, val);
					}
				}
				postingRule = formData;
			}
			const res = await axios.put(
				`${urls.social_media_posting_rules}/${id}`,
				postingRule
			);
			cb(res.data.social_media_posting_rule);
		} catch (ex) {
			err(ex);
		}
	},
	deleteSocialMediaPostingRule: async (postingRuleId, cb) => {
		try {
			await axios.delete(`${urls.social_media_posting_rules}/${postingRuleId}`);
			cb();
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	setFavoriteWidget: async (widget_option, callback) => {
		try {
			const url = widgetsBaseURL();

			let res = await axios.post(url + "/widgets/set_favorite_widget", {
				widget_option: widget_option,
			});
			callback(res.data);
			return res.data;
		} catch (ex) {
			console.log(ex.response.data);
		}
	},
	getFavoriteWidgetOption: async (callback) => {
		try {
			const url = widgetsBaseURL();

			let res = await axios.get(url + "/widgets/client_option");
			callback(res.data);
			return res.data;
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},
	getWidgets: async (callback) => {
		try {
			const url = widgetsBaseURL();

			let res = await axios.get(url + "/widgets/index");
			callback(res.data);
			return res.data;
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	getCustomWidgetPreview: async (colors,font, hash, callback) => {
		try {
			const url = widgetsBaseURL();

			let res = await axios.get(
				url + `/widgets/custom_widget_preview?hash=${hash}`,
				{
					params: {
						...colors,
						...font ,
					},
				}
			);
			callback(res.data);
			return res.data;
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	getWidgetCustomColors: async (callback) => {
		try {
			const url = widgetsBaseURL();

			let res = await axios.get(url + `/widgets/custom_colors`);
			callback(res.data);
			return res.data;
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	setCustomWidgetColor: async (colors,font, callback) => {
		try {
			const url = widgetsBaseURL();

			let res = await axios.post(url + "/widgets/set_custom_widget", {
				...colors,
				...font,
			});
			callback(res.data);
			return res.data;
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	setWidgetDescriptionPreopen: async (check, callback) => {
		try {
			const url = widgetsBaseURL();

			let res = await axios.post(url + "/widgets/set_description_preopening", {
				preopen_description: check,
			});
			callback();
			return res.data;
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	getCurrencies: function (cb, err) {
		axios.get(urls.currencies).then(cb).catch(err);
	},

	getCurrency: async (clientId, cb, err) => {
		try {
			let res = await axios.get(`/clients/${clientId}${urls.get_currency}`);
			cb(res.data);
			return res.data;
		} catch (ex) {
			console.log(ex.response);
		}
	},

	updateCurrency: async (clientId, newCurrencyId, cb) => {
		try {
			let res = await axios.put(`clients/${clientId}${urls.set_currency}`, {
				currency_id: newCurrencyId,
			});
			sessionStorage.setItem("currency_name", res.data.name);
			sessionStorage.setItem("currency_id", res.data.currency_id);
			cb(res.data);
		} catch (ex) {
			console.log(ex.response.data.message);
			cb(null, ex.response.data.message);
		}
	},

	getReservationCustomWidgetPreview: async (colors, font, hash, callback) => {
		try {
			const url = widgetsBaseURL();

			let res = await axios.get(
				url + `/widgets/reservation_widget_preview?hash=${hash}`,
				{
					params: {
						...colors,
						...font,
					},
				}
			);
			callback(res.data);
			return res.data;
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	getReservationWidgetCustomColors: async (callback) => {
		try {
			let res = await axios.get(`/widgets/get_reservations_colors`);
			callback(res.data);
			return res.data;
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	setReservationCustomWidgetColor: async (colors, font, callback) => {
		try {
			let res = await axios.post("/widgets/set_reservations_colors", {
				...colors,
				...font,
			});
			callback(res.data);
			return res.data;
		} catch (ex) {
			console.log(ex.response.data.message);
		}
	},

	getReservationWidgetCustomFont: async (callback) => {
		try {
			let res = await axios.get("widgets/get_reservations_font");
			callback(res.data);
			return res.data;
		} catch (ex) {
			console.log(ex.response.data);
		}
	},

	deleteNotification: async (notificationId, cb) => {
		try {
			const res = await axios.delete(`${urls.notifications}/${notificationId}`);
			cb(res);
			return res;
		} catch (ex) {
			console.log(ex.response);
		}
	},

	deleteNotifications: async (notificationIds, cb) => {
		try {
			const res = await axios.delete(urls.notifications, {
				data: { notification_ids: notificationIds },
			});
			cb(res);
			return res;
		} catch (ex) {
			console.log(ex.response);
		}
	},

	getNotifications: async (cb, err) => {
		try {
			const res = await axios.get(urls.notifications);
			cb(res);
			return res;
		} catch (ex) {
			console.log(ex.response.data);
		}
	},

	getNotificationById: async (notificationId, cb) => {
		try {
			const res = await axios.get(`${urls.notifications}/${notificationId}`);
			cb(res);
			return res;
		} catch (ex) {
			console.log(ex.response.data);
		}
	},

	getUnseenNotifications: async (cb, err) => {
		try {
			const res = await axios.get(`${urls.notifications}/unseen_notifications`);
			cb(res);
			return res;
		} catch (ex) {
			console.log(ex.response.data);
		}
	},

	getWidgetCustomFont: async ( callback ) => {
		try {
			const url = widgetsBaseURL();
			let res = await axios.get(url + "/widgets/get_widget_font" );
			console.log(res)
			callback(res.data);
			return res.data;
		} catch (ex) {
			console.log(ex.response.data);
		}
	},
};
