import React, {Component} from "react";
import PropTypes from "prop-types";

class CustomAttributeInput extends Component {
  static propTypes = {
    text: PropTypes.string,
    imageSource: PropTypes.string,
    onChange: PropTypes.func
  };

  render() {
    const {text, imageSource, inputClassName, value, onChange} = this.props;
    return (
      <div className="feature-div">
        <img
          src={imageSource}
          className="feature-img"
          alt="map"/>

        <h5 className="feature-name text--gray8">
          {text}
        </h5>

        <input
          type={"number"}
          className={inputClassName}
          placeholder={value}
          value={value}
          onChange={onChange}
        />

      </div>
    )
  }
}
export default CustomAttributeInput
