import React from "react";

const CustomButtonShareEvents = (props) => {
	const handleChange = () => {
		props.onClick();
	};

	return (
		<div
			className="checkbox-button ml-0 mr-1 pr-4"
			style={{ border: props.style }}
		>
			<label className="d-flex align-items-center mt-1 mb-1">
				<img className="ml-2 mr-2" src={props.image} alt={props.name} />
				<input type={props.type} name={props.name} onClick={handleChange} />
				<span>{props.name}</span>
			</label>
		</div>
	);
};

export default CustomButtonShareEvents;
