import React, { useState, useEffect, useRef } from "react";
import * as api from "../utils/api";
import * as actionCreators from "../modules/actions";
import { connect } from "react-redux";
import { logError } from "../utils/utils";
import Header from "../components/Header";
import NotificationItems from "./notifications/NotificationItems";
import NotificationActionPanel from "./notifications/NotificationActionsPanel";
import PublishStatusPopup from "../components/PublishStatusPopup";

const errorDisplayLevel = "detailed"; //'low' or 'medium' or 'detailed' can be later adjusted as a function of Rails.env
const genericErrorMsg =
  "An error has occurred. Please try again. If the error persists please contact Sport Compass.";

const Notifications = (props) => {
  const [selNotificationIds, setSelNotificationIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [popup, setPopup] = useState(false);
  const [notificationItem, setNotificationItem] = useState({});

  const setSeenStatus = (id) => {
    api.endpoints.getNotificationById(
      id,
      (res) => "success",
      (err) => "err"
    );
  };

  useEffect(() => {
    reloadNotifications();
  }, []);

  useEffect(() => {
    if (!props.showNotifications) {
      cancelPublishStatusModal();
    }
  }, [props.showNotifications]);
  
  const showPopup = (item) => {
    setPopup(true);
    setNotificationItem(item);
    setSeenStatus(item.id);
    reloadNotifications();
  };

  const cancelPublishStatusModal = () => {
    setPopup(false);
    reloadNotifications();
  };

  const selectItemHandler = (itemId, event) => {
    const ids = [...selNotificationIds];
    const index = ids.indexOf(itemId);
    if (index >= 0) {
      if (!event.target.checked) ids.splice(index, 1);
    } else if (event.target.checked) ids.push(itemId);
    setSelNotificationIds(ids);
    setAllSelected(props.notifications.length === ids.length);
  };

  const selectAllHandler = (event) => {
    const b = event.target.checked;
    setSelNotificationIds(b ? props.notifications.map((i) => i.id) : []);
    setAllSelected(b);
  };

  const reloadNotifications = () => {
    setSelNotificationIds([]);
    setAllSelected(false);
    props.getNotifications({
      err: (error) => {
        let message = "Error in reloading notifications.";
        const errorMessage = logError(error, message);
        if (errorDisplayLevel === "detailed") {
          if (errorMessage) message += `; (${errorMessage})`;
        } else message = genericErrorMsg;
        props.hideNotice();
        props.showNotice(message, false, { loading: false });
      },
    });
  };

  const deleteNotification = (notificationId) => {
    api.endpoints.deleteNotification(
      notificationId,
      () => {
        reloadNotifications();
        props.refreshMeetups(true);
      },
      (err) => {
        let message = "Error when deleting notification.";
        const errorMessage = logError(err, message);
        if (errorDisplayLevel === "detailed") {
          if (errorMessage) message += `; (${errorMessage})`;
        } else message = genericErrorMsg;
        props.hideNotice();
        props.showNotice(message, false, { loading: false });
      }
    );
  };

  const deleteNotifications = () => {
    const selIds = selNotificationIds;
    api.endpoints.deleteNotifications(
      selIds,
      () => {
        reloadNotifications();
        props.refreshMeetups(true);
      },
      (err) => {
        let message = "Error when deleting notifications.";
        const errorMessage = logError(err, message);
        if (errorDisplayLevel === "detailed") {
          if (errorMessage) message += `; (${errorMessage})`;
        } else message = genericErrorMsg;
        props.hideNotice();
        props.showNotice(message, false, { loading: false });
      }
    );
  };

  const renderNoMatchingCriteria = () => (
    <div className="d-flex flex-column w-50 m-auto pt-5 justify-content-center align-items-center">
      <p className="fs-h2 text--gray7 text-center">
        No notifications matching your filters
      </p>
      <p className="fs-body1 text--gray7 text-center">
        There are no notifications that match your search criteria. Please
        change your filters in order to display results.
      </p>
    </div>
  );

  const renderNoNotificationExists = () => (
    <div className="d-flex flex-column w-50 h-100 pt-5 m-auto justify-content-center align-items-center">
      <p className="fs-h2 text--gray7  text-center">Your list looks empty</p>
    </div>
  );
  const useOnOutsideClick = () => {
    const insideOfNotificationSlideIn = useRef();

    const onClick = (event) => {
      const insideOfNotificationSlideInActive = document.querySelector(
        ".notifications-content.active"
      );
      if (
        insideOfNotificationSlideIn.current &&
        !insideOfNotificationSlideIn.current.contains(event.target) &&
        insideOfNotificationSlideInActive !== null
      ) {
        const activeNotification = document.querySelector(
          ".notification-element.active"
        );

        const menuNotificationActive = document.querySelector(
          ".bar-container--vertical"
        );
        setTimeout(function () {
          if (slideInContent !== null) {
            slideInContent.classList.remove("active");
          }
          if (activeNotification !== null) {
            activeNotification.classList.remove("active");
          }
          menuNotificationActive.classList.remove("notification-active");
        }, 200);
        const slideInContent = document.querySelector(".notifications-content");

        cancelPublishStatusModal();
      }
    };

    useMountEffect(() => {
      document.addEventListener("click", onClick, true);
      return () => {
        document.removeEventListener("click", onClick, true);
      };
    });

    return { insideOfNotificationSlideIn };
  };

  const useMountEffect = (fun) => useEffect(fun, []);
  const [open, setOpen] = useState(false);
  const { insideOfNotificationSlideIn } = useOnOutsideClick(() =>
    setOpen(false)
  );

  const renderNotifications = () => (
    
    // <div className="col bg-light overflow-auto ">
    <div className="slide-in" ref={insideOfNotificationSlideIn}>
      <div className="slide-in-first-level">
        <Header container="notifications" />

        {props.notifications.length > 0 && (
          <NotificationItems
            data={props.notifications}
            selectItemHandler={selectItemHandler}
            allSelected={allSelected}
            selectAllHandler={selectAllHandler}
            selNotificationIds={selNotificationIds}
            timezone={props.timezone}
            time_format={props.time_format}
            reloadNotifications={reloadNotifications}
            deleteNotification={deleteNotification}
            showPopup={showPopup}
            cancelPopup={cancelPublishStatusModal}
          />
        )}
        {props.notifications.length === 0 &&
          (props.notificationExist
            ? renderNoMatchingCriteria()
            : renderNoNotificationExists())}
        {selNotificationIds.length > 0 && (
          <NotificationActionPanel
            selNotificationIds={selNotificationIds}
            onDelete={deleteNotifications}
          />
        )}
      </div>
      <div className="slide-in-second-level">
        {popup ? (
          <PublishStatusPopup
            classes={"modal fade show"}
            cancelModal={() => {
              cancelPublishStatusModal();
            }}
            title={notificationItem.meetup_names}
            meetupsDetails={notificationItem.meetup_details}
            errorMessage={notificationItem.general_error}
            SC={notificationItem.SC_status}
            facebook_page={notificationItem.facebook_page_status}
            facebook_group={null}
            instagram_page={notificationItem.instagram_page_status}
          />
        ) : null}
      </div>
    </div>
  );

  return (
    <div className="container-fluid ">
      <div className="row flex-nowrap">
        {props.client_loading === false && renderNotifications()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
    notificationsExist: state.notifications_exist,
    needPay: state.currentClientNeedPay,
    client_loading: state.client_loading,
    client_id: state.client_id,
    timezone: state.timezone,
    time_format: state.time_format,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: () => dispatch(actionCreators.getNotifications()),
    showNotice: (message, success, options) =>
      dispatch(actionCreators.showNotice(message, success, options)),
    hideNotice: (params) => dispatch(actionCreators.hideNotice(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
