import React, { useState } from "react";
import Lightbox from 'react-image-lightbox';

import Button from "react-bootstrap/esm/Button";

const SelectionButton = props => {
  if (props.isThisSelected) {
    return (
      <Button
        className="button--success fs-btn2"
        disabled
      >
        Selected
      </Button>
    )
  }
  return (
    <Button
      className="button--success fs-btn2"
      onClick={props.setSelected}
    >
      Select type
    </Button>
  );
}

const PosterSelector = props => {

  const images = props.images;
  const keys = Object.keys(images);
  const [photoIndex, setPhotoIndex] = useState(keys.indexOf(props.selected.toString()));
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <>
      <Button
        className="button--success fs-btn2"
        onClick={() => setIsOpen(true)}
      >
        Change poster style
      </Button>

      {isOpen && (
        <Lightbox
          mainSrc={images[keys[photoIndex]].img}
          nextSrc={images[keys[(photoIndex + 1) % keys.length]].img}
          prevSrc={images[keys[(photoIndex - 1 + keys.length) % keys.length]].img}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex - 1 + keys.length) % keys.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % keys.length)
          }
          toolbarButtons={[<SelectionButton
            isThisSelected={keys[photoIndex] === props.selected.toString()}
            setSelected={() => {
              props.setSelected(keys[photoIndex])
              setIsOpen(false)
            }}
          />]}
          enableZoom={false}
          imageTitle={images[keys[photoIndex]].name + " (example)"}
        />
      )}
    </>
  );
};

export default PosterSelector;
