import React from "react";

/**Returns JSX For events section to display checkbox for selection */
const RenderCheckboxInEvents = props => {
  return (
    <div className="d-flex  justify-content-center align-items-center m-0">
      <div className="checkbox-wrapper">
        <input
          type="checkbox"
          onChange={event =>
            props.checkBoxSelection(
              props.container.includes("myevents") ? props.meetupId : props.id,
              event
            )
          }
          checked={
            props.selEventIds &&
            ((props.container.includes("myevents") &&
              props.selEventIds.indexOf(props.meetupId) >= 0) ||
              (props.container.includes("addevents") &&
                props.selEventIds.indexOf(props.id) >= 0))
              ? true
              : false
          }
        />
        <span className="checkmark"></span>
      </div>
    </div>
  );
};

export default RenderCheckboxInEvents;
