import React, { useState } from "react";
import { Filters, publishStatus } from "../utils/constants";
import * as svgs from "../utils/svg";

// const SvgIcon = useState(null);\
const iconType = "OtherIcon";
const imagesSocial = require.context("../assests/images/img/events", true);
const svgMap = {
  [Filters.CUSTOM_EVENTS]: "CustomEventIcon",
  [Filters.FOOTBALL]: "FootballIcon",
  [Filters.RUGBY]: "RugbyIcon",
  [Filters.HOCKEY]: "HockeyIcon",
  [Filters.CRICKET]: "CricketIcon",
  [Filters.RACING]: "RacingIcon",
  [Filters.TENNIS]: "TennisIcon",
  [Filters.CYCLING]: "CyclingIcon",
  [Filters.COMBAT_SPORTS]: "FightingIcon",
  [Filters.HANDBALL]: "HandballIcon",
  [Filters.AMERICAN_FOOTBALL]: "AmericanFootballIcon",
  [Filters.BASKETBALL]: "BasketballIcon",
  [Filters.GAA]: "GAAIcon",
  [Filters.HORSERACING]: "HorseRacingIcon",
  [Filters.BADMINTON]: "BadmintonIcon",
  [Filters.VOLLEYBALL]: "VolleyballIcon",
  [Filters.BASEBALL]: "BaseballIcon",
  [Filters.AUSTRALIAN_FOOTBALL]: "AustralianFootballIcon",
  [Filters.OTHER]: "OtherIcon",
};

const RenderMeetupsDetail = (props) => {
  const meetupDetails = props.meetupsDetails || [];
  const meetupDetailsArr = [];
  meetupDetails.forEach((element) => {
    element.forEach((item) => {
      const name = item.name || "";
      const description = item.description || "";
      const type = (item.type && item.type) || "";
      const time = item.time || "";
      const SvgIcon = svgs[svgMap[item.type]|| "OtherIcon"];
      meetupDetailsArr.push({
        name: name.replace(" - ", " - "),
        description: description,
        type: type,
        time: time,
        SvgIcon: <SvgIcon alt={item.type} />,
      });
    });
  });

  return (
    <>
      {meetupDetailsArr.map((item, index) => (
        <React.Fragment key={index}>
          <div className="event-row">
            <div className={`event-icon ${item.type}`}>{item.SvgIcon}</div>
            <div>
              <p className="limit-to-1line">{item.name}</p>
              <p className="limit-to-1line">
                <span>{item.description}</span>
              </p>
              <div className="d-none">{item.time}</div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

const StatusItem = (props) => {
  return (
    <React.Fragment>
      <div className={`status-text ${props.classes}`}>
        {
          <div>
            <img
              className="float-left status-icon mr-2"
              src={props.imgsocialsrc}
              alt={props.imgsocialalt}
            />
            <p className="mb-0">{props.message}</p>
          </div>
        }
        {props.errorMessage && <>{props.errorMessage}</>}
        {props.status && <b>{props.status}</b>}
      </div>
    </React.Fragment>
  );
};

const RenderMessage = (props) => {
  switch (props.status) {
    case publishStatus.SUCCESS:
      return props.media === publishStatus.SC_TITLE ? (
        <StatusItem
          classes="success"
          imgsocialsrc={imagesSocial(`./` + `${props.mediaName}` + `.svg`)}
          imgsocialalt="sports copmpass"
          media={props.media}
          message={`${props.media} and widgets`}
          status="Posted"
        />
      ) : (
        <StatusItem
          classes="success"
          imgsocialsrc={imagesSocial(`./` + `${props.mediaName}` + `.svg`)}
          imgsocialalt="sports copmpass"
          media={props.media}
          message={`${props.media}`}
          errorMessage={props.errorMessage}
          status="Posted"
        />
      );
    case publishStatus.ERROR:
      return (
        <StatusItem
          classes="error"
          imgsocialsrc={imagesSocial(`./` + `${props.mediaName}` + `.svg`)}
          media={props.media}
          message={`${props.media}`}
          errorMessage={props.errorMessage}
          status="Error"
        />
      );
    case publishStatus.NOT_SET:
      return (
        <StatusItem
          classes="not-set"
          imgsocialsrc={imagesSocial(`./` + `${props.mediaName}` + `.svg`)}
          media={props.media}
          message={`${props.media}`}
          status="Not Set (config)"
        />
      );
    default:
      return null;
  }
};

const PublishStatusPopup = (props) => {
  return (
    <React.Fragment key={props.id}>
      <div className="slide-in-content">
        <div className="modal-header border-bottom-0 p-0 pr-2">
          <button
            onClick={props.cancelModal}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body pr-3 pl-3">
          <div className="social-chanel-box">
            <h2>Social Chanels</h2>
            <RenderMessage
              status={props.SC}
              media={publishStatus.SC_TITLE}
              mediaName={publishStatus.SC_TITLE.toLowerCase()}
            />
            {props.instagram_page !== "NOT_SET" ? (
              <RenderMessage
                status={props.instagram_page}
                media={publishStatus.INSTAGRAM_PAGE_TITLE}
                mediaName={
                  publishStatus.INSTAGRAM_PAGE_TITLE.toLowerCase().split(" ")[0]
                }
                errorMessage={props.errorMessage}
              />
            ) : null}
            {props.facebook_page !== "NOT_SET" ? (
              <RenderMessage
                status={props.facebook_page}
                media={publishStatus.FACEBOOK_PAGE_TITLE}
                mediaName={
                  publishStatus.FACEBOOK_PAGE_TITLE.toLowerCase().split(" ")[0]
                }
                errorMessage={props.errorMessage}
              />
            ) : null}
            {props.facebook_group !== "NOT_SET" ? (
              <RenderMessage
                status={props.facebook_group}
                media={publishStatus.FACEBOOK_GROUP_TITLE}
                mediaName={
                  publishStatus.FACEBOOK_GROUP_TITLE.toLowerCase().split(" ")[0]
                }
              />
            ) : null}
          </div>
          {/* {props.meetupsDetails && props.meetupsDetails.length && (
            <div className="posted-event-box">
              <h2>Events Posted</h2>
              <RenderMeetupsDetail
                classes="title-class mb-0"
                meetupsDetails={props.meetupsDetails}
              />
            </div>
          )} */}
          {props.meetupsDetails && props.meetupsDetails.length ? (
        <div className="posted-event-box">
        <h2>Events Posted</h2>
        <RenderMeetupsDetail
          classes="title-class mb-0"
          meetupsDetails={props.meetupsDetails}
        />
      </div>
      ) : (
        <div className="d-none"></div>
      )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PublishStatusPopup;
