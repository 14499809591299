import React from "react";

const FileUploadShareEvents = (props) => {
  const handleChange = (event) => {
    event.persist();
    const value = event.target.files
    props.onChange(value);
  };

  return (
		<div
			className="checkbox-button ml-0 mr-2 pr-4"
			style={props.value ? { border: props.style } : null}
		>
			<label className="d-flex align-items-center mt-1 mb-1">
				<img className="ml-2 mr-2" src={props.image} alt={props.name} />
				<input
					type={props.type}
					name={props.name}
					disabled={props.disabled}
					multiple={props.multiple}
					accept={props.accept}
					onChange={handleChange}
				/>
				<span>{props.name}</span>
			</label>
		</div>
	);
};

export default FileUploadShareEvents;
