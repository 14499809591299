import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomSwitch from "./CustomSwitch";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";

class CustomAttributeToggle extends Component {
	static propTypes = {
		text: PropTypes.string,
		onToggle: PropTypes.func,
		selectable: PropTypes.bool,
		hasAnErrorAttached: PropTypes.string,
	};
	render() {
		const {
			text,
			imageSource,
			onToggle,
			switchClass,
			enabled,
			textClass,
			tooltip,
			tooltipName,
			selectable,
			spacing,
		} = this.props;
		return (
			<div
				className="feature-div"
				style={spacing && { width: "100%", maxWidth: "400px" }}
			>
				{imageSource != null && (
					<img src={imageSource} className="feature-img" alt="map" />
				)}

				<h5 className={"feature-name text--gray8 " + textClass}>
					{text.split(" ")[0] == "Facebook" ? (
						<>
							<label className="font-weight-bold text-primary">
								Facebook &nbsp;
							</label>
							{text.substr(text.indexOf(" ") + 1)}
						</>
					) : text.split(" ")[0] == "Instagram" ? (
						<>
							<label className="font-weight-bold text-dark">
								Instagram &nbsp;
							</label>
							{text.substr(text.indexOf(" ") + 1)}
						</>
					) : (
						text
					)}
				</h5>

				{tooltip != null && (
					<OverlayTrigger
						trigger="focus"
						placement="top"
						overlay={
							<Popover>
								<Popover.Title as="h3">
									{tooltipName != null ? tooltipName : text}
								</Popover.Title>
								<Popover.Content>
									{tooltip}
									<div>
										<span
											style={{
												color: "red",
												fontWeight: "bold",
											}}
										>
											{this.props.hasAnErrorAttached}
										</span>
									</div>
								</Popover.Content>
							</Popover>
						}
					>
						{this.props.hasAnErrorAttached ? (
							<Button
								variant="danger"
								size="sm"
								className="ml-2 py-0"
							>
								?
							</Button>
						) : (
							<Button
								variant="info"
								size="sm"
								className="ml-2 py-0"
							>
								?
							</Button>
						)}
					</OverlayTrigger>
				)}

				<CustomSwitch
					checked={enabled}
					className={switchClass}
					onChange={onToggle}
					selectable={
						selectable == true || selectable == null ? true : false
					}
				/>
			</div>
		);
	}
}
export default CustomAttributeToggle;
