import React, { useState, useEffect, useReducer } from "react";
import "react-datepicker/dist/react-datepicker.css";
import channels from "../../utils/channels";
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteConfirmation from "../../components/DeleteConfirm";

const images = require.context("../../assests/images", true);
const channelimages = require.context(
    "../../assests/images/img/channels/48pxIcons/",
    true
);

const FavoritesChannelsFilter = props => {
    let fav_stations_ids = [];
    let channelsSelected = [];

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [filterText, setFilterText] = useState("");
    // const [checked, setChecked] = useState(false);


    // const hideConfirmationModal = () => {
    //     setDisplayConfirmationModal(false);
    //   };

    const onDeleteConfirmed = () => {
        deleteAll()
        // window.location.reload();
        setDisplayConfirmationModal(false);
    }

    const selectChannel = (chId, e) => {
        if (fav_stations_ids.includes(chId)) {
            props.updatePreferences(chId, "delete");
        } else {
            props.updatePreferences(chId, "add");
        }
    };

    const deleteAll = () => {
        props.updatePreferences(channelsSelected,"delete");
    }

    props.favstations.map( (s) => {
        fav_stations_ids.push(s.id);
    })

    if(props.favstations.length === 0){
        return (
          <>
              <h6>No Channels Selected!</h6>
          </>
        );
    }

    const RenderChannel = props => {
        const { id, name, iconPath} = props;
        channelsSelected.push(id);

        return (
            <li
                className="bg-white  mb-3 channel-list__border element--clickable highlight_on_hover"
            >
                <div className="d-flex  p-2 align-items-center ">
                    <div className="checkbox-wrapper">
                        <input
                            type="checkbox"
                            readOnly
                            className="align-self-center border-right ml-1 mr-3"
                            checked={fav_stations_ids.includes(id)}
                            onClick={ (event) => selectChannel(id, event)}
                        />
                        <span className="checkmark"></span>
                    </div>
                    <div className="align-self-start border-right w-20 ml-1 pr-3">
                        <img className="" src={channelimages(iconPath)} alt="club name" />
                    </div>
                    <div className="m-auto ">
                        <h3 className="channel-names">{name}</h3>
                    </div>
                </div>
            </li>
        );
    };

    return (
            <>
                <div className="form-group">
                    <label className="fs-caption2 text--gray7 ">
                        USE THE SEARCH FILTER OR SCROLL THROUGH THE LIST TO FIND
                        YOUR SUPPORTED CHANNELS
                    </label>
                    <div className="input-group">
                            <div className="" style={{height : "100%",marginTop : 5, minWidth : "120px"}}>
                                <button
                                    className="btn p-0"
                                    onClick={()=>{setDisplayConfirmationModal(true)}}
                                >
                                    <img src={images('./img/events/remove.svg')} alt="Delete" style={{paddingRight: '10px', paddingBottom: '2px'}} />
                                    Delete All
                                </button>
                            </div>
                            <div style={{height : "100%",marginTop : 5,marginRight: 10}}>
                        </div>
                        <div className="input-group-prepend "   style={{height : "100%"}} >
                            <button
                                className="btn bg-white btn-sm border-right-0 "
                                type="button"
                                style={{
                                    disabled: true
                                }}
                            >
                                <img
                                    src={images("./img/searchIconDisabled.svg")}
                                    alt="close"
                                />
                            </button>
                        </div>
                        <input
                            type="text"
                            className="form-control border-0 bg-white"
                            value={filterText}
                            onChange={e => {
                                setFilterText(e.target.value);
                                // setChecked(false);
                            }
                            }
                            placeholder="Filter channels by name"
                            style={{height : "100%"}}
                        />
                    </div>
                </div>
                <div className=" d-flex flex-column justify-content-center">
                    <ul>
                        <InfiniteScroll
                            dataLength={props.favstations.length}
                            // next={fetchMoreStations}
                            // hasMore={hasMore}
                            height={"calc(100vh - 370px)"}
                            // loader={
                            //     <div className={"events-data-status"}>
                            //         <h4>Loading...</h4>
                            //     </div>
                            // }
                            // endMessage={
                            //     <p className={"events-data-status"}>
                            //         <b>All the data has been loaded!</b>
                            //     </p>
                            // }
                        >
                        {
                            (props.favstations || [])
                                .filter(
                                    station =>
                                        !filterText ||
                                        station.name
                                            .toLowerCase()
                                            .includes(filterText.toLowerCase())
                                )
                                .map((station, index) => {
                                    let channel = Object.keys(channels).find(chName =>
                                        station.name.toLowerCase().includes(chName.toLowerCase())
                                    );
                                    if (channels[channel].name) station.name = channels[channel].name;
                                    return (
                                        <RenderChannel
                                            id={station.id}
                                            name={station.name}
                                            iconPath={channels[channel].icon || channels[channel] || "default.svg"}
                                            key={station.name + index}
                                        />
                                    );
                                })
                        }
                        </InfiniteScroll>
                    </ul>
                </div>
                <DeleteConfirmation showDeleteConfirm={displayConfirmationModal} confirmModal={ onDeleteConfirmed } hideModal={() => setDisplayConfirmationModal(false)} message='Are you sure you want to delete all the channels?'  />
            </>
        );
};

export default FavoritesChannelsFilter;

