import React from "react";
import { NotificationCalendarIcon } from "../../utils/svg";
import moment from "moment";

const images = require.context("../../assests/images", true);

const Button = (props) => (
	<>
		<button
			className="btn p-0"
			onClick={() => props.onClick(props.notificationId)}
			title={props.title}
		>
			<img src={images(props.imagePath)} alt={props.imageAlt} />
		</button>
	</>
);


const NotificationItem = (props) => {

	const itemID = props.itemID;
	const onClickNotificationItem = () => {
		props.showPopup(props);
	
		if(props.id === itemID){
			props.cancelPopup(props);
		}
		
	};
	const breakArray = () => {
		const meetupDetails = props.meetup_details || [];
		const meetupDetailsNames = [];
		const meetupDetailsDescription = [];

		meetupDetails.forEach(element => {
			element.forEach(item => {
				const name = item.name && item.name || '';
				const description = item.description && item.description || '';
				meetupDetailsNames.push((name).replace(" - ", " - "));
				meetupDetailsDescription.push((description));
			});
		});
		
		return <>
			<p className="limit-to-1line">{meetupDetailsNames.map((name,index) => (
				<React.Fragment key={index}>
					{name}
				</React.Fragment>
				))}
			</p>
			<p className="limit-to-1line">{meetupDetailsDescription.map((description,index) => (
				<React.Fragment key={index}>
					{description}
				</React.Fragment>
				))}
			</p>
		</>
	};
	const notificationPublishTime = props.title
	const createdAt = props.created_at
	const plusLocalTime = createdAt.split('+').pop();
	const formatedTime = moment(notificationPublishTime.replace(/[^0-9.-:-]/g, ''),'YYYY-MM-DD HH:mm');
	// comment below line to have timestamp displayed as "about x hours ago", "a few minutes ago."
	// const finalDate = moment(formatedTime.add({hours:1})).add(plusLocalTime).startOf('minutes').fromNow();
	const finalDate = moment(formatedTime,'').toString().split(':00 GMT').slice(0,1).pop();

	const now = moment().local(); //todays date
	const end = moment(finalDate); // another date

	const duration = moment.duration(now.diff(end));
	const durationInHours = duration.asHours();
	const durationInHoursFixed = durationInHours.toFixed() 
	let aboutLabel;

	if(durationInHoursFixed >= 1 && durationInHoursFixed <= 24 ) {
		aboutLabel = 'about ';
	} 
	return (
    <React.Fragment key={props.id}>
      <div onClick={onClickNotificationItem}>
        <div
          className={`icon-container 
				${props.SC_status === "SUCCESS" ? "success" : ""} ${
            props.SC_status === "ERROR" ? "error" : ""
          }
				${props.facebook_page_status === "SUCCESS" ? "success" : ""} ${
            props.facebook_page_status === "ERROR" ? "error" : ""
          }
				${props.instagram_page_status === "SUCCESS" ? "success" : ""} ${
            props.instagram_page_status === "ERROR" ? "error" : ""
          }`}
        >
          <NotificationCalendarIcon alt="notification" className="icon " />
        </div>
        <div className="info-container">
          <div className="">{breakArray()}</div>

          <div className="actions d-none">
            <Button
              notificationId={props.id}
              onClick={props.deleteNotification}
              imagePath="./img/events/remove.svg"
              imageAlt="delete"
              title="Delete"
            />
          </div>
          <span className="publish-timestamp limit-to-1line">
            {/* {aboutLabel} */}
			{finalDate}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotificationItem;
