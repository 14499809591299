import * as actionTypes from "./actionTypes";
import moment from "moment-timezone";
import * as constants from "../utils/constants";

const initTimeDate = moment();
const defaultStartDate = initTimeDate.startOf("day").toDate();
const defaultEndDate = initTimeDate
	.clone()
	.add(7, "days")
	.endOf("day")
	.toDate();
const reservationsDefStartDate = initTimeDate.toDate();
const fixturesDefStartDate = moment().startOf("day").toDate();

const initialState = {
	modal: {
		show: false,
		success: false,
		message: "",
		loading: false,
	},
	fixtures: [],
	meetups_today: [],
	meetups_week: [],
	reservations: [],
	reservations_exist: null,
	reservationsMinDate: moment().add(-5, "year").startOf("year").toDate(),
	fixturesMinDate: moment().add(-5, "year").startOf("year").toDate(),
	reservationsDefStartDate: reservationsDefStartDate,
	reservationsDefEndDate: defaultEndDate,
	reservationsFilters: {
		tags: [],
		start_date: reservationsDefStartDate,
		end_date: defaultEndDate,
		pageNo: 0,
	},
	notifications: [],
	notifications_exist: null,
	unseenNotifications: 0,
	cable: null,
	dashboardsWhen: null,
	eventsWhen: null,
	meetupsFilters: {
		sports: [],
		tags: [],
		start_date: fixturesDefStartDate,
		end_date: defaultEndDate,
		offset: 0,
		pageNo: 0,
	},
	meetups_exist: null,
	fixturesFilters: {
		sports: [],
		tags: [],
		fav_teams: [],
		fav_competitions: [],
		start_date: defaultStartDate,
		end_date: defaultEndDate,
		offset: 0,
		pageNo: 0,
	},
	defaultStartDate: fixturesDefStartDate,
	defaultEndDate: defaultEndDate,
	meetupsFiltered: [],
	fixturesFiltered: [],
	favoriteStations: [],
	sports: [],
	favoriteSports: [],
	favoriteTeams: [],
	favoriteCompetitions: [],
	initState: true,
	reloadMeetups: false,
	dashboardActiveTabIndex: sessionStorage.getItem(
		constants.SessionFields.dashboardActiveTabIndex
	)
		? JSON.parse(
				sessionStorage.getItem(constants.SessionFields.dashboardActiveTabIndex)
		  )
		: 0,
	eventsActiveTabIndex: sessionStorage.getItem(
		constants.SessionFields.eventsActiveTabIndex
	)
		? JSON.parse(
				sessionStorage.getItem(constants.SessionFields.eventsActiveTabIndex)
		  )
		: 1,
	settingsActiveTab: sessionStorage.getItem(constants.SettingsActiveMenu)
		? JSON.parse(sessionStorage.getItem(constants.SettingsActiveMenu))
		: "profile",
	currentClientType: null,
	currentClientNeedPay: false,
	time_format: "24H",
	timezone: process.env.NODE_ENV === "production" ? moment.tz.guess() : null,
	language: null,
	client_id: null,
	client_loading: true,
	currencies: null,
	currency: null,
	fixtures_length: null,
	meetups_length: null,
	header_message: null,
	showFakeLoader: false,
	sharingOptionsCount: 0,
};

const reducer = (state = initialState, action) => {
	//DEBUG: console.log("in reducer: ", action.type, ', view: ', action.view, ', payload: ', action.payload);
	switch (action.type) {
		case actionTypes.INIT_STATE:
			return { ...state, initState: false };
		case actionTypes.SHOW_NOTICE:
			return {
				...state,
				modal: {
					show: true,
					success: action.success,
					message: action.message,
					loading: action.loading,
				},
			};
		case actionTypes.HIDE_NOTICE:
			return {
				...state,
				modal: {
					show: false,
					success: false,
					message: "",
					loading: false,
				},
			};
		case actionTypes.SAVE_MEETUPS:
			switch (action.view) {
				case "today":
					return { ...state, meetups_today: action.payload };
				case "week":
					return { ...state, meetups_week: action.payload };
				default:
					return state;
			}
		case actionTypes.SAVE_RESERVATIONS:
			return { ...state, reservations: action.payload };
		case actionTypes.SAVE_NOTIFICATIONS:
			return { ...state, notifications: action.payload };
		case actionTypes.SAVE_FILTERED_MEETUPS:
			return {
				...state,
				meetupsFiltered:
					action.offset === 0
						? action.payload
						: [...state.meetupsFiltered, ...action.payload],
				meetups_loading: false,
				meetups_length: action.payload.length,
			};
		case actionTypes.SAVE_FILTERED_FIXTURES:
			return {
				...state,
				fixturesFiltered:
					action.offset === 0
						? action.payload
						: [...state.fixturesFiltered, ...action.payload],
				eventsWhen: action.reqTime,
				fixtures_loading: false,
				fixtures_length: action.payload.length,
			};
		case actionTypes.READ_STATE:
			return { ...state };
		case actionTypes.GET_MEETUPS_FILTERS:
			return { ...state };
		case actionTypes.GET_RESERVATIONS_FILTERS:
			return { ...state };
		case actionTypes.IS_EXIST_FILTERED_MEETUPS:
			return { ...state, meetups_exist: action.payload };
		case actionTypes.SAVE_RESERVATIONS_EXIST:
			return { ...state, reservations_exist: action.payload };
		case actionTypes.SAVE_RESERVATIONS_FILTERS:
			return { ...state, reservationsFilters: action.payload };
		case actionTypes.SAVE_NOTIFICATIONS_EXIST:
			return { ...state, notifications_exist: action.payload };
		case actionTypes.SAVE_CABLE:
			return { ...state, cable: action.payload };
		case actionTypes.SAVE_UNSEEN_NOTIFICATIONS:
			return { ...state, unseenNotifications: action.payload };
		case actionTypes.SAVE_MEETUPS_FILTERS:
			return { ...state, meetupsFilters: action.payload };
		case actionTypes.GET_FIXTURES_FILTERS:
			return { ...state };
		case actionTypes.FIXTURES_LOADER:
			return { ...state, fixtures_loading: true };
		case actionTypes.MEETUPS_LOADER:
			return { ...state, meetups_loading: true };
		case actionTypes.SAVE_FIXTURES_FILTERS:
			return { ...state, fixturesFilters: action.payload };
		case actionTypes.GET_FAVORITE_STATIONS:
			return { ...state };
		case actionTypes.SAVE_FAVORITE_STATIONS:
			return { ...state, favoriteStations: action.payload };
		case actionTypes.GET_SPORTS:
			return { ...state };
		case actionTypes.SAVE_SPORTS:
			return { ...state, sports: action.payload };
		case actionTypes.GET_FAVORITE_SPORTS:
			return { ...state };
		case actionTypes.SAVE_FAVORITE_SPORTS:
			return { ...state, favoriteSports: action.payload };
		case actionTypes.GET_FAVORITE_TEAMS:
			return { ...state };
		case actionTypes.SAVE_FAVORITE_TEAMS:
			return { ...state, favoriteTeams: action.payload };
		case actionTypes.GET_FAVORITE_COMPETITIONS:
			return { ...state };
		case actionTypes.SAVE_FAVORITE_COMPETITIONS:
			return { ...state, favoriteCompetitions: action.payload };
		case actionTypes.RELOAD_MEETUPS:
			return { ...state, reloadMeetups: action.payload };
		case actionTypes.SAVE_ACTIVE_TABINDEX:
			switch (action.view) {
				case "DASHBOARD":
					sessionStorage.setItem(
						constants.SessionFields.dashboardActiveTabIndex,
						JSON.stringify(action.payload)
					);
					return { ...state, dashboardActiveTabIndex: action.payload };
				case "EVENTS":
					sessionStorage.setItem(
						constants.SessionFields.eventsActiveTabIndex,
						JSON.stringify(action.payload)
					);
					return { ...state, eventsActiveTabIndex: action.payload };
				default:
					return state;
			}

		case actionTypes.SAVE_ACTIVE_SETTINGS_MENU:
			sessionStorage.setItem(
				constants.SettingsActiveMenu,
				JSON.stringify(action.payload)
			);
			return { ...state, settingsActiveTab: action.payload };
		case actionTypes.SET_CURRENT_CLIENT_TYPE:
			return { ...state, currentClientType: action.payload };
		case actionTypes.SET_CURRENT_CLIENT_NEED_PAY:
			return { ...state, currentClientNeedPay: action.payload };
		case actionTypes.SET_USER_TIME_FORMAT:
			return { ...state, time_format: action.payload };
		case actionTypes.SET_USER_LANGUAGE:
			return { ...state, language: action.payload };
		case actionTypes.SET_CLIENT_ID:
			return { ...state, client_id: action.payload };
		case actionTypes.SAVE_CURRENCIES:
			return { ...state, currencies: action.payload };
		case actionTypes.GET_CURRENCY_DATA:
			return { ...state, currency: action.payload };
		case actionTypes.SET_USER_TIME_ZONE:
			const timezone = action.payload;
			const defaultStartDate = moment().tz(timezone).startOf("day").toDate();
			const defaultEndDate = moment()
				.tz(timezone)
				.add(7, "days")
				.endOf("day")
				.toDate();
			return {
				...state,
				timezone: timezone,
				defaultStartDate: defaultStartDate,
				defaultEndDate: defaultEndDate,
				client_loading: false,
				meetupsFilters: {
					sports: [],
					tags: [],
					fav_teams: [],
					fav_competitions: [],
					start_date: defaultStartDate,
					end_date: defaultEndDate,
					offset: 0,
					pageNo: 0,
				},
				fixturesFilters: {
					sports: [],
					tags: [],
					fav_teams: [],
					fav_competitions: [],
					start_date: defaultStartDate,
					end_date: defaultEndDate,
					offset: 0,
					pageNo: 0,
				},
			};
		case actionTypes.SAVE_HEADER_MESSAGE:
			return { ...state, header_message: action.payload };
		case actionTypes.DELETE_HEADER_MESSAGE:
			return { ...state, header_message: action.payload };
		case actionTypes.SHOW_FAKE_LOADER:
			return {
				...state,
				showFakeLoader: action.payload.showFakeLoader,
			};
		default:
			return state;
	}
};

export default reducer;
