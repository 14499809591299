import React from 'react';
import { ErrorMessage } from 'formik';

const validateTextField = value => {
  if (value === '') {
    return ' is required';
  }
  if (value.length < 3) {
    return ' is too short';
  }
  return null;
};

const validateIntegerField = (value, min, max) => {
  //alert(`${value}, ${min}, ${max}`);
  if ((value === '') || (value === null))
    return null;
  const valueIsAStr = typeof value === 'string';
  const v = valueIsAStr ? Number(value) : value;
  if (isNaN(v))
    return ' must be a number';
  if ((v < min) || (v > max))
    return ' must be between 0 and 999';
  if (valueIsAStr) {
    if (value !== (~~v).toString())
      return ' must be a round number';
  } else if (!Number.isInteger(v))
    return ' must be a round number'
  return null;
};

const validatePhoneNumber = value => {
  var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
 
  let regexArray = [];
  regexArray = String(value).match(regex);
  if (value === '') {
    return ' is required';
  }
  if (regexArray === null) {
    return ' is invalid';
  }
  return null;
}

const validateEmail = value => {
  let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  let regexArray = [];
  regexArray = String(value).match(regex);
  if (value === '') {
    return ' is required';
  }
  if (regexArray === null) {
    return ' is invalid';
  }
  return null;
}

const validateMapsLinkInTextField = value => {
  let regex = /(?:https:\/\/www.google.com\/maps\/place\/(\S+)\/@+(\S+)\/data=(\S+))/y;
  let regexArray = [];
  regexArray = String(value).match(regex);
  if (value === '') {
    return ' is required';
  }
  if (regexArray === null) {
    return ' is invalid';
  }
  return null;
};

const validateDateInput = values => {
  const currentDate = new Date();
  const getEventDate = new Date(values.event_date);
  const getCurrentDateAndMonth =
    currentDate.getMonth() +
    1 +
    '/' +
    currentDate.getDate() +
    '/' +
    currentDate.getFullYear();
  const getEventDateAndMonth =
    getEventDate.getMonth() +
    1 +
    '/' +
    getEventDate.getDate() +
    '/' +
    getEventDate.getFullYear();
  const errors = {};

  if (Date.parse(getEventDateAndMonth) <= Date.parse(getCurrentDateAndMonth)) {
    errors.event_date = 'Start time cannot be in the past';
  }
  return errors;
};

const renderFieldError = (isTouched, errors, fieldLabel, fieldName) => {
  if (isTouched === true && errors !== undefined && fieldLabel) {
    return (
      <div className="text-danger">
        {fieldLabel}
        <ErrorMessage name={fieldName} className="text-danger" />
      </div>
    );
  }
};

export {
  validateDateInput,
  validateIntegerField,
  validateTextField,
  renderFieldError,
  validateMapsLinkInTextField,
  validatePhoneNumber,
  validateEmail
};
