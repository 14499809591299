import React, { useState } from "react";
import channels from "../utils/channels";
import moment from "moment-timezone";
import * as api from "../utils/api";
import { Button } from "react-bootstrap-buttons";

const images = require.context(
  "../assests/images/img/channels/48pxIcons/",
  true
);
const images_base = require.context("../assests/images", true);
const ChannelsList = props => {
  const [selectedChannel, setSelectedChannel] = useState("");
  const updateChannel = () => {
    api.endpoints.updateMeetupsChannel(
      props.meetupId,
      selectedChannel,
      props.reloadEventsTab
    );
    props.cancelModal();
  };
  let favIds = [];
  props.favoriteStations.map( (station) => {
    favIds.push(station.id)
  })
  const renderChannel = (name, id, iconPath, index, pref_station, favorite) => (
    <li
      key={name + index}
      className="highlight_on_hover element--clickable"
      onClick={() => setSelectedChannel(id)}
    >
      <div className="d-flex align-items-center justify-content-start">
        {props.container === "myevents" ? (
          <div className="checkbox-wrapper ml-2">
            <input
              type="checkbox"
              checked={
                (!selectedChannel &&
                  (pref_station && id === pref_station)) ||
                id === selectedChannel
              }
            />
            <span className="checkmark"></span>
          </div>
        ) : null}
        <div className="border-right pl-3 pr-3">
          <img className="" src={images(iconPath)} alt="club name" />
        </div>
        <div className="d-flex  p-2  m-auto">
          <div className="">
            <h3 className={"channel-names mb-0"  + (favorite ? " text--green1" : "")}>{name}</h3>
          </div>
        </div>
      </div>
    </li>
  );

  return (
    <div className="mb-5">
      <div
        className="modal-backdrop bg-secondary"
        style={{
          display: props.show ? "block" : "none",
          opacity: props.show ? "0.6" : "0"
        }}
        onClick={props.cancelModal}
      />

      <div
        className="modal channels-popup"
        style={{
          transform: props.show ? "translateY(0)" : "translateY(-100vh)",
          display: props.show ? "block" : "none"
        }}
      >
        <div className="modal-dialog  modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header p-3 bg-white">
              <div className=" d-flex flex-column justify-content-center w-100 align-items-top">
                <div className="d-flex align-items-center position_relative">
                  {props.name.includes(" v ") ? (
                    props.name.split(" v ").map((teamName, index) =>
                      index === 0 ? (
                        <div
                          className="fs-h2 col-6  pl-2 text-right text-wrap"
                          key={props.name + "team1 " + index}
                        >
                          {teamName}
                        </div>
                      ) : (
                          <React.Fragment key={index}>
                            <div
                              className="d-flex  event-item__team-icons  justify-content-center"
                              key={props.name + " dash " + index}
                            >
                              <div className="fs-caption1">-</div>
                            </div>
                            <div
                              className="fs-h2 col-6 pr-3 text-left text-wrap"
                              key={props.name + "team2" + index}
                            >
                              {teamName}
                            </div>
                          </React.Fragment>
                        )
                    )
                  ) : (
                      <div
                        className="fs-h2 col-12  pl-2 text-center text-wrap"
                        key={props.name + "team1 "}
                      >
                        {props.name}
                      </div>
                    )}

                  <button
                    className="btn p-1 btn-danger event-item__action-icons position_absolute__right--5"
                    onClick={props.cancelModal}
                  >
                    <img
                      src={images_base(`./img/close-icon.svg`)}
                      alt="Close pop-up icon"
                    />
                  </button>
                </div>
                <div className="d-flex flex-column justify-content-center  align-items-center">
                  <div className="fs-h2 p-2 match-time">
                    {moment(props.time).tz(props.timezone).format(props.time_format === "24H" ? "HH:mm" : "hh:mm a")}
                  </div>

                  <div className="fs-caption1 text--gray8">
                    {moment(props.time).tz(props.timezone).format("ddd, D MMM YYYY")}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body p-0 bg--gray1">
              <div className=" d-flex flex-column">
                <ul>
                  {(props.stations || [])
                    .map((station) => {
                      station.favorite = props.favoriteStations.includes(station.id)
                      let channel = Object.keys(channels).find(chName =>
                        station.name
                          .toLowerCase()
                          .includes(chName.toLowerCase())
                      );
                      if (channel) {
                        if (channels[channel].name) station.name = channels[channel].name
                        station.icon = channels[channel].icon || channels[channel]
                      }
                      return station;
                    })
                    .sort((a,b) => {
                      if (a.favorite === b.favorite)
                        return a.name.localeCompare(b.name);
                      else
                        return b.favorite - a.favorite;
                    })
                    .map((station, index) => {
                      return renderChannel(
                        station.name,
                        station.id,
                        station.icon || "./default.svg",
                        index,
                        props.pref_station,
                        favIds.includes(station.id)
                      );
                    })}
                </ul>
              </div>
            </div>
            {props.container === "myevents" ? (
              <div className="modal-footer p-3 justify-content-center bg-white">
                <Button
                  type="button"
                  className="button-success--modal p-1 fs-btn2"
                  onClick={updateChannel}
                >
                  Confirm Selection
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelsList;
