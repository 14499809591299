import React from "react";
import { Filters } from "../../utils/constants";
import * as svgs from "../../utils/svg";


const svgMap = {
  [Filters.CUSTOM_EVENTS]: "CustomEventIcon",
  [Filters.FOOTBALL]: "FootballIcon",
  [Filters.RUGBY]: "RugbyIcon",
  [Filters.HOCKEY]: "HockeyIcon",
  [Filters.CRICKET]: "CricketIcon",
  [Filters.RACING]: "RacingIcon",
  [Filters.TENNIS]: "TennisIcon",
  [Filters.CYCLING]: "CyclingIcon",
  [Filters.COMBAT_SPORTS]: "FightingIcon",
  [Filters.HANDBALL]: "HandballIcon",
  [Filters.AMERICAN_FOOTBALL]: "AmericanFootballIcon",
  [Filters.BASKETBALL]: "BasketballIcon",
  [Filters.GAA]: "GAAIcon",
  [Filters.HORSERACING]: "HorseRacingIcon",
  [Filters.BADMINTON]: "BadmintonIcon",
  [Filters.VOLLEYBALL]: "VolleyballIcon",
  [Filters.BASEBALL]: "BaseballIcon",
  [Filters.AUSTRALIAN_FOOTBALL]: "AustralianFootballIcon",
  [Filters.OTHER]: "OtherIcon"
};

const EventFilterItem = ({ item, selected, handleClick }) => {
  const SvgIcon = svgs[svgMap[item]];
  return (
    <div
      className={"col d-flex sport-filters__item justify-content-center element--clickable" +
        (selected ? " sport-filters--selected" : "")}
      onClick={event => handleClick(item)}
    >
      <SvgIcon
        height="35px"
        width="35px"
        className="sport-filters__icon element--clickable"
        alt={item}
        style={item === "badminton" ? {margin: "5px"} : {}}
      />
      <label
        className="form-check-label sport-filters__label position-absolute text-center fs-btn2 element--clickable"
      >
        {item}
        <div
          className="sport-filters__underline"
        ></div>
      </label>
    </div>
  );
};

export default EventFilterItem;
