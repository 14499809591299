import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assests/scss/components/_showloader.scss";
import { connect } from "react-redux";

const ShowFakeLoader = ({ showFakeLoader, isVideo }) => {
	const toastId = useRef(null);
	const [selEventIds] = useState([]);
	const time = isVideo ? 120000 : 60000;

	const messages = [
		"Fine-tuning details to perfection.",
		"Creating excitement for your patrons and followers.",
		"Bringing your sports bar to life on screen.",
		"Preparing your game lineup for the ultimate experience.",
		"Curating the best moments for your social media presence.",
		"Crafting vibrant animations to captivate your audience.",
		"Transforming your selections into engaging content.",
		"Polishing transitions to keep your viewers hooked.",
		"Enhancing visuals for maximum impact.",
		"Infusing energy into every frame.",
		"Weaving magic with your chosen games.",
		"Adding a touch of flair to your social media strategy.",
	];
	const prevMessageIndexRef = useRef(0);
	const initialProgressRef = useRef(null);
	const intervalStartTimeRef = useRef(null);

	const [elapsedTime, setElapsedTime] = useState(0);

	const startTimer = () => {
		const interval = setInterval(() => {
			setElapsedTime((prevElapsedTime) => prevElapsedTime + 10);
		}, 1000); // Increase elapsed time every second
		return () => clearInterval(interval);
	};

	useEffect(() => {
		const timerId = startTimer();

		return () => {
			clearInterval(timerId); // Clear the interval when the component unmounts
		};
	}, []); // Run this effect only once when the component mounts

	useEffect(() => {
		if (showFakeLoader) {
			const toastElem = document.querySelector(
				".Toastify__toast.Toastify__toast--default"
			);
			// prevent duplicate toasts
			if (toastElem) {
				toastElem.classList.remove("hide");
				toastElem.classList.add("visible");

				return () => {
					toastElem.classList.add('hide');
					toastElem.classList.remove('visible');
				};
			}

			const initialMessageIndex = prevMessageIndexRef.current;
			const initialMessage = messages[initialMessageIndex];

			toastId.current = toast(initialMessage, {
				toastId: { selEventIds },
				position: "bottom-center",
				autoClose: time,
				hideProgressBar: false,
				progress: 1,
				progressClassName: "success-progress-bar",
				closeOnClick: false,
				pauseOnHover: false,
				closeButton: false,
				draggable: false,
				theme: "light",
				className: "visible",
				isLoading: false,
				pauseOnFocusLoss: false,
				success: {
					progress: 98,
					duration: 2000,
					bodyClassName: "completed",
				},
			});

			initialProgressRef.current = 100;
			intervalStartTimeRef.current = Date.now();

			return () => {
				setTimeout(function () {
					const toastElem = document.querySelector('.Toastify__toast.Toastify__toast--default');
					if( toastElem !== null) {
						toastElem.classList.add('hide');
						toastElem.classList.remove('visible');
					}
					toast.update(toastId.current, {
						className: "hide",
					});
				}, 1000);
			};
		} else {
			setTimeout(function () {
				toast.update(toastId.current, {
					className: "visible",
				});
			}, 1000);
		}
	}, [showFakeLoader]);

	useEffect(() => {
		const newMessageIndex = (prevMessageIndexRef.current + 1) % messages.length;
		if (newMessageIndex !== prevMessageIndexRef.current && showFakeLoader) {
			if (toastId.current) {
				const elapsedTime = Date.now() - intervalStartTimeRef.current;
				const totalDuration = time;
				const progressIncrease = (elapsedTime / totalDuration) * 50; // Decrease the divisor to slow down the progress
				if (progressIncrease >= 100) {		
					return;
				}
				const newProgress = Math.min(100, progressIncrease);
				let secondsCounter = Math.floor(elapsedTime / 1000);

				if (secondsCounter % 10 === 0) {
					toast.update(toastId.current, {
						render: messages[newMessageIndex],
						progress: newProgress / 98,
					});
					prevMessageIndexRef.current = newMessageIndex;
				} else {
					toast.update(toastId.current, {
						render: messages[newMessageIndex],
						progress: newProgress / 98,
					});
				}
			}
		}
	}, [elapsedTime, showFakeLoader]);

	// updating the text inside the toaster
	useEffect(() => {
		const toastUpdateElem = document.querySelector(".Toastify__toast-body");
		const newMessageIndex = (prevMessageIndexRef.current + 1) % messages.length;

		if (toastUpdateElem !== null) {
			// setTimeout(function () {
			toastUpdateElem.innerHTML = messages[newMessageIndex];
			// }, 10);
		}
	});

	return (
		<div key={toastId.current}>
			<ToastContainer />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		showFakeLoader: state.showFakeLoader,
	};
};

export default connect(mapStateToProps, null)(ShowFakeLoader);
