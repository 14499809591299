import React, {useEffect, useReducer, useState} from "react";
import SettingHeader from "./SettingsHeader";
import { Tab, Tabs } from 'react-bootstrap';
import SportsFilter from "./SportsFilter";
import TeamsFilter from "./TeamsFilter";
import CompetitionsFilter from "./CompetitionsFilter";
import ChannelsFilter from "./ChannelsFilter";
import FavoritesChannelsFilter from "./FavoritesChannelsFilter";
import axios from "axios";
import * as api from "../../utils/api";

const Preferences = props => {
      const [limit, setLimit] = useState(0);

      const prefReducer = (state, action) => {
        switch (action.type) {
          case "INIT":
            return {
              ...state,
              stations: action.payload
            };
          case "INITFAV":
            return {
              ...state,
              favstations: action.payload
            };
          default:
            return state
        }
      };

      const [preferences, dispatch] = useReducer(prefReducer, {
        stations: [],
        favstations: []
      });

      useEffect(() => {
        async function getStations() {
          let res = await axios.get(`/stations/?limit=${limit+50}`);
          dispatch({
            type: "INIT",
            payload: res.data.stations
          });
        }
        async function getFavStations() {
          let res = await axios.get("/favorite_stations");
          dispatch({
            type: "INITFAV",
            payload: res.data.favorite_stations
          });
        }
        getStations();
        getFavStations();
      }, [limit]);

      const updatePreferences = async (chIds, crudType) => {
        api.endpoints.updatePreferences(chIds, crudType, res =>
            dispatch({
              type: "INITFAV",
              payload: res
            })
        );
      };

  return (
    <div className="d-flex bg-secondary w-75 h-100 ml-5 overflow-auto">
      <div className="w-75 mr-5 ml-2 bg-secondary">
        <SettingHeader
          image="./filters.svg"
          imageAlt="Preferences icon"
          title="Your Preferences"
        />
        <div className="p-2 mt-1 ">
          <Tabs defaultActiveKey="all_events" id="uncontrolled-tab-example" className="mb-3">
            <Tab
                eventKey="mychannels"
                title="My Channels"
                className="tabs-container h-100 events-tab-header"
            >
              <div className="p-2 mt-1 ">
                <FavoritesChannelsFilter
                    favstations={preferences.favstations}
                    stations={preferences.stations}
                    updatePreferences={updatePreferences}
                />
              </div>
            </Tab>
            <Tab
              eventKey="all_events"
              title="Available Channels"
              className="tabs-container h-100 events-tab-header"
            >            
              <div className="p-2 mt-1 ">                
                  <ChannelsFilter
                      favstations={preferences.favstations}
                      stations={preferences.stations}
                      updatePreferences={updatePreferences}
                      limit={{limit}}
                      setLimit={() => setLimit(limit+50)}
                  />
              </div>
            </Tab>
            <Tab 
              eventKey="sports" 
              title="Sports"
              className="tabs-container h-100 events-tab-header"
            >
              <div className="p-2 mt-1 ">  
                <label className="fs-caption2 text--gray7 ">
                  CHOOSE YOUR FAVORITE SPORTS FROM THE LIST                   
                </label>  
                <div className=" d-flex flex-column justify-content-center">
                  <SportsFilter/>
                </div>
              </div>
            </Tab>
            <Tab eventKey="teams" title="Teams">
              <div className="p-2 mt-1 ">       
                <label className="fs-caption2 text--gray7 ">
                  SELECT YOUR FAVORITE TEAMS FROM THE LIST               
                </label>     
                <div className=" d-flex flex-column justify-content-center">
                  <TeamsFilter/>
                </div>
              </div>
            </Tab>
            <Tab eventKey="competitions" title="Competitions">
              <div className="p-2 mt-1 ">       
                <label className="fs-caption2 text--gray7 ">
                  SELECT YOUR FAVORITE COMPETITIONS FROM THE LIST 
                </label>    
                <div className=" d-flex flex-column justify-content-center">
                  <CompetitionsFilter/>
                </div>  
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Preferences;
