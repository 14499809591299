import React, { Component } from "react";
import * as api from "../utils/api";
import * as actionCreators from "../modules/actions";
import { connect } from "react-redux";
import UsernameIcon from "./login/UsernameIcon";
import PasswordIcon from "./login/PasswordIcon";
import { withRouter } from "react-router-dom";
const images = require.context("../assests/images/img/", true);
class Login extends Component {
  state = {
    email: "",
    password: "",
    hidden: true,
    error: "all good",
    vissibleError: "hidden"
  };

  dologin = () => {
    this.setState({ vissibleError: "hidden " });
    const data = {
      auth: {
        email: this.state.email,
        password: this.state.password
      }
    };

    api.endpoints.login(
      data
    ).then((jwt) => { 
      sessionStorage.setItem("userToken", JSON.stringify(jwt));
      this.props.handleClientLoaded();
      this.props.history.push({ pathname: "/dashboard" });
    }).catch((err) => {
      this.setState({
        error:
          err.status === 404
            ? "Incorrect username or password"
            : "An system error occured, if it persists please contact the Sport Compass team"
      });
      this.setState({ vissibleError: "visible" });
    })
  };

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  render() {
    return (
      <form
        className="login-form bg--gray4 d-flex justify-content-center align-items-center h-100"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <div className="form-container bg--grad5 d-flex flex-wrap  justify-content-center">
          <img
            src={images("./new-logo.svg")}
            alt="Sport Compass"
            className="logo"
          />
          <h1 className="fs-h2 text--white mb-3 mt-2 pt-3">MEMBER LOGIN</h1>
          <input
            type="text"
            value={this.state.email}
            onChange={event => this.setState({ email: event.target.value })}
            className="input--text bg--gray3 text--white fs-caption1 mb-2 pl-2 py-1 ml-2"
            placeholder="USERNAME..."
            autoComplete="username"
          />
          <div className="input-group">
            <UsernameIcon />
          </div>
          <input
            value={this.state.password}
            onChange={event => this.setState({ password: event.target.value })}
            className="input--text bg--gray3 text--white fs-caption1 pl-2 mb-2 py-1 ml-2"
            placeholder="PASSWORD..."
            data-toggle="password"
            type={this.state.hidden ? "password" : "text"}
            autoComplete="current-password"
          />
          <div className="input-group" onClick={this.toggleShow}>
            <PasswordIcon />
          </div>
          <div
            className="fs-caption-error error-message"
            style={{ visibility: this.state.vissibleError }}
          >
            {this.state.error}
          </div>
          <div>
            <button onClick={this.dologin} className="button--success">
              Log in
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClientLoaded: params =>
      dispatch(actionCreators.handleClientLoaded(params))
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Login));
