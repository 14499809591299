import React, { Component } from "react";
import { Button } from "react-bootstrap-buttons";
import CustomEventPopup from "./CustomEventPopup";
import { NotificationIcon, ReservationIcon } from "../utils/svg";
const images = require.context("../assests/images", true);

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEventPopup: false
    };
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops.editMeetupObj) {
      this.setState({ showEventPopup: true });
    }
  }

  datafields = {
    myScheduleHeader: {
      title: "My Schedule",
      descr:
        "Begin the day by checking your schedule here. Know what’s ahead" +
        " and stay in the loop with real-time updates.",
      iconPath: "./img/myschedule-header-icon.svg",
      addCustomBtn: true,
      backgroundGradient: "bg--grad11"
    },
    addEventsHeader: {
      title: "Add Events",
      descr:
        "Explore the upcoming games list, learn ahead of time, search for" +
        " your favorites and add them to your schedule.",
      iconPath: "./img/addevents-header-icon.svg",
      addCustomBtn: true
    },
    dashboardTodayHeader: {
      title: "Today",
      descr:
        "Begin the day by checking your schedule here. Know what’s ahead" +
        " and stay in the loop with real-time updates on the right.",
      iconPath: "",
      addCustomBtn: false
    },
    dashboardThisWeekHeader: {
      title: "This Week",
      descr:
        "Get a head start by checking your schedule here. Know what’s ahead" +
        " and stay in the loop with real-time updates on the right.",
      iconPath: "",
      addCustomBtn: false
    },
    reservationsHeader: {
      title: "Reservations",
      descr: "Begin the day by checking your reservations here.",
      icon: (<ReservationIcon alt="Reservations" width="40px" height="40px" />),
      addCustomBtn: false,
      cls: "reservations"
    },
    settingsHeader: {
      title: "Settings",
      descr:
        "Update your personal details, company information and social medial" +
        " integrations.",
      iconPath: "./img/settings/settings.svg",
      addCustomBtn: false
    },
    promosHeader: {
      title: "Promos",
      descr:
        "Manage marketing promotions",
      iconPath: "",
      addCustomBtn: false
    },
    schedulerHeader: {
      title: "Facebook and Instagram post scheduler",
      descr:
        "Manage your social media posts",
      iconPath: "",
      addCustomBtn: false
    },
    notificationsHeader: {
      title: "Notifications",
      descr: "Check out your notifications here.",
      icon: (
        <NotificationIcon alt="Notifications" width="40px" height="40px"/>
      ),
      cls: 'notifications',
      addCustomBtn: false,
    }
  };

  /**Opens the create custom event modal
   */
  showCustomEventPopup = () => {
    this.setState({ showEventPopup: true });
  };

  /**Cancels the create custom event modal
   */
  cancelCustomEventModal = () => {
    this.setState({ showEventPopup: false });
    this.props.editCustomEvent();
  };

  showEventCreatedDialog = () => {
    this.props.reloadEventsTab();
    this.setState({ showEventPopup: false });
  };

  renderHeader = dataObj => {
    const { iconPath, title, descr, addCustomBtn, backgroundGradient, cls, icon } = dataObj;
    return (
      <div className={" header p-2 d-flex justify-content-start align-items-center " + (cls ? cls + " " : "") + ((backgroundGradient != null) ? backgroundGradient : "bg--grad9")}>
        {iconPath || icon ? (
          <div className="for-icon">
            {iconPath ? (<img src={images(iconPath)} alt="" />) : icon}
          </div>
        ) : null}
        <div>
          <h2 className="fs-h2 text--white">{title}</h2>
          <span className="fs-body3 text--white">{descr}</span>
        </div>
        {addCustomBtn ? this.renderAddCustomEventButton() : null}
        {this.props.additionalBtnLabel ? this.renderAdditionalBtn() : null}
      </div>
    );
  };
  
  renderNotificationsHeader = dataObj => {
    const { iconPath, title, descr, addCustomBtn, backgroundGradient, cls, icon } = dataObj;
    return (
      <div className="slide-in-header">
				<h1>{title}</h1>
				<div className="header-filters d-none" >
					<div className="filter-btn filter-mail"></div>
					<div className="filter-btn filter-sort"></div>
				</div>
			</div>
    );
  };

  renderAdditionalBtn = () => {
    return (
        <Button
            className="button-outline--large-transparent  p-1 ml-auto fs-btn2 text--white"
            onClick={this.props.additionalBtnClicked}
        >
          {this.props.additionalBtnLabel}
        </Button>
    );
  };

  renderAddCustomEventButton = () => {
    return (
      <Button
        className="button-outline--large-transparent  p-1 ml-auto fs-btn2 text--white"
        onClick={this.showCustomEventPopup}
      >
        NEW CUSTOM EVENT
      </Button>
    );
  };

  renderForContainerTab(container, tabIndex) {
    switch(container) {
      case 'dashboard':
        switch(tabIndex) {
          case 0:
            return this.renderHeader(this.datafields.dashboardTodayHeader);
          case 1:
            return this.renderHeader(this.datafields.dashboardThisWeekHeader);
          default:
            return null;
        }
      case 'events':
        switch(tabIndex) {
          case 0:
            return this.renderHeader(this.datafields.myScheduleHeader);
          case 1:
            return this.renderHeader(this.datafields.addEventsHeader);
          default:
            return null;
        }
      case 'reservations':
        return this.renderHeader(this.datafields.reservationsHeader);
      case 'settings':
        return this.renderHeader(this.datafields.settingsHeader);
      case 'promos':
        return this.renderHeader(this.datafields.promosHeader);
      case 'scheduler':
        return this.renderHeader(this.datafields.schedulerHeader);
      case 'notifications':
        return this.renderNotificationsHeader(this.datafields.notificationsHeader);
      default:
        return null;
    }
  }

  render() {
    return (
      <React.Fragment>
        { this.renderForContainerTab(this.props.container, this.props.tabIndex) }
        {this.state.showEventPopup ? (
          <CustomEventPopup
            cancelModal={this.cancelCustomEventModal}
            handleSuccess={this.showEventCreatedDialog}
            meetupObj={this.props.editMeetupObj}
            locationId={this.props.locationId}
            timezone={this.props.timezone}
            time_format={this.props.time_format}
            editMode={this.props.editMode}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default Header;
