import React, { Component } from 'react';
import PropTypes from 'prop-types';
const images = require.context('../assests/images', true);

/**Component for customized input element for Date picker. Passed as prop to DatePicker
 * @param onClick : function handler for click on the icon at right most posn inside input
 * @param value : holds the selected value for input
 * @param onChange : function handler for value change in input
 *
 *
 */
class CustomInputDate extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
    onChange: PropTypes.func
  };
  render() {
    const { value, onClick, onChange } = this.props;

    return (
      <div className="input-group">
        <input
          type="text"
          className="form-control fs-body2"
          value={value}
          onClick={onClick}
          onChange={onChange}
          disabled={this.props.disabled ? this.props.disabled : false}
        />
        <div className="input-group-append ">
          <button
            className="btn btn-outline-secondary btn-sm date-picker-icon"
            type="button"
            id="calenderinputicon"
            onClick={onClick}
            disabled={this.props.disabled ? this.props.disabled : false}
          >
            <img src={images('./img/calender.svg')} alt="calender" />
          </button>
        </div>
      </div>
    );
  }
}

export default CustomInputDate;
