import React, { Fragment } from "react";
import Select from 'react-select';
import { Button } from "react-bootstrap-buttons";
import FacebookAuth from 'react-facebook-auth';
import * as api from "../../utils/api";
import { FBIcon } from '../../utils/svg.js';
import { IGIcon } from '../../utils/svg.js';
import Loader from 'react-loader-spinner'
import SettingHeader from "./SettingsHeader";
import CustomAttributeToggle from '../../components/CustomAttributeToggle';
import * as actionCreators from "../../modules/actions";
import { connect } from "react-redux";
import { Alert } from 'react-bootstrap';

const ConnectFacebookButton = ({ onClick }) => (
  <FacebookButton
    onClick={onClick}
    classes="button--facebook-connect mt-3"
    text="Connect with Facebook"
  />
);

const ConnectInstagramButton = ({ onClick }) => (
  <InstagramButton
    onClick={onClick}
    classes="button--instagram-connect mt-3"
    text="Manage Instagram"
  />
);

const FacebookButton = params => (
  <button onClick={params.onClick} className={params.classes + " fs-btn2 d-flex justify-content-left align-items-center"}>
    <FBIcon className="mr-3" />
    <div className="d-flex flex-column justify-content-center" style={{ height: "24px" }}>
      {params.text}
    </div>
  </button>
);

const InstagramButton = params => (
  <button onClick={params.onClick} className={params.classes + " fs-btn2 d-flex justify-content-left align-items-center"}>
    <IGIcon className="mr-3" />
    <div className="d-flex flex-column justify-content-center" style={{ height: "24px" }}>
      {params.text}
    </div>
  </button>
);

const postingOptionsErrorMessages = {
	imageErr:
		"Facebook's guidelines do not allow images to be posted together with videos.",
	videoErr:
		"Facebook's guidelines do not allow videos to be posted together with images.",
};



class SocialMedia extends React.Component {
  DEFAULT_STATE = {
    pages: [],
    selectedPage: null,
    instagramPage: false,
    groups: [],
    selectedGroup: null,
    requiresConnect: true,
    connection: false,
    loading: false
  };

  constructor(props) {
    super(props);
    this.state = this.DEFAULT_STATE;
  }

  componentDidMount = () => {
    this.getStatus(this.handleData);
  }

  handleShortLivedToken = (response) => {
    if (response.status === "unknown") return;
    this.setState({ loading: true });
    // Api call to server so we can validate the token
    api.endpoints.handleFBToken(response.accessToken, this.handleData, this.handleError)
  };

  getStatus = (handleData) => {
    this.setState({ loading: true });
    api.endpoints.getFBStatus(this.handleData, this.handleError);
  }

  revokeAccess = () => {
    this.setState({ loading: true });
    api.endpoints.fbRevokeAccess(res => {
      this.setState(this.DEFAULT_STATE);
    }, this.handleError)
  }

  handleError = (ex) => {
    console.log(ex);
    this.setState({ loading: false });
    this.props.showNotice(ex.response.data.message, false);
    //display msg that something went wrong
  }

  updatePostingTargets = () => {
    const obj = {};
    if (this.state.pages.length > 0)
      obj.page = this.state.selectedPage.value;
    if (this.state.groups.length > 0)
      obj.group = this.state.selectedGroup.value;
    if (this.state.instagramPage)
      obj.instagram = this.state.selectedPage.instagram_id;
    obj.manual_posting = this.state.manualPosting;
    obj.post_editor = this.state.postEditor;
    obj.mp_default_header = this.state.mpDefaultHeader;
    obj.mp_default_header_text = this.state.mpDefaultHeaderText;
    obj.mp_default_footer = this.state.mpDefaultFooter;
    obj.mp_default_footer_text = this.state.mpDefaultFooterText;
    obj.mp_image_post = this.state.mpImagePost;
    obj.mp_video_post = this.state.mpVideoPost
    obj.mp_instagram_video_post = this.state.mpInstagramVideoPost
    obj.mp_video_story_instagram = this.state.mpVideoStoryInstagram
    obj.mp_image_story_instagram = this.state.mpImageStoryInstagram
    obj.mp_attachment = this.state.mpAttachment;
    obj.mp_text_post = this.state.mpTextPost;
    obj.mp_instagram_image_post = this.state.mpInstagramImagePost;
    obj.mp_instagram_attachment = this.state.mpInstagramAttachment;
    obj.mp_instagram_caption = this.state.mpInstagramCaption;
    obj.mp_instagram_tag = this.state.mpInstagramTag;
    obj.daily_posting = this.state.dailyPosting;
    obj.dp_default_header = this.state.dpDefaultHeader;
    obj.dp_default_header_text = this.state.dpDefaultHeaderText;
    obj.dp_default_footer = this.state.dpDefaultFooter;
    obj.dp_default_footer_text = this.state.dpDefaultFooterText;
    obj.dp_image_post = this.state.dpImagePost;
    obj.dp_video_post = this.state.dpVideoPost
    obj.dp_instagram_video_post = this.state.dpInstagramVideoPost
    obj.dp_video_story_instagram = this.state.dpVideoStoryInstagram
    obj.dp_image_story_instagram = this.state.dpImageStoryInstagram
    obj.dp_attachment = this.state.dpAttachment;
    obj.dp_text_post = this.state.dpTextPost;
    obj.dp_instagram_image_post = this.state.dpInstagramImagePost;
    obj.dp_instagram_attachment = this.state.dpInstagramAttachment;
    obj.dp_instagram_caption = this.state.dpInstagramCaption;
    obj.dp_instagram_tag = this.state.dpInstagramTag;
    this.setState({ loading: true });
    api.endpoints.fbUpdatePostingTargets(obj, res => {
      // TODO: change with feedback to the user
      this.setState({ loading: false });
    }, this.handleError)
  }

  handleData = (data) => {
    if (!data.permissions || data.permissions == null) {
      this.setState(this.DEFAULT_STATE);
      return;
    }

    const processEntity = (entity) => {
      entity.value = entity.id;
      entity.label = entity.name;
      delete entity.id;
      delete entity.name
      return entity
    }

    let connection = false;
    let defaultPage = null;
    if (data.permissions.pages) {
      data.pages.forEach(page => {
        page = processEntity(page);
        if (page.value === data.page) {
          defaultPage = page
        }
      })
      connection = true;
    }
    
    let defaultGroup = null;
    if (data.permissions.groups) {
      data.groups.forEach(group => {
        group = processEntity(group);
        if (group.value === data.group) {
          defaultGroup = group
        }
      })
      connection = true;
    }
 
    this.setState({
      pages: data.pages || [],
      selectedPage: defaultPage,
      instagramPage: defaultPage ? defaultPage.instagram_name : null,
      groups: data.groups || [],
      selectedGroup: defaultGroup,
      manualPosting: data.manual_posting || false,
      postEditor: data.post_editor || false,
      mpDefaultHeader: data.mp_default_header || false,
      mpDefaultHeaderText: data.mp_default_header_text || "",
      mpDefaultFooter: data.mp_default_footer || false,
      mpDefaultFooterText: data.mp_default_footer_text || "",
      mpImagePost: data.mp_image_post || false,
      mpInstagramVideoPost: data.mp_instagram_video_post || false,
      mpVideoStoryInstagram: data.mp_video_story_instagram || false,
      mpImageStoryInstagram: data.mp_image_story_instagram || false,
      mpVideoPost: data.mp_video_post || false,
      mpAttachment: data.mp_attachment || false,
      mpTextPost: data.mp_text_post || false,
      mpInstagramImagePost: data.mp_instagram_image_post || false,
      mpInstagramAttachment: data.mp_instagram_attachment || false,
      mpInstagramCaption: data.mp_instagram_caption || false,
      mpInstagramTag: data.mp_instagram_tag || false,
      dailyPosting: data.daily_posting || false,
      dpDefaultHeader: data.dp_default_header || false,
      dpDefaultHeaderText: data.dp_default_header_text || "",
      dpDefaultFooter: data.dp_default_footer || false,
      dpDefaultFooterText: data.dp_default_footer_text || "",
      dpImagePost: data.dp_image_post || false,
      dpVideoPost: data.dp_video_post || false,
      dpInstagramVideoPost: data.dp_instagram_video_post || false,
      dpVideoStoryInstagram: data.dp_video_story_instagram || false,
      dpImageStoryInstagram: data.dp_image_story_instagram || false,
      dpAttachment: data.dp_attachment || false,
      dpTextPost: data.dp_text_post || false,
      dpInstagramImagePost: data.dp_instagram_image_post || false,
      dpInstagramAttachment: data.dp_instagram_attachment || false,
      dpInstagramCaption: data.dp_instagram_caption || false,
      dpInstagramTag: data.dp_instagram_tag || false,
      connection: connection,
      // requiresConnect: data.groups.length === 0 || data.pages.length === 0,
      requiresConnect: data.pages.length === 0,
      loading: false,
      fbVideoErr: null,
			fbImageErr: null,
			igVideoErr: null,
			igImageErr: null,
    })
  }

componentDidMount() {
  const {
      mpImagePost,
      mpVideoPost,
      mpInstagramVideoPost,
      mpInstagramImagePost,


  } = this.state;

  const postingOptionErrorChanges = {};

  if (!mpVideoPost && mpImagePost) {
      postingOptionErrorChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
  } else {
      postingOptionErrorChanges.fbVideoErr = null; 
  }

  if (mpVideoPost && !mpImagePost) {
      postingOptionErrorChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
  } else {
      postingOptionErrorChanges.fbImageErr = null;
  }

  if (!mpInstagramVideoPost && mpInstagramImagePost) {
      postingOptionErrorChanges.igVideoErr = postingOptionsErrorMessages.videoErr;
  } else {
      postingOptionErrorChanges.igVideoErr = null;
  }

  if (mpInstagramVideoPost && !mpInstagramImagePost) {
      postingOptionErrorChanges.igImageErr = postingOptionsErrorMessages.imageErr;
  } else {
      postingOptionErrorChanges.igImageErr = null;
  }

  if (Object.keys(postingOptionErrorChanges).length > 0) {
  this.setState((currentState) => ({
      ...currentState,
      ...postingOptionErrorChanges,
    
  }));
}
}

componentDidUpdate(prevProps, prevState) {
  if (this.isUpdating) {
    return;
  }

  this.isUpdating = true;

  const {
    mpImagePost,
              mpVideoPost,
              mpInstagramVideoPost,
              mpInstagramImagePost,
  } = this.state;

  const stateChanges = { ...this.state };

  if (mpImagePost && prevState.mpVideoPost) {
    stateChanges.mpVideoPost = false;
    stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
    stateChanges.fbImageErr = null;
  }

  if (mpVideoPost && prevState.mpImagePost) {
    stateChanges.mpImagePost = false;
    stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
    stateChanges.fbVideoErr = null;
  }

  if (mpInstagramImagePost && prevState.mpInstagramVideoPost) {
    stateChanges.mpInstagramVideoPost = false;
    stateChanges.igVideoErr = postingOptionsErrorMessages.videoErr;
    stateChanges.igImageErr = null;
  }

  if (mpInstagramVideoPost && prevState.mpInstagramImagePost) {
    stateChanges.mpInstagramImagePost = false;
    stateChanges.igImageErr = postingOptionsErrorMessages.imageErr;
    stateChanges.igVideoErr = null;
  }

  if (!mpVideoPost && mpImagePost) {
    stateChanges.fbVideoErr = postingOptionsErrorMessages.videoErr;
  }

  if (mpVideoPost && !mpImagePost) {
    stateChanges.fbImageErr = postingOptionsErrorMessages.imageErr;
  }

  if (!mpInstagramVideoPost && mpInstagramImagePost) {
    stateChanges.igVideoErr = postingOptionsErrorMessages.videoErr;
  }
  if (mpInstagramVideoPost && !mpInstagramImagePost) {
    stateChanges.igImageErr = postingOptionsErrorMessages.imageErr;
  }

  if (!mpVideoPost && !mpImagePost) {
    stateChanges.fbVideoErr = null;
    stateChanges.fbImageErr = null;
  }
  if (!mpInstagramVideoPost && !mpInstagramImagePost) {
    stateChanges.igVideoErr = null;
    stateChanges.igImageErr = null;
  }

  this.setState(stateChanges, () => {
    this.isUpdating = false;
  });
}

tlTipErrorManager = (errMsg) => {
  const images = require.context("../../assests/images/img", true);

  return (
    <div style={{ position: "relative" }}>
      <div className="tl-tip">
        <img
          className="ml-2 mr-2"
          src={images("./share-events-alert.svg")}
          alt="tip"
        />
      </div>
      <div className="tl-tip-content">{errMsg}</div>
    </div>
  );
};



  renderRequiresConnection = () => {
    if (this.state.requiresConnect === true) {

    // default production APP_ID
    var APP_ID_FACEBOOK = "696957667401375";

    console.log('********************************************')
    console.log('process.env.NODE_ENV: ', process.env.NODE_ENV)
    if (process.env.NODE_ENV !== "production")
    {
      // local development
      APP_ID_FACEBOOK = "2231833100185422";
    }
    else
    {
      // on staging get id from .env
      if (process.env.REACT_APP_ID_FACEBOOK) {
        APP_ID_FACEBOOK =  process.env.REACT_APP_ID_FACEBOOK;
      }
    }
    console.log('APP_ID_FACEBOOK: ', APP_ID_FACEBOOK)
    console.log('********************************************')

      return (
        <Fragment>
          <p>
            In order to further the reach of your viewing schedules and take full advantage of our services,
            we ask that you allow us to post on your behalf on Facebook and Instagram. With your permission, our service
            will automatically share your event schedule on your social media pages when you publish it using Sport Compass.
            Additionally, you have the option to enable the daily publishing of your event schedule on your
            Facebook and Instagram pages, which would occur every morning at 8AM. Our service will perform according to your
            settings. Nothing more, and nothing less.
          </p>
          <p>
            Yes, we are aware of the concerns that come with the idea of granting others permission to post
            on your social media. It would certainly give us a moment’s pause if we were in your shoes.
            That said, we understand and value the importance of social media privacy and have the utmost respect for
            yours. We kindly request your permission to allow us to provide you with the most complete range of benefits our service can offer.
          </p>
          <FacebookAuth
            appId={APP_ID_FACEBOOK}
            callback={this.handleShortLivedToken}
            component={ConnectFacebookButton}
            scope="pages_read_engagement,pages_manage_posts,pages_show_list,business_management"
            className="button--warning fs-btn2"
          />
          <div/>
        </Fragment>
      )
    }
    else
      return null
  }

  renderRequiresInstagramConnection = () => {
    if (this.state.connection) {

      // default production APP_ID
      var APP_ID_FACEBOOK = "696957667401375";

      if (process.env.NODE_ENV !== "production")
      {
        // local development
        APP_ID_FACEBOOK = "2231833100185422";
      }
      else
      {
        // on staging get id from .env
        if (process.env.APP_ID_FACEBOOK) {
          APP_ID_FACEBOOK =  process.env.REACT_APP_ID_FACEBOOK;
        }
      }

      return (
        <Fragment>
          <FacebookAuth
            appId={APP_ID_FACEBOOK} 
            callback={this.getStatus}
            component={ConnectInstagramButton}
            scope="instagram_basic,instagram_content_publish"
            className="button--warning fs-btn2"
          />
        </Fragment>
      )
    }
    else
      return null
  }

  renderConnected = () => {
    if (this.state.connection === true) {
      return (
        <Fragment>
          <div>
            <label className="fs-caption2 text--gray7">
              <label className="font-weight-bold text-primary">Facebook</label> page:
            </label>
            {this.state.pages && this.state.pages.length > 0 ? (
              <Select
                options={this.state.pages}
                value={this.state.selectedPage}
                onChange={selectedOption => {
                  this.setState({ selectedPage: selectedOption, instagramPage: selectedOption.instagram_name} )
                }}
              />
            ) : (
                <p> We currently don't have access to post on any of your pages</p>
              )}
            <Fragment>
                  {this.renderRequiresInstagramConnection()} 
            </Fragment>
            <label className="fs-caption2 text--gray7">
              <label className="font-weight-bold text-dark">Instagram</label> linked account:
            </label>
            {this.state.instagramPage ? (
              <>
                <br />
                <label>
                {this.state.instagramPage}
                </label>
                <br />
              </>
            ) : (
              <>
              <br />
              <label> Instagram account not linked.</label>
              <br />
              </>
            )}
            {/*
            <br />
            <label className="fs-caption2 text--gray7">
              Facebook group:
            </label>
            this.state.groups && this.state.groups.length > 0 ? (
              <Select
                options={this.state.groups}
                value={this.state.selectedGroup}
                onChange={selectedOption => {
                  this.setState({ selectedGroup: selectedOption })
                }}
              />
            ) : (
                <p> We currently don't have access to post on any of your groups.</p>
              )
            */}
            <br />
            {(this.state.pages && this.state.pages.length > 0) ||
              (this.state.groups && this.state.groups.length > 0) ? (
                <Fragment>
                  <div className="border rounded p-1">
                    <>
                    <br />
                    <div className="ml-3">
                      <CustomAttributeToggle
                        text={"Manual posting"}
                        enabled={this.state.manualPosting}
                        switchClass="feature-switch"
                        onToggle={checked => this.setState({ manualPosting: checked })}
                        tooltip="This is about posting to facebook when the Share Event button is pressed."
                      />
                      <br />
                      <CustomAttributeToggle
                        text={"Posting Editor"}
                        enabled={this.state.postEditor}
                        selectable={this.state.manualPosting ? true : false}
                        switchClass="feature-switch"
                        onToggle={checked => this.setState({ postEditor: checked })}
                        tooltip="Before the post is made, having the ability to edit the post."
                      />
                      <br />
                      <CustomAttributeToggle
                        text={"Facebook post as text"}
                        enabled={this.state.mpTextPost}
                        selectable={this.state.manualPosting ? true : false}
                        switchClass="feature-switch"
                        onToggle={checked => this.setState({ mpTextPost: checked })}
                        tooltip="Add games in text format"
                      />
                      <br />
                      <CustomAttributeToggle
                        text={"Facebook post as image"}
                        enabled={this.state.mpImagePost} //
                        selectable={this.state.manualPosting ? true : false}
                        switchClass="feature-switch"
                        onToggle={checked => {
                          this.setState({ mpImagePost: checked });
                        }}
                        tooltip="Add a poster image with all the games"
                        hasAnErrorAttached={this.state.fbImageErr}
                      />  
                      <br />
                      <CustomAttributeToggle
                        text={"Facebook post as video"}
                        enabled={this.state.mpVideoPost}
                        selectable={this.state.manualPosting ? true : false}
                        switchClass="feature-switch"
                        onToggle={checked => {
                          this.setState({ mpVideoPost: checked });
                        }}
                        tooltip="Add a video with all the games"
                        hasAnErrorAttached={this.state.fbVideoErr}
                      />
                      <br />
                      <CustomAttributeToggle
                        text={"Facebook post attachment"}
                        enabled={this.state.mpAttachment} //
                        selectable={this.state.manualPosting ? true : false}
                        switchClass="feature-switch"
                        onToggle={checked => {
                          this.setState({ mpAttachment: checked });
                          if (checked) {
                            this.setState({
                              mpVideoPost: false,
                              mpInstagramVideoPost: false,
                          });
                          }
                        }}
                        
                        tooltip="Add the attached file to the post"
                      />  
                      <>
                      <br />
                      <CustomAttributeToggle
                        text={"Instagram post as image"}
                        enabled={this.state.mpInstagramImagePost}//
                        selectable={this.state.manualPosting && this.state.instagramPage ? true : false}
                        switchClass="feature-switch"
                        onToggle={checked => {
                          this.setState({ mpInstagramImagePost: checked, mpInstagramCaption: checked });
                        }}
                        tooltip="Add a poster image with all the games and a caption containing the chosen headers"
                        hasAnErrorAttached={this.state.igImageErr}
                      />
                      <br />
                      <CustomAttributeToggle
                        text={"Instagram post as video"}
                        enabled={this.state.mpInstagramVideoPost}
                        selectable={this.state.manualPosting && this.state.instagramPage ? true : false}
                        switchClass="feature-switch"
                        onToggle={checked => {
                          this.setState({ mpInstagramVideoPost: checked, mpInstagramCaption: checked });
                        }}
                        tooltip="Add a video with all the games"
                        hasAnErrorAttached={this.state.igVideoErr}
                      />
                      <br />
                      <CustomAttributeToggle
                        text={"Instagram post as video story"}
                        enabled={this.state.mpVideoStoryInstagram}
                        selectable={this.state.manualPosting && this.state.instagramPage ? true : false}
                        switchClass="feature-switch"
                        onToggle={checked => {
                          this.setState({ mpVideoStoryInstagram: checked, mpInstagramCaption: checked });
                        }}
                        tooltip="Add a video story with all the games"
                      />
                      <br />
                      <CustomAttributeToggle
                        text={"Instagram post as image story"}
                        enabled={this.state.mpImageStoryInstagram} //
                        selectable={this.state.manualPosting && this.state.instagramPage ? true : false}
                        switchClass="feature-switch"
                        onToggle={checked => {
                          this.setState({ mpImageStoryInstagram: checked, mpInstagramCaption: checked });
                        }}
                        tooltip="Add an image story with all the games"
                      />
                      <br />
                      <CustomAttributeToggle
                        text={"Instagram post attachment"}
                        enabled={this.state.mpInstagramAttachment}//
                        selectable={this.state.manualPosting && this.state.instagramPage ? true : false}
                        switchClass="feature-switch"
                        onToggle={checked => {
                          this.setState({ mpInstagramAttachment: checked });
                          if (checked) {
                            this.setState({
                              mpVideoPost: false,
                              mpInstagramVideoPost: false,
                          });
                          }
                        }}
                        tooltip="Add the attached file to the post"
                      />
                      </>
                      <br />
                      <div className="form-group">
                        <div className="form-group" style={{ height: "24px" }}>
                          <CustomAttributeToggle
                          text={"Default header"}
                          enabled={this.state.mpDefaultHeader}
                          selectable={this.state.manualPosting}
                          switchClass="feature-switch"
                          onToggle={checked => this.setState({ mpDefaultHeader: checked })}
                          tooltip="If this is enabled, the text below will be the default header for your posts.
                          If this is not enabled one of our many random headers will be added to your post everytime instead."
                          />
                        </div>
                        <textarea
                          readOnly={!this.state.mpDefaultHeader}
                          rows="3"
                          disabled={!this.state.manualPosting}
                          type="text"
                          className="form-control align-top"
                          placeholder="Your default header"
                          value={this.state.mpDefaultHeaderText}
                          onChange={event => this.setState({ mpDefaultHeaderText: event.target.value})}
                        />
                      </div>
                      <div className="form-group ">
                        <div className="form-group" style={{ height: "24px" }}>
                          <CustomAttributeToggle
                          text={"Default footer"}
                          enabled={this.state.mpDefaultFooter}
                          selectable={this.state.manualPosting}
                          switchClass="feature-switch"
                          onToggle={checked => this.setState({ mpDefaultFooter: checked })}
                          tooltip="If this is enabled, the text below will be added at the end of your posts."
                          />
                        </div>
                        <textarea
                          readOnly={!this.state.mpDefaultFooter}
                          rows="3"
                          disabled={!this.state.manualPosting}
                          type="text"
                          className="form-control align-top"
                          placeholder="Your default footer"
                          value={this.state.mpDefaultFooterText}
                          onChange={event => this.setState({ mpDefaultFooterText: event.target.value})}
                        />
                      </div>
                    </div>
                    </>
                  </div>
                  <br />
                  <Button
                    className="button--success fs-btn2"
                    onClick={this.updatePostingTargets}
                  >
                    Update Selections
                  </Button>
                </Fragment>
              ) : null
            }
          </div>
          <div className="border-bottom  mx-2 my-4" />
          <label className="fs-caption2 text--gray7">
            Remove Facebook integration:
          </label>
          <FacebookButton
            onClick={this.revokeAccess}
            classes="button--facebook-disconnect"
            text="Disconnect Facebook"
          />
        </Fragment>
      )
    }
    else
      return false;
  };

  render() {
    return (
      <div className="d-flex bg-secondary w-75 h-100 ml-5 overflow-auto">
        <div className="w-75 mr-5 ml-2 bg-secondary">
          <SettingHeader
            image="./social-media.svg"
            imageAlt="Social Media page icon"
            title="Social Media"
          />
          <div className="p-2 mt-2  ">
            <h4 className="font--size--sm font-color--grey font-weight-bold">
              Facebook & Instagram Setup
            </h4>
            {(this.state.loading) ? (
              <div className='m-5'>
                <Loader
                  type="TailSpin"
                  color="#0084f4"
                  height="120"
                  width="120"
                />
              </div>
            ) : (
                <Fragment>
                  {this.renderRequiresConnection()}
                  {this.renderConnected()}
                </Fragment>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showNotice: (message, success) =>
      dispatch(actionCreators.showNotice(message, success))
  };
};
export default connect(null, mapDispatchToProps)(SocialMedia);
