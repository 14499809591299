import React, { Fragment, useEffect, useState } from "react";
import { Button } from "react-bootstrap-buttons";
import { Link } from "react-router-dom";
import { CastIcon } from "../../utils/svg";
import { ConfirmRepick } from "./ConfirmRepick";
import * as api from "../../utils/api";
import moment from "moment-timezone";

const FormField = props => {
  return (
    <div className="mb-2 text-center">
      <p className="fs-caption1 text--gray7 mb-1">And the winner is: </p>
      <input
        type="text"
        className="fs-h1 text-center text--shadenavy winner"
        value={props.value}
        readOnly
      />
      <p>{props.email}</p>
    </div>
  );
};

const PromoItem = props => {
  const [visiblewinner, setVisibleWinner] = useState("invisible_winner");
  const [winner, setWinner] = useState("");
  const [email, setEmail] = useState("");

  const getWinner = promoId => {
    api.endpoints.getPromoWinner(promoId, res => {
      setWinner(res.promo.winner.name);
      setEmail(res.promo.winner.email);
      setVisibleWinner("visible_winner");
    });
  };

  useEffect(() => {
    if (props.promo.winner !== null) {
      setWinner(props.promo.winner.name);
      setEmail(props.promo.winner.email);
      setVisibleWinner("visible_winner");
    } else {
      setVisibleWinner("invisible_winner");
    }
    ConfirmRepick();
    // eslint-disable-next-line
  }, []);

  const promo = props.promo;

  return (
    <Fragment>
      <div className="row promo-item mb-3 bg-white">
        <div className="col d-flex promo-item--inner p-3 justify-content-between">
          <div className="col d-flex flex-column border-right  justify-content-center  align-items-center promo-item__match-times">
            <div className="fs-h3">Competition</div>
            <div className="d-flex">
              <div className="fs-caption1 text--gray8">
                {promo.competitions}
              </div>
            </div>
          </div>
          <div className="col d-flex flex-column border-right justify-content-center align-items-center promo-item__match-times">
            <div className="fs-h3">Start date</div>
            <div className="d-flex">
              <div className="fs-caption1 text--gray8">
                {moment(promo.start_time).tz(props.timezone)}
              </div>
            </div>
          </div>
          <div className="col d-flex flex-column border-right justify-content-center  align-items-center promo-item__match-times">
            <div className="fs-h3">End date</div>
            <div className="d-flex">
              <div className="fs-caption1 text--gray8">
                {moment(promo.end_time).tz(props.timezone)}
              </div>
            </div>
          </div>
          <div className="col d-flex fs-h2 justify-content-center align-items-center">
            {promo.promo_type}
          </div>
        </div>
        <div className={`col-12 mb-3 text-center justify-content-center`}>
          <Button
            className="button--success mr-4 no-border-button"
            onClick={() => {
              if (winner !== "") {
                window.ShowConfirmation(
                  "Are you sure you want to pick a new winner?",
                  result => {
                    if (result) {
                      getWinner(promo.id);
                    }
                  }
                );
              } else {
                getWinner(promo.id);
              }
            }}
          >
            Pick The Winner
            <i className="fas fa-presentation"></i>
          </Button>
          <Link to={`/promo/${promo.id}`} target="_blank">
            <Button className="button--warning no-border-button">
              Winner Presentation
              <CastIcon className="cast-icon" />
            </Button>
          </Link>
        </div>
        <div className={`col-12 ${visiblewinner}`}>
          <FormField id="Winner" name="Winner" value={winner} email={email} />
        </div>
      </div>
    </Fragment>
  );
};

export default PromoItem;
