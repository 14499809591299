import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap-buttons";
import * as api from "../../utils/api";
import { ConfirmRepick } from "./ConfirmRepick";
import WinnerName from "./WinnerName";
import moment from "moment-timezone";
const images = require.context("../../assests/images/img/", true);

const PromoItemPage = props => {
  const [promo, setPromo] = useState({});
  const [visiblewinner, setVisibleWinner] = useState("invisible_winner");
  const [winner, setWinner] = useState("");
  const [showWinner, setShowWinner] = useState(false);
  const [duration, setDuration] = useState(600);
  const [interval, setInterval] = useState(1000);
  const [delay, setDelay] = useState(1000);

  const getWinner = promoId => {
    setShowWinner(false);
    api.endpoints.getPromoWinner(promoId, res => {
      setWinner(res.promo.winner.name);
      setVisibleWinner("visible_winner");
      setShowWinner(true);
    });
  };

  const getNewWinner = promoId => {
    setShowWinner(false);
    setDuration(0);
    setInterval(0);
    setDelay(0);
    setWinner("");
    api.endpoints
      .getPromoWinner(promoId, res => {
        setWinner(res.promo.winner.name);
      })
      .then(() => {
        setDuration(600);
        setInterval(1000);
        setDelay(1000);
        setShowWinner(true);
      });
  };

  const getPromo = promoId => {
    api.endpoints.getPromo(promoId, res => {
      setPromo(res.promo);
      if (res.promo.winner !== null) {
        setWinner(res.promo.winner.name);
        setVisibleWinner("visible_winner");
        setShowWinner(true);
      } else {
        setVisibleWinner("invisible_winner");
        setShowWinner(false);
      }
    });
  };

  useEffect(() => {
    document.body.classList.add("promo-page");
    getPromo(props.match.params.promoId);
    ConfirmRepick();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="promo-page bg--gray4 d-flex justify-content-center align-items-center h-100">
      <div className="row flex-column promo-page-container bg--grad5 d-flex flex-wrap  justify-content-center">
        <div className="mb-3 bg-white">
          <div className="col d-flex promotype justify-content-center align-items-center text--shadenavy">
            {promo.promo_type}
          </div>
          <div className="col d-flex promo-page--inner p-3 justify-content-between">
            <div className="col-12 d-flex flex-column justify-content-center align-items-center promo-page__match-times">
              <div className="fs-h3">Period</div>
              <div className="d-flex">
                <div className="fs-caption1 text--gray8">
                  {`${moment(promo.start_time).tz(props.timezone).format("D MMM YYYY")} - ${moment(
                    promo.end_time
                  ).tz(props.timezone).format("D MMM YYYY")}`}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`col-12 mb-3 mt-4 text-center justify-content-center`}
          >
            <Button
              className="button--success no-border-button"
              onClick={() => {
                if (winner !== "") {
                  window.ShowConfirmation(
                    "Are you sure you want to pick a new winner?",
                    result => {
                      if (result) {
                        getNewWinner(promo.id);
                      }
                    }
                  );
                } else {
                  getWinner(promo.id);
                }
              }}
            >
              Pick The Winner
            </Button>
          </div>
          <div className={`col-12 mt-4 mb-4 ${visiblewinner}`}>
            <div className="mb-2 text-center">
              <p className="fs-caption1 text--gray7 mb-1">And the winner is:</p>
              <h1 className="text-center text--shadenavy winner">
                <WinnerName
                  winner={winner}
                  showWinner={showWinner}
                  type={"mask-top"}
                  exclusive={true}
                  duration={duration}
                  interval={interval}
                  delay={delay}
                />
              </h1>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center">
          <img
            src={images("./new-logo.svg")}
            alt="Sport Compass"
            className="logo"
          />
        </div>
      </div>
    </div>
  );
};

export default PromoItemPage;
