import React, { Component } from "react";
import EventItems from "./events/EventItems";
import { Tabs, Tab } from "react-bootstrap-tabs";
import Header from "../components/Header";
import moment from "moment-timezone";
import * as actionCreators from "../modules/actions";
import { connect } from "react-redux";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationId: ""
    };
  }

  fetchData = () => {
    const {
      reloadMeetups,
      initState,
      fetchMeetups,
      refreshMeetups,
      timezone
    } = this.props;

    if (reloadMeetups || initState) {
      const start_of_day_tzm = moment().tz(timezone).startOf('day');
      const end_of_day_tzm = moment().tz(timezone).endOf('day');
      const end_of_7_days_tzm = end_of_day_tzm.clone().add(7, "days");

      fetchMeetups({
        view: "today",
        filters: {
          add_reservations: "1",
          start_date: start_of_day_tzm.toDate(),
          end_date: end_of_day_tzm.toDate()
        }
      });

      fetchMeetups({
        view: "week",
        filters: {
          add_reservations: "1",
          start_date: start_of_day_tzm.toDate(),
          end_date: end_of_7_days_tzm.toDate()
        }
      });

      refreshMeetups(false);
    }
  }

  componentDidMount = () => {
    if (this.props.client_loading === false) {
      this.fetchData();
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.client_loading !== this.props.client_loading) {
      this.fetchData();
    }
  }

  componentWillUnmount = () => {
    if (this.props.initState) {
      this.props.initialLoad();
    }
  };

  renderDashBoard = () => (
    <div className="col bg-light overflow-auto">
      <Header container="dashboard" tabIndex={this.props.tabIndex} />
      <Tabs
        selected={this.props.tabIndex}
        onSelect={index => {
          this.props.saveActiveTabIndex(index);
        }}
        className="tabs-container dashboard-tab--header"
        headerClass="tabs-header"
        activeHeaderClass="tabs-header--active"
        contentClass="tabs-content"
      >
        <Tab label="Today">
          <EventItems
              data={this.props.meetups_today}
              container="mydashboard"
              timezone={this.props.timezone}
              time_format={this.props.time_format}
          />
        </Tab>
        <Tab label="This Week">
          <EventItems
            data={this.props.meetups_week}
            container="weekdashboard"
            timezone={this.props.timezone}
            time_format={this.props.time_format}
          />
        </Tab>
      </Tabs>
    </div>
  );

  render() {
    return (
      <div className="container-fluid">
        <div className="row flex-nowrap h-100">
          { (this.props.client_loading ===  false) && this.renderDashBoard() }
          {/* <div className="col-2 insight-container--vertical">
            <Insights />
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    meetups_week: state.meetups_week,
    meetups_today: state.meetups_today,
    reloadMeetups: state.reloadMeetups,
    initState: state.initState,
    tabIndex: state.dashboardActiveTabIndex,
    defaultStartDate: state.defaultStartDate,
    defaultEndDate: state.defaultEndDate,
    client_loading: state.client_loading,
    timezone: state.timezone,
    time_format: state.time_format
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchMeetups: params => dispatch(actionCreators.getMeetups(params)),
    initialLoad: () => dispatch(actionCreators.initState()),
    refreshMeetups: params => dispatch(actionCreators.refreshMeetups(params)),
    saveActiveTabIndex: params =>
      dispatch(actionCreators.saveActiveTabIndex(params, "DASHBOARD"))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
