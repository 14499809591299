import React, {useEffect} from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Header from "../components/Header";
import Sections from "./settings/Sections";
import ProfileDetails from "./settings/ProfileDetails";
import CompanyDetails from "./settings/CompanyDetails";
import SocialMedia from "./settings/SocialMedia";
import Preferences from "./settings/Preferences";
import Widget from "./settings/Widget";
import { connect} from "react-redux";
import * as actionCreators from "../modules/actions";
import * as constants from "../utils/constants";
import Payment from "./settings/Payment";
import PosterSettings from "./settings/PosterSettings";
import ReservationsDetails from "./settings/ReservationsDetails";

const Settings = props => {
  
  
    const url = props.match.url;
  
  return (
    
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <div className="col bg-light ">
          <Header container="settings" />
          <div className="content-height border  pt-2 d-flex w-100">
            <Sections {...props} />
            <Switch>
              <Route
                path={url + "/profile"}
                render={routeProps => {
                  props.saveActiveTab(constants.SettingsMenuEnum.PROFILE);
                  return <ProfileDetails {...routeProps} />;
                }}
              />
              <Route
                path={url + "/company"}
                render={routeProps => {
                  props.saveActiveTab(constants.SettingsMenuEnum.COMPANY);
                  return <CompanyDetails {...routeProps} />;
                }}
              />
              <Route
                path={url + "/reservation"}
                render={routeProps => {
                  props.saveActiveTab(constants.SettingsMenuEnum.RESERVATION);
                  return <ReservationsDetails {...routeProps} />
                }}
              />
              {/* <Route
                path={url + "/management"}
                render={routeProps => {
                  props.saveActiveTab(constants.SettingsMenuEnum.MANAGEMENT);
                  return <Management {...routeProps} />;
                }}
              /> */}
              <Route
                path={url + "/socialmedia"}
                render={routeProps => {
                  props.saveActiveTab(constants.SettingsMenuEnum.SOCIAL_MEDIA);
                  return <SocialMedia {...routeProps} />;
                }}
              />
              <Route
                path={url + "/preferences"}
                render={routeProps => {
                  props.saveActiveTab(constants.SettingsMenuEnum.PREFERENCES);
                  return <Preferences {...routeProps} />;
                }}
              />
              <Route
                path={url + "/widget"}
                render={routeProps => {
                  props.saveActiveTab(constants.SettingsMenuEnum.WIDGET);
                  return <Widget {...routeProps} />;
                }}
              />
              <Route
                path={url + "/payment"}
                render={routeProps => {
                  props.saveActiveTab(constants.SettingsMenuEnum.PAYMENT);
                  return <Payment {...routeProps} />;
                }}
              />
              <Route
                path={url + "/postersettings"}
                render={routeProps => {
                  props.saveActiveTab(constants.SettingsMenuEnum.POSTERSETTINGS);
                  return <PosterSettings {...routeProps} />;
                }}
              />
              <Redirect
                from="/settings"
                exact
                to={`/settings/${props.activeTab}`}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
    
  );
};

const mapStateToProps = state => {
  return {
    activeTab: state.settingsActiveTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveActiveTab: params =>
      dispatch(actionCreators.saveActiveSettingsMenu(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
