import React, { useState } from "react";
import {
  Modal,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";

const EditModal = (props) => {
  const [radioValue, setRadioValue] = useState(0);

  const cancelModal = () => {
    props.cancelModal();
  };

  return (
    <Modal show={true} onHide={cancelModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit recurring event</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <h4>Which event(s) do you want to edit?</h4>
        <ToggleButtonGroup
          type="radio"
          name="deleteEvents"
          style={{ marginTop: "10px" }}
          vertical
        >
          <ToggleButton
            key={"this_event"}
            id={"this_event"}
            className="mb-2"
            variant="outline-primary"
            value={1}
            onChange={(e) => setRadioValue(e.target.value)}
          >
            This event
          </ToggleButton>
          <ToggleButton
            key={"this_and_following"}
            id={"this_and_following"}
            className="mb-2"
            variant="outline-primary"
            value={2}
            onChange={(e) => setRadioValue(e.target.value)}
          >
            This and following events
          </ToggleButton>
          <ToggleButton
            key={"all_events"}
            id={"all_events"}
            className="mb-2"
            variant="outline-primary"
            value={3}
            onChange={(e) => setRadioValue(e.target.value)}
          >
            All events
          </ToggleButton>
        </ToggleButtonGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelModal}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            props.editCustomEvent(props.selectedEvent, radioValue);
            cancelModal();
          }}
          disabled={radioValue === 0}
        >
          Edit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
