import React, { useEffect, useState } from "react";
import Select from "react-select";
import ImageGallery from "react-image-gallery";

const CustomPostSelector = (props) => {
	const images = props.images;

	const keys = Object.keys(images);
	const [photoIndex, setPhotoIndex] = useState(
		keys.indexOf(props.selected.toString())
	);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		props.setSelected(props.selected);
	}, [options, props.selected]);

	const imageObjects = keys.map((key) => ({
		original: images[key].img,
		thumbnail: images[key].img,
		originalHeight: "500px",
	}));

	const options = Object.keys(images).map((key) => ({
		value: key,
		label: images[key].name,
	}));

	return (
		<div style={{ width: "100%", height: "100%" }}>
			<Select
				styles={{
					control: (baseStyles) => ({
						...baseStyles,
						borderRadius: "10px",
						border: "2px solid #dadee2",
					}),
				}}
				components={{
					IndicatorSeparator: () => null,
				}}
				options={options}
				onChange={(selectedOption) => {
					setPhotoIndex(keys.indexOf(selectedOption.value));
					setIsOpen(true);
				}}
				value={options.find((option) => option.value == props.selected)}
			/>

			{isOpen && (
				<React.Fragment>
					<div className="modal-backdrop bg-white opacity6" />
					<div className="modal modal__publish-status d-flex align-items-center modal--overflow">
						<div className="modal-dialog modal-dialog-centered modal-responsive">
							<div className="modal-content p-2">
								<div className="modal-header border-bottom-0 p-0 pr-2">
									<strong>Please select the wanted poster</strong>
									<button
										onClick={() => setIsOpen(false)}
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close"
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body pr-3 pl-3">
									<ImageGallery
										items={imageObjects}
										startIndex={photoIndex}
										onSlide={(index) => {
											setPhotoIndex(index);
										}}
										showPlayButton={false}
										showFullscreenButton={false}
										className="custom-image-gallery"
									/>
								</div>
								<div className="modal-footer pt-0 justify-content-center border-top-0">
									<div className="d-flex w-100 justify-content-center">
										<strong>Selected Poster: &nbsp; </strong> {images[keys[photoIndex]].name}
									</div>
									<button
										type="button"
										className="button--warning no-border-button bootstrap-buttons---btn bootstrap-buttons---btn-md bootstrap-buttons---btn-default"
										data-dismiss="modal"
										onClick={() => setIsOpen(false)}
									>
										Close
									</button>
									<button
										type="button"
										className="button--success no-border-button bootstrap-buttons---btn bootstrap-buttons---btn-md bootstrap-buttons---btn-default"
										data-dismiss="modal"
										onClick={() => {
											props.setSelected(keys[photoIndex]);
											setIsOpen(false);
										}}
									>
										Select
									</button>
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default CustomPostSelector;
