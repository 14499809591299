/** Returns the jsx for custom event type */
import React, {useState} from "react";
import moment from "moment-timezone";
import PromosInEvents from "./PromosInEvents";
import SocialsInEvents from "./SocialsInEvents";
import {CustomEventIcon, ReservationIcon} from "../../../../utils/svg";
import ReservationModal from "../../../../components/ReservationModal";
import { connect } from "react-redux";
import { formatTime } from "../../../../utils/utils";

const RenderCustomEvent = props => {
    const [showReservationModal, setShowReservationModal] = useState(false);
    const timeFormat = props.time_format === '24H' ? 'HH:mm' : 'h:mm A';
    let to = null;
    if (props.type === "reservation" && props.end_time && props.end_time !== props.start_time){
        let sameDay = moment(props.start_time).tz(props.timezone).isSame(moment(props.end_time).tz(props.timezone), 'day');
        to = (
            <React.Fragment>
                <span className="fs-body2"> to</span>
                <br />
                <span className={"text-nowrap " + (sameDay ? "" : "fs-body3")}>{moment(props.end_time).tz(props.timezone).format(sameDay ? timeFormat : "D MMMM " + timeFormat)}</span>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
          <div className="p-3 col-6 d-flex m-0 align-items-center">
            {props.children}
            <div className="d-flex justify-content-center align-items-center">
                {props.type === "custom" ?
                    (<CustomEventIcon alt="custom event" className="event-item__sport-type" />)
                    : ( <ReservationIcon alt="reservation" className="icon-reservation" /> )
                }
            </div>
            <div  {...props.type === "reservation" && {className: "element--clickable w-100", title: 'Click to see details', onClick: () => setShowReservationModal(true)}} >
                {props.type === "custom" ?
                    (<p className="mb-0">{props.name}</p>)
                    : (
                        <>
                            <span className="limit-to-2lines">{props.name}</span>
                            {props.description.length > 0 && (
                                <span className="limit-to-2lines fs-body2 text--gray8">{props.description}</span>
                            )}
                        </>
                    )
                }
            </div>
          </div>
          <div className="d-flex col-1 align-items-center flex-column justify-content-center event-item__match-times">
            <div
                className="fs-body1"
                title={props.timezone + ' time (UTC: ' + moment(props.start_time).utc().format('LLL') + ')'}
            >
                {formatTime(props.start_time, props.time_format, props.timezone)}
                {to}
            </div>
          </div>
          <div className="col d-flex align-items-center justify-content-center"></div>
          {/* Promos in events disabled for now */}
           <PromosInEvents />
          {props.socials && <SocialsInEvents />}
          { showReservationModal &&
            <ReservationModal {...props} show={showReservationModal} cancelModal={() => setShowReservationModal(false)} />
          }
        </React.Fragment>
    );
};

const mapStateToProps = state => {

  return {
    timezone: state.timezone,
    time_format: state.time_format
  };
};

export default connect(mapStateToProps)(RenderCustomEvent);
