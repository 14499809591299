export const INIT_STATE = "INIT_STATE";
export const READ_STATE = "READ_STATE";

export const SHOW_NOTICE = "SHOW_NOTICE";
export const HIDE_NOTICE = "HIDE_NOTICE";

export const SAVE_MEETUPS = "SAVE_MEETUPS";

export const SAVE_RESERVATIONS = "SAVE_RESERVATIONS";
export const RELOAD_RESERVATIONS = "RELOAD_RESERVATIONS";
export const GET_RESERVATIONS_FILTERS = "GET_RESERVATIONS_FILTERS";
export const SAVE_RESERVATIONS_EXIST = "SAVE_RESERVATIONS_EXIST";
export const SAVE_RESERVATIONS_FILTERS = "SAVE_RESERVATIONS_FILTERS";

export const SAVE_NOTIFICATIONS = "SAVE_NOTIFICATIONS";
export const RELOAD_NOTIFICATIONS = "RELOAD_NOTIFICATIONS";
export const SAVE_NOTIFICATIONS_EXIST = "SAVE_NOTIFICATIONS_EXIST";

export const SAVE_CABLE = "SAVE_CABLE";
export const SAVE_UNSEEN_NOTIFICATIONS = "SAVE_UNSEEN_NOTIFICATIONS";

export const GET_MEETUPS_FILTERS = "GET_MEETUPS_FILTERS";
export const SAVE_MEETUPS_FILTERS = "SAVE_MEETUPS_FILTERS";
export const MEETUPS_LOADER = "MEETUPS_LOADER";


export const GET_FIXTURES_FILTERS = "GET_FIXTURES_FILTERS";
export const SAVE_FIXTURES_FILTERS = "SAVE_FIXTURES_FILTERS";
export const FIXTURES_LOADER = "FIXTURES_LOADER";


export const FILTER_MEETUPS = "FILTER_MEETUPS";
export const FILTER_FIXTURES = "FILTER_FIXTURES";

export const SAVE_FILTERED_MEETUPS = "SAVE_FILTERED_MEETUPS";
export const SAVE_FILTERED_FIXTURES = "SAVE_FILTERED_FIXTURES";

export const IS_EXIST_FILTERED_MEETUPS = "IS_EXIST_FILTERED_MEETUPS";

export const RELOAD_MEETUPS = "RELOAD_MEETUPS";

export const SAVE_ACTIVE_TABINDEX = "SAVE_ACTIVE_TABINDEX";

export const SAVE_ACTIVE_SETTINGS_MENU = "SAVE_ACTIVE_SETTINGS_MENU";

export const GET_FAVORITE_STATIONS = "GET_FAVORITE_STATIONS";
export const SAVE_FAVORITE_STATIONS = "SAVE_FAVORITE_STATIONS";

export const GET_SPORTS = "GET_SPORTS";
export const SAVE_SPORTS = "SAVE_SPORTS";

export const GET_FAVORITE_SPORTS = "GET_FAVORITE_SPORTS";
export const SAVE_FAVORITE_SPORTS = "SAVE_FAVORITE_SPORTS";

export const GET_FAVORITE_TEAMS = "GET_FAVORITE_TEAMS";
export const SAVE_FAVORITE_TEAMS = "SAVE_FAVORITE_TEAMS";

export const GET_FAVORITE_COMPETITIONS = "GET_FAVORITE_COMPETITIONS";
export const SAVE_FAVORITE_COMPETITIONS = "SAVE_FAVORITE_COMPETITIONS";

export const SET_CURRENT_CLIENT_TYPE = "SET_CURRENT_CLIENT_TYPE";
export const SET_CURRENT_CLIENT_NEED_PAY = "SET_CURRENT_CLIENT_NEED_PAY";

export const SET_USER_TIME_FORMAT = "SET_USER_TIME_FORMAT";
export const SET_USER_TIME_ZONE = "SET_USER_TIME_ZONE";
export const SET_CLIENT_ID = "SET_CLIENT_ID";

export const SAVE_CURRENCIES = "SAVE_CURRENCIES";
export const GET_CURRENCY_DATA = "GET_CURRENCY_DATA";

export const SET_USER_LANGUAGE = "SET_USER_LANGUAGE";

export const SAVE_HEADER_MESSAGE = "SAVE_HEADER_MESSAGE";
export const DELETE_HEADER_MESSAGE = "DELETE_HEADER_MESSAGE";
export const SHOW_FAKE_LOADER = "SHOW_FAKE_LOADER";
