import React, { useState } from "react";
import { Button } from "react-bootstrap-buttons";
import * as api from "../../utils/api";
import SettingHeader from "./SettingsHeader";

const INITIAL_STATE = "";

const FormField = props => {
  return (
    <div className="form-group">
      <label className="fs-caption2 text--gray7">
        {props.name}
      </label>
      <input
        type="password"
        className="form-control fs-h4 text--gray8"
        value={props.value}
        onChange={e => props.setter(e.target.value)}
        autoComplete={props.autoComplete}
      />
    </div>
  )
}

const ProfileDetails = props => {
  const [oldPassword, setOldPassword] = useState(INITIAL_STATE)
  const [newPassword, setNewPassword] = useState(INITIAL_STATE)
  const [newPasswordRepeat, setNewPasswordRepeat] = useState(INITIAL_STATE)
  const [error, setError] = useState(INITIAL_STATE)

  const updatePasword = props => {
    const userData = {
      old_password: oldPassword,
      new_password: newPassword,
      new_password_repeat: newPasswordRepeat
    };
    setError(INITIAL_STATE);
    api.endpoints.updatePassword(userData,
      res => {
        setOldPassword(INITIAL_STATE)
        setNewPassword(INITIAL_STATE)
        setNewPasswordRepeat(INITIAL_STATE)
      },
      err => {
        // Should be improved
        setError(err.data.message.replace("Validation failed:", "New"));
      }
    );
  }

  return (
    <div className="d-flex bg-secondary w-75 h-100 ml-5">
      <div className="w-75 mr-5 ml-2 bg-secondary">
        <SettingHeader
          image="./user-profile.svg"
          imageAlt="User profile icon"
          title="Your Profile"
        />

        <div className="p-2 mt-2  ">
          <h4 className="fs-h4 text--gray8 ">Change your account password</h4>
          <h6 className="fs-body2 mb-10">Your password must contain an uppercase letter, a lowercase letter, a number, a symbol and be over eight characters long.</h6>
          <form className="d-flex justify-content-between align-items-center fs-caption2 text--gray7 ">
            <input
              type="text"
              name="email"
              value="..."
              autoComplete="username email"
              className="d-none"
              readOnly
            />
            <FormField
              id="CP"
              name="CURRENT PASSWORD"
              value={oldPassword}
              setter={setOldPassword}
              autoComplete={"current-password"}
            />
            <FormField
              id="NP"
              name="NEW PASSWORD"
              value={newPassword}
              setter={setNewPassword}
              autoComplete={"new-password"}
            />
            <FormField
              id="RP"
              name="REPEAT PASSWORD"
              value={newPasswordRepeat}
              setter={setNewPasswordRepeat}
              autoComplete={"new-password"}
            />
          </form>
          <span className="fs-caption-error" style={{ visibility: error === "" ? "hidden" : "visible" }}>{error}</span>
          <div className="d-flex justify-content-start pt-3">
            <Button
              className="button--success fs-btn2"
              onClick={updatePasword}
            >
              Update Password
            </Button>
            {/* Commented out until we can send emails */}
            {/* <Button className="button--warning ml-5 fs-btn2">
              Forgot Password
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
