import React from "react";
import { Button } from "react-bootstrap-buttons";

const panel = props => {
    const plural = props.selNotificationIds.length === 1 ? '':'s';

    return (
        <div className=" bg-white actions-panel">
            <div className=" d-flex m-auto p-3  reservation-dialog__bar col-11 align-items-center text--gray11">
                <Button
                    className="button--warning ml-auto mr-4 p-1 fs-btn2 "
                    onClick={props.onDelete}
                >
                    {"Delete notification" + plural}
                </Button>
            </div>
        </div>
    );
};

export default panel;
