import React from "react";
const images = require.context("../assests/images", true);

/**Component for customized input element for Date picker. Passed as prop to DatePicker
 * @param onInputClick : function handler for click on the icon at right most posn inside input
 * @param value : holds the selected value for input
 * @param onChange : function handler for value change in input
 * @param onBtnClick : function handler for icon clicks
 * @param keyPress : function handler for key press in input
 *
 */
const customInput = props => {
  const {
    value,
    onInputClick,
    onChange,
    onBtnClick,
    keyPress,
    tabIndex,
    placeholder
  } = props;
  const placeholderText = placeholder || (tabIndex === 0
      ? "Search through your scheduled events"
      : "Search for your favorite team or league");
  return (
    <div className="input-group  event-name-filter">
      <input
        type="text"
        className="form-control border-0 bg-white fs-body2"
        value={value}
        onClick={onInputClick}
        onKeyDown={event => keyPress(event)}
        onChange={event => onChange(event)}
        placeholder={placeholderText}
      />
      <div className="input-group-append ">
        <button
          className="btn search-icon-bg bg--grad1 btn-sm border-left-0 "
          type="button"
          onClick={onBtnClick}
          style={{
            display: value ? "" : "none"
          }}
        >
          <img src={images("./img/searchIcon.svg")} alt="close" />
        </button>
        <button
          className="btn bg-white btn-sm border-left-0 "
          type="button"
          onClick={onBtnClick}
          style={{
            display: value ? "none" : "",
            disabled: true
          }}
        >
          <img src={images("./img/searchIconDisabled.svg")} alt="close" />
        </button>
      </div>
    </div>
  );
};

export default customInput;
