import React, { useState } from "react";
const images = require.context("../../../../assests/images/img/events", true);

const PromoItem = ({ active, title, image }) => {
  return (
    <div
      className={`eventlist-promos__wrapper justify-content-center element--clickable ${active &&
        "eventlist-promos__wrapper-active"}`}
    >
      <div className="eventlist-promos__icon bg-white eventlist-promos__icon-offer element--clickable border d-flex justify-content-center align-items-center rounded p-2">
        <img src={image} alt={title} />
        <p className="eventlist-promos__label position-absolute">{title}</p>
      </div>
    </div>
  );
};

const PromosInEvents = () => {
  const [hasPromos, setHasPromos] = useState({
    offer: false,
    drink: false,
    activity: false
  });
  return (
    <div className="eventlist-promos col d-flex align-items-center justify-content-center">
      <PromoItem
        active={hasPromos.offer}
        title="Special Offer"
        image={images("./offer.svg")}
      />
      <PromoItem
        active={hasPromos.drink}
        title="Free Drink"
        image={images("./drink.svg")}
      />
      <PromoItem
        active={hasPromos.activity}
        title="Bar Activity"
        image={images("./activity.svg")}
      />
    </div>
  );
};

export default PromosInEvents;
