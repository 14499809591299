import React from "react";
import RenderCheckboxInEvents from "./eventItem/CheckboxesinEvents";
import RenderCustomEvent from "./eventItem/CustomEvents";
import RenderStandardEvent from "./eventItem/StandardEvents";
import RenderButtonsInEvents from "./eventItem/ButtonsinEvents";

// Functional component to render a event item from props
const Eventitem = props => {
  return (
    <React.Fragment key={props.id}>
      {props.type && (props.type === "custom" || props.type === "reservation") ? (
        <RenderCustomEvent {...props}>
          {props.container.includes("events") ? (
            <RenderCheckboxInEvents {...props} />
          ) : null}
        </RenderCustomEvent>
      ) : (
        <RenderStandardEvent {...props}>
          {props.container.includes("events") ? (
            <RenderCheckboxInEvents {...props} />
          ) : null}
        </RenderStandardEvent>
      )}
      {props.container.includes("events") ? (
        <RenderButtonsInEvents {...props} />
      ) : null}
    </React.Fragment>
  );
};

export default Eventitem;
