import React from "react";
import moment from "moment-timezone";
import { CloseIcon, CalendarStarIcon } from "../utils/svg";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/esm/Button";

function ModalFieldLabel(props) {
    return <div className="modal-field-label">{props.name}</div>;
}

function ModalFieldValue(props) {
    return <div className="modal-field-value">{props.name}</div>;
}

function ModalSubtitle(props) {
    return <div className="modal-subtitle">{props.name}</div>;
}

function ReservationModal(props) {
    const timeFormat = props.time_format === '24H' ? 'HH:mm' : 'h:mm A';
    let subtitle = moment(props.start_time).tz(props.timezone).format("D MMMM YYYY - dddd") +
        '\u00a0\u00a0\u00a0' +
        moment(props.start_time).tz(props.timezone).format(timeFormat);
    if (props.end_time && props.end_time !== props.start_time){
        let sameDay = moment(props.start_time).tz(props.timezone).isSame(moment(props.end_time).tz(props.timezone), 'day');
        subtitle += " to " + moment(props.end_time).tz(props.timezone).format(sameDay ? timeFormat : "D MMMM " + timeFormat);
    }
    return (
        <Modal show={true} onHide={props.cancelModal} size="lg">
            <Modal.Header closeButton>
                <CalendarStarIcon style={{marginRight: "10px"}} />
                <Modal.Title>Reservation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalSubtitle name={subtitle}/>

                <ModalFieldLabel name="For"/>
                <ModalFieldValue name={props.name}/>

                <ModalFieldLabel name="Phone number"/>
                <ModalFieldValue name={props.phone_number}/>

                <ModalFieldLabel name="Email"/>
                <ModalFieldValue name={props.email}/>

                <ModalFieldLabel name="Details"/>
                <ModalFieldValue name={props.description}/>

                <ModalFieldLabel name="Number of guests"/>
                <ModalFieldValue name={props.attendees_count > 0 ? props.attendees_count : null}/>

                <ModalFieldLabel name="Sitting area"/>
                <ModalFieldValue name={props.sitting_area}/>

                <ModalFieldLabel name="Offers"/>
                <ModalFieldValue name={props.offers}/>

                <ModalFieldLabel name="For event"/>
                <ModalFieldValue name={props.reason}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.cancelModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ReservationModal;
