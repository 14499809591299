import React, { Component } from "react";
import PropTypes from "prop-types";

/**Component for customized input element for the selection of time. Passed as prop to DatePicker
 * @param onClick : function handler for click on the icon at right most posn inside input
 * @param value : holds the selected value for input
 * @param onChange : function handler for value change in input
 *
 *
 */
class CustomInputTime extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
    onChange: PropTypes.func
  };
  render() {
    const { value, onClick, onChange } = this.props;
    return (
      <div className="input-group pl-1">
        <div className="input-group-append ">
          <button className="btn--xs ">
            <span>
              <i className="fas fa-clock " />
            </span>
          </button>
        </div>
        <input
          type="text"
          className="form-control "
          value={value}
          onClick={onClick}
          onChange={onChange}
        />
        <div className="input-group-append ">
          <button className="btn--xs" type="button" onClick={onClick}>
            <span>
              <i className="fas fa-caret-down" />
            </span>
          </button>
        </div>
      </div>
    );
  }
}
export default CustomInputTime;
