import React from "react";
import "../../../assests/scss/components/prices_style.css";

const BasicCard = (props) => {
	return (
		<div>
			<section
				className="containerPayment ml-2"
				style={{
					height: "600px",
					width: "320px",
				}}
			>
				<div
					className={props.type === "basic" ? "cardTopBasic" : "cardTopAuto"}
				>
					<div className="banner d-flex flex-column align-items-center">
						<u>
							<h3
								style={{
									textTransform: "capitalize",
									fontWeight: "bold",
								}}
							>
								{props.type}
							</h3>
						</u>
					</div>

					<div className="price-currency">
						<div className="price">
							{(props.interval === "year" ? (props.price / 12) : props.price) +
								" "}
						</div>
						<div className="currency">{props.currency + "/mo"}</div>
					</div>

					<h6 className="monthlySub">
						{"Billed " +
							(props.interval == "year"
								? "at " + props.price + props.currency + "/year"
								: "monthly")}
					</h6>
					<h5 className="discount text--green1">
						{props.discount ? props.discount : " "}
					</h5>
					<div className="triangle"></div>
				</div>

				{props.type === 'basic' ? (
					<>
					<p>&#10004; Live sport database</p>
					<p>&#10004; Copyrighting</p>
					<p>&#10004; Poster design</p>
					<p>&#10004; Web integration</p>
					<p>&#10004; Staf schedule screen</p>
					<p>&#10004; Integrations</p>
					<p>&#10004; Semi-auto social media (5min/week)</p>

					</>
				) : (
					<div className="d-flex flex-column align-items-center text-center">
					<p>&#10004; All benefits from <strong>Basic</strong></p>
					<p>&#10004; Full-auto (no need to think about livesports again)</p>
					<p>&#10004; Other</p>
					<p>&#10004; Other</p>
					</div>
				)}
				<button
					className={
						"button--success fs-btn2" + (props.terms ? "" : " button--disabled")
					}
					onClick={() =>
						!props.terms
							? props.setDangerChekmark(true)
							: props.handleClick(props)
					}
				>
					Checkout
				</button>
			</section>
		</div>
	);
};

export default BasicCard;
