import React, { useEffect, useReducer, useState } from "react";
import { Button } from "react-bootstrap-buttons";
import { Formik, Form, Field } from "formik";
import Select from "react-select";

import * as api from "../../utils/api";
import { validateTextField, renderFieldError } from "../../utils/validation";
import { connect } from "react-redux";
import * as actionCreators from "../../modules/actions";

import axios from "axios";
import CustomAttributeToggle from "../../components/CustomAttributeToggle";
import SettingHeader from "./SettingsHeader";
import ReservationBlockerPopup from "../../components/ReservationBlockerPopup";
import moment from "moment";

const HourSelector = ({ timeFormat, selectedValue, onChange, label, val }) => {
	const [hours, setHours] = useState([]);

	useEffect(() => {
		const generateOptions = () => {
			const hourOptions = [];
			for (let i = 0; i < 24; i++) {
				const label12H = `${(i % 12 || 12).toString().padStart(2, "0")}:00 ${
					i < 12 ? "AM" : "PM"
				}`;
				const label24H = `${i.toString().padStart(2, "0")}:00`;

				hourOptions.push({
					label: timeFormat === "24H" ? label24H : label12H,
					value: i
				});
			}
			return hourOptions;
		};

		setHours(generateOptions());
	}, [timeFormat]);

	const handleHourChange = (selectedOption) => {
		onChange(selectedOption.value);
	};

	return (
		<>
			<label
				className="fs-body2 text--gray8"
				style={{ textTransform: "uppercase" }}
			>
				{label}
			</label>
			<Select
				options={hours}
				placeholder={`Select hour (${timeFormat})`}
				onChange={handleHourChange}
				value={hours.find((hour) => hour.value == selectedValue)}
			/>
			<br />
		</>
	);
};

const reservationDetailsFieldLabel = {
	email: { description: "Reservations Email" },
};

const LabelledField = (props) => {
	const handleTextFieldChange = (value) => {
		props.setter(value);
		return props.title;
	};
	return (
		<div className="form-group">
			<label className="fs-body2 text--gray8">{props.labelName}</label>
			<div className="input-group">
				{props.img && (
					<div className="input-group-prepend">
						<span className="input-group-text ">
							<img src={images(props.img)} alt={props.imageAlt} />
						</span>
					</div>
				)}
				<Field
					name={props.title}
					className={"form-control" + (props.img ? " border-left-0" : "")}
					placeholder={props.placeholder}
					value={props.value || ""}
					validate={validateTextField}
					onChange={(e) =>
						props.setFieldValue(
							handleTextFieldChange(e.target.value),
							e.target.value
						)
					}
				/>
			</div>
			<div>
				{renderFieldError(
					props.touched[props.title],
					props.errors[props.title],
					reservationDetailsFieldLabel[props.title].description,
					props.title
				)}
			</div>
		</div>
	);
};

const RenderReservationDetails = (props) => {
	const [blockerPopup, setBlockerPopup] = useState(false);

	const dateFormat =
		sessionStorage.getItem("time_format") == "24H"
			? "DD-MM-YYYY HH:mm"
			: "DD-MM-YYYY HH:mm A";

	const submitReservationBlockerPopup = (startValue, endValue) => {
		setBlockerPopup(false);
		props.setReservationsBlockStartTime(startValue);
		props.setReservationsBlockEndTime(endValue);
	};

	const cancelReservationBlockerPopup = () => {
		setBlockerPopup(false);
	};

	return (
		<div className="p-2 mt-1 font--size--mm font-color--light-grey">
			<LabelledField
				labelName="TYPE IN THE EMAIL YOU WANT TO GET YOUR RESRVATIONS ON"
				value={props.remail}
				setter={props.setREmail}
				placeholder="Enter Email"
				setFieldValue={props.setFieldValue}
				title="email"
				errors={props.errors}
				touched={props.touched}
			/>

			<CustomAttributeToggle
				text="ONLY ACCEPT RESERVATIONS DURING OPENING HOURS"
				spacing={true}
				textClass="text-lg"
				enabled={props.open_close_hours}
				switchClass="feature-switch"
				onToggle={(value) => props.setOpenCloseHours(value)}
			/>
			<br />

			{props.open_close_hours ? (
				<>
					<div className="d-flex justify-content-between">
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={props.closing_opening_hour.monday_opening_hour}
								onChange={(value) => props.setOH("MONDAY", value)}
								val="closing_opening_hour.monday_opening_hour"
								label="Select opening hour on mondays"
							/>
						</div>
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={props.closing_opening_hour.monday_closing_hour}
								onChange={(value) => props.setCH("MONDAY", value)}
								val="closing_opening_hour.monday_closing_hour"
								label="Select closing hour on mondays"
							/>
						</div>
					</div>
					<div className="d-flex justify-content-between">
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={props.closing_opening_hour.tuesday_opening_hour}
								onChange={(value) => props.setOH("TUESDAY", value)}
								val="closing_opening_hour.tuesday_opening_hour"
								label="Select opening hour on tuesdays"
							/>
						</div>
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={props.closing_opening_hour.tuesday_closing_hour}
								onChange={(value) => {
									props.setCH("TUESDAY", value);
								}}
								val="closing_opening_hour.tuesday_closing_hour"
								label="Select closing hour on tuesdays"
							/>
						</div>
					</div>
					<div className="d-flex justify-content-between">
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={
									props.closing_opening_hour.wednesday_opening_hour
								}
								onChange={(value) => props.setOH("WEDNESDAY", value)}
								val="closing_opening_hour.wednesday_opening_hour"
								label="Select opening hour on wednesdays"
							/>
						</div>
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={
									props.closing_opening_hour.wednesday_closing_hour
								}
								onChange={(value) => props.setCH("WEDNESDAY", value)}
								val="closing_opening_hour.wednesday_closing_hour"
								label="Select closing hour on wednesdays"
							/>
						</div>
					</div>
					<div className="d-flex justify-content-between">
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={props.closing_opening_hour.thursday_opening_hour}
								onChange={(value) => props.setOH("THURSDAY", value)}
								val="closing_opening_hour.thursday_opening_hour"
								label="Select opening hour on thursdays"
							/>
						</div>
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={props.closing_opening_hour.thursday_closing_hour}
								onChange={(value) => props.setCH("THURSDAY", value)}
								val="closing_opening_hour.thursday_closing_hour"
								label="Select closing hour on thursdays"
							/>
						</div>
					</div>
					<div className="d-flex justify-content-between">
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={props.closing_opening_hour.friday_opening_hour}
								onChange={(value) => props.setOH("FRIDAY", value)}
								val="closing_opening_hour.friday_opening_hour"
								label="Select opening hour on fridays"
							/>
						</div>
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={props.closing_opening_hour.friday_closing_hour}
								onChange={(value) => props.setCH("FRIDAY", value)}
								val="closing_opening_hour.friday_closing_hour"
								label="Select closing hour on fridays"
							/>
						</div>
					</div>
					<div className="d-flex justify-content-between">
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={props.closing_opening_hour.saturday_opening_hour}
								onChange={(value) => props.setOH("SATURDAY", value)}
								val="closing_opening_hour.saturday_opening_hour"
								label="Select opening hour on saturdays"
							/>
						</div>
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={props.closing_opening_hour.saturday_closing_hour}
								onChange={(value) => props.setCH("SATURDAY", value)}
								val="closing_opening_hour.saturday_closing_hour"
								label="Select closing hour on saturdays"
							/>
						</div>
					</div>
					<div className="d-flex justify-content-between">
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={props.closing_opening_hour.sunday_opening_hour}
								onChange={(value) => props.setOH("SUNDAY", value)}
								val="closing_opening_hour.sunday_opening_hour"
								label="Select opening hour on sundays"
							/>
						</div>
						<div style={{ width: "30%" }}>
							<HourSelector
								timeFormat={sessionStorage.getItem("time_format")}
								selectedValue={props.closing_opening_hour.sunday_closing_hour}
								onChange={(value) => props.setCH("SUNDAY", value)}
								val="closing_opening_hour.sunday_closing_hour"
								label="Select closing hour on sundays"
							/>
						</div>
					</div>
				</>
			) : null}

			<CustomAttributeToggle
				text="PRE-APPROVE RESERVATIONS?"
				textClass="text-lg"
				spacing={true}
				enabled={props.approve_reservations}
				switchClass="feature-switch"
				onToggle={(value) => props.setApproveReservations(value)}
			/>
			<br />

			<CustomAttributeToggle
				text="BLOCK INCOMING RESERVATIONS"
				spacing={true}
				enabled={props.blocked_start_time || props.blocked_end_time !== null}
				switchClass="feature-switch"
				onToggle={(value) => {
					value ? setBlockerPopup(true) : null;
					!value
						? (props.setReservationsBlockEndTime(null),
						  props.setReservationsBlockStartTime(null))
						: null;
				}}
			/>

			{props.blocked_start_time ||
			(props.blocked_end_time &&
				new Date(props.blocked_end_time) <
					new Date(new Date().setFullYear(new Date().getFullYear() + 1))) ? (
				<div className="mt-3">
					{!props.blocked_start_time && props.blocked_end_time ? (
						<>
							<h5
								className="feature-name text--gray8"
								style={{ textTransform: "uppercase" }}
							>
								Your reservations are blocked until:
							</h5>
							<br />
							<h6>{moment(props.blocked_end_time).format("DD-MM-YYYY")}</h6>
						</>
					) : (
						<>
							<h5
								className="feature-name text--gray8"
								style={{ textTransform: "uppercase" }}
							>
								Your reservations are blocked between:
							</h5>
							<br />

							<h6>
								{moment(props.blocked_start_time).format(dateFormat)}&nbsp; -
								&nbsp;{moment(props.blocked_end_time).format(dateFormat)}
							</h6>
						</>
					)}
					<Button
						className="button--success no-border-buttonbutton"
						onClick={() => setBlockerPopup(true)} // Use a function to update the state
					>
						Edit
					</Button>
				</div>
			) : null}

			{blockerPopup === true ? (
				<ReservationBlockerPopup
					cancelModal={cancelReservationBlockerPopup}
					submitModal={submitReservationBlockerPopup}
					blocked_start_time={props.blocked_start_time}
					blocked_end_time={props.blocked_end_time}
					setReservationsBlockStartTime={props.setReservationsBlockStartTime}
					setReservationsBlockEndTime={props.setReservationsBlockEndTime}
				/>
			) : null}
		</div>
	);
};
const ReservationsDetails = (props) => {
	const language = props.language
		? props.language
		: sessionStorage.getItem("language") === "null"
		? ""
		: sessionStorage.getItem("language");
	const timeFormat =
		sessionStorage.getItem("time_format") === "null"
			? ""
			: sessionStorage.getItem("time_format");
	const timezone =
		sessionStorage.getItem("timezone") === "null"
			? null
			: sessionStorage.getItem("timezone");

	const cancelChangesMade = (props) => {
		// TODO to be fixed to work with dispatch
		// dispatch({ type: "RESET" });
		window.location.reload();
	};

	const updateReservationDetails = () => {
		const locationData = {
			email: reservationDetail.email,
			open_close_hours: reservationDetail.open_close_hours,
			approve_reservations: reservationDetail.approve_reservations,
			closing_opening_hour_attributes: reservationDetail.closing_opening_hour,
			blocked_start_time: reservationDetail.blocked_start_time,
			blocked_end_time: reservationDetail.blocked_end_time,
			language:
				reservationDetail.language !== undefined
					? reservationDetail.language
					: sessionStorage.getItem("language"),
			time_format:
				reservationDetail.time_format !== undefined
					? reservationDetail.time_format
					: sessionStorage.getItem("time_format"),
			timezone:
				reservationDetail.timezone !== undefined
					? reservationDetail.timezone
					: sessionStorage.getItem("timezone"),
		};

		if (reservationDetail.locationId) {
			api.endpoints.updateLocation(
				reservationDetail.locationId,
				locationData,
				null,
				handleApiResponse
			);
		} else {
			api.endpoints.createLocation(locationData, null, handleApiResponse);
		}
	};

	const handleApiResponse = (res, err) => {
		if (res) {
			dispatch({ type: "UPDATE", payload: res });
			props.showNotice("Settings updated", true);
		} else if (err) {
			props.showNotice(err, false);
		}
	};

	const reservationDetailsReducer = (state, action) => {
		const daysOfWeek = [
			"MONDAY",
			"TUESDAY",
			"WEDNESDAY",
			"THURSDAY",
			"FRIDAY",
			"SATURDAY",
			"SUNDAY",
		];

		const initialState = {
			email: null,
			open_close_hours: null,
			approve_reservations: null,
			blocked_start_time: null,
			blocked_end_time: null,
			locationId: null,
			closing_opening_hour: {},
			language: null,
			time_format: null,
			timezone: null,
		};

		daysOfWeek.forEach((day) => {
			initialState.closing_opening_hour[`${day.toLowerCase()}_opening_hours`] =
				null;
			initialState.closing_opening_hour[`${day.toLowerCase()}_closing_hours`] =
				null;
		});

		switch (action.type) {
			case "INIT":
			case "UPDATE":
				let obj = action.payload ? action.payload : {};
				const originalValues = {
					email: obj.email,
					open_close_hours: obj.open_close_hours,
					approve_reservations: obj.approve_reservations,
					blocked_start_time: obj.blocked_start_time,
					blocked_end_time: obj.blocked_end_time,
					locationId: obj.id,
					closing_opening_hour: obj.closing_opening_hour || {},
					language: obj.language,
					time_format: obj.time_format,
					timezone: obj.timezone,
				};

				return {
					...state,
					...originalValues,
					originalValues: originalValues,
				};

			case "RESET":
				return {
					...state,
					...state.originalValues,
				};

			case "UPD_EMAIL":
				return { ...state, email: action.payload };
			case "UPD_OPEN_CLOSE_HOURS":
				return { ...state, open_close_hours: action.payload };

			case "UPD_APPROVE_RESERVATIONS":
				return { ...state, approve_reservations: action.payload };
			case "UPD_BLOCKED_START_TIME":
				return { ...state, blocked_start_time: action.payload };
			case "UPD_BLOCKED_END_TIME":
				return { ...state, blocked_end_time: action.payload };
			case "UPD_DAY_OH":
				const { dayz, openingHours } = action.payload;
				return {
					...state,
					closing_opening_hour: {
						...state.closing_opening_hour,
						[`${dayz.toLowerCase()}_opening_hour`]: openingHours,
					},
				};
			case "UPD_DAY_CH":
				const { day, closingHours } = action.payload;
				return {
					...state,
					closing_opening_hour: {
						...state.closing_opening_hour,
						[`${day.toLowerCase()}_closing_hour`]: closingHours,
					},
				};

			default:
				return { ...state };
		}
	};

	const [reservationDetail, dispatch] = useReducer(reservationDetailsReducer, {
		email: "",
		approve_reservations: "",
		open_close_hours: "",
		blocked_start_time: "",
		blocked_end_time: "",
		locationId: "",
		closing_opening_hour: {
			monday_opening_hour: "",
			monday_closing_hour: "",
			tuesday_opening_hour: "",
			tuesday_closing_hour: "",
			wednesday_opening_hour: "",
			wednesday_closing_hour: "",
			thursday_opening_hour: "",
			thursday_closing_hour: "",
			friday_opening_hour: "",
			friday_closing_hour: "",
			saturday_opening_hour: "",
			saturday_closing_hour: "",
			sunday_opening_hour: "",
			sunday_closing_hour: "",
		},
		time_format: "",
		timezone: "",
		language: "",
	});

	useEffect(() => {
		async function getLocations() {
			let res = await axios.get("/locations");
			dispatch({
				type: "INIT",
				payload: res.data.locations ? res.data.locations[0] : null,
			});
		}
		getLocations();
	}, [props.client_id]);

	const setEmail = (val) => {
		dispatch({ type: "UPD_EMAIL", payload: val });
	};
	// W-week D-day/E-end O-opening/C-closing H-hour
	const setOH = (day, val) => {
		dispatch({ type: "UPD_DAY_OH", payload: { dayz: day, openingHours: val } });
	};
	const setCH = (day, val) => {
		dispatch({ type: "UPD_DAY_CH", payload: { day: day, closingHours: val } });
	};
	const setOpenCloseHours = (val) => {
		dispatch({ type: "UPD_OPEN_CLOSE_HOURS", payload: val });
	};
	const setApproveReservations = (val) => {
		dispatch({ type: "UPD_APPROVE_RESERVATIONS", payload: val });
	};
	const setReservationsBlockStartTime = (val) => {
		dispatch({ type: "UPD_BLOCKED_START_TIME", payload: val });
	};
	const setReservationsBlockEndTime = (val) => {
		dispatch({ type: "UPD_BLOCKED_END_TIME", payload: val });
	};

	const getInitialValue = (elem) => {
		return elem === undefined || "" ? "" : elem;
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				email: getInitialValue(reservationDetail.email),
				closing_opening_hour: getInitialValue(
					reservationDetail.closing_opening_hour
				),
				open_close_hours: getInitialValue(reservationDetail.open_close_hours),
				approve_reservations: getInitialValue(
					reservationDetail.approve_reservations
				),
				blocked_start_time: getInitialValue(
					reservationDetail.blocked_start_time
				),
				blocked_end_time: getInitialValue(reservationDetail.blocked_end_time),
				time_format: timeFormat,
				timezone: timezone,
				language: language,
			}}
			onSubmit={() => {
				updateReservationDetails();
			}}
		>
			{({ errors, touched, setFieldValue }) => (
				<Form className="d-flex bg-secondary w-75 h-100 ml-5 overflow-auto">
					<div className="w-75 mr-5 ml-2 bg-secondary">
						<SettingHeader
							image="./user-company.svg"
							imageAlt="User Reservatio Image"
							title="Your Reservation Settings"
						/>
						<RenderReservationDetails
							remail={reservationDetail.email}
							closing_opening_hour={reservationDetail.closing_opening_hour}
							open_close_hours={reservationDetail.open_close_hours}
							approve_reservations={reservationDetail.approve_reservations}
							blocked_start_time={reservationDetail.blocked_start_time}
							blocked_end_time={reservationDetail.blocked_end_time}
							setREmail={setEmail}
							setOH={setOH}
							setCH={setCH}
							setOpenCloseHours={setOpenCloseHours}
							setApproveReservations={setApproveReservations}
							setReservationsBlockStartTime={setReservationsBlockStartTime}
							setReservationsBlockEndTime={setReservationsBlockEndTime}
							setFieldValue={setFieldValue}
							errors={errors}
							touched={touched}
						/>
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
					</div>

					<div className="update-reservations-info-div">
						<Button type="submit" className="button--success no-border-button">
							Update Details
						</Button>
						<Button
							className="button--warning ml-5 no-border-button"
							onClick={() => cancelChangesMade()}
						>
							Cancel
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

const mapStateToProps = (state) => {
	return {
		client_id: state.client_id,
		language: state.language,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showNotice: (message, success) =>
			dispatch(actionCreators.showNotice(message, success)),
		setUserTimeZone: (timezone) =>
			dispatch(actionCreators.setUserTimeZone(timezone)),
		setUserTimeFormat: (time_format) =>
			dispatch(actionCreators.setUserTimeFormat(time_format)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReservationsDetails);
