import React, { useState, useEffect } from "react";
import * as api from "../../utils/api";
import * as actionCreators from "../../modules/actions";
import NotificationItem from "./NotificationItem";
import { groupItemsByDay } from "../../utils/utils";
import { connect } from "react-redux";
import { event } from "jquery";


const NOTIFICATIONS_FILTER = {
    ALL: 'ALL',
    UNSEEN: 'UNSEEN'
  };

const notificationItems = props => {
    const [filter, setFilter] = React.useState(NOTIFICATIONS_FILTER.ALL);  
    const [selNotificationIds, setSelNotificationIds] = useState([]);
    
    /**Returns JSX for rendering an item  */
    const [isActive, setActive] = useState(null);

    const setSeenStatus = (id) => {
		api.endpoints.getNotificationById(
			id,
			(res) => "success",
			(err) => "err"
		);
	};
    const notificationRow = item => {
        const itemID = item.id
        
        const toggle = (itemID) => {
            reloadNotifications();
            setActive(itemID);
            setSeenStatus(item.id);
            if(isActive === itemID){
                setActive(null);
                const slideInSecondLevel = document.querySelector('.slide-in-content');
            }
        };
        
        return(
            <div key={item.id} id={item.id} onClick={() => toggle(item.id)} className={`notification-element ${filter === NOTIFICATIONS_FILTER.UNSEEN && item.seen ? 'hide' : ''} ${isActive === itemID ? 'active': ''}  ${item.seen ? 'seen' : 'not-seen'} ${item.general_error ? 'genral-error' : ''}`}>
                <NotificationItem {...item} {...props} itemID={isActive} />
            </div>
        )
    }
    
    const itemsGroupedByDay = groupItemsByDay(props.data, 'created_at', props.timezone);

    const toggleFilter = (event) => {
        if (event.preventDefault) event.preventDefault();

        const target = event.currentTarget || event.target;
        const id = target?.dataset?.id;

        if (id) {
            setFilter(id);
        }
        // props.refreshMeetups(true);
	};
    const reloadNotifications = () => {
		setSelNotificationIds([]);
		props.getNotifications();
        
	};
    return (
        <>  
        <div className="read-status-filter-c" >
            <div onClick={toggleFilter} className={`read-status-filter-btn ${filter === NOTIFICATIONS_FILTER.ALL ? 'active-filter': ""}`} data-id={NOTIFICATIONS_FILTER.ALL}>All</div>
            <div onClick={toggleFilter} className={`read-status-filter-btn ${filter === NOTIFICATIONS_FILTER.UNSEEN ? 'active-filter': ""}`}  data-id={NOTIFICATIONS_FILTER.UNSEEN}>Unread</div>
        </div>
            <div className="tb-max-not-used reservations-not-used notifications-list " >

                {/* Groups */}
                {Object.keys(itemsGroupedByDay).map((group, index) => {
                    const hasUnseenItems = !!itemsGroupedByDay[group].find(item => !item.seen);
                    const showGroupTitle = filter === NOTIFICATIONS_FILTER.ALL || (filter === NOTIFICATIONS_FILTER.UNSEEN && hasUnseenItems) ? true : false;
                    
                    return (<React.Fragment key={index}>
                        {group && showGroupTitle && <div className="title row" style={{top: 50}}>
                            <div>{group}</div>
                        </div>}
                        
                        {itemsGroupedByDay[group].map(item => notificationRow(item))}
                        </React.Fragment>);
                    })
                }
                
            </div>
            <div className="banner" />
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
	return {
		getNotifications: () => dispatch(actionCreators.getNotifications()),
	};
};
export default connect(null, mapDispatchToProps)(notificationItems);
// export default notificationItems;
