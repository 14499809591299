import React from "react";
import ReservationItem from "./ReservationItem";
import { groupItemsByDay } from "../../utils/utils";

const reservationItems = props => {
    /**Returns JSX for rendering "Select all" checkbox */
    const renderSelectAllCheckBox = () => (
        <div className="d-flex align-items-center">
            <div className="checkbox-wrapper d-flex align-items-center d-block float-left">
                <input
                    type="checkbox"
                    name="select all checkbox"
                    onChange={props.selectAllHandler}
                    checked={props.allSelected}
                />
                <span className="checkmark"/>
            </div>
            <span className="text-left text--gray7 fs-body3 m-0 w-25 d-block float-left">SELECT ALL</span>
        </div>
    );

    /**Returns JSX for rendering an item  */
    const reservationRow = item => {     
        if(item.validation === 'Rejected'){
            return (
                //red
                <div key={item.id} className="tb-row" style={{backgroundColor: "rgba(255, 197, 200, 255)"}}>
                    <ReservationItem {...item} {...props}/>
                </div>
            )
        }
        if(item.validation === 'Approved'){

            if(item.guest_canceled === true){
                return (
                    //red
                    <div key={item.id} className="tb-row" style={{backgroundColor: "rgba(255, 197, 200, 255)"}}>
                        <ReservationItem {...item} {...props}/>
                    </div>
                )
            }
            if(item.guest_confirmed === true) {
                return(
                    //green
                    <div key={item.id} className="tb-row" style={{backgroundColor: "rgba(184, 255, 200, 255)"}}>
                        <ReservationItem {...item} {...props}/>
                    </div>
                )
            }
            if(item.guest_canceled === false && item.guest_confirmed === false){
                return(
                    //transparent
                    <div key={item.id} className="tb-row">
                        <ReservationItem {...item} {...props}/>
                    </div>
                )
            }
            } else {
                return(
                    //blue
                    <div key={item.id} className="tb-row" style={{backgroundColor: "rgba(177, 215, 255, 255)"}}>
                        <ReservationItem {...item} {...props}/>
                    </div>)
            }
        }


    const itemsGroupedByDay = groupItemsByDay(props.data, 'start_time', props.timezone);

    return (
        <>
            <div className="tb-max reservations">
                {/* Header row: */}
                <div className="tb-row tb-head">
                    <div className="col-4">{renderSelectAllCheckBox()}</div>
                    <div className="col-1">TIME</div>
                    <div className="col-1">PHONE NUMBER</div>
                    <div className="col-1">EMAIL</div>
                    <div className="col-1">GUESTS</div>
                    <div className="col-2">SITTING AREA</div>
                    <div className="col-1">OFFERS</div>
                    <div className="col-1">EVENT</div>
                    <div className="col-1"></div>
                    <div className="col-1">ACTIONS</div>
                </div>
                {/* Groups */}
                {Object.keys(itemsGroupedByDay).map(group => (
                    <div className="tb-grp" key={group}>
                        <div className="title tb-row">
                            <div>{group}</div><div/><div/><div/><div/><div/><div/>
                        </div>
                        {itemsGroupedByDay[group].map(item => reservationRow(item))}
                    </div>
                ))}
            </div>
            <div className="banner" />
        </>
    );
};

export default reservationItems;
