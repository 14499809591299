import React, { Component } from "react";
import CustomInputText from "../../components/CustomInput";
import moment from "moment-timezone";
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import CustomInputDate from "../../components/CustomInputDate";
import * as actionCreators from "../../modules/actions";
import { connect } from "react-redux";
import { Filters, SportsFilters } from "../../utils/constants";
import SportsFilterItem from "./SportsFilterItem";
import CustomSwitch from "../../components/CustomSwitch";
import { dtzWithLocalZone, dtLocalToDtz } from "../../utils/utils";
import Dropdown from 'react-bootstrap/Dropdown';
import style from './style.css'

/**Register the locale to display calendar in UK format, week starting with Monday */
registerLocale("en-GB", enGB);
const images = require.context("../../assests/images", true);

class Eventpanel extends Component {
  constructor(props) {
    super(props);
    let defaultStartDate = moment().tz(props.timezone).startOf('day').toDate();
    this.state = {
      tab1Panel: {
        _filters: [],
        sportFilters: [],
        tagFilters: [],
        teamFilters: [],
        competitionFilters: [],
        searchText: "",
        fromDate: defaultStartDate,
        toDate: this.props.defaultEndDate,
        filterTypes: Object.values(Filters),
        onlyPrefferedStations: false,
        visibleIcons: [],
        moreIcons: [],
      },
      tab2Panel: {
        _filters: [],
        sportFilters: [],
        teamFilters: [],
        competitionFilters: [],
        tagFilters: [],
        searchText: "",
        fromDate: defaultStartDate,
        toDate: this.props.defaultEndDate,
        filterTypes: Object.values(SportsFilters),
        onlyPrefferedStations: false
      }
    };
  }

  componentDidMount() {
    const { _meetupsFilters, _fixturesFilters, _favoriteTeams, _favoriteCompetitions } = this.props;
    if (Object.keys(_meetupsFilters).length !== 0) {
      let tab1 = this.loadTabPanelFromProps(
        { ...this.state.tab1Panel },
        _meetupsFilters
      );
      this.setState({ tab1Panel: tab1 });
    }

    if (Object.keys(_fixturesFilters).length !== 0) {
      let tab2 = this.loadTabPanelFromProps(
        { ...this.state.tab2Panel },
        _fixturesFilters,
        _favoriteTeams,
        _favoriteCompetitions
      );
      this.setState({ tab2Panel: tab2 });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateSportFilters);
    this.updateSportFilters();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSportFilters);
  }

  updateSportFilters = () => {
    const panelObj = this.getCurrentPanel();
    const filterTypes = panelObj.filterTypes;
    const containerWidth = document.querySelector('.sport-filters').offsetWidth;
    const iconWidth = 100;
    const maxVisibleItems = Math.floor(containerWidth / iconWidth);
    
    const visibleIcons = filterTypes.slice(0, maxVisibleItems);
    const moreIcons = filterTypes.slice(maxVisibleItems);

    this.setState({ visibleIcons, moreIcons });
  };

  loadTabPanelFromProps = (tabPanel, _filters) => {
    tabPanel.sportFilters = _filters.sports;
    tabPanel.tagFilters = _filters.tags;
    tabPanel.fromDate = _filters.start_date;
    tabPanel.toDate = _filters.end_date;
    tabPanel.teamFilters = _filters.fav_teams;
    tabPanel.competitionFilters = _filters.fav_competitions;
    tabPanel.onlyPrefferedStations = _filters.only_fav_stations;

    return tabPanel;
  };

  componentWillReceiveProps(nextprops) {
    if (Object.keys(nextprops._meetupsFilters).length !== 0) {
      let tab1 = this.loadTabPanelFromProps(
        { ...this.state.tab1Panel },
        nextprops._meetupsFilters
      );
      this.setState({ tab1Panel: tab1 });
    }
    if (Object.keys(nextprops._fixturesFilters).length !== 0) {
      var favTeamTags = [];
      if(this.teamsFiltersStatus())  {
        nextprops._favoriteTeams.filter(favTeam => favTeamTags.push(favTeam.team_name))
      }
      var favCompetitionTags = [];
      if(this.competitionsFiltersStatus())  {
        nextprops._favoriteCompetitions.filter(favCompetition => favCompetitionTags.push(favCompetition.competition_name))
      }
      let tab2 = this.loadTabPanelFromProps(
        { ...this.state.tab2Panel, teamFilters: favTeamTags, competitionFilters: favCompetitionTags },
        nextprops._fixturesFilters
      );
      this.setState({ tab2Panel: tab2 });
    }
  }

  filterEvents = filterObj => {
    let panelObj = this.getCurrentPanel();
    let filterData = {
      sports: panelObj.sportFilters.slice(),
      tags: panelObj.tagFilters.slice(),
      start_date: panelObj.fromDate,
      end_date: panelObj.toDate,
      offset: 0,
      only_fav_stations: panelObj.onlyPrefferedStations,
      fav_teams: panelObj.teamFilters.slice(),
      fav_competitions: panelObj.competitionFilters.slice(),
    };
    let mergeFilterData = { ...filterData, ...filterObj };
    this.props.filterEvents(mergeFilterData);
  };

  toggleSportFiltersSelection = item => {
    let panelObj = this.getCurrentPanel();
    let _filters = panelObj.sportFilters.slice();
    if (!panelObj.sportFilters.includes(item)) {
      _filters = this.arrayAdd(_filters, item);
    } else {
      _filters = this.arrayRemove(_filters, item);
    }
    this.filterEvents({ sports: _filters });
  };

  sportsFiltersStatus = () => {
    let panelObj = this.getCurrentPanel();
    if(panelObj.sportFilters.length != 0) {
      let _filters = panelObj.sportFilters.slice();
      var favSports = [];  
      this.props._favoriteSports.filter(favSport => favSports.push(favSport.sport_short_name) );  
      const enable = favSports.every(value => {        
        return _filters.includes(value) && _filters.length == favSports.length;
      });

      if (favSports.length == 0) {
        return false;
      } else {
        return enable;
      }
    }
  };

  teamsFiltersStatus = () => {
    let panelObj = this.getCurrentPanel();
    if(panelObj.teamFilters.length != 0) {
      let _filters = panelObj.teamFilters ? panelObj.teamFilters.slice() : null; 
      var favTeams = [];    
      this.props._favoriteTeams.filter(favTeam => favTeams.push(favTeam.team_name) );  
      const enable = favTeams.every(value => {
        return _filters.includes(value);
      });
      return enable;
    }   
  };

  competitionsFiltersStatus = () => {
    let panelObj = this.getCurrentPanel();
    if(panelObj.competitionFilters.length != 0) {
      let _filters = panelObj.competitionFilters.slice() ; 
      var favCompetitions = [];    
      this.props._favoriteCompetitions.filter(favCompetition => favCompetitions.push(favCompetition.competition_name) );  
      const enable = favCompetitions.every(value => {
        return _filters.includes(value);
      });
      return enable;
    }     
  };

  arrayRemove = (arr, value) => {
    return arr.filter(ele => ele !== value);
  };

  arrayAdd = (arr, value) => {
    if (!arr.includes(value)) {
      arr.push(value);
    }
    return arr;
  };

  onSearchInput = event => {
    const txtVal = event.target.value;
    this.setState(prevState => {
      const tab =
        this.props.tabIndex === 0
          ? { ...prevState.tab1Panel }
          : { ...prevState.tab2Panel };
      tab.searchText = txtVal;
      return this.props.tabIndex === 0
        ? { tab1Panel: tab }
        : { tab2Panel: tab };
    });
  };

  onSearchKeyDown = event => {
    if (event.keyCode === 13) {
      this.onSearchClick();
    }
  };

  onSearchClick = () => {
    let panelObj = this.getCurrentPanel();
    if (panelObj.searchText) {
      const inText = panelObj.searchText.slice();
      const _filters = panelObj.tagFilters.slice();
      panelObj.tagFilters = this.arrayAdd(_filters, inText);
      panelObj.searchText = "";
      this.setState(prevState => {
        const tab =
          this.props.tabIndex === 0
            ? { ...prevState.tab1Panel }
            : { ...prevState.tab2Panel };
        tab.searchText = "";
        return this.props.tabIndex === 0
          ? { tab1Panel: tab }
          : { tab2Panel: tab };
      });
      this.filterEvents({ tags: panelObj.tagFilters });
    }
  };

  removeFilter = item => {
    let panelObj = this.getCurrentPanel();
    const _filters = panelObj.tagFilters.slice();
    if (_filters.includes(item)) {
      panelObj.tagFilters = this.arrayRemove(_filters, item);
      this.filterEvents({ tags: panelObj.tagFilters });
    }
  };

  removeTeamFilter = item => {
    let panelObj = this.getCurrentPanel();
    const _filters = panelObj.teamFilters.slice();
    if (_filters.includes(item)) {
      panelObj.teamFilters = this.arrayRemove(_filters, item);
      this.filterEvents({ fav_teams: panelObj.teamFilters });
    }
  };

  removeCompetitionFilter = item => {
    let panelObj = this.getCurrentPanel();
    const _filters2 = panelObj.competitionFilters.slice();
    if (_filters2.includes(item)) {
      panelObj.competitionFilters = this.arrayRemove(_filters2, item);
      this.filterEvents({ fav_competitions: panelObj.competitionFilters });
    } 
  };

  resetTabsFilters = () => {
    const defaultStartDate = moment().tz(this.props.timezone).startOf("day").toDate();
    const defaultEndDate = moment().tz(this.props.timezone).add(7, "days").endOf("day").toDate();
    this.filterEvents({
      sports: [],
      tags: [],
      start_date: defaultStartDate,
      end_date: defaultEndDate,
      only_fav_stations: false,
      fav_sports: [],
      fav_teams: [],
      fav_competitions: [],
    });
  };

  renderUserFilters = () =>
    this.getCurrentPanel().tagFilters.map((item, index) => (
      <button
        type="button"
        className="btn btn-info btn-sm m-1 bg--grad4 fs-btn2"
        onClick={() => this.removeFilter(item)}
        key={"_usrFltr" + index}
      >
        <span className="mr-2 text--white">{item}</span>
        <span>
          <img src={images("./img/closeIcon.svg")} alt="close" />
        </span>
      </button>
    ));

  renderTeamsFilters = () =>
  this.getCurrentPanel().teamFilters.map((item, index) => (
        <button
          type="button"
          className="btn btn-info btn-sm m-1  bg--blue2 fs-btn2"
          onClick={() => this.removeTeamFilter(item)}
          key={"_usrFltr" + index}
        >
          <span className="mr-2 text--white">{item}</span>
          <span>
            <img src={images("./img/closeIcon.svg")} alt="close" />
          </span>
        </button>
    ));

  renderCompetitionsFilters = () =>
    this.getCurrentPanel().competitionFilters.map((item, index) => (
      <button
        type="button"
        className="btn btn-sm m-1  bg--blue1 fs-btn2"
        onClick={() => this.removeCompetitionFilter(item)}
        key={"_usrFltr" + index}
      >
        <span className="mr-2 text--white">{item}</span>
        <span>
          <img src={images("./img/closeIcon.svg")} alt="close" />
        </span>
      </button>
    ));

  handleFromDateSelect = date => {
    if(date !== null){
      let panelObj = this.getCurrentPanel();
      panelObj.fromDate = dtLocalToDtz(date, this.props.timezone).toDate();

      this.filterEvents({ start_date: panelObj.fromDate });
    }
  };

  handleToDateSelect = date => {
    if(date !== null){
      let panelObj = this.getCurrentPanel();
      panelObj.toDate = dtLocalToDtz(date, this.props.timezone).toDate();

      this.filterEvents({ end_date: panelObj.toDate });
    }
  };

  handleOnlyPrefferedStations = bool => {
    this.filterEvents({ only_fav_stations: bool });
  }

  handleOnlyPrefferedSports = bool => {
    var favSports = [];    
    this.props._favoriteSports.filter(favSport => favSports.push(favSport.sport_short_name) );  
    if(!bool) {
      var selectedSports = this.state.tab2Panel.sportFilters;
      favSports.map(item => (
        selectedSports = this.arrayRemove(selectedSports, item)
      ));
      this.filterEvents({ 
        sports: selectedSports, 
      });
      let newTab2Panel = this.state.tab2Panel;
      const _filters = newTab2Panel.sportFilters.slice();
      if (_filters) {
        newTab2Panel.sportFilters = selectedSports;
        this.setState(prevstate => {
          const prevState = prevstate;
          prevState.tab2Panel = newTab2Panel;
          return ({
            tab2Panel: { 
              ...prevState.tab2Panel,
            }
          })
        });
      }      
    } else {
      var selectedSports = this.state.tab2Panel.sportFilters;
      favSports.map(item => (
        selectedSports = this.arrayAdd(selectedSports, item)
      ));
      this.filterEvents({ 
        sports: selectedSports, 
      });
    }    
  }

  handleOnlyPrefferedTeams = bool => {
    var favTeams = [];    
    this.props._favoriteTeams.filter(favTeam => favTeams.push(favTeam.team_name) );
    if(!bool) {
      var selectedTeams = this.state.tab2Panel.teamFilters;
      favTeams.map(item => (
        selectedTeams = this.arrayRemove(selectedTeams, item)
      ));
      this.filterEvents({ 
        fav_teams: selectedTeams, 
      });
      let newTab2Panel = this.state.tab2Panel;
      const _filters = newTab2Panel.teamFilters.slice();
      if (_filters) {
        newTab2Panel.teamFilters = selectedTeams;
        this.setState(prevstate => {
          const prevState = prevstate;
          prevState.tab2Panel = newTab2Panel;
          return ({
            tab2Panel: { 
              ...prevState.tab2Panel,
            }
          })
        });
      }      
    } else {
      var selectedTeams = this.state.tab2Panel.teamFilters;
      favTeams.map(item => (
        selectedTeams = this.arrayAdd(selectedTeams, item)
      ));
      this.filterEvents({ 
        fav_teams: selectedTeams, 
      });
    }    
    this.filterEvents({ fav_teams: this.state.tab2Panel.teamFilters });
  }

  handleOnlyPrefferedCompetitions = bool => {
    var favCompetitions = [];    
    this.props._favoriteCompetitions.filter(favCompetition => favCompetitions.push(favCompetition.competition_name) );  
    if(!bool) {
      var selectedCompetitions = this.state.tab2Panel.competitionFilters;
      favCompetitions.map(item => (
        selectedCompetitions = this.arrayRemove(selectedCompetitions, item)
      ));
      this.filterEvents({ 
        fav_competitions: selectedCompetitions, 
      });
      let newTab2Panel = this.state.tab2Panel;
      const _filters = newTab2Panel.competitionFilters.slice();
      if (_filters) {
        newTab2Panel.competitionFilters = selectedCompetitions;
        this.setState(prevstate => {
          const prevState = prevstate;
          prevState.tab2Panel = newTab2Panel;
          return ({
            tab2Panel: { 
              ...prevState.tab2Panel,
            }
          })
        });
      }      
    } else {
      var selectedCompetitions = this.state.tab2Panel.competitionFilters;
      favCompetitions.map(item => (
        selectedCompetitions = this.arrayAdd(selectedCompetitions, item)
      ));
      this.filterEvents({ 
        fav_competitions: selectedCompetitions, 
      });
    }    
    this.filterEvents({ fav_competitions: this.state.tab2Panel.competitionFilters });
  }

  getCurrentPanel = () =>
    this.props.tabIndex
      ? { ...this.state.tab2Panel }
      : { ...this.state.tab1Panel };

  render() {
    const { visibleIcons, moreIcons } = this.state;

    return (
      <div className={this.props.className}>
        <div className="d-flex justify-content-between w-100 sport-filters">
          {visibleIcons?.map((item) => (
            <SportsFilterItem
              key={`_evtfltr_${item}`}
              item={item}
              selected={this.getCurrentPanel().sportFilters.includes(item)}
              handleClick={this.toggleSportFiltersSelection}
            />
          ))}
          {moreIcons?.length > 0 && (
            <Dropdown>
              <Dropdown.Toggle id="input-group-dropdown-1" variant="light">
                More
              </Dropdown.Toggle>
              <Dropdown.Menu variant="light" className="dropdown-menu">
                {moreIcons?.map((item) => (
                  <Dropdown.Item key={`_evtfltr_${item}`}>
                    <SportsFilterItem
                      item={item}
                      selected={this.getCurrentPanel().sportFilters.includes(item)}
                      handleClick={this.toggleSportFiltersSelection}
                    />
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <div className="event-panel  d-flex justify-content-between ml-3 mt-2 mb-2 mr-3">
          <CustomInputText
            onChange={this.onSearchInput}
            keyPress={this.onSearchKeyDown}
            value={this.getCurrentPanel().searchText}
            onBtnClick={this.onSearchClick}
            tabIndex={this.props.tabIndex}
          />
          <div className="d-flex">
            <div className="event-date-filter ml-2" title={this.props.timezone + ' time (UTC: ' + moment(this.getCurrentPanel().fromDate).utc().format('LLL') + ')'}>
              <DatePicker
                selected={dtzWithLocalZone(moment(this.getCurrentPanel().fromDate).tz(this.props.timezone))}
                dateFormat="dd/MM/yyyy"
                strictParsing={true}
                popperPlacement="top-end"
                onChange={this.handleFromDateSelect}
                locale="en-GB"
                useWeekdaysShort={true}
                monthsShown={1}
                customInput={<CustomInputDate />}
                minDate={dtzWithLocalZone(moment().tz(this.props.timezone))}
                maxDate={dtzWithLocalZone(moment(this.getCurrentPanel().toDate).tz(this.props.timezone))}
              />
            </div>
            <div className="event-date-filter ml-2">
              <DatePicker
                selected={dtzWithLocalZone(moment(this.getCurrentPanel().toDate).tz(this.props.timezone))}
                dateFormat="dd/MM/yyyy"
                popperPlacement="top-end"
                strictParsing={true}
                onChange={this.handleToDateSelect}
                locale="en-GB"
                useWeekdaysShort={true}
                monthsShown={1}
                customInput={<CustomInputDate />}
                minDate={dtzWithLocalZone(moment(this.getCurrentPanel().fromDate).tz(this.props.timezone))}
              />
            </div>
          </div>
        </div>
        <div className="container p-0 pr-4 pl-1 w-100 mw-100 h-100">
          <div className="float-right sport-filters__reset fs-body1 ml-3">
            <u className="element--clickable" onClick={this.props.handleShowFilters}>
              Hide Filters
              <img
                src={images("./img/arrow-up.svg")}
                alt="arrow up"
              />
            </u>
          </div>
          <div className="float-right sport-filters__reset fs-body1">
            <u className="element--clickable" onClick={this.resetTabsFilters}>
              Reset Filters
            </u>
          </div>

          {this.props.tabIndex === 1 &&
            <div className="float-right fs-body1 pr-4">
              <h5 className="fs-body1 feature-name pr-1" >
                My TV Channels
              </h5>
              <CustomSwitch
                checked={this.getCurrentPanel().onlyPrefferedStations || false}
                className="event-filter__stations-switch"
                onChange={checked => this.handleOnlyPrefferedStations(checked)}
                onHandleColor={"#769BF9"}
                selectable={!this.props._favoriteStations.length == 0}
              />
            </div>
          }
          {this.props.tabIndex === 1 &&
            <div className="float-right fs-body1 pr-4">
              <h5 className="fs-body1 feature-name pr-1" >
                My Sports
              </h5>
              <CustomSwitch
                checked={this.sportsFiltersStatus()|| false}
                className="event-filter__stations-switch"
                onChange={checked => this.handleOnlyPrefferedSports(checked)}
                onHandleColor={"#769BF9"}
                selectable={!this.props._favoriteSports.length == 0}
              />
            </div>
          }
          {this.props.tabIndex === 1 &&
            <div className="float-right fs-body1 pr-4">
              <h5 className="fs-body1 feature-name pr-1" >
                My Teams
              </h5>
              <CustomSwitch
                checked={this.teamsFiltersStatus() || false}
                className="event-filter__stations-switch"
                onChange={checked => this.handleOnlyPrefferedTeams(checked)}
                onHandleColor={"#34b3f5"}
                selectable={!this.props._favoriteTeams.length == 0}
              />
            </div>
          }
          {this.props.tabIndex === 1 &&
            <div className="float-right fs-body1 pr-4">
              <h5 className="fs-body1 feature-name pr-1" >
                My Competitions
              </h5>
              <CustomSwitch
                checked={this.competitionsFiltersStatus()|| false}
                className="event-filter__stations-switch"
                onChange={checked => this.handleOnlyPrefferedCompetitions(checked)}
                onHandleColor={"#0084f4"}
                selectable={!this.props._favoriteCompetitions.length == 0}
              />
            </div>
          }
          <div className="form-inline">{this.renderUserFilters()}{this.renderTeamsFilters()}{this.renderCompetitionsFilters()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    _meetupsFilters: state.meetupsFilters,
    _fixturesFilters: state.fixturesFilters,
    _sports: state.sports,
    _favoriteSports: state.favoriteSports,
    _favoriteTeams: state.favoriteTeams,
    _favoriteCompetitions: state.favoriteCompetitions,
    _favoriteStations: state.favoriteStations,
    defaultStartDate: state.defaultStartDate,
    defaultEndDate: state.defaultEndDate,
    fixturesMinDate: state.fixturesMinDate
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMeetupsFilters: () => dispatch(actionCreators.getMeetupsFilters()),
    getFixturesFilters: () => dispatch(actionCreators.getFixturesFilters())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Eventpanel);
