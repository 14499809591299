import React, {
	useState,
	useEffect,
	useRef,
	forwardRef,
	useImperativeHandle,
} from "react";
import * as api from "../../utils/api";
import { SketchPicker } from "react-color";
import { Alert, Button, Container, Row } from "react-bootstrap";
import CustomLoader from "../../components/CustomLoader";
import rgbHex from "rgb-hex";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Select from "react-select";

const iframeStyle = {
	backgroundColor: "transparent",
	width: "100%",
	height: "100vh",
	marginTop: "20px",
	padding: "0px 0px",
};

let customWidgetColors = {
	widget_fixture_row_odd_background: "#FFFFFF",
	widget_fixture_row_even_background: "#333333",
	widget_font_color: "#000000",
	widget_competition_font_color: "#FFFFFF",
	widget_fixture_date_background: "#5A3A3A",
	widget_date_font_color: "#000000",
};

let customWidgetFont = {
	widget_events_font: "Asap",
};

const styles = {
	color: {
		width: "36px",
		height: "14px",
		borderRadius: "2px",
	},
	swatch: {
		padding: "5px",
		background: "#fff",
		borderRadius: "1px",
		boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
		display: "inline-block",
		cursor: "pointer",
	},
	popover: {
		position: "absolute",
		zIndex: "2",
	},
	cover: {
		position: "fixed",
		top: "0px",
		right: "0px",
		bottom: "0px",
		left: "0px",
	},
};

const CustomWidget = forwardRef(({ locationHash }, ref) => {
	const [fontColor, setFontColor] = useState("#000000");
	const [oddFixturesBackground, setOddFixturesBackground] = useState("#FFFFFF");
	const [evenFixturesBackground, setEvenFixturesBackground] =
		useState("#333333");
	const [fixtureDateBackground, setFixtureDateBackground] = useState("#000000");
	const [competitionFontColor, setCompetitionFontColor] = useState("#000000");
	const [dateFontColor, setDateFontColor] = useState("#000000");
	const [widgetPreview, setWidgetPreview] = useState("");
	const [displayFontColorPicker, setDisplayFontColorPicker] = useState(false);
	const [displayEvenFixturesColorPicker, setDisplayEvenFixturesColorPicker] =
		useState(false);
	const [displayOddFixturesColorPicker, setDisplayOddFixturesColorPicker] =
		useState(false);
	const [displayFixtureDateColorPicker, setDisplayFixtureDateColorPicker] =
		useState(false);
	const [
		displayCompetitionFontColorPicker,
		setDisplayCompetitionFontColorPicker,
	] = useState(false);
	const [displayDateFontColor, setDisplayDateFontColor] = useState(false);
	const [selectedFont, setSelectedFont] = useState("Asap");

	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	const [timer, setTimer] = useState(null);

	const availableFonts = [
		{ value: "Asap", label: "Asap" },
		{ value: "Cabin", label: "Cabin" },
		{ value: "Courgette", label: "Noto Mono" },
		{ value: "Dhurjati", label: "Dhurjati" },
		{ value: "Kanit", label: "Kanit" },
		{ value: "Merriweather", label: "Merriweather" },
		{ value: "Outfit", label: "Outfit" },
		{ value: "Playpen+Sans", label: "Playpen Sans" },
		{ value: "Roboto", label: "Roboto" },
		{ value: "Ubuntu", label: "Ubuntu" },
		{ value: "hwt-aetna", label: "Hwt-Aetna" },
		{ value: 'Gill+Sans', label: 'Gill Sans'},
		{ value: 'Cortado', label: 'Cortado'},
	];

	useImperativeHandle(ref, () => ({
		setPreview() {
			setLoading(true);
			getCustomWidgetPreview(customWidgetColors, customWidgetFont);
		},
	}));

	useEffect(() => {
		api.endpoints.getWidgetCustomColors((response) => {
			customWidgetColors = response;
			setFontColor(customWidgetColors.widget_font_color);
			setOddFixturesBackground(
				customWidgetColors.widget_fixture_row_odd_background
			);
			setEvenFixturesBackground(
				customWidgetColors.widget_fixture_row_even_background
			);
			setCompetitionFontColor(customWidgetColors.widget_competition_font_color);
			setFixtureDateBackground(
				customWidgetColors.widget_fixture_date_background
			);
			setDateFontColor(customWidgetColors.widget_date_font_color);
		});
	}, []);

	useEffect(() => {
		api.endpoints.getWidgetCustomFont((response) => {
			customWidgetFont = response;
			console.log(response);
			setSelectedFont(customWidgetFont.widget_events_font);
		});
	}, []);

	useEffect(() => {
		api.endpoints.getWidgetCustomFont((response) => {
                customWidgetFont = response;
                setSelectedFont(customWidgetFont.widget_events_font);
		});
	}, []);

	useEffect(() => {
		locationHash && getCustomWidgetPreview("#FFFFFF");
	}, [locationHash]);

	const getCustomWidgetPreview = (colors, font) => {
		api.endpoints.getCustomWidgetPreview(
			colors,
			font,
			locationHash,
			(response) => {
				setWidgetPreview(response);
				setLoading(false);
			}
		);
	};

	const saveWidgetColors = () => {
		api.endpoints.setCustomWidgetColor(
			customWidgetColors,
			 customWidgetFont,
			(response) => {
				setShow(true);
			}
		);
	};

	const handleColorChange = (color, element) => {
		color = "#" + rgbHex(color.r, color.g, color.b, color.a);
		switch (element) {
			case "font": {
				setFontColor(color);
				customWidgetColors.widget_font_color = color;
				break;
			}
			case "oddFixtures": {
				setOddFixturesBackground(color);
				customWidgetColors.widget_fixture_row_odd_background = color;
				break;
			}
			case "evenFixtures": {
				setEvenFixturesBackground(color);
				customWidgetColors.widget_fixture_row_even_background = color;
				break;
			}
			case "competitionFont": {
				setCompetitionFontColor(color);
				customWidgetColors.widget_competition_font_color = color;
				break;
			}
			case "fixtureDate": {
				setFixtureDateBackground(color);
				customWidgetColors.widget_fixture_date_background = color;
				break;
			}
			case "dateFont": {
				setDateFontColor(color);
				customWidgetColors.widget_date_font_color = color;
				break;
			}
		}

		clearTimeout(timer);

		const newTimer = setTimeout(() => {
			api.endpoints.getCustomWidgetPreview(
				customWidgetColors,
				 customWidgetFont,
				locationHash,
				(response) => {
					setWidgetPreview(response);
					setLoading(false);
				}
			);
		}, 500);

		setTimer(newTimer);
	};
	return (
		<Container style={{ marginTop: "20px", height: "100%" }}>
			<Alert
				show={show}
				variant="success"
				onClose={() => setShow(false)}
				style={{ marginTop: "10px" }}
				dismissible
			>
				<Alert.Heading>Custom event widget successfully updated</Alert.Heading>
			</Alert>
			<div className="col-12">
				<h4 className="font-color--grey font-weight-bold text-center">
					Style your custom event widget!
					<OverlayTrigger
						trigger="focus"
						placement="top"
						overlay={
							<Popover id="widget_preview_popover">
								<Popover.Title as="h3">Widget Live Preview</Popover.Title>
								<Popover.Content>
									For live preview, please publish the events in your list.
								</Popover.Content>
							</Popover>
						}
					>
						<Button variant="info" size="sm" className="ml-2 py-0">
							?
						</Button>
					</OverlayTrigger>
				</h4>
				<Row
					className="d-flex align-items-center"
					style={{ height: "100%", marginTop: "50px" }}
				>
					<div className="col-6 col-lg-3">
						<h6>Odd fixtures background: </h6>
					</div>
					<div className="col-6 col-lg-3">
						<div
							style={styles.swatch}
							onClick={() =>
								setDisplayOddFixturesColorPicker(!displayOddFixturesColorPicker)
							}
						>
							<div
								style={{ ...styles.color, background: oddFixturesBackground }}
							/>
						</div>
						{displayOddFixturesColorPicker ? (
							<div style={styles.popover}>
								<div
									style={styles.cover}
									onClick={() => setDisplayOddFixturesColorPicker(false)}
								/>
								<SketchPicker
									onChange={(color) => {
										handleColorChange(color.rgb, "oddFixtures");
									}}
									color={oddFixturesBackground}
								/>
							</div>
						) : null}
					</div>

					<div className="col-6 col-lg-3">
						<h6>Even fixtures background: </h6>
					</div>
					<div className="col-6 col-lg-3">
						<div
							style={styles.swatch}
							onClick={() =>
								setDisplayEvenFixturesColorPicker(
									!displayEvenFixturesColorPicker
								)
							}
						>
							<div
								style={{ ...styles.color, background: evenFixturesBackground }}
							/>
						</div>
						{displayEvenFixturesColorPicker ? (
							<div style={styles.popover}>
								<div
									style={styles.cover}
									onClick={() => setDisplayEvenFixturesColorPicker(false)}
								/>
								<SketchPicker
									onChange={(color) => {
										handleColorChange(color.rgb, "evenFixtures");
									}}
									color={evenFixturesBackground}
								/>
							</div>
						) : null}
					</div>

					<div className="col-6 col-lg-3">
						<h6>Fixture font color: </h6>
					</div>
					<div className="col-6 col-lg-3">
						<div
							style={styles.swatch}
							onClick={() => setDisplayFontColorPicker(!displayFontColorPicker)}
						>
							<div style={{ ...styles.color, background: fontColor }} />
						</div>
						{displayFontColorPicker ? (
							<div style={styles.popover}>
								<div
									style={styles.cover}
									onClick={() => setDisplayFontColorPicker(false)}
								/>
								<SketchPicker
									onChange={(color) => {
										handleColorChange(color.rgb, "font");
									}}
									color={fontColor}
								/>
							</div>
						) : null}
					</div>

					<div className="col-6 col-lg-3">
						<h6>Date background: </h6>
					</div>
					<div className="col-6 col-lg-3">
						<div
							style={styles.swatch}
							onClick={() =>
								setDisplayFixtureDateColorPicker(!displayFixtureDateColorPicker)
							}
						>
							<div
								style={{ ...styles.color, background: fixtureDateBackground }}
							/>
						</div>
						{displayFixtureDateColorPicker ? (
							<div style={styles.popover}>
								<div
									style={styles.cover}
									onClick={() => setDisplayFixtureDateColorPicker(false)}
								/>
								<SketchPicker
									onChange={(color) => {
										handleColorChange(color.rgb, "fixtureDate");
									}}
									color={fixtureDateBackground}
								/>
							</div>
						) : null}
					</div>

					<div className="col-6 col-lg-3">
						<h6>Competition font color: </h6>
					</div>
					<div className="col-6 col-lg-3">
						<div
							style={styles.swatch}
							onClick={() =>
								setDisplayCompetitionFontColorPicker(
									!displayCompetitionFontColorPicker
								)
							}
						>
							<div
								style={{ ...styles.color, background: competitionFontColor }}
							/>
						</div>
						{displayCompetitionFontColorPicker ? (
							<div style={styles.popover}>
								<div
									style={styles.cover}
									onClick={() => setDisplayCompetitionFontColorPicker(false)}
								/>
								<SketchPicker
									onChange={(color) => {
										handleColorChange(color.rgb, "competitionFont");
									}}
									color={competitionFontColor}
								/>
							</div>
						) : null}
					</div>

					<div className="col-6 col-lg-3">
						<h6>Date font color: </h6>
					</div>
					<div className="col-6 col-lg-3">
						<div
							style={styles.swatch}
							onClick={() => setDisplayDateFontColor(!displayDateFontColor)}
						>
							<div style={{ ...styles.color, background: dateFontColor }} />
						</div>
						{displayDateFontColor ? (
							<div style={styles.popover}>
								<div
									style={styles.cover}
									onClick={() => setDisplayDateFontColor(false)}
								/>
								<SketchPicker
									onChange={(color) => {
										handleColorChange(color.rgb, "dateFont");
									}}
									color={dateFontColor}
								/>
							</div>
						) : null}
					</div>
					<div className="col-6 col-lg-3">
						<h6>Select a font: </h6>
					</div>
					<Select
						className="col-6 col-lg-3"
						options={availableFonts}
						placeholder={selectedFont}
						onChange={(e) => {
							setSelectedFont(e);
							customWidgetFont.widget_events_font = e.value;
							clearTimeout(timer);

							const newTimer = setTimeout(() => {
								api.endpoints.getCustomWidgetPreview(
									customWidgetColors,
									customWidgetFont,
									locationHash,
									(response) => {
										setWidgetPreview(response);
										setLoading(false);
									}
								);
							}, 500);
							setTimer(newTimer);
						}}
					/>
					<hr style={{ width: "100%" }} />

					<div className="col-6 col-lg-6">
						<Button onClick={() => saveWidgetColors()}>Save widget</Button>
					</div>
				</Row>
				<Row>
					<CustomLoader loading={loading}>
						<iframe
							className="col-12"
							srcDoc={widgetPreview}
							style={iframeStyle}
							frameBorder="0"
						/>
					</CustomLoader>
				</Row>
			</div>
		</Container>
	);
});

export default CustomWidget;
