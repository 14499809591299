import React from "react";

const PasswordIcon = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.363525C4.36364 0.363525 1.25818 2.62534 0 5.81807C1.25818 9.0108 4.36364 11.2726 8 11.2726C11.6364 11.2726 14.7418 9.0108 16 5.81807C14.7418 2.62534 11.6364 0.363525 8 0.363525ZM8 9.45443C5.99273 9.45443 4.36364 7.82534 4.36364 5.81807C4.36364 3.8108 5.99273 2.18171 8 2.18171C10.0073 2.18171 11.6364 3.8108 11.6364 5.81807C11.6364 7.82534 10.0073 9.45443 8 9.45443ZM8 3.63625C6.79273 3.63625 5.81818 4.6108 5.81818 5.81807C5.81818 7.02534 6.79273 7.99989 8 7.99989C9.20727 7.99989 10.1818 7.02534 10.1818 5.81807C10.1818 4.6108 9.20727 3.63625 8 3.63625Z"
      fill="#DADADA"
    />
  </svg>
);

export default PasswordIcon;
